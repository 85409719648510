<template>
  <dl>
    <div v-if="isAvailable" class="flex w-full items-center gap-x-2 text-green">
      <dt class="flex-none">
        <span class="sr-only">delivery time</span>
        <svg-icon
          aria-hidden="true"
          class="h-4 w-4 stroke-2 lg:h-5 lg:w-5"
          name="Checkmark-Circle"
        />
      </dt>
      <dd class="text-sm font-normal leading-5 lg:text-base lg:leading-6">
        <CommonLabel
          :placeholder="{ deliveryTime: product?.deliveryTime?.name }"
          path="BWF/shop/productDetailPage/deliveryTime"
        >
        </CommonLabel>
      </dd>
    </div>
    <div v-else class="text-medium-grey">
      <dd class="text-sm font-normal leading-5 lg:text-base lg:leading-6">
        <span class="sr-only">product not available</span>
        <CommonLabel path="BWF/shop/productDetailPage/productNotAvailable"></CommonLabel>
      </dd>
    </div>
  </dl>
</template>
<script setup lang="ts">
import type { Product } from "@shopware-pwa/types";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import SvgIcon from "~/templates/elements/SvgIcon.vue";

const props = defineProps<{ product: Product; isAvailable: boolean }>();
const { product } = toRefs(props);
</script>
