export default function () {
  const trackAccountModification = (description) => {
    try {
      const gtm = useGtm(); // auto-imported by the module

      gtm?.trackEvent({
        event: "gx.account_modification",
        event_name: "account_modification",
        event_source: "source_code",
        account_modification: { description: description },
      });
    } catch (e) {
      console.error(e);
    }
  };
  return { trackAccountModification };
}
