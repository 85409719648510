<template>
  <div>
    <div class="mb-6 grid gap-6">
      <HeadlineComponent class="text-2xl font-medium text-dark-blue md:text-3xl" tag="h2">
        <CommonLabel path="BWF/shop/shared/alreadyRegistered" />
      </HeadlineComponent>
      <button class="btn btn-blue md:btn-size-normal w-full" @click="loginMsal">
        <CommonLabel path="BWF/shop/checkout/mybrita/loginToMybrita" />
      </button>
    </div>

    <hr class="my-6 xl:my-12" />
    <div class="col-span-full mb-4 text-lg font-medium md:text-2xl">
      <CommonLabel path="BWF/shop/checkout/mybrita/registerNow" />
    </div>

    <ShopBenefits
      class="mb-6 text-sm md:text-base xl:mb-7"
      :benefits="marketSetup?.myBritaBenefits"
    />

    <button class="btn btn-blue w-full" @click="signUpMsal">
      <CommonLabel path="BWF/shop/checkout/mybrita/createAccount" />
    </button>
  </div>
</template>

<script setup lang="ts">
import HeadlineComponent from "~/templates/components/cms/HeadlineComponent.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import ShopBenefits from "~/templates/components/shop/product/ShopBenefits.vue";
const { $msal } = useNuxtApp();
const { marketSetup } = useMarketSetup();

const loginMsal = () => {
  $msal.signIn();
};
const signUpMsal = () => {
  $msal.signUp();
};
</script>
