<template>
  <div>
    <div class="mb-2 text-lg font-medium xl:my-4 xl:text-lg">
      <CommonLabel path="BWF/shop/account/orderDetails/returnInformations"></CommonLabel>
    </div>
    <div class="mb-2 text-sm font-medium xl:my-4 xl:text-sm">
      <CommonLabel path="BWF/shop/account/orderDetails/returnDate"></CommonLabel>
    </div>
    <div class="mb-2 text-base xl:my-4 xl:text-base">
      {{ returnDate }}
    </div>
    <div v-if="parcelCount > 1" class="notification is-blue col-span-full mb-0 mt-4 gap-4">
      <SvgIcon class="h-6 w-6" name="Tooltip"></SvgIcon>
      <CommonLabel
        class="prose-black"
        path="BWF/shop/account/orderDetails/exchangeBox/returnLabelTamNotification"
      ></CommonLabel>
    </div>
    <div v-else-if="retoureLabel" class="mb-2 text-base font-medium xl:my-4 xl:text-xl">
      <a
        :href="`${cleanedShopwareEndpoint}/${retoureLabel}`"
        class="text-link flex items-end gap-2 text-base no-underline"
        download
        target="_blank"
      >
        <CommonLabel
          class="truncate leading-none no-underline"
          path="BWF/shop/account/orderDetails/returnLabel"
        ></CommonLabel>
        <SvgIcon class="h-5 w-5" name="Download"></SvgIcon
      ></a>
    </div>
    <div v-else class="notification is-blue col-span-full mb-0 mt-4 gap-4">
      <SvgIcon class="h-6 w-6" name="Tooltip"></SvgIcon>
      <CommonLabel
        class="prose-black"
        path="BWF/shop/account/orderDetails/exchangeBox/returnLabelAvailabilityNotification"
      ></CommonLabel>
    </div>
  </div>
</template>
<script setup>
import CommonLabel from "~/templates/components/CommonLabel.vue";
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import { useShopConfiguration } from "~/composables/shop/useShopConfiguration";
const { apiInstance } = useShopwareContext();
const { cleanedShopwareEndpoint } = useShopConfiguration();

const props = defineProps(["order"]);

const parcelCount = props?.order?.extensions?.returns?.[0]?.extensions?.parcelCount.value;

const retoureLabel = ref("");

onMounted(async () => {
  if (props.order?.extensions?.returns?.length) {
    try {
      const { data } = await apiInstance.invoke.get(`/store-api/order/${props.order?.id}/label`);
      retoureLabel.value = data?.data?.orderLabelUrl;
    } catch (e) {
      console.error(e);
    }
  }
});

const returnDate = computed(() => {
  return new Date(props.order?.extensions?.returns?.[0]?.requestedAt ?? "").toLocaleDateString(
    (typeof navigator !== "undefined" && navigator.language) || "de-DE",
  );
});
</script>
