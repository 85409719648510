<template>
  <div
    v-if="components.length > 0"
    ref="rootElement"
    :class="{ 'is-mounted': isMounted }"
    class="relative overflow-hidden"
  >
    <EditableComponent
      v-for="(childContent, i) in mapNodes(content)"
      :key="childContent['@id']"
      :content="childContent"
      :index="i"
      :is-active="isActive(i)"
    />

    <div
      v-if="components.length > 1"
      class="pointer-events-none absolute top-0 z-10 aspect-[6/7] max-h-screen w-full overflow-hidden md:aspect-video"
    >
      <div class="absolute bottom-0 w-full">
        <div
          class="container pointer-events-auto flex w-full gap-2 overflow-auto pb-6 md:pb-4 xl:pb-10"
        >
          <button
            v-for="(childContent, i) in components"
            :key="childContent['@id']"
            :class="{ 'is-active': i === index, 'animation-paused': isMenuOpen }"
            class="btn h-8 shrink-0 rounded-md bg-black bg-opacity-50 px-4 py-0 text-xs text-white last:mr-8 md:h-10 md:py-3 md:text-sm md:last:mr-0"
            @animationend="nextCarouselSlide"
            @click="navigateTo(i)"
          >
            <span class="z-10">{{ childContent.navText }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { mapNodes } from "~/utils/helper/magnolia";
import { EditableComponent } from "@magnolia/vue-editor";
const props = defineProps(["content"]);
const index = ref(0);
const DEFAULT_INTERVAL = "0ms";
const components = computed(() => {
  return mapNodes(props?.content);
});

const interval = ref(props.content?.interval ? `${props.content.interval}ms` : DEFAULT_INTERVAL);

const isActive = (carouselIndex) => {
  return parseInt(index.value) === parseInt(carouselIndex);
};

const isMounted = ref(false);

const { isMenuOpen } = useMainNavigationState();

onMounted(() => {
  // this is flag to prevent animations from playing (going nuts) before hydration has finished
  isMounted.value = true;
});

const nextCarouselSlide = () => {
  if (interval.value !== DEFAULT_INTERVAL && index?.value < components.value?.length - 1) {
    index.value++;
  } else if (interval.value !== DEFAULT_INTERVAL) {
    index.value = 0;
  }
};

const navigateTo = (i) => {
  index.value = i;
};
</script>

<style scoped>
.btn {
  @apply relative overflow-hidden;
}

.btn.is-active {
  @apply bg-white bg-opacity-50 text-black;
}

.btn:before {
  @apply bg-white;
  @apply absolute inset-0 right-full;
  content: "";
}

.btn:hover:before {
  @screen md {
    animation-play-state: paused;
  }
}

.animation-paused:before {
  animation-play-state: paused;
}

.is-mounted .btn.is-active:before {
  animation-name: button-progress;
  animation-duration: v-bind(interval);
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}

@keyframes button-progress {
  0% {
    right: 100%;
  }

  100% {
    right: 0;
  }
}
</style>
