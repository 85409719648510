<template>
  <div class="stage-content-component">
    <div
      class="stage-content relative aspect-2/3 max-h-[80vh] w-full overflow-hidden rounded-b-xl md:aspect-[7/4] md:rounded-b-none"
    >
      <div class="absolute inset-0 bg-light-grey">
        <PictureElement
          :breakpoints="BREAKPOINTS"
          :img="image"
          img-class=" absolute inset-0  block object-cover w-full h-full"
          loading="eager"
        ></PictureElement>
        <div
          :class="contentToTailwindClasses({ opacity: opacity })"
          class="absolute inset-0 bg-black to-transparent"
        ></div>
        <DotPattern
          ref="dots"
          :variant="dotPattern"
          class="absolute inset-0 block h-full w-full"
        ></DotPattern>
        <div class="text-container container relative h-full w-full py-8">
          <div
            class="responsive-gap flex h-full w-full flex-col items-start justify-end md:justify-center xl:w-8/12"
          >
            <div
              v-if="subline"
              :class="[containsImage(image) ? 'text-white' : 'text-black']"
              class="text-sm font-medium xl:text-lg"
            >
              {{ subline }}
            </div>
            <HeadlineComponent
              v-if="headline"
              :class="[containsImage(image) ? 'text-white' : 'text-dark-blue']"
              :headline="headline"
              class="text-3xl font-medium md:text-4xl xl:text-6xl"
              tag="h1"
            ></HeadlineComponent>
            <CallToActionComponent
              v-if="link?.label ?? false"
              :link="link"
              :stretch-link="stretchLink"
              alignment="left"
            ></CallToActionComponent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { DIMENSIONS } from "~/utils/helper/imaging";
import DotPattern from "~/templates/elements/DotPattern.vue";
import { containsImage } from "~/utils/helper/magnolia";
import { contentToTailwindClasses } from "~/utils/helper/tailwind";
import CallToActionComponent from "~/templates/components/cms/CallToActionComponent.vue";
import HeadlineComponent from "~/templates/components/cms/HeadlineComponent.vue";
import PictureElement from "~/templates/elements/PictureElement.vue";

defineProps([
  "subline",
  "headline",
  "image",
  "link",
  "dotPattern",
  "opacity",
  "backgroundColor",
  "stretchLink",
]);

const BREAKPOINTS = {
  start: DIMENSIONS["start"].ratio["2/3"],
  sm: DIMENSIONS["sm"].ratio["2/3"],
  md: DIMENSIONS["md"].ratio["7/4"],
  xl: DIMENSIONS["xl"].ratio["7/4"],
  container: DIMENSIONS["container"].ratio["7/4"],
  page: DIMENSIONS["page"].ratio["7/4"],
};
</script>

<style scoped>
.stage-content {
  @apply transition-all duration-500;
}

.text-container {
  @screen md {
    padding-top: var(--fixed-header-height, 0px);
  }
}
</style>
