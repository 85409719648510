// @ts-nocheck
// TODO figure out how to properly type tauschbox extension
import { computed, type ComputedRef, type Ref } from "vue";
import { _useContext } from "~/composables/shop/_useContext";
import { getProductEndpoint } from "@shopware-pwa/api-client";

export type UseExchangeBoxReturn = {
  containsExchangeBox: ComputedRef<boolean>;
  exchangeBoxTotal: ComputedRef<number>;
  totalDepositPrice: ComputedRef<number>;
  amountCylinders: ComputedRef<number>;
  exchangeBoxVariants: ComputedRef<never>;
  maxAmount: ComputedRef<number>;
  nextAmount: ComputedRef<number>;
  nextPrice: ComputedRef<number>;
  tauschboxPrice: ComputedRef<number>;
  depositLineItemLabel: ComputedRef<string>;
  tauschbox: ComputedRef<never>;
};

export async function useExchangeBox(): Promise<UseExchangeBoxReturn> {
  const { apiInstance } = useShopwareContext();

  const { product } = useProduct();

  const _exchangeBoxVariants: Ref<never> = _useContext("exchangeBoxVariants");

  const loadExchangeBoxVariants = async () => {
    const { data: exchangeBoxVariants } = await useAsyncData(
      "exchangeBoxVariants-" + product.value.parentId,
      async () => {
        const { data } = await apiInstance.invoke.post(getProductEndpoint(), {
          filter: [
            {
              type: "equals",
              field: "parentId",
              // either variant OR product
              value: product.value?.parentId ?? product.value?.id,
            },
            {
              type: "range",
              field: "customFields.contract_amount_cylinders",
              parameters: {
                gte: 0,
              },
            },
          ],
          includes: {
            product: ["id", "productNumber", "customFields", "calculatedPrice", "seoUrls"],
          },
        });

        return data.elements;
      },
    );
    return exchangeBoxVariants.value;
  };

  const containsExchangeBox = computed(() =>
    product?.value?.customFields.hasOwnProperty("contract_amount_cylinders"),
  );
  if (containsExchangeBox.value && !_exchangeBoxVariants.value) {
    _exchangeBoxVariants.value = await loadExchangeBoxVariants();
  }
  const tauschbox = computed(() => product?.value?.extensions?.tauschbox);
  const maxAmount: ComputedRef<number> = computed(() =>
    _exchangeBoxVariants.value.reduce?.((maxAmount: number, obj: never) => {
      return Math.max(maxAmount, obj?.customFields?.["contract_amount_cylinders"] ?? 0);
    }, 0),
  );

  const nextPrice: ComputedRef<number> = computed(() => {
    // maybe this is supposed to be an amount lookup !?
    const nextIndex = Math.max(maxAmount.value, 0);
    const nextVariant = _exchangeBoxVariants.value?.find(
      (variant: never) => variant.customFields?.["contract_amount_cylinders"] === nextIndex,
    );
    return nextVariant?.calculatedPrice?.totalPrice;
  });

  return {
    containsExchangeBox,
    amountCylinders: computed(() => product?.value?.customFields?.contract_amount_cylinders),
    exchangeBoxTotal: computed(() => tauschbox.value?.totalTauschboxPrice),
    totalDepositPrice: computed(() => tauschbox.value?.totalDepositPrice),
    tauschboxPrice: computed(() => tauschbox.value?.tauschboxPrice),
    depositLineItemLabel: computed(() => tauschbox.value?.depositLineItemLabel),
    exchangeBoxVariants: computed(() => _exchangeBoxVariants.value),
    nextAmount: computed(() => Math.max(maxAmount.value, 0)),
    nextPrice,
    maxAmount,
    tauschbox,
  };
}
