<template>
  <div v-if="exchangeBoxVariants?.length > 1" class="exchange-box-variant-configurator">
    <div
      v-if="isLoading"
      class="absolute inset-0 z-10 flex items-center justify-end bg-white/75"
    ></div>

    <CommonLabel
      class="font-medium xl:text-2xl"
      path="BWF/shop/productDetailPage/exchangeBox/configuratorHeadline"
      tag="div"
    ></CommonLabel>
    <CommonLabel
      class="mt-6 font-medium xl:text-2xl"
      path="BWF/shop/productDetailPage/exchangeBox/cylinderHeadline"
      tag="div"
    ></CommonLabel>
    <CommonLabel
      class="mt-2 xl:text-2xl"
      path="BWF/shop/productDetailPage/exchangeBox/cylinderQuestion"
      tag="div"
    ></CommonLabel>
    <CommonLabel
      class="mt-1 text-sm text-medium-grey"
      path="BWF/shop/productDetailPage/exchangeBox/cylinderQuestionHint"
      tag="div"
    ></CommonLabel>

    <fieldset class="mt-2 block flex-1 lg:mt-6">
      <div class="switch-container">
        <SelectField
          v-model="selectedOption"
          :options="selectableOptions"
          class="w-full"
          label="Anzahl Zylinder zuhause"
        />
      </div>
    </fieldset>
  </div>
</template>

<script setup>
import SelectField from "~/templates/elements/form/SelectField.vue";
import { useExchangeBox } from "~/composables/shop/useExchangeBoxProduct";
import CommonLabel from "~/templates/components/CommonLabel.vue";

const emit = defineEmits(["change", "beforeChange"]);
const isLoading = ref(false);

const { product } = useProduct();

const { exchangeBoxVariants, maxAmount } = await useExchangeBox();

const selectedOption = ref(product.value.id);

const selectableOptions = computed(() =>
  exchangeBoxVariants.value?.map?.((option) => ({
    value: option.id,
    label: 1 + (maxAmount.value - parseInt(option.customFields["contract_amount_cylinders"])),
  })),
);

watch(selectedOption, () => {
  emit("beforeChange");
  const selectedVariant = exchangeBoxVariants.value?.find(
    (variant) => variant.id === selectedOption.value,
  );
  emit("change", selectedVariant);
});
</script>
