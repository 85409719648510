<template>
  <div class="responsive-gap grid grid-cols-1 md:grid-cols-2">
    <EditableComponent
      v-for="(childContent, i) in components"
      :key="childContent['@id']"
      :content="childContent"
      :index="i"
    />
  </div>
</template>

<script setup>
import { EditableComponent } from "@magnolia/vue-editor";
const props = defineProps(["content"]);

const components = computed(() => {
  return props.content?.["@nodes"]?.map((nodeName) => props.content[nodeName]) ?? [];
});
</script>
