<template>
  <svg
    id="eVOdNSIz4br1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 375 667"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
  >
    <g id="eVOdNSIz4br2_ts" transform="translate(44,468) scale(0.2,0.2)">
      <ellipse
        id="eVOdNSIz4br2"
        rx="52"
        ry="52"
        transform="translate(0,0)"
        opacity="0"
        fill="#fff"
        fill-opacity="0.35"
        stroke-width="0"
      />
    </g>
    <g id="eVOdNSIz4br3_ts" transform="translate(44,356) scale(0.2,0.2)">
      <ellipse
        id="eVOdNSIz4br3"
        rx="52"
        ry="52"
        transform="translate(0,0)"
        opacity="0"
        fill="#84d0f5"
        fill-opacity="0.65"
        stroke-width="0"
      />
    </g>
    <g id="eVOdNSIz4br4_ts" transform="translate(380,244) scale(0.2,0.2)">
      <ellipse
        id="eVOdNSIz4br4"
        rx="52"
        ry="52"
        transform="translate(0,0)"
        opacity="0"
        fill="#fff"
        fill-opacity="0.35"
        stroke-width="0"
      />
    </g>
    <g id="eVOdNSIz4br5_ts" transform="translate(156,20) scale(0.2,0.2)">
      <ellipse
        id="eVOdNSIz4br5"
        rx="52"
        ry="52"
        transform="translate(0,0)"
        opacity="0"
        fill="#fff"
        fill-opacity="0.35"
        stroke-width="0"
      />
    </g>
  </svg>
</template>
<style scoped>
#eVOdNSIz4br2_ts {
  animation: eVOdNSIz4br2_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eVOdNSIz4br2_ts__ts {
  0% {
    transform: translate(44px, 468px) scale(0.2, 0.2);
  }
  30% {
    transform: translate(44px, 468px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  80% {
    transform: translate(44px, 468px) scale(1, 1);
  }
  100% {
    transform: translate(44px, 468px) scale(1, 1);
  }
}
#eVOdNSIz4br2 {
  animation: eVOdNSIz4br2_c_o 2000ms linear 1 normal forwards;
}
@keyframes eVOdNSIz4br2_c_o {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#eVOdNSIz4br3_ts {
  animation: eVOdNSIz4br3_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eVOdNSIz4br3_ts__ts {
  0% {
    transform: translate(44px, 356px) scale(0.2, 0.2);
  }
  20% {
    transform: translate(44px, 356px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  70% {
    transform: translate(44px, 356px) scale(1, 1);
  }
  100% {
    transform: translate(44px, 356px) scale(1, 1);
  }
}
#eVOdNSIz4br3 {
  animation: eVOdNSIz4br3_c_o 2000ms linear 1 normal forwards;
}
@keyframes eVOdNSIz4br3_c_o {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#eVOdNSIz4br4_ts {
  animation: eVOdNSIz4br4_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eVOdNSIz4br4_ts__ts {
  0% {
    transform: translate(380px, 244px) scale(0.2, 0.2);
  }
  10% {
    transform: translate(380px, 244px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  60% {
    transform: translate(380px, 244px) scale(1, 1);
  }
  100% {
    transform: translate(380px, 244px) scale(1, 1);
  }
}
#eVOdNSIz4br4 {
  animation: eVOdNSIz4br4_c_o 2000ms linear 1 normal forwards;
}
@keyframes eVOdNSIz4br4_c_o {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#eVOdNSIz4br5_ts {
  animation: eVOdNSIz4br5_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eVOdNSIz4br5_ts__ts {
  0% {
    transform: translate(156px, 20px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(156px, 20px) scale(1, 1);
  }
  100% {
    transform: translate(156px, 20px) scale(1, 1);
  }
}
#eVOdNSIz4br5 {
  animation: eVOdNSIz4br5_c_o 2000ms linear 1 normal forwards;
}
@keyframes eVOdNSIz4br5_c_o {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
</style>
