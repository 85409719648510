<template>
  <form @submit.prevent="invokeSave()">
    <div class="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2">
      <SelectField
        v-model="addressFormState.salutationId"
        :label="addressFormLabels?.salutationLabel"
        :options="salutationOptions(getSalutations)"
        class="form-field-grey"
        name="salutationId"
      />

      <InputField
        v-model="addressFormState.firstName"
        :error-message="addressFormVuelidate?.firstName?.$errors?.[0]?.$message"
        :label="addressFormLabels?.firstNameLabel"
        :placeholder="addressFormLabels?.firstNamePlaceholder"
        class="form-field-grey col-start-1"
        name="first-name"
        required="required"
        @blur="addressFormVuelidate.firstName.$touch()"
      />

      <InputField
        v-model="addressFormState.lastName"
        :error-message="addressFormVuelidate?.lastName?.$errors?.[0]?.$message"
        :label="addressFormLabels?.lastNameLabel"
        :placeholder="addressFormLabels?.lastNamePlaceholder"
        class="form-field-grey"
        name="last-name"
        required="required"
        @blur="addressFormVuelidate.lastName.$touch()"
      />

      <InputField
        v-model="addressFormState.street"
        :error-message="addressFormVuelidate?.street?.$errors?.[0]?.$message"
        :label="addressFormLabels?.streetAndHouseNoLabel"
        :placeholder="addressFormLabels?.streetAndHouseNoPlaceholder"
        autocomplete="street-address"
        class="form-field-grey"
        name="street-address"
        required="required"
        @blur="addressFormVuelidate.street.$touch()"
      />

      <InputField
        v-model="addressFormState.additionalAddressLine1"
        :error-message="addressFormVuelidate?.additionalAddressLine1?.$errors?.[0]?.$message"
        :label="addressFormLabels?.addressSuffixLabel"
        :placeholder="addressFormLabels?.addressSuffixPlaceholder"
        class="form-field-grey"
        name="additionalAddressLine1"
        @blur="addressFormVuelidate.additionalAddressLine1.$touch()"
      />

      <InputField
        v-model="addressFormState.zipcode"
        :error-message="addressFormVuelidate?.zipcode?.$errors?.[0]?.$message"
        :label="addressFormLabels?.postalCodeLabel"
        :placeholder="addressFormLabels?.postalCodePlaceholder"
        autocomplete="postal-code"
        class="form-field-grey"
        name="postal-code"
        required="required"
        @blur="addressFormVuelidate.zipcode.$touch()"
      />
      <InputField
        v-model="addressFormState.city"
        :error-message="addressFormVuelidate?.city?.$errors?.[0]?.$message"
        :label="addressFormLabels?.cityLabel"
        :placeholder="addressFormLabels?.cityPlaceholder"
        class="form-field-grey"
        name="city"
        required="required"
        @blur="addressFormVuelidate.city.$touch()"
      />

      <SelectField
        v-model="addressFormState.countryId"
        :error-message="addressFormVuelidate?.countryId?.$errors?.[0]?.$message"
        :label="addressFormLabels?.countryLabel"
        :options="selectableCountries"
        autocomplete="country-name"
        class="form-field-grey"
        name="country"
        required="required"
        @blur="addressFormVuelidate.countryId.$touch()"
      ></SelectField>
    </div>
    <div class="mt-4 text-sm text-gray-500 xl:mt-8">
      <CommonLabel path="BWF/shop/shared/mandatoryFieldsLabel"></CommonLabel>
    </div>
    <div class="mt-6 flex flex-col gap-4 text-right xl:mt-8 xl:flex-row xl:gap-6">
      <button class="btn btn-blue" type="submit">
        <CommonLabel path="BWF/shop/shared/saveChangesLabel"></CommonLabel>
      </button>
      <button class="btn btn-ghost" type="button" @click="$emit('close-modal')">
        <CommonLabel path="BWF/shop/shared/cancel"></CommonLabel>
      </button>
    </div>
  </form>
</template>

<script setup lang="ts">
import type { CustomerAddress } from "@shopware-pwa/types";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import InputField from "~/templates/elements/form/InputField.vue";
import SelectField from "~/templates/elements/form/SelectField.vue";
import { useVuelidate } from "@vuelidate/core";
import { salutationOptions } from "~/utils/helper/shop/salutations";
import { countryOptions } from "~/utils/helper/shop/countries";
import type { UnwrapNestedRefs } from "vue";
const props = defineProps<{
  address: CustomerAddress;
  filterShippingCountries?: boolean;
}>();

const { getCountries } = useCountries();
const getActiveCountries = computed(() =>
  props.filterShippingCountries
    ? getCountries.value.filter((country) => country.shippingAvailable)
    : getCountries.value,
);

const selectableCountries = computed(() => countryOptions(getActiveCountries.value));

const emit = defineEmits<{
  (
    e: "address-submit",
    formsState: UnwrapNestedRefs<{
      zipcode: string | undefined;
      firstName: string;
      lastName: string;
      city: string | undefined;
      street: string | undefined;
      salutationId: string;
      countryId: string | undefined;
      additionalAddressLine1: string;
    }>,
  ): void;
  (e: "close-modal"): void;
}>();

const { getLabels } = useCommonLabels();

const addressFormLabels = getLabels("/BWF/shop/shared/addressForm/", [
  "identicalToBillingAddress",
  "salutationLabel",
  "chooseSalutationLabel",
  "firstNameLabel",
  "firstNamePlaceholder",
  "lastNameLabel",
  "lastNamePlaceholder",
  "streetAndHouseNoLabel",
  "streetAndHouseNoPlaceholder",
  "postalCodeLabel",
  "postalCodePlaceholder",
  "cityLabel",
  "cityPlaceholder",
  "addressSuffixLabel",
  "addressSuffixPlaceholder",
  "countryLabel",
]);

const addressFormState = reactive({
  firstName: props?.address?.firstName ?? "",
  lastName: props?.address?.lastName ?? "",
  street: !props?.address?.customFields?.dummyAddress ? props.address?.street : "",
  zipcode: !props?.address?.customFields?.dummyAddress ? props.address?.zipcode : "",
  city: !props?.address?.customFields?.dummyAddress ? props.address?.city : "",
  countryId: !props?.address?.customFields?.dummyAddress ? props.address?.countryId : "",
  additionalAddressLine1: props.address?.additionalAddressLine1 ?? "",
  salutationId: props.address?.salutationId ?? "",
});

const {
  firstNameValidators,
  lastNameValidators,
  streetNumberValidators,
  addressAdditionalValidators,
  cityValidators,
  zipCodeValidators,
  requiredValidator,
} = await useValidation();

const rules = computed(() => ({
  firstName: {
    ...requiredValidator,
    ...firstNameValidators,
  },
  lastName: {
    ...requiredValidator,
    ...lastNameValidators,
  },
  street: {
    ...requiredValidator,
    ...streetNumberValidators,
  },
  zipcode: {
    ...requiredValidator,
    ...zipCodeValidators,
  },
  city: {
    ...requiredValidator,
    ...cityValidators,
  },
  additionalAddressLine1: {
    ...addressAdditionalValidators,
  },
  countryId: {
    ...requiredValidator,
  },
}));

const addressFormVuelidate = useVuelidate(rules, addressFormState);

const { getSalutations } = useSalutations();

const invokeSave = async (): Promise<void> => {
  addressFormVuelidate.value.$touch();
  if (await addressFormVuelidate.value.$validate()) {
    emit("address-submit", addressFormState);
  }
};
</script>
