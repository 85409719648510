<template>
  <main class="container mt-12">
    <LoadingElement v-if="isLoading && !isEditMode" class="aspect-2/1 w-full"></LoadingElement>
    <template v-else>
      <div class="flex flex-col gap-16">
        <EditableArea
          v-if="isExpired || isEditMode"
          :content="doiExpiredErrorArea"
          :custom-view="FeedbackArea"
        />
        <EditableArea
          v-if="(!isExpired && isInvalid) || isEditMode"
          :content="errorArea"
          :custom-view="FeedbackArea"
        />

        <template v-if="(!isInvalid ?? !isExpired) || isEditMode">
          <EditableArea
            v-if="!informationSubmitted || isEditMode"
            :content="successArea"
            :custom-view="FeedbackArea"
          ></EditableArea>
          <EditableArea
            v-if="informationSubmitted || isEditMode"
            :content="successUserDataArea"
            :custom-view="FeedbackArea"
          ></EditableArea>
          <form
            v-if="hasMissingFields && !informationSubmitted"
            class="flex flex-col gap-4 rounded-xl border border-light-grey p-6"
            @submit.prevent="onSubmitInformation()"
          >
            <CommonLabel
              path="BWF/components/newsletter/personalData"
              class="text-lg font-medium"
            ></CommonLabel>

            <div class="grid gap-4 xl:grid-cols-3 xl:gap-2">
              <SelectField
                v-if="isMissing('salutation')"
                v-model="state.salutation"
                :label="formLabels?.salutationLabel"
                class="form-field-grey"
                name="salutation"
                :options="selectableSalutations"
              ></SelectField>

              <InputField
                v-if="isMissing('firstname')"
                v-model="state.firstName"
                class="form-field-grey w-full"
                name="firstname"
                :label="formLabels?.firstnameLabel"
                :placeholder="formLabels?.firstnamePlaceholder"
                :error-message="$validatedPersonals?.firstName?.$errors?.[0]?.$message"
                @blur="$validatedPersonals.firstName.$touch()"
              ></InputField>

              <InputField
                v-if="isMissing('lastname')"
                v-model="state.lastName"
                class="form-field-grey w-full"
                :label="formLabels?.lastnameLabel"
                :placeholder="formLabels?.lastnamePlaceholder"
                :error-message="$validatedPersonals?.lastName?.$errors?.[0]?.$message"
                @blur="$validatedPersonals.lastName.$touch()"
              ></InputField>
              <div
                v-if="isMissing('salutation') || isMissing('firstname') || isMissing('lastname')"
                class="col-span-full text-sm"
              >
                <CommonLabel path="/BWF/components/newsletter/doi/personalHint"> </CommonLabel>
              </div>
            </div>

            <div class="grid gap-4 xl:grid-cols-3 xl:gap-y-2">
              <InputField
                v-if="isMissing('birthdate')"
                v-model="state.birthdate"
                class="form-field-grey"
                name="birthdate"
                min="1900-01-01"
                :max="today"
                :label="formLabels?.birthdateLabel"
                :placeholder="formLabels?.birthdatePlaceholder"
                type="date"
                :error-message="$validatedPersonals?.birthdate?.$errors?.[0]?.$message"
                @blur="$validatedPersonals.birthdate.$touch()"
              ></InputField>
              <InputField
                v-model="state.middlename"
                name="middlename"
                type="text"
                label="middlename"
                placeholder="middlename"
                autocomplete="off"
                class="form-field-umbrella"
              />
              <div v-if="isMissing('birthdate')" class="col-span-full text-sm">
                <CommonLabel path="BWF/components/newsletter/doi/birthdayHint"> </CommonLabel>
              </div>
            </div>

            <button class="btn btn-blue w-auto self-start">
              <CommonLabel path="BWF/components/newsletter/doi/form/sendButtonLabel"> </CommonLabel>
            </button>
          </form>
        </template>
      </div>
    </template>
  </main>
  <EditableArea v-if="main" :content="main" />
</template>

<script setup>
import { EditableArea } from "@magnolia/vue-editor";
import InputField from "~/templates/elements/form/InputField.vue";
import useBritaMiddleware from "~/composables/useBritaMiddleware";

import { useJwt } from "@vueuse/integrations/useJwt";
import LoadingElement from "~/templates/elements/LoadingElement.vue";
import { isEditMode as evaluateEditMode } from "~/utils/content/magnolia";
import FeedbackArea from "~/templates/areas/FeedbackArea.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import SelectField from "~/templates/elements/form/SelectField.vue";
import useCommonLabels from "~/composables/useCommonLabels";
defineOptions({
  inheritAttrs: false,
});

defineProps([
  "title",
  "metadata",
  "main",
  "successArea",
  "errorArea",
  "successUserDataArea",
  "doiExpiredErrorArea",
]);
const { doiConfirmation, addCustomerInformation } = useBritaMiddleware();
const { getLabels } = useCommonLabels();
const { pushError, pushSuccess } = useNotifications();
const { newsletterDoiTracking } = useNewsletterTracking();
import { useVuelidate } from "@vuelidate/core";

const { firstNameValidators, lastNameValidators, birthdateValidator } = await useValidation();

const rules = computed(() => ({
  firstName: {
    ...firstNameValidators,
  },
  lastName: {
    ...lastNameValidators,
  },
  birthdate: {
    ...birthdateValidator,
  },
}));

const formLabels = getLabels("/BWF/components/newsletter/doi/form/", [
  "salutationLabel",
  "firstnameLabel",
  "firstnamePlaceholder",
  "lastnameLabel",
  "lastnamePlaceholder",
  "birthdateLabel",
  "birthdatePlaceholder",
  "successMessage",
  "errorMessage",
  "noDataErrorMessage",
]);

const salutationLabels = getLabels("/BWF/form/shared/salutations/", ["mr", "mrs", "diverse"]);
const selectableSalutations = [
  ...(Object.entries(salutationLabels ?? {})?.map(([key, value]) => ({
    value: key,
    label: value,
  })) ?? []),
];

const { query } = useRoute();
const token = ref("");
const tokenPayload = ref({});
const isEditMode = ref(evaluateEditMode());
const isExpired = ref(true);
const isInvalid = ref(false);
const isLoading = ref(true);

// sample token
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjU0NmVkZTUxLWJhMTQtZWUxMS04ZjZkLTAwMjI0ODlmOGU5NyIsIk1pc3NpbmdGaWVsZHMiOiJiaXJ0aGRhdGUiLCJFeHBpcmVzT24iOiIyMDIzLTA5LTI1VDAwOjAwOjAwLjAwMDAwMDBaIn0.AfTx_CU8fZdEuUHpPRmsDjG3oTYmyc6gKIum7UlVaro
// sample expired token:
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjE1NzBhZmQ3LWJmNmQtZWUxMS05YWU3LTAwMjI0OGEyODVmNSIsIk1pc3NpbmdGaWVsZHMiOiJmaXJzdG5hbWUsbGFzdG5hbWUsYnJpX3NhbHV0YXRpb25fb3B0LGJpcnRoZGF0ZSIsIkV4cGlyZXNPbiI6IjIwMjMtMTAtMTlUMDA6MDA6MDAuMDAwMDAwMFoifQ.1JTs681oB-jjrfXzKDlnKE7oZU3aWxy60gvvpU5RGVg
token.value = query.token;
const { payload: parsedPayload } = useJwt(token);
tokenPayload.value = parsedPayload.value;

if (parsedPayload.value?.["ExpiresOn"]) {
  const expiryDate = new Date(tokenPayload.value?.["ExpiresOn"]);
  isExpired.value = Date.now() > expiryDate.getTime();
}

const state = reactive({
  firstName: "",
  lastName: "",
  salutation: "",
  birthdate: "",
  middlename: "",
});

const $validatedPersonals = useVuelidate(rules, state);

const today = new Date().toISOString().slice(0, -14);

const isMissing = (fieldName) => {
  return isEditMode.value || tokenPayload.value?.["MissingFields"]?.includes(fieldName);
};

const hasMissingFields = computed(
  () => isEditMode.value || tokenPayload.value?.["MissingFields"]?.length > 0,
);

const informationSubmitted = ref(false);
const onSubmitInformation = async () => {
  try {
    const hasData = Object.values(state).some((value) => !!value);
    if (!hasData) {
      pushError(formLabels?.noDataErrorMessage);
      return;
    }

    await addCustomerInformation({
      doiToken: token.value,
      middlename: state.middlename,
      ...(state.salutation ? { salutation: state.salutation } : {}),
      ...(state.firstName ? { firstname: state.firstName } : {}),
      ...(state.lastName ? { lastname: state.lastName } : {}),
      ...(state.birthdate ? { birthday: state.birthdate } : {}),
    });
    pushSuccess(formLabels?.successMessage);
    informationSubmitted.value = true;
  } catch (e) {
    pushError(formLabels?.errorMessage);
  }
};

onMounted(async () => {
  if (token.value && isExpired.value === false) {
    try {
      // validates the token
      await doiConfirmation(token.value);
      newsletterDoiTracking();
    } catch (e) {
      isInvalid.value = true;
    }
  } else {
    isInvalid.value = true;
  }
  isLoading.value = false;
});
</script>
