<template>
  <div v-if="!promoLineItems.length">
    <CommonLabel
      class="mb-2 block font-medium xl:mb-4"
      path="BWF/shop/checkout/promo-code/headline"
    ></CommonLabel>
    <div class="flex flex-row items-center gap-4">
      <InputField
        v-model="promoCode"
        :error-message="promotionErrorMsg"
        :label="inputLabel"
        :placeholder="inputPlaceholder"
        class="form-field-grey xl:form-field-white w-full"
        name="promoCode"
        type="text"
        @focusin="trackActivateCouponField()"
        @keydown.enter="addAndValidatePromoCode()"
      ></InputField>
      <button class="text-link h-10 text-black" @click="addAndValidatePromoCode()">
        <CommonLabel path="BWF/shop/checkout/promo-code/add"></CommonLabel>
      </button>
    </div>
  </div>
  <div v-else class="flex content-start rounded-lg bg-white p-4">
    <SvgIcon class="mr-4 h-5 w-5 shrink-0" name="Tooltip"></SvgIcon>
    <CommonLabel path="BWF/shop/checkout/promo-code/existingCode"></CommonLabel>
  </div>
</template>

<script setup lang="ts">
import InputField from "~/templates/elements/form/InputField.vue";
import useCommonLabels from "~/composables/useCommonLabels";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import { getPromotiontLineItems } from "~/utils/helper/shop/cart";

const { getLabelByPath } = useCommonLabels();
const inputPlaceholder = getLabelByPath("BWF/shop/checkout/promo-code/inputPlaceholder");
const inputLabel = getLabelByPath("BWF/shop/checkout/promo-code/inputLabel");
const { trackAddCouponEvent, trackAddErrorEvent, trackActivateCouponField } = useCouponTracking();

const { getTranslatedCartErrorMessage } = useShopwareNotifications();
const { addPromotionCode, cart } = useCart();

const promoLineItems = computed(() => getPromotiontLineItems(cart.value?.lineItems ?? []));

const promotionErrorMsg = computed(() => {
  const [, foundError] =
    Object.entries(cart?.value?.errors ?? {}).find(([messageKey]) =>
      messageKey.includes("promotion-not"),
    ) || [];
  return foundError ? getTranslatedCartErrorMessage(foundError) : "";
});

const addAndValidatePromoCode = async () => {
  await addPromotionCode(promoCode.value);

  if (promotionErrorMsg.value && !promoLineItems.value.length) {
    trackAddErrorEvent(promotionErrorMsg.value);
  } else {
    trackAddCouponEvent(promoCode.value);
    promoCode.value = "";
  }
};

const promoCode = ref<string>("");
</script>
