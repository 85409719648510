<template>
  <LinkComponent
    :title="text"
    :link="link"
    class="whitespace"
    optional-link-classes="hover:text-dark-blue cursor-pointer"
  >
    <header class="text-sm">
      {{ text }}
    </header>
  </LinkComponent>
</template>

<script>
import LinkComponent from "~/templates/components/cms/LinkComponent.vue";

export default {
  name: "FooterHeadline",
  components: { LinkComponent },
  props: { text: String, link: Object },
};
</script>

<style scoped></style>
