<template>
  <div
    class="fade-content"
    :class="{ 'fade-to-almost-transparent': parseInt(animationVariant) === 1 }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { scrollableComponent } from "~/utils/mixins/scrollableComponent";

export default {
  name: "FadeContainer",
  components: {},
  mixins: [scrollableComponent],
  props: ["animationVariant", "animationOffset"],
  emits: ["percentage"],
  data() {
    return {
      percentage: 0.5,
      animationsDelay: "-50s",
    };
  },
  mounted() {
    this.onScrollUpdate();
  },

  methods: {
    onScrollUpdate() {
      const progress = this.getViewPortIntersectionRatio(
        this.$el,
        this.$props.animationOffset ?? 0,
      );
      this.percentage = Math.min(Math.max(progress, 0), 1);
      this.animationsDelay = `${this.percentage * -100}s`;
      this.$emit("percentage", this.percentage);
    },
  },
};
</script>

<style scoped>
.fade-content {
  animation-name: fadeInOut;
  animation-duration: 100s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-play-state: paused;
  animation-delay: v-bind(animationsDelay);
}

.fade-to-almost-transparent {
  animation-name: almostTransparent;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.5;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes almostTransparent {
  0% {
    opacity: 0.1;
  }
  30% {
    opacity: 0.2;
  }
  40% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }
  70% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.1;
  }
}
</style>
