<template>
  <!-- Image gallery / Slider -->
  <div
    class="product-gallery relative -mx-[var(--container-padding)] aspect-square lg:col-span-6 lg:mx-0"
  >
    <Swiper
      :modules="[Pagination, Navigation]"
      :navigation="{ prevEl: '.swiper-navigation.prev', nextEl: '.swiper-navigation.next' }"
      :pagination="{ clickable: true }"
      class="h-full w-full xl:overflow-hidden xl:rounded-lg xl:bg-light-grey"
      @slide-change="(swiper) => trackProductPageSlider(sortedProductMedia[swiper.activeIndex])"
    >
      <SwiperSlide v-for="(media, index) of sortedProductMedia" :key="index">
        <ProductVideo
          v-if="
            //@ts-ignore
            media?.videoId
          "
          :loading="index === 0 ? 'eager' : 'lazy'"
          :src="
            //@ts-ignore
            media?.videoId
          "
          :thumbnail-media="
            //@ts-ignore
            media?.thumbnailMedia
          "
          class="h-full w-full object-contain"
        ></ProductVideo>
        <ProductImage
          v-else
          :class="{ 'opacity-50': !product.available }"
          :loading="index === 0 ? 'eager' : 'lazy'"
          :product-media="media.media"
          :product-name="getProductName({ product })"
          fallback-class="w-full h-full object-contain"
          main-class="w-full h-full object-contain"
        ></ProductImage>
      </SwiperSlide>

      <div
        class="pointer-events-none absolute inset-4 isolate z-10 flex items-center justify-between"
      >
        <div class="swiper-navigation prev pointer-events-auto cursor-pointer">
          <svg-icon class="h-4 w-4 -rotate-90" name="Arrow-Up"></svg-icon>
        </div>
        <div class="swiper-navigation next pointer-events-auto cursor-pointer">
          <svg-icon class="h-4 w-4 rotate-90" name="Arrow-Up"></svg-icon>
        </div>
      </div>
    </Swiper>
  </div>
</template>

<script setup lang="ts">
import { getProductName } from "@shopware-pwa/helpers-next";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper/modules";
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import { getSortedPdpMediaWithVideos } from "~/utils/helper/shop/media";
import ProductVideo from "~/templates/elements/ProductVideo.vue";
import ProductImage from "~/templates/elements/ProductImage.vue";

const { product } = useProduct();

const sortedProductMedia = computed(() => getSortedPdpMediaWithVideos(product.value));

//@ts-ignore
const { trackProductPageSlider } = useNavigationTracking();
</script>

<!--<style src="~/assets/css/thirdparty/swiper.css"></style>-->
<style>
@import url("~/assets/css/thirdparty/swiper.css");

.product-gallery {
  @apply transition-all lg:sticky;
  @screen lg {
    top: var(--sticky-header-height, 0px);
  }

  .swiper-navigation {
    @apply transition-opacity duration-700;
  }

  .swiper-navigation.swiper-button-disabled {
    @apply pointer-events-none opacity-0;
  }

  .swiper-pagination-bullets {
    --swiper-pagination-bottom: theme("spacing.4");
    --swiper-pagination-bullet-horizontal-gap: theme("spacing.3");
  }
}
</style>
