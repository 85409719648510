<script setup lang="ts">
import PageNotification from "~/templates/partials/PageNotification.vue";

const { notifications, removeOne } = useNotifications();
</script>
<template>
  <div data-testid="notification-container" class="notification-container">
    <PageNotification
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
      @click:close="removeOne(notification.id)"
    />
  </div>
</template>

<style scoped>
.notification-container {
  @apply pointer-events-none fixed top-20 z-60 md:top-32;
  right: var(--container-page-offset, 0px);
  padding-left: var(--container-page-offset, 0px);
}
</style>
