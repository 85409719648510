<template>
  <div class="rounded-md border p-4">
    <div class="grid grid-cols-[min-content_1fr] gap-4">
      <ProductImage
        :product-media="contractImage"
        class="w-20"
        fallback-class="product-image flex-shrink-0 object-contain object-center aspect-[74/100] rounded-lg bg-light-grey p-2"
        main-class="product-image flex-shrink-0 object-contain object-center aspect-[74/100] rounded-lg bg-light-grey"
      ></ProductImage>

      <div class="flex flex-col gap-2">
        <SubscriptionStatus :status="contract?.status" class="self-start" />

        <div class="grid grid-cols-2">
          <div class="flex flex-col">
            <div class="text-xl font-medium">
              {{ contractName }}
              <CommonLabel
                v-if="isSubscription"
                path="BWF/shop/account/subscriptions/contract/subscriptionLabel"
              ></CommonLabel>
              <!--                            <span v-if="isSubscription"> Abo </span>-->
            </div>
            <div v-if="isSubscription" class="text-sm text-medium-grey">
              <CommonLabel path="BWF/shop/account/orderDetails/orderNumber"></CommonLabel
              >&nbsp;<span>{{ subscriptionOrderNumber }}</span>
            </div>
          </div>

          <div class="hidden justify-self-end xl:block">
            <TextSelectField
              ref="subscriptionActionSelectField"
              v-model="selectedMenuOption"
              :label="menuActionLabel"
              :options="menuOptions"
            >
              <CommonLabel
                v-if="isSubscription"
                class="text-sm"
                path="BWF/shop/account/subscriptions/contract/subscriptionActions"
              ></CommonLabel>
              <CommonLabel
                v-else
                class="text-sm"
                path="BWF/shop/account/subscriptions/contract/exchangeboxActions"
              ></CommonLabel>
            </TextSelectField>
          </div>
        </div>

        <hr class="my-4 xl:my-0" />

        <div class="grid gap-4 hyphens-auto text-sm xl:grid-cols-3">
          <div>
            <h4 class="mb-2 font-medium">
              <CommonLabel path="BWF/shop/shared/shippingAddress"></CommonLabel>
            </h4>

            <div>
              <h5>
                {{
                  `${contract?.shippingAddress?.firstName} ${contract?.shippingAddress?.lastName}`
                }}
              </h5>
              <div>{{ contract?.shippingAddress?.street }}</div>
              <div>{{ contract?.shippingAddress?.additionalAddressLine1 }}</div>

              <div>
                {{ contract?.shippingAddress?.zipcode }} {{ contract?.shippingAddress?.city }}
              </div>
              <div>{{ contract?.shippingAddress?.country?.name }}</div>
            </div>
          </div>

          <ContractSubscriptionInformation v-if="isSubscription" :contract="contract" />

          <ContractExchangeboxInformation v-else :contract="contract" :plan="plan" />

          <div>
            <h4 class="mb-2 font-medium">
              <CommonLabel
                path="BWF/shop/account/subscriptions/contract/paymentLabel"
              ></CommonLabel>
            </h4>
            <div>
              {{ contract?.paymentMethod?.name }}
            </div>

            <CommonLabel
              v-if="creditCardNumber"
              :placeholder="{ creditCardNumber }"
              path="BWF/shop/account/subscriptions/contract/creditCardNumber"
            />

            <div v-if="paymentExpired || paymentExpiringSoon" class="mt-2 text-red-500">
              <CommonLabel
                v-if="paymentExpired"
                path="BWF/shop/account/subscriptions/contract/paymentExpired"
              />
              <CommonLabel
                v-if="paymentExpiringSoon"
                path="BWF/shop/account/subscriptions/contract/paymentExpiringSoon"
              />
              <button class="text-link" @click="$emit('contract-change-payment')">
                <CommonLabel path="BWF/shop/account/subscriptions/contract/updatePaymentMethod" />
              </button>
            </div>

            <span v-else>{{ contract?.customFields?.paymentDetails?.consumerAccount }}</span>
          </div>
        </div>
      </div>
      <div class="col-span-full mt-4 xl:hidden">
        <hr />
        <TextSelectField
          ref="orderActionSelectField"
          v-model="selectedMenuOption"
          :label="menuActionLabel"
          :options="menuOptions"
          class="justify-center p-4 pb-0"
        >
          <CommonLabel
            v-if="isSubscription"
            class="text-sm"
            path="BWF/shop/account/subscriptions/contract/subscriptionActions"
          ></CommonLabel>
          <CommonLabel
            v-else
            class="text-sm"
            path="BWF/shop/account/subscriptions/contract/exchangeboxActions"
          ></CommonLabel>
        </TextSelectField>
      </div>
    </div>

    <ModalComponent :controller="addressModal">
      <template #headline>
        <p class="block text-center text-lg md:text-2xl">
          <CommonLabel
            path="BWF/shop/account/subscriptions/contract/subscriptionActionAddressEdit"
          ></CommonLabel>
        </p>
      </template>
      <AccountAddressForm
        :address="contract?.shippingAddress"
        @close-modal="addressModal.close()"
        @address-submit="onAddressSave"
      ></AccountAddressForm>
    </ModalComponent>
  </div>
</template>

<script setup lang="ts">
import TextSelectField from "~/templates/elements/form/TextSelectField.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import AccountAddressForm from "~/templates/components/shop/account/AccountAddressForm.vue";
import ModalComponent from "~/templates/components/ModalComponent.vue";
import { isCancelled } from "~/utils/helper/shop/subscription";
import ProductImage from "~/templates/elements/ProductImage.vue";
import ContractSubscriptionInformation from "~/templates/elements/ContractSubscriptionInformation.vue";
import ContractExchangeboxInformation from "~/templates/elements/ContractExchangeboxInformation.vue";

const { getLabels } = useCommonLabels();
const { apiInstance } = useShopwareContext();
const { pushSuccess, pushError } = useNotifications();

const props = defineProps(["contract", "cancellationModalArea", "cancellationSuccessModalArea"]);

const emit = defineEmits<{
  (e: "contract-update"): void;
  (e: "contract-cancel"): void;
  (e: "subscription-contract-cancel"): void;
  (e: "contract-change-payment"): void;
}>();

const commonLabels = getLabels("BWF/shop/account/subscriptions/contract", [
  "exchangeboxActions",
  "exchangeboxActionCancel",
  "subscriptionActions",
  "subscriptionActionCancel",
  "subscriptionChangePayment",
  "cancellationReasons",
  "subscriptionActionAddressEdit",
  "setAddressSuccess",
]);

const plan = computed(() => props?.contract?.contractPlan);

const contractName = computed(() => {
  return isSubscription.value
    ? `${props.contract?.contractProducts[0]?.product?.translated?.name} (${props.contract?.contractLabel})`
    : plan.value?.name;
});

const contractImage = computed(
  () =>
    props?.contract?.contractPlan?.product?.cover?.media ??
    props?.contract?.contractProducts?.[0]?.product?.cover?.media,
);

// TODO cleanup / move to composable ?
const isSubscription = computed(() => props?.contract?.contractType == "subscription");
const subscriptionOrderNumber = computed(
  () => props?.contract?.webContractOrders?.[0]?.order.orderNumber,
);

//
const addressModal = useModal();

const menuActionLabel = computed(() => {
  if (isSubscription.value) {
    return commonLabels.subscriptionActions;
  } else {
    return commonLabels.exchangeboxActions;
  }
});

const menuOptions = computed(() => {
  return [
    {
      label: commonLabels.subscriptionActionAddressEdit,
      value: "addressEdit",
      disabled: isCancelled(props?.contract),
    },
    {
      label: commonLabels.subscriptionChangePayment,
      value: "payment",
      disabled: isCancelled(props?.contract),
    },
    {
      label: isSubscription.value
        ? commonLabels.subscriptionActionCancel
        : commonLabels.exchangeboxActionCancel,
      value: isSubscription.value ? "subscription-cancel" : "cancel",
      disabled: isCancelled(props?.contract),
    },
  ];
});
const creditCardNumber = computed(() => {
  return props?.contract?.customFields?.paymentDetails?.cardNumber ?? false;
});

const subscriptionActionSelectField = ref(null);
const selectedMenuOption = ref("");
watch(selectedMenuOption, (option) => {
  switch (option) {
    case "addressEdit":
      addressModal.open();
      break;
    case "cancel":
      emit("contract-cancel");
      break;
    case "subscription-cancel":
      emit("subscription-contract-cancel");
      break;
    case "payment":
      emit("contract-change-payment");
      break;
  }
  // reset options
  //@ts-ignore
  subscriptionActionSelectField.value?.reset();
});

//@ts-ignore
const onAddressSave = async (formState) => {
  try {
    //@ts-ignore
    const { error } = await apiInstance.invoke.patch(
      `/store-api/contract/${props.contract?.id}/address/${props.contract?.shippingAddress?.id}`,
      formState,
    );
    if (error?.value) {
      pushError(error?.value?.messages?.[0]?.detail);
    } else {
      pushSuccess(commonLabels.setAddressSuccess);
    }
    addressModal.close();
    emit("contract-update");
  } catch (e) {
    //@ts-ignore
    pushError(e?.messages?.[0]?.detail);
  }
};

const paymentExpiringSoon = ref(false);
const paymentExpired = ref(false);

const checkExpiration = () => {
  const expiryState = props?.contract?.extensions?.creditCardExpiryState?.value;
  if (expiryState === "expired" || props?.contract?.status === "payment_update_requested") {
    paymentExpired.value = true;
  }
  if (expiryState === "expiringSoon") {
    paymentExpiringSoon.value = true;
  }
};

onMounted(() => {
  checkExpiration();
});
</script>
