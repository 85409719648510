/**
 * @param {Object} options
 * @param {*} options.item - Required item parameter.
 * @param {*} [options.activeBulkChild] - Optional parameter that can be anything.
 * @param {*} [options.eShopSet] - Optional parameter that can be anything.
 * @param {*} [options.hasEShopSet] - Optional parameter that can be anything.
 * @param {*} [options.subscriptionChooserActive] - Optional parameter that can be anything.
 * @param {*} [options.activeSubscriptionIntervalId] - Optional parameter that can be anything.
 * @param {*} [options.subscriptionTotalPrice] - Optional parameter that can be anything.
 */
export default function ({
  item,
  activeBulkChild = null,
  eShopSet = null,
  hasEShopSet = null,
  subscriptionChooserActive = null,
  activeSubscriptionIntervalId = null,
  subscriptionTotalPrice = null,
}) {
  // provide tracking information and items array
  const { currency } = useSessionContext();
  const gtm = useGtm(); // auto-imported by the module
  const trackingContext = useTrackingContext();
  const { saveItemToStorage, getItemFromStorage, transformItem } = useItemsArray();
  const deposit = item?.value?.extensions?.tauschbox?.totalDepositPrice;

  const trackItemAddToCart = () => {
    try {
      const storageItem = getItemFromStorage(item.value);
      storageItem.quantity = activeBulkChild?.value?.quantity ?? 1;
      let bulkTotalPrice = activeBulkChild?.value?.totalPrice;
      gtm?.trackEvent({ ecommerce: null });

      let value = bulkTotalPrice || item.value?.calculatedPrice?.totalPrice || storageItem.price;
      if (subscriptionChooserActive.value) {
        storageItem.price = subscriptionTotalPrice.value;
        value = subscriptionTotalPrice.value;
        storageItem["item_subscription"] = true;
        storageItem["item_subscription_id"] = activeSubscriptionIntervalId.value;
      }
      gtm?.trackEvent({
        event: "eec.add_to_cart",
        event_name: "add_to_cart",
        event_source: "source_code",
        ecommerce: {
          ...(deposit ? { deposit } : {}),
          currency: currency.value?.isoCode,
          value,
          items: [storageItem],
        },
      });
    } catch (e) {
      console.error("eec.add_to_cart", e);
    }
  };

  const trackItemView = () => {
    try {
      const storageItem = getItemFromStorage(item.value);

      if (subscriptionChooserActive.value) {
        storageItem.price = subscriptionTotalPrice.value;
        storageItem["item_subscription"] = true;
        storageItem["item_subscription_id"] = activeSubscriptionIntervalId.value;
      }

      gtm?.trackEvent({ ecommerce: null });
      gtm?.trackEvent({
        event: "eec.view_item",
        event_name: "view_item",
        event_source: "source_code",
        ecommerce: {
          ...(deposit ? { deposit } : {}),
          currency: currency.value?.isoCode,
          value: storageItem.price,
          items: [storageItem],
        },
      });
    } catch (e) {
      console.error("eec.view_item", e);
    }
  };
  const trackItemSelect = () => {
    try {
      const { items, item_list_id, item_list_name } = trackingContext.value ?? {};

      let trackingProduct =
        getItemFromStorage(item.value) ||
        items?.find((arrayItem) => arrayItem?.item_variant === item.value.id);
      if (!trackingProduct) {
        // product was not in listing response
        trackingProduct = transformItem(item.value, -1, item_list_name, item_list_id);
      }
      gtm?.trackEvent({ ecommerce: null });
      gtm?.trackEvent({
        event: "eec.select_item",
        event_name: "select_item",
        ecommerce: {
          item_list_id,
          item_list_name,
          items: [toRaw(trackingProduct)],
        },
      });
      // store item under its
      saveItemToStorage(trackingProduct);
    } catch (e) {
      console.error("eec.select_item", e);
    }
  };

  if (import.meta.client && hasEShopSet?.value) {
    try {
      const trackingProduct = getItemFromStorage(item.value);
      // patch tracking item with eshopset item
      watch(
        eShopSet,
        () => {
          trackingProduct.price = eShopSet.value.eshopSetPriceTotal;
          // save patched item
          saveItemToStorage(trackingProduct);
        },
        { immediate: true },
      );
    } catch (e) {
      console.error("eec.patchEshopSetItem", e);
    }
  }

  return { trackItemSelect, trackItemView, trackItemAddToCart };
}
