<template>
  <div
    :class="[
      !separatedColumns || hasNoColumns ? contentToTailwindClasses({ backgroundColor }) : '',
      (!separatedColumns || hasNoColumns) && hasValue(backgroundColor)
        ? 'rounded-2xl border p-6'
        : '',
    ]"
    class="prose max-w-none hyphens-auto"
  >
    <div v-if="hasNoColumns" v-html="richText"></div>
    <div v-else class="responsive-gap-x grid gap-y-6 md:grid-cols-2 xl:flex">
      <div
        :class="[
          separatedColumns ? contentToTailwindClasses({ backgroundColor }) : '',
          separatedColumns && hasValue(backgroundColor) ? 'rounded-2xl border p-6' : '',
        ]"
        class="flex-1"
        v-html="richText"
      ></div>
      <div
        v-for="(column, index) in textColumns"
        :key="index"
        :class="[
          separatedColumns ? contentToTailwindClasses({ backgroundColor }) : '',
          separatedColumns && hasValue(backgroundColor) ? 'rounded-2xl border p-6' : '',
        ]"
        class="flex-1"
        v-html="column"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { contentToTailwindClasses } from "~/utils/helper/tailwind";
import { hasValue } from "~/utils/helper/magnolia";

const props = defineProps(["richText", "textColumns", "backgroundColor", "separatedColumns"]);

const hasNoColumns = computed(() => {
  return (props.textColumns ?? []).length === 0;
});
</script>
