import { computed } from "vue";
import type { Ref } from "vue";
import type { UseProductReturn } from "@shopware-pwa/composables-next";
import type { Product, PropertyGroup } from "@shopware-pwa/types";
import { _useContext } from "~/composables/shop/_useContext";

export function useProduct(
  product?: Ref<Product> | Product,
  configurator?: Ref<PropertyGroup[]> | PropertyGroup[],
): UseProductReturn & { changeConfigurator(variant?: Partial<PropertyGroup[]>): void } {
  const _product = _useContext("product", { context: product });
  if (!_product.value) {
    throw new Error("Product Context missing");
  }

  const _configurator = _useContext("configurator", {
    context: product && configurator,
  });

  function changeVariant(variant?: Partial<Product>) {
    if (!variant) {
      console.warn("[useProduct][changeVariant]: Provided variant is empty");
      return;
    }
    _product.value = Object.assign({}, _product.value, variant);
  }

  function changeConfigurator(configurator: PropertyGroup[]) {
    if (!configurator) {
      console.warn("[useProduct][changeConfigurator]: Provided configurator is empty");
      return;
    }
    _configurator.value = configurator;
  }

  return {
    product: computed(() => _product.value),
    configurator: computed(() => _configurator.value),
    changeVariant,
    changeConfigurator,
  };
}
