<template>
  <PageLayout>
    <section
      v-if="mgnlNotFound"
      class="container mt-8 flex w-full flex-1 flex-col items-center justify-center gap-4 overflow-x-clip rounded-xl bg-light-grey p-8 xl:p-18"
    >
      <div class="prose text-center">
        <h1 class="text-dark-blue">We're experiencing technical difficulties</h1>
        <p>
          Our team is diligently working to resolve this as quickly as possible. While we sort this
          out, we encourage you to try refreshing the page in a few moments or come back later to
          see if things are back to normal.
        </p>
      </div>

      <template v-if="!!mgnlAuthor">
        <NuxtLink class="btn btn-blue" @click="gotoLastKnownMarket">Go to last Market</NuxtLink>
        <span class="text-xs">Only for debug purposes / magnolia authors ;) </span>
      </template>
    </section>

    <EditablePage
      v-if="hasCustomErrorContent"
      :config="config"
      :content="content"
      :fallback-component="DefaultFallbackComponent"
    />
    <main
      v-if="query.debug"
      class="container mt-8 flex w-full flex-1 flex-col items-center justify-center gap-4 overflow-x-clip rounded-xl bg-light-grey p-8 xl:p-18"
      role="main"
    >
      <h2 class="text-4xl font-medium text-dark-blue">{{ error?.statusCode }}</h2>
      <div v-if="error?.message" class="prose break-all">{{ error?.message }}</div>
      <div class="max-w-full overflow-x-auto" v-html="error?.stack"></div>
    </main>
  </PageLayout>
</template>

<script setup>
import { DefaultFallbackComponent, EditablePage } from "@magnolia/vue-editor";
import PageLayout from "~/templates/partials/PageLayout";
import { provide } from "vue";
import { componentMappings } from "~/utils/config/components";
import { getTemplate } from "~/utils/helper/magnolia";

const { query } = useRoute();

const error = useError();

const config = {
  componentMappings,
  fallbackComponent: DefaultFallbackComponent,
};

const mgnlNotFound = ref(false);
const { contentPath, magnoliaAuthorRedirect } = useMagnoliaContext();
// load market setup
const { marketSetup } = useMarketContent();
const { content, loadContent } = useMagnoliaContent();

const hasCustomErrorContent = computed(() => getTemplate(content.value) === "d2c-lm:pages/error");
//
provide("fallbackComponent", DefaultFallbackComponent);

if (!contentPath.value && error?.value?.statusCode === 412) {
  mgnlNotFound.value = true;
} else {
  const authenticationProblem = error.value?.statusCode === 401 || error.value?.statusCode === 403;
  let errorPath;
  if (error.value?.statusCode === 404 && marketSetup.value?.pageNotFoundPage) {
    errorPath = marketSetup.value.pageNotFoundPage;
  } else if (authenticationProblem && marketSetup.value?.unauthorizedPage) {
    errorPath = marketSetup.value.unauthorizedPage;
  } else if (error.value?.statusCode === 500 && marketSetup.value?.internalServerErrorPage) {
    errorPath = marketSetup.value.internalServerErrorPage;
  }
  // load content by error path
  if (errorPath) {
    await loadContent(errorPath);
  } else {
    mgnlNotFound.value = true;
  }
}

// TODO we need to load error content here again because we must also support runtime errors (like products not being loaded -> 404 which is sam same but different from magnolia)

const {
  public: { mgnlAuthor },
} = useRuntimeConfig();
const gotoLastKnownMarket = () => {
  magnoliaAuthorRedirect();
};

useHead({ title: content.value?.title ?? marketSetup?.fallbackTitle });
</script>

<script>
export default {
  name: "ErrorPage",
};
</script>
