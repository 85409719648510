<template>
  <div class="md:col-span-2">
    <LinkComponent :link="link">
      <div class="image-tile relative h-full rounded-xl bg-light-grey px-5 pb-6 pt-3">
        <div class="flex h-full flex-col gap-1.5">
          <PictureElement
            :breakpoints="imageBreakpoints"
            :img="image"
            class="my-auto"
            img-class="mx-auto object-contain w-auto"
          />
          <h3 class="relative mt-auto text-center text-sm font-medium xl:mt-0 xl:text-lg">
            {{ headline }}
          </h3>
        </div>
      </div>
    </LinkComponent>
  </div>
</template>

<script setup>
import PictureElement from "~/templates/elements/PictureElement.vue";
import LinkComponent from "~/templates/components/cms/LinkComponent.vue";
import { DIMENSIONS_FIXED } from "~/utils/helper/imaging";
defineProps(["headline", "image", "link"]);
const imageBreakpoints = {
  start: DIMENSIONS_FIXED["200x200"],
};
</script>
