<template>
  <div class="product-stage grid grid-cols-1 lg:grid-cols-12">
    <!-- Image gallery / Slider -->
    <NuxtErrorBoundary>
      <ProductStageGallery></ProductStageGallery>
    </NuxtErrorBoundary>

    <div class="relative mt-4 lg:col-span-4 lg:col-start-8 lg:mt-0">
      <!-- Product info -->
      <div :class="{ 'pointer-events-none animate-pulse': isLoading }" class="relative">
        <div class="lg:col-span-2">
          <ProductBadge class="hidden lg:mb-4 lg:inline-flex" />
          <div v-if="product?.manufacturer?.name" class="mb-1 text-sm font-medium">
            {{ product?.manufacturer?.name }}
          </div>
          <div class="flex gap-x-5">
            <h1 class="text-2xl font-normal text-black lg:text-3xl" v-html="productName"></h1>
            <DiscountRateLabel
              v-if="isMobile && hasBulkPrice && activeBulkChild.displayDiscountRate"
              :discount-rate="activeBulkChild.displayDiscountRate"
              class="mt-1 self-start"
            />
          </div>

          <div
            v-if="productDataDeliveryNote"
            class="mt-1 text-sm font-normal"
            v-html="productDataDeliveryNote"
          ></div>
          <EShopSetPrice v-if="hasEShopSet" />
          <SubscriptionProductPrice
            v-else-if="hasSubscriptionConfiguration && subscriptionChooserActive"
          />
          <BulkProductPrice
            v-else-if="hasBulkPrice"
            :active-bulk-child="activeBulkChild"
            :product="product"
            class="mt-1 lg:mt-2"
          />
          <ProductPrice v-else :product="product" class="mt-1 lg:mt-2" />
          <ProductDeliveryScope
            v-if="deliveryScope"
            :product-delivery-scope="deliveryScope"
            class="mt-4 lg:mt-8"
          />
          <slot name="introText"></slot>
          <slot name="howTo"></slot>
        </div>

        <div v-if="hasBulkPrice && !hasSubscriptionConfiguration" class="mt-4 space-y-2 md:mt-6">
          <CommonLabel path="BWF/shop/shared/tableQuantity"></CommonLabel>
          <span>:</span>
          <SelectField v-model="productQuantity" :options="bulkChooserOptions"></SelectField>
        </div>

        <div v-if="containsExchangeBox" class="flex flex-col gap-2">
          <ExchangeBoxVariantConfigurator
            v-if="!(exchangeBoxArea?.hideConfigurator === true)"
            class="mt-6 xl:mt-10"
            @change="handleVariantChange"
            @before-change="beforeVariantChange"
          />
          <ProductExchangeBoxInformation class="mt-8">
            <template #depositInfo>
              <slot name="depositInfo"></slot>
            </template>
            <template #priceInfo>
              <slot name="priceInfo"></slot>
            </template>
          </ProductExchangeBoxInformation>
        </div>

        <ProductSubscriptionConfigurator
          v-else-if="hasSubscriptionConfiguration"
          :option-button-default="true"
          :show-option-name="true"
          @change="handleVariantChange"
          @before-subscription-change="beforeVariantChange"
        />

        <ProductVariantConfigurator
          v-else
          :option-button-default="true"
          :show-option-name="true"
          class="mt-6"
          @change="handleVariantChange"
          @before-change="beforeVariantChange"
        />

        <EShopSetConfigurator v-if="hasEShopSet" class="mt-7 xl:mt-10" />

        <div class="mt-6">
          <div class="flex flex-col gap-4 lg:gap-6">
            <ProductAvailability :is-available="isProductAvailable" :product="product" />
            <CommonLabel
              v-if="containsExchangeBox"
              class="text-sm text-medium-grey"
              path="BWF/shop/productDetailPage/exchangeBox/additionalVAT"
            ></CommonLabel>
            <div v-if="isProductAvailable" ref="addToCartButton">
              <AddToCartButton
                :disabled="isLoading"
                :product="product"
                :product-quantity="productQuantity"
                class="btn btn-blue mt-auto w-full"
              >
                <div class="flex items-center leading-5">
                  <svg-icon class="h-4 w-4" name="Cart" />
                  <CommonLabel
                    class="ml-2"
                    path="BWF/shop/productDetailPage/addToCartLabel"
                  ></CommonLabel>
                  &nbsp;
                  <SharedPrice :value="totalPrice" />
                </div>
              </AddToCartButton>
            </div>
          </div>
          <ShopBenefits v-if="marketSetup?.shopBenefits" class="mt-6 lg:mt-8" />
        </div>

        <!-- Description and details -->
        <div class="mt-10 lg:col-span-2 lg:col-start-1">
          <div v-if="description" class="product-description-and-details">
            <h3 class="text-sm font-medium">
              <CommonLabel path="BWF/shop/productDetailPage/productDetails"></CommonLabel>
            </h3>
            <div class="mt-4">
              <div class="prose" v-html="description" />
            </div>
          </div>
          <p v-if="!hasHiddenMainProduct" class="mt-4 flex gap-2 text-sm text-black">
            <span class="font-medium">
              <CommonLabel path="BWF/shop/productDetailPage/productNumber"></CommonLabel>
            </span>
            <span class="font-normal">{{ product?.productNumber }}</span>
          </p>
        </div>

        <div
          v-if="isProductAvailable"
          :class="[
            showStickyAddToCartButton && !isEditMode ? 'translate-y-0' : 'translate-y-[150%]',
          ]"
          class="fixed inset-x-0 bottom-0 z-[4] bg-white p-2 shadow-3xl transition-transform lg:py-5"
        >
          <div class="container grid grid-cols-1 lg:grid-cols-12">
            <div class="lg:col-span-4 lg:col-start-8">
              <AddToCartButton
                :disabled="isLoading"
                :product="product"
                :product-quantity="productQuantity"
                class="btn btn-blue mt-auto w-full"
              >
                <div class="flex items-center leading-5">
                  <svg-icon class="h-4 w-4" name="Cart" />
                  <CommonLabel
                    class="ml-2"
                    path="BWF/shop/productDetailPage/addToCartLabel"
                  ></CommonLabel>
                  &nbsp;
                  <SharedPrice :value="totalPrice" />
                </div>
              </AddToCartButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Product } from "@shopware-pwa/types";
import { getProductUrl, getTranslatedProperty } from "@shopware-pwa/helpers-next";
import AddToCartButton from "~/templates/partials/header/AddToCartButton.vue";
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import ProductBadge from "~/templates/components/shop/product/ProductBadge.vue";
import { useProductPrice } from "@shopware-pwa/composables-next";
import ShopBenefits from "~/templates/components/shop/product/ShopBenefits.vue";
import EShopSetConfigurator from "~/templates/components/shop/product/EShopSets/EShopSetConfigurator.vue";
import { useProductBulkPrices } from "~/composables/shop/useProductBulkPrices";
import { useProductEShopSetConfigurator } from "~/composables/shop/useProductEShopSetConfigurator";
import ExchangeBoxVariantConfigurator from "~/templates/components/shop/product/ExchangeBoxVariantConfigurator.vue";
import { useExchangeBox } from "~/composables/shop/useExchangeBoxProduct";
import ProductSubscriptionConfigurator from "~/templates/components/shop/product/ProductSubscriptionConfigurator.vue";
import SubscriptionProductPrice from "~/templates/components/shop/product/subscriptionProductPrice.vue";
import type { Ref } from "vue";
import { isEditMode as evaluateEditMode } from "~/utils/content/magnolia";
import EShopSetPrice from "~/templates/components/shop/product/EShopSets/EShopSetPrice.vue";
import useItemTracking from "~/composables/tracking/useItemTracking";
import {
  type SubscriptionConfiguration,
  useProductSubscriptionConfigurator,
} from "~/composables/shop/useProductSubscriptionConfigurator";
import SelectField from "~/templates/elements/form/SelectField.vue";
import BulkProductPrice from "~/templates/components/shop/product/BulkProductPrice.vue";
import DiscountRateLabel from "~/templates/elements/DiscountRateLabel.vue";
import useMagnoliaContent from "~/composables/useMagnoliaContent";
import { useDebounceFn } from "@vueuse/core";

const isEditMode = evaluateEditMode();
const { product } = useProduct();

const { price } = useProductPrice(product);
const { isMobile } = useDevice();

const { hasBulkPrice, bulkChooserOptions, activeBulkChild, productQuantity } =
  useProductBulkPrices(product);
const { query, fullPath } = useRoute();
const { pathVariable } = useMagnoliaContent();

const { marketSetup } = useMarketSetup();
const querySetOptions = ((query?.setOptions ?? "") as string)
  .split(",")
  .filter((option) => option.length > 0);

const {
  eShopSet,
  hasEShopSet,
  eshopSetAvailable,
  eShopSetCurrentSelection,
  isLoadingUpdatedEShopSet,
  hasHiddenMainProduct,
} = await useProductEShopSetConfigurator(querySetOptions);

const { containsExchangeBox, exchangeBoxTotal } = await useExchangeBox();

const isLoading: Ref<boolean> = inject("isLoading", ref(false));

const exchangeBoxArea = inject("exchangeBoxArea", null);

watch(isLoadingUpdatedEShopSet, () => {
  isLoading.value = isLoadingUpdatedEShopSet.value;
});

const addToCartButton: Ref<HTMLElement | null> = ref(null);

const showStickyAddToCartButton = ref(false);

useIntersectionObserver(addToCartButton, ([{ isIntersecting }]) => {
  const positionY = addToCartButton.value?.getBoundingClientRect?.()?.y ?? 0;
  showStickyAddToCartButton.value = !isIntersecting && positionY > 0;
});

const {
  hasSubscriptionConfiguration,
  subscriptionChooserActive,
  subscriptionTotalPrice,
  activeSubscriptionIntervalId,
  intervalConfigurations,
} = useProductSubscriptionConfigurator(product);

/**
 * Reactive Product Data
 */

const productName = computed(() => getTranslatedProperty(product.value.translated, "name"));
const productDataDeliveryNote = computed(() =>
  getTranslatedProperty(
    product.value?.translated?.customFields,
    "brita_product_data_delivery_note",
  ),
);
const deliveryScope = computed(() =>
  getTranslatedProperty(product.value?.translated?.customFields, "brita_product_data_delivery"),
);
const description = computed(() => getTranslatedProperty(product.value, "description"));

const totalPrice = computed(() => {
  if (hasSubscriptionConfiguration.value && subscriptionChooserActive.value) {
    return subscriptionTotalPrice.value;
  }
  if (hasBulkPrice.value && activeBulkChild.value) {
    return activeBulkChild.value?.totalPrice;
  }
  if (containsExchangeBox.value) {
    return exchangeBoxTotal.value;
  }
  if (hasEShopSet.value && eShopSet.value) {
    return eShopSet.value?.eshopSetPriceTotal;
  }
  return price?.value?.totalPrice;
});

const isProductAvailable = computed(
  () => (!hasEShopSet.value && product?.value?.available) || eshopSetAvailable.value,
);

const beforeVariantChange = async () => {
  isLoading.value = true;
};

const emit = defineEmits(["variantChange"]);
const handleVariantChange = async (changedProduct: Partial<Product>) => {
  emit("variantChange", changedProduct);
};

const { trackItemView } = useItemTracking({
  item: product,
  hasEShopSet,
  eShopSet,
  subscriptionChooserActive,
  activeSubscriptionIntervalId,
  subscriptionTotalPrice,
});

const trackItemDebounce = useDebounceFn(() => trackItemView(), 100);

onMounted(() => {
  watch([subscriptionChooserActive, activeSubscriptionIntervalId, product, eShopSet], async () => {
    /**
     * UPDATE PDP URLS with vanilla history API as any changes to the path via vue router will cause NUXT to reload page data
     *
     * reloads do not mak eany sense as data is already loaded at this point.
     * just need representation in the URL
     */
    // extract first segment of fullUrl to get the vanillaurl without a variant
    const [currentPdpUrl] = fullPath.split(pathVariable.value);
    if (eShopSetCurrentSelection.value?.length > 0) {
      // eshop set options
      const currentUrl = new URL(globalThis.location.href);
      currentUrl.searchParams?.delete("setOptions");
      currentUrl.searchParams?.set("setOptions", eShopSetCurrentSelection.value.join(","));
      // eshopsets shall never add the variant to the url fix pdpUrl / just updates setOptions
      history?.replaceState(null, "", currentUrl);
    } else {
      // evaluate new variant pdp segment
      const changedPathVariable = product?.value?.seoUrls?.[0]?.seoPathInfo
        ? getProductUrl(product?.value)
        : `/${product?.value?.id}`;
      // fullpath contains query and will break eventually
      const updatedPdpUrl = `${currentPdpUrl}${changedPathVariable}${subscriptionChooserActive.value ? `?subscription=${activeSubscriptionIntervalId?.value}` : ``}`;
      history?.replaceState(null, "", updatedPdpUrl);
    }

    // reset selected bulk amount if subscription configurator is activated
    if (subscriptionChooserActive.value && hasBulkPrice.value) {
      productQuantity.value = product.value.minPurchase;
    }

    // need to wait one tick for updated storage values as updates are triggered by watchers elsewhere
    // there might be multiple changes (url & variant) at once. So we need debounce here
    requestAnimationFrame(() => trackItemDebounce());
  });

  watch(activeSubscriptionIntervalId, () => {
    const changedSubscriptionProductId = intervalConfigurations.value?.find(
      (configurationToLoad: SubscriptionConfiguration) =>
        configurationToLoad.intervalConfigurationId === activeSubscriptionIntervalId.value,
    )?.productId;
    if (changedSubscriptionProductId !== product?.value?.id) {
      beforeVariantChange();
      emit("variantChange", { id: changedSubscriptionProductId });
    }
  });

  trackItemView();
});
</script>

<style>
.product-stage {
  .product-variant-configurator {
    @apply gap-10;
  }

  .product-description-and-details {
    ul {
      list-style-image: url("~/assets/sprite/svg/Checkmark.svg");
    }

    li {
      @apply pl-2;
    }
  }
}
</style>
