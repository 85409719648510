export const intersectingComponent = {
  data() {
    return {
      observer: null,
    };
  },
  computed: {
    intersectingEl() {
      return this.$el;
    },
    intersectionOptions() {
      return {
        rootMargin: "10px",
        threshold: [0, 0.1, 0.9, 1.0],
      };
    },
  },
  methods: {
    onIntersection(entries) {
      // implement in importing component
      entries.forEach((entry) => {
        console.log("implement intersection handler", entry);
      });
    },

    /**
     * evaluates the progress of an element scrolling through the viewport from
     * 0 -> below the fold
     * to 1 -> completely above the fold
     *
     * @param element
     * @param interSectionOffset {number} offsets the intersection reference point by x pixel
     * @returns {number} 0-1
     */
    getViewPortIntersectionRatio(element, interSectionOffset = 0) {
      const elementRect = element.getBoundingClientRect();
      const windowInnerHeight = Math.round(window.innerHeight + interSectionOffset);
      // distance upper edged of el from top of document
      const distance = elementRect.height + windowInnerHeight;
      // covered distance (0 - distance)
      const covered = windowInnerHeight - elementRect.top;

      return covered / distance;
    },
    enableObserver() {
      if (this?.intersectingEl?.tagName) this.observer?.observe(this?.intersectingEl);
    },
    disableObserver() {
      if (this?.intersectingEl?.tagName) this.observer?.unobserve(this?.intersectingEl);
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.onIntersection, this.intersectionOptions);
  },
  unmounted() {
    this.disableObserver();
    this.observer?.disconnect();
  },
};
