<template>
  <div class="contents">
    <div class="flex items-center gap-2 text-2xl lg:text-3xl">
      <SharedPrice :value="subscriptionTotalPrice" class="font-medium text-signal-red" />
      <SharedPrice
        v-if="subscriptionListPrice && subscriptionListPrice !== subscriptionTotalPrice"
        :value="subscriptionListPrice"
        class="font-normal text-medium-grey line-through"
      />
      <div class="badge is-highlight-green lg:is-large ml-6 shrink-0 lg:ml-auto lg:mr-12">
        <CommonLabel
          path="BWF/shop/productDetailPage/subscriptionProduct/subscriptionPriceBadgeLabel"
        />
      </div>
    </div>

    <div class="mt-1 text-xxxs font-normal leading-tight text-medium-grey lg:text-sm">
      <CommonLabel path="BWF/shop/productDetailPage/plus"></CommonLabel>
      <span>&nbsp;</span>
      <CommonLabel path="BWF/shop/checkout/cart/taxes" />
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonLabel from "~/templates/components/CommonLabel.vue";

import { useProductSubscriptionConfigurator } from "~/composables/shop/useProductSubscriptionConfigurator";

defineProps(["showOptionName", "optionButtonDefault"]);
const { product } = useProduct();

const { subscriptionTotalPrice, subscriptionListPrice } =
  useProductSubscriptionConfigurator(product);
</script>
