export default function () {
  const trackAddCouponEvent = (coupon) => {
    try {
      const gtm = useGtm(); // auto-imported by the module
      gtm?.trackEvent({
        event: "gx.add_coupon",
        event_name: "add_coupon",
        event_source: "source_code",
        add_coupon: {
          coupon: coupon,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const trackAddErrorEvent = (errorMessage) => {
    try {
      const gtm = useGtm(); // auto-imported by the module
      gtm?.trackEvent({
        event: "gx.add_coupon_error",
        event_name: "add_coupon_error",
        event_source: "source_code",
        add_coupon_error: {
          error_message: errorMessage,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const trackActivateCouponField = () => {
    try {
      const gtm = useGtm(); // auto-imported by the module
      gtm?.trackEvent({
        event: "gx.activate_coupon_field",
        event_name: "activate_coupon_field",
        event_source: "source_code",
        activate_coupon_field: {},
      });
    } catch (e) {
      console.error(e);
    }
  };
  return { trackAddCouponEvent, trackAddErrorEvent, trackActivateCouponField };
}
