<template>
  <div class="contents">
    <div v-if="hasBestPrice" class="flex items-end gap-2">
      <SharedPrice
        :value="eShopSet?.eshopSetPriceTotal"
        class="mt-1 text-2xl font-medium text-signal-red lg:mt-2 lg:text-3xl"
      />
      <SharedPrice
        :value="eShopSet?.bestEshopPriceFromHistory"
        class="mt-1 text-base font-normal text-medium-grey line-through lg:mt-2"
      />
    </div>
    <SharedPrice
      v-else
      :value="eShopSet?.eshopSetPriceTotal"
      class="mt-1 text-2xl font-medium lg:mt-2 lg:text-3xl"
    />
    <div class="mt-1 text-xxxs font-normal leading-tight text-medium-grey lg:text-sm">
      <CommonLabel path="BWF/shop/productDetailPage/plus"></CommonLabel>
      <span>&nbsp;</span>
      <CommonLabel path="BWF/shop/checkout/cart/taxes" />
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonLabel from "~/templates/components/CommonLabel.vue";
import { useProductEShopSetConfigurator } from "~/composables/shop/useProductEShopSetConfigurator";

const { eShopSet, hasBestPrice } = await useProductEShopSetConfigurator();
</script>
