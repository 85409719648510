import { useWindowScroll, useWindowSize, createSharedComposable } from "@vueuse/core";

const scrollTracking = function () {
  const { y } = useWindowScroll();
  const { height } = useWindowSize();
  const scrolledToBottom = ref(false);
  const reached90Percent = computed(() => {
    return y?.value / (globalThis?.document?.body?.scrollHeight - height?.value) >= 0.9;
  });
  const tooSmallToScroll = computed(() => {
    return (globalThis?.document?.body?.scrollHeight ?? 0) <= (globalThis?.outerHeight ?? 0);
  });
  const trackEvent = () => {
    try {
      const gtm = useGtm(); // auto-imported by the module
      gtm?.trackEvent({
        event: "gx.scroll_depth_90_percent",
        event_name: "scroll_depth_90_percent",
        event_source: "source_code",
        scroll_depth_90_percent: {},
      });
    } catch (e) {}
  };

  watch(
    reached90Percent,
    () => {
      scrolledToBottom.value = true;
      trackEvent();
    },
    { immediate: tooSmallToScroll.value, once: true },
  );
  return { trackEvent, scrolledToBottom };
};
export default createSharedComposable(scrollTracking);
