<template>
  <div v-if="order" class="xl:flex xl:justify-end">
    <div class="grid grid-cols-2">
      <div class="mb-1 xl:mb-2 xl:text-base">
        <CommonLabel path="BWF/shop/account/orderHistory/positionPrice"></CommonLabel>
      </div>
      <SharedPrice
        :value="subtotal"
        class="justify-self-end"
        data-testid="cart-subtotal"
        has-asterisk
      />
      <div v-if="withPromotion" class="mb-1 xl:mb-2 xl:text-base">
        <CommonLabel path="BWF/shop/account/orderHistory/promotionLabel"></CommonLabel>
      </div>
      <SharedPrice v-if="withPromotion" :value="discount" class="justify-self-end"
        ><template #beforePrice>-</template></SharedPrice
      >
      <div class="xl:text-base">
        <CommonLabel path="BWF/shop/account/orderHistory/shippingCost"></CommonLabel>
      </div>
      <SharedPrice
        :value="shippingTotal"
        class="justify-self-end"
        data-testid="cart-subtotal"
        has-asterisk
      />
      <hr class="col-span-full my-2 xl:mb-3 xl:mt-5" />
      <div class="mb-1 font-medium xl:mb-2 xl:text-base">
        <CommonLabel path="BWF/shop/account/orderHistory/grandTotal"></CommonLabel>
      </div>
      <SharedPrice
        :value="amountTotal"
        class="justify-self-end font-medium"
        data-testid="cart-subtotal"
        has-asterisk
      />
      <div class="text-sm text-medium-grey xl:mb-1">
        <CommonLabel path="BWF/shop/account/orderHistory/totalNetSum"></CommonLabel>
      </div>
      <SharedPrice
        :value="amountNet"
        class="justify-self-end text-sm text-medium-grey"
        data-testid="cart-subtotal"
      />
      <div
        v-for="calculatedTax in taxes"
        :key="calculatedTax.taxRate"
        class="col-span-full grid grid-cols-2 gap-1"
      >
        <div class="flex text-sm text-medium-grey">
          <CommonLabel path="/BWF/shop/checkout/includes"></CommonLabel>
          <p>&nbsp;{{ calculatedTax?.taxRate }}%&nbsp;</p>
          <CommonLabel path="/BWF/shop/checkout/tax"></CommonLabel>
        </div>
        <SharedPrice
          v-if="calculatedTax?.tax"
          :value="calculatedTax?.tax"
          class="place-self-end text-sm text-medium-grey"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import CommonLabel from "~/templates/components/CommonLabel.vue";
import type { Order } from "@shopware-pwa/types";
import SharedPrice from "~/templates/components/shop/shared/SharedPrice.vue";

const props = defineProps<{
  order?: Order;
}>();

const customFields = computed(() => props.order?.customFields);
const withPromotion = computed(() => customFields.value?.hasOwnProperty("promotions"));

const subtotal = computed(() => {
  // @ts-ignore
  return (
    customFields.value?.originalOrderPrices?.positionPrice ??
    customFields.value?.priceWithoutPromotion
  );
});
const discount = computed(() => {
  // @ts-ignore
  return customFields.value?.promotions?.[0]?.discount;
});
const shippingTotal = computed(() => {
  // @ts-ignore
  return (
    customFields.value?.originalOrderPrices?.shippingCosts?.totalPrice ?? props.order?.shippingTotal
  );
});
const amountTotal = computed(() => {
  // @ts-ignore
  return customFields.value?.originalOrderPrices?.totalPrice ?? props.order?.amountTotal;
});

const amountNet = computed(() => {
  // @ts-ignore
  return customFields.value?.originalOrderPrices?.netPrice ?? props.order?.amountNet;
});

const taxes = computed(() => {
  // @ts-ignore
  return customFields.value?.originalOrderPrices?.totalTaxes ?? props.order?.price?.calculatedTaxes;
});
</script>
