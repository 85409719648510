<template>
  <div>
    <div
      :class="
        contentToTailwindClasses({
          backgroundColor,
        })
      "
      class="relative rounded-xl py-12 xl:py-16"
    >
      <DotPattern v-if="dotPattern" :variant="dotPattern"></DotPattern>
      <div
        class="relative mx-auto flex w-10/12 flex-col items-center gap-4 md:flex-row md:items-start"
      >
        <div v-if="containsImage(image)" class="shrink-0 grow-0 md:w-5/12 xl:w-3/12">
          <PictureElement
            :breakpoints="BREAKPOINTS"
            :img="image"
            img-class="w-44 h-auto shrink-0 rounded-xl safari-overflow-hidden-fix overflow-hidden"
          ></PictureElement>
        </div>

        <div
          :class="[{ 'text-white': backgroundColor === 'magenta' }]"
          class="flex w-full flex-1 flex-col gap-4 xl:gap-6"
        >
          <SvgIcon
            v-if="showQuote"
            :class="[{ 'text-white': backgroundColor === 'magenta' }]"
            class="h-10 w-10 text-dark-blue xl:mb-2 xl:h-16 xl:w-16"
            name="icon-quote"
          ></SvgIcon>

          <div>
            <HeadlineComponent
              :class="
                contentToTailwindClasses({
                  fontSize: fontSize,
                })
              "
              :headline="headline"
              class="font-medium"
            ></HeadlineComponent>
          </div>
          <div>{{ subline }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SvgIcon from "../../elements/SvgIcon";
import HeadlineComponent from "./HeadlineComponent";
import PictureElement from "../../elements/PictureElement";
import { contentToTailwindClasses } from "~/utils/helper/tailwind";
import { containsImage } from "~/utils/helper/magnolia";

import { DIMENSIONS } from "~/utils/helper/imaging";
import DotPattern from "~/templates/elements/DotPattern.vue";
const BREAKPOINTS = {
  start: DIMENSIONS["sm"].width["1/2"].ratio["original"],
};

defineProps([
  "headline",
  "fontSize",
  "subline",
  "showQuote",
  "image",
  "backgroundColor",
  "dotPattern",
]);
</script>
