<template>
  <div>
    <HeadlineComponent
      v-if="headline.text"
      :headline="headline"
      class="mb-8 text-2xl text-dark-blue xl:mb-14 xl:text-3xl"
    ></HeadlineComponent>

    <div class="flex flex-col items-start gap-4">
      <DownloadLinkComponent
        v-for="download in mapNodes(downloads)"
        :key="download['@id']"
        :download="download?.download"
      ></DownloadLinkComponent>
    </div>
  </div>
</template>

<script setup>
import HeadlineComponent from "./HeadlineComponent";
import DownloadLinkComponent from "./DownloadLinkComponent";
import { mapNodes } from "~/utils/helper/magnolia";
</script>
<script>
export default {
  name: "DownloadListComponent",
  props: ["headline", "downloads"],
};
</script>
