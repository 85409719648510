<template>
  <div class="faq-component">
    <HeadlineComponent
      v-if="headline"
      :class="contentToTailwindClasses({ textAlign: alignment })"
      :text="headline"
      class="mb-4 text-2xl font-medium xl:text-3xl"
      tag="h2"
    ></HeadlineComponent>
    <RichTextComponent v-if="richText" :rich-text="richText" class="mb-5" />
    <div class="border-t">
      <template v-for="(faq, index) in faqList" :key="faq">
        <AccordionComponent
          :id="faq?.anchor ?? faq?.['@name'] ?? `faq-${index}`"
          :update-hash="true"
          class="w-full border-b"
          itemprop="mainEntity"
          itemscope
          itemtype="https://schema.org/Question"
          @on-open="trackAccordionExpand(faq?.question)"
        >
          <template #header>
            <HeadlineComponent
              :text="faq?.question"
              class="my-6 text-lg font-medium xl:my-8 xl:text-2xl"
              itemprop="name"
              tag="h3"
            ></HeadlineComponent>
          </template>
          <template #content>
            <div
              :class="{ 'md:grid-cols-2': isResizerImage(faq.image) }"
              class="mb-4 grid gap-4 last:pb-4 xl:mb-6 xl:gap-6 last:xl:pb-6"
              itemprop="acceptedAnswer"
              itemscope
              itemtype="https://schema.org/Answer"
            >
              <VideoPictureElement
                v-if="isResizerImage(faq.image)"
                :breakpoints="imageBreakpoints"
                :class="[
                  {
                    'order-last': faq?.mediaPositionMobile === 'right',
                    'md:order-first': faq?.mediaPosition === 'left',
                  },
                  imageFaqList?.indexOf(faq) % 2 !== 0 || faq?.mediaPosition === 'right'
                    ? 'md:order-last'
                    : 'md:order-none',
                ]"
                :img="faq?.image"
                :show-video-inline="faq?.showVideoInline"
                :video="faq?.video"
                class="overflow-hidden rounded-xl"
              />
              <RichTextComponent v-if="faq?.answer" :rich-text="faq?.answer" itemprop="text" />
            </div>
            <div v-if="faq?.download" class="flex flex-col gap-4 pb-4 xl:pb-6">
              <DownloadLinkComponent
                v-for="downloadObject in faq?.download"
                :key="downloadObject['id']"
                :download="downloadObject"
              ></DownloadLinkComponent>
            </div>
          </template>
        </AccordionComponent>
      </template>
    </div>
  </div>
</template>

<script setup>
import HeadlineComponent from "~/templates/components/cms/HeadlineComponent.vue";
import RichTextComponent from "~/templates/components/cms/RichTextComponent.vue";
import AccordionComponent from "~/templates/elements/AccordionComponent.vue";
import { contentToTailwindClasses } from "~/utils/helper/tailwind";
import DownloadLinkComponent from "~/templates/components/cms/DownloadLinkComponent.vue";
import VideoPictureElement from "~/templates/elements/VideoPictureElement.vue";
import { containsImage, isResizerImage, mapNodes } from "~/utils/helper/magnolia";
import { DIMENSIONS } from "~/utils/helper/imaging";
import useMagnoliaSite from "~/composables/useMagnoliaSite";
import useFaqTracking from "~/composables/tracking/useFaqTracking";

const props = defineProps([
  "headline",
  "alignment",
  "richText",
  "faqs",
  "subCategory",
  "isThisSingleFaq",
]);
const { trackAccordionExpand } = useFaqTracking();

const imageBreakpoints = {
  start: DIMENSIONS["start"].ratio["3/2"],
  sm: DIMENSIONS["sm"].ratio["3/2"],
  md: DIMENSIONS["md"].width["1/2"].ratio["3/2"],
  xl: DIMENSIONS["xl"].width["1/2"].ratio["3/2"],
};

const { mgnlContentPath } = useMagnoliaContent();

const faqList = computed(() => {
  const faqs = [...(categoryFAQs?.value ?? []), ...(props.faqs ?? [])];
  const flatFaqs = faqs
    ?.map((faq) => (faq?.["@nodeType"] === "mgnl:folder" ? mapNodes(faq) : faq))
    ?.flat();

  if (
    mgnlContentPath.value.startsWith("/SourceMarket") ||
    mgnlContentPath.value.startsWith("/MasterContent")
  ) {
    // skip filter for source and master
    return flatFaqs;
  } else {
    // filter by market visibility
    return flatFaqs?.filter((faq) => {
      return !(
        faq?.["showInAllMarkets"] === "false" &&
        faq?.["showInTheseMarkets"]?.some((market) => mgnlContentPath.value.startsWith(market)) ===
          false
      );
    });
  }
});

const imageFaqList = computed(() => {
  return faqList.value.filter((faq) => containsImage(faq?.image));
});

const {
  public: { mgnlApi },
} = useRuntimeConfig();

const { currentLanguage } = useMagnoliaLanguage();
const categoryFAQs = ref([]);
const { mgnlSiteHeaders } = useMagnoliaSite();

// create key based on category configuration
const key = `faq-subcategory-${props?.subCategory?.join("|")}`;

const { data, refresh } = await useAsyncData(
  key,
  async () => {
    try {
      const params = new URLSearchParams({
        lang: currentLanguage.value,
        orderBy: "priority,@name",
        subCategory: props.subCategory.join("|"),
      });
      return $fetch(`${mgnlApi}/.rest/delivery/faq/v1?${params.toString()}`, {
        method: "GET",
        headers: mgnlSiteHeaders,
      });
    } catch (e) {
      return {};
    }
  },
  { immediate: false },
);

if (props.subCategory?.filter((entry) => entry?.length > 0)?.length > 0) {
  await refresh();
  // add category values
  categoryFAQs.value = data?.value?.results ?? [];
}
</script>
