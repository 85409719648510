<template>
  <div class="product-tile flex h-full flex-col">
    <ProductCard v-if="product" class="h-full"></ProductCard>
  </div>
</template>

<script setup>
import ProductCard from "~/templates/components/shop/ProductCard.vue";
const props = defineProps(["product", "configurator"]);

// start injection
try {
  useProduct(props.product, props.configurator);
} catch (e) {
  console.error("could not start product injection");
}
</script>
