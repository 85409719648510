const PREG_STATE = "PREG_STATE";

const useProductRegistrationState = async () => {
  const pregModel = {
    id: "",
    bri_email: "",
    bri_salutation_opt: "",
    bri_firstname: "",
    bri_lastname: "",
    bri_postalcode: "",
    bri_city: "",
    bri_street1: "",
    bri_country: "",
    bri_registration_code: "",
    bri_sku: "",
    bri_label: "",
    bri_customertype: null,
  };

  const registrationState = ref(pregModel);
  const readFromStorage = () => {
    const storageData = JSON.parse(localStorage.getItem(PREG_STATE)) ?? {};
    registrationState.value = { ...registrationState.value, ...storageData };
  };
  const persistToStorage = () => {
    localStorage.setItem(PREG_STATE, JSON.stringify(registrationState.value));
  };

  const addMultipleValues = (fields) => {
    Object.entries(fields)?.forEach(([key, value]) => {
      addModelValue(key, value);
    });
    persistToStorage();
  };
  const addModelValue = (key, value) => {
    registrationState.value[key] = value;
    registrationState.value = { ...registrationState.value };
    persistToStorage();
  };
  const addProduct = (sku, id, label = "", code = "") => {
    // registrationState.value.bri_sku = sku;
    // registrationState.value = { ...registrationState.value };
    addMultipleValues({ bri_sku: sku, id: id, bri_label: label, bri_registration_code: code });
  };

  const removeMultipleValues = (fields) => {
    fields?.forEach((field) => {
      removeModelValue(field);
    });
    persistToStorage();
  };

  const removeModelValue = (key) => {
    registrationState.value[key] = "";
  };
  const removeProduct = () => {
    removeMultipleValues(["bri_sku", "id", "bri_label", "bri_registration_code"]);
  };

  const deleteRegistrationState = () => {
    localStorage.removeItem(PREG_STATE);
  };

  onMounted(() => {
    readFromStorage();
  });

  return {
    deleteRegistrationState,
    removeMultipleValues,
    addMultipleValues,
    addProduct,
    addModelValue,
    removeProduct,
    registrationState: computed(() => registrationState.value),
    hasProduct: computed(() => !!registrationState.value["id"]),
  };
};

export default createSharedComposable(useProductRegistrationState);
