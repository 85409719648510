<template>
  <div class="relative flex flex-col">
    <slot name="back-button"></slot>
    <div class="xl:flex">
      <div class="mb-2 text-lg xl:mb-0 xl:text-4xl">
        <h2 class="font-medium text-dark-blue">
          <CommonLabel path="BWF/shop/account/orderDetails/orderDetailsHeadline"></CommonLabel>
        </h2>
        <h3 class="flex flex-wrap items-center gap-1 gap-x-4 xl:gap-4">
          <span>
            <CommonLabel path="BWF/shop/account/orderDetails/orderNumber"></CommonLabel>
            <span>&nbsp;{{ order?.orderNumber }}</span>
          </span>
          <AccountOrderStateLabel :state="order?.stateMachineState"></AccountOrderStateLabel>
        </h3>
      </div>
      <div class="mb-6 ml-auto flex place-self-end xl:mb-0">
        <TextSelectField
          ref="orderActionSelectField"
          v-model="selectedMenuOption"
          :label="orderActions"
          :options="menuOptions"
        >
          <CommonLabel
            class="text-sm"
            path="BWF/shop/account/orderDetails/orderActions"
          ></CommonLabel>
        </TextSelectField>
      </div>
    </div>

    <hr class="hidden xl:mb-4 xl:mt-2 xl:block" />
    <div class="flex flex-col gap-2 xl:gap-4">
      <OrderDetailsHeader></OrderDetailsHeader>
      <hr class="hidden xl:block" />
      <OrderDetailsAddresses></OrderDetailsAddresses>
      <hr class="mt-4 xl:mt-0" />
      <div class="flex flex-col gap-4">
        <div class="text-xl font-medium">
          <CommonLabel path="BWF/shop/account/orderDetails/orderDetails"></CommonLabel>
        </div>
        <OrderLineItems :order="order" class="mt-4" />

        <hr class="mb-6 mt-4 w-full" />
        <OrderLineItemsPrice :order="order" class="mt-4" />
      </div>

      <hr v-if="invoiceDownloads?.length" class="mb-6 mt-4 xl:my-0" />
      <div v-if="invoiceDownloads?.length" class="flex flex-col gap-4 pb-4 xl:pb-6">
        <div class="mb-2 text-base font-medium xl:my-4 xl:text-xl">
          <CommonLabel path="BWF/shop/account/orderDetails/receiptInformations"></CommonLabel>
        </div>
        <a
          v-for="invoiceDownload in invoiceDownloads"
          :key="invoiceDownload.id"
          :href="invoiceDownload.url"
          class="text-link flex items-end gap-2 text-base no-underline"
          download
          target="_blank"
        >
          <span class="truncate leading-none no-underline">
            {{ `${invoiceDownload.name}_${invoiceDownload.number}` }}
          </span>
          <SvgIcon class="h-5 w-5" name="Download"></SvgIcon>
        </a>
      </div>

      <template v-if="containsReturned || containsRefund">
        <hr class="mb-6 mt-4 xl:my-0" />
        <div class="mt-4 flex flex-col">
          <div class="text-xl font-medium">
            <CommonLabel
              v-if="containsRefund"
              path="BWF/shop/account/orderDetails/refundedItems"
            ></CommonLabel>
            <CommonLabel v-else path="BWF/shop/account/orderDetails/returnedItems"></CommonLabel>
          </div>
          <OrderLineItems :order="order" :show-returns="true" class="mt-4" />
        </div>
      </template>

      <hr v-if="order?.extensions?.returns?.length" class="mb-6 mt-4 xl:my-0" />
      <OrderReturnDetails
        v-if="order?.extensions?.returns?.length"
        :order="order"
      ></OrderReturnDetails>

      <hr v-if="order?.deliveries?.length" class="mb-6 mt-4 xl:my-0" />
      <div v-if="order?.deliveries?.length" class="pb-4 xl:pb-6">
        <div class="mb-2 text-base font-medium xl:my-4 xl:text-xl">
          <CommonLabel path="BWF/shop/account/orderDetails/trackingInformations"></CommonLabel>
        </div>

        <div
          v-for="delivery in order?.deliveries"
          :key="delivery?.id"
          class="grid w-full grid-cols-2 xl:w-1/2"
        >
          <div class="text-sm font-medium">
            <CommonLabel path="BWF/shop/account/orderDetails/trackingCarrier"></CommonLabel>
          </div>
          <div>{{ delivery?.shippingMethod?.name }}</div>
          <div
            class="col-span-full text-sm font-medium md:col-span-1 md:col-start-2 md:row-start-1"
          >
            <CommonLabel path="BWF/shop/account/orderDetails/trackingId"></CommonLabel>
          </div>
          <div>
            <CommonLabel
              v-if="!delivery?.trackingCodes?.length"
              path="BWF/shop/account/orderDetails/noTrackingId"
            ></CommonLabel>
            <a
              v-for="trackingCode in delivery?.trackingCodes"
              v-else
              :key="trackingCode"
              :href="`${delivery?.shippingMethod?.trackingUrl?.replace('%s', trackingCode)}`"
              class="text-link mb-3 flex items-end text-base no-underline"
              target="_blank"
            >
              <span class="leading-none no-underline">
                {{ trackingCode }}
              </span>
              &nbsp;
              <SvgIcon class="h-4 w-4 shrink-0" name="External-Link"></SvgIcon>
            </a>
          </div>
        </div>
      </div>

      <hr class="mb-6 mt-4 xl:my-0" />
      <CommonLabel path="BWF/shared/vatFootnote" />
    </div>
  </div>
</template>

<script setup>
import useCommonLabels from "~/composables/useCommonLabels";

import CommonLabel from "~/templates/components/CommonLabel.vue";
import TextSelectField from "~/templates/elements/form/TextSelectField.vue";

import OrderLineItems from "~/templates/components/shop/account/OrderLineItems.vue";
import OrderReturnDetails from "~/templates/components/shop/account/OrderReturnDetails.vue";
import OrderDetailsHeader from "~/templates/components/shop/account/OrderDetailsHeader.vue";
import OrderDetailsAddresses from "~/templates/components/shop/account/OrderDetailsAddresses.vue";
import OrderLineItemsPrice from "~/templates/components/shop/account/OrderLineItemsPrice.vue";
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import {
  containsCreditLineItems,
  ORDER_DOCUMENT_TYPE_CREDIT_NOTE,
  ORDER_DOCUMENT_TYPE_INVOICE,
} from "~/utils/helper/shop/order";
import { useShopConfiguration } from "~/composables/shop/useShopConfiguration";

const { cleanedShopwareEndpoint } = useShopConfiguration();

const { getLabels } = useCommonLabels();
const props = defineProps(["order"]);

const { startReturn, startCancellation, startWarranty, orderActions } = getLabels(
  "BWF/shop/account/orderDetails",
  ["startReturn", "startCancellation", "startWarranty", "orderActions"],
);

const containsReturned = computed(() => {
  return props.order?.extensions?.returns?.length > 0;
});

const containsRefund = computed(() => {
  return containsCreditLineItems(props.order?.lineItems);
});

const isCancellable = computed(() => {
  // all deliveries need to be open to be cancellable
  return props.order?.extensions?.isCancellationAllowed?.value;
});

const isReturnable = computed(() => {
  // if containsReturned will be handled after selection. user will be send to customer service
  return props.order?.extensions?.isReturnAllowed?.value || containsReturned.value;
});

const isWarranty = computed(() => {
  return props.order?.extensions?.isWarrantyAllowed?.value;
});

const buildMenuOptions = async () => [
  {
    label: startReturn,
    value: "return",
    disabled: !isReturnable.value,
  },
  {
    label: startCancellation,
    value: "cancel",
    disabled: !isCancellable.value,
  },
  {
    label: startWarranty,
    value: "warranty",
    disabled: !isWarranty.value,
  },
];

const invoiceDownloads = computed(() => {
  const invoiceTypes = [ORDER_DOCUMENT_TYPE_INVOICE, ORDER_DOCUMENT_TYPE_CREDIT_NOTE];
  return props.order?.documents
    ?.filter((document) =>
      invoiceTypes.includes(document.documentType?.technicalName?.toLowerCase()),
    )
    .map((document) => {
      return {
        url: `${cleanedShopwareEndpoint.value}/store-api/brita/document/download/${document.id}/${document.deepLinkCode}`,
        id: document.id,
        number: document.config.documentNumber,
        name: document.documentType.name,
      };
    });
});

const menuOptions = ref([]);

const selectedMenuOption = ref("");

const orderActionSelectField = ref(null);

const emit = defineEmits([
  "order-action-cancel",
  "order-action-return",
  "order-action-warranty",
  "order-action-service",
]);

watch(selectedMenuOption, (option) => {
  switch (option) {
    case "return":
      if (containsReturned.value) {
        emit("order-action-service");
      } else {
        emit("order-action-return");
      }
      break;
    case "warranty":
      emit("order-action-warranty");
      break;
    case "cancel":
      emit("order-action-cancel");
      break;
  }
  // reset options
  orderActionSelectField.value?.reset();
});

menuOptions.value = await buildMenuOptions();
</script>
