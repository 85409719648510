<template>
  <div class="w-full p-4">
    <div class="flex items-center justify-between">
      <p class="text-base font-medium text-black">{{ title }}</p>
      <SharedPrice
        v-if="absPriceDifference && !stepProduct?.selected"
        :value="absPriceDifference"
        class="text-sm font-normal leading-snug text-black"
      >
        <template #beforePrice>{{ priceSign }}</template>
      </SharedPrice>
    </div>
    <div v-if="productLongDescriptionEshopStep" class="mt-6">
      <div class="prose" v-html="productLongDescriptionEshopStep"></div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { getTranslatedProperty } from "@shopware-pwa/helpers-next";
import type { EshopSetConfiguratorStepProduct } from "~/composables/shop/eShopSetConfiguratorTypes";
import { getValueSign } from "~/utils/helper/number";

const props = defineProps<{ stepProduct: EshopSetConfiguratorStepProduct }>();

const product = computed(() => props?.stepProduct?.product);
const title = computed(() => getTranslatedProperty(product?.value, "name"));
const productLongDescriptionEshopStep = computed(() => props?.stepProduct?.description);
const absPriceDifference = computed(() => Math.abs(props?.stepProduct?.differencePrice));
const priceSign = computed(() => getValueSign(props?.stepProduct?.differencePrice));
</script>
