<template>
  <svg
    id="eWpqbwcgLVQ1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 375 667"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
  >
    <g id="eWpqbwcgLVQ2_ts" transform="translate(156,580) scale(0.2,0.2)">
      <ellipse
        id="eWpqbwcgLVQ2"
        rx="52"
        ry="52"
        transform="translate(0,0)"
        opacity="0"
        fill="#fff"
        fill-opacity="0.35"
        stroke-width="0"
      />
    </g>
    <g id="eWpqbwcgLVQ3_ts" transform="translate(380,244) scale(0.2,0.2)">
      <ellipse
        id="eWpqbwcgLVQ3"
        rx="52"
        ry="52"
        transform="translate(0,0)"
        opacity="0"
        fill="#fff"
        fill-opacity="0.35"
        stroke-width="0"
      />
    </g>
    <g id="eWpqbwcgLVQ4_ts" transform="translate(156,132) scale(0.2,0.2)">
      <ellipse
        id="eWpqbwcgLVQ4"
        rx="52"
        ry="52"
        transform="translate(0,0)"
        opacity="0"
        fill="#fff"
        fill-opacity="0.35"
        stroke-width="0"
      />
    </g>
    <g id="eWpqbwcgLVQ5_ts" transform="translate(44,132) scale(0.2,0.2)">
      <ellipse
        id="eWpqbwcgLVQ5"
        rx="52"
        ry="52"
        transform="translate(0,0)"
        opacity="0"
        fill="#84d0f5"
        fill-opacity="0.65"
        stroke-width="0"
      />
    </g>
    <g id="eWpqbwcgLVQ6_ts" transform="translate(44,20) scale(0.2,0.2)">
      <ellipse
        id="eWpqbwcgLVQ6"
        rx="52"
        ry="52"
        transform="translate(0,0)"
        opacity="0"
        fill="#fff"
        fill-opacity="0.35"
        stroke-width="0"
      />
    </g>
  </svg>
</template>
<style scoped>
#eWpqbwcgLVQ2_ts {
  animation: eWpqbwcgLVQ2_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eWpqbwcgLVQ2_ts__ts {
  0% {
    transform: translate(156px, 580px) scale(0.2, 0.2);
  }
  40% {
    transform: translate(156px, 580px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  90% {
    transform: translate(156px, 580px) scale(1, 1);
  }
  100% {
    transform: translate(156px, 580px) scale(1, 1);
  }
}
#eWpqbwcgLVQ2 {
  animation: eWpqbwcgLVQ2_c_o 2000ms linear 1 normal forwards;
}
@keyframes eWpqbwcgLVQ2_c_o {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#eWpqbwcgLVQ3_ts {
  animation: eWpqbwcgLVQ3_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eWpqbwcgLVQ3_ts__ts {
  0% {
    transform: translate(380px, 244px) scale(0.2, 0.2);
  }
  30% {
    transform: translate(380px, 244px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  80% {
    transform: translate(380px, 244px) scale(1, 1);
  }
  100% {
    transform: translate(380px, 244px) scale(1, 1);
  }
}
#eWpqbwcgLVQ3 {
  animation: eWpqbwcgLVQ3_c_o 2000ms linear 1 normal forwards;
}
@keyframes eWpqbwcgLVQ3_c_o {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#eWpqbwcgLVQ4_ts {
  animation: eWpqbwcgLVQ4_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eWpqbwcgLVQ4_ts__ts {
  0% {
    transform: translate(156px, 132px) scale(0.2, 0.2);
  }
  20% {
    transform: translate(156px, 132px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  70% {
    transform: translate(156px, 132px) scale(1, 1);
  }
  100% {
    transform: translate(156px, 132px) scale(1, 1);
  }
}
#eWpqbwcgLVQ4 {
  animation: eWpqbwcgLVQ4_c_o 2000ms linear 1 normal forwards;
}
@keyframes eWpqbwcgLVQ4_c_o {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#eWpqbwcgLVQ5_ts {
  animation: eWpqbwcgLVQ5_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eWpqbwcgLVQ5_ts__ts {
  0% {
    transform: translate(44px, 132px) scale(0.2, 0.2);
  }
  10% {
    transform: translate(44px, 132px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  60% {
    transform: translate(44px, 132px) scale(1, 1);
  }
  100% {
    transform: translate(44px, 132px) scale(1, 1);
  }
}
#eWpqbwcgLVQ5 {
  animation: eWpqbwcgLVQ5_c_o 2000ms linear 1 normal forwards;
}
@keyframes eWpqbwcgLVQ5_c_o {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#eWpqbwcgLVQ6_ts {
  animation: eWpqbwcgLVQ6_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eWpqbwcgLVQ6_ts__ts {
  0% {
    transform: translate(44px, 20px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(44px, 20px) scale(1, 1);
  }
  100% {
    transform: translate(44px, 20px) scale(1, 1);
  }
}
#eWpqbwcgLVQ6 {
  animation: eWpqbwcgLVQ6_c_o 2000ms linear 1 normal forwards;
}
@keyframes eWpqbwcgLVQ6_c_o {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
</style>
