import type { Product, ProductMedia } from "@shopware-pwa/types";
export const MEDIA_PDP = "brita_media_attr_pdp";
export const MEDIA_GRID = "brita_media_attr_grid";
export const MEDIA_HERO = "brita_media_attr_product_hero";

export const sortMedia = (productMedia: ProductMedia[]) => {
  return productMedia?.sort((a, b) => a.position - b.position) ?? [];
};

export const filterMedia = (productMedia: ProductMedia[], filter = "") => {
  return productMedia?.filter(({ media }) =>
    filter.length > 1 ? media.customFields?.[filter] : true,
  );
};

export const findProductMedia = (
  productMedia: ProductMedia[],
  filter = "",
): ProductMedia | undefined => {
  return productMedia.find(({ media }) => media?.customFields?.[filter]);
};

export const pdpMediaWithVideos = (product: Product) => {
  //@ts-ignore
  return [
    ...filterMedia(product.media, MEDIA_PDP),
    ...(product.extensions?.solidPvEmbeddedVideoMedia ?? []),
  ];
};

export const getSortedPdpMediaWithVideos = (product: Product) =>
  sortMedia(pdpMediaWithVideos(product));
export const getSortedPdpMedia = (product: Product) =>
  filterMedia(sortMedia(product.media), MEDIA_PDP);
export const getSortedGridMedia = (product: Product) =>
  filterMedia(sortMedia(product.media), MEDIA_GRID);
export const getSortedHeroMedia = (product: Product) =>
  filterMedia(sortMedia(product.media), MEDIA_HERO);
export const findGridMedium = (product: Product): ProductMedia | undefined => {
  return findProductMedia(sortMedia(product.media), MEDIA_GRID);
};

export const findHeroMedium = (product: Product): ProductMedia | undefined => {
  return findProductMedia(sortMedia(product.media), MEDIA_HERO);
};

/**
 * Returns the srcset attribute for the image, for available breakpoints until a given one
 *
 * @param media image object
 * @param until dimension limit
 *
 * @public
 * @category Media
 */
export function getSrcSetForMediaUntil<
  T extends {
    thumbnails?: Array<{
      width: number;
      url: string;
    }>;
  },
>(media?: T, until = 1000000): string {
  if (!media?.thumbnails?.length) {
    return "";
  }

  return media.thumbnails
    .filter((thumbnail) => {
      return thumbnail.width <= until;
    })
    .map((thumbnail) => {
      return `${thumbnail.url} ${thumbnail.width}w`;
    })
    .join(", ");
}
