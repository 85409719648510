<template>
  <div class="group/product relative flex items-center gap-4 rounded-2xl bg-light-grey p-4">
    <PictureElement
      :img="productLink.image"
      :breakpoints="BREAKPOINTS"
      image-rendering="pixelated"
      class="shrink-0"
      img-class="h-20 w-20 object-contain group-hover/product:scale-110 transition-transform duration-300"
    ></PictureElement>
    <div class="flex flex-col gap-2">
      <LinkComponent
        class="stretched-link cursor-pointer text-sm font-medium group-hover/product:text-deep-blue"
        :link="productLink.link"
      >
        {{ productLink?.link?.label }}
      </LinkComponent>
      <TagList
        :tags="productLink?.tags"
        class="flex-wrap"
        gap-class="gap-1"
        tag-class="tag"
      ></TagList>
    </div>
  </div>
</template>

<script setup>
import { DIMENSIONS } from "~/utils/helper/imaging";
import PictureElement from "../../elements/PictureElement";
import TagList from "~/templates/components/TagList.vue";

const BREAKPOINTS = {
  start: DIMENSIONS.fixed["200x200"],
};
</script>

<script>
import LinkComponent from "../../components/cms/LinkComponent";
import { mapNodes, imagePath, imageName } from "~/utils/helper/magnolia";

export default {
  name: "ProductLink",
  components: { LinkComponent },
  props: {
    productLink: {},
  },
  methods: {
    mapNodes,
    imagePath,
    imageName,
  },
};
</script>
