<template>
  <div class="level-two container py-4 pt-16" :class="{ 'is-active': isActive }">
    <div class="level-two-header mb-6 flex w-full items-center xl:mb-8">
      <div class="level-title text-xl font-medium xl:text-2xl">{{ level.title }}</div>
      <button
        class="level-close-icon btn btn-icon ml-auto"
        aria-label="menu-close"
        @click="emit('level-close')"
      >
        <SvgIcon name="icon-menu-close"></SvgIcon>
      </button>
    </div>
    <div class="level-two-content">
      <LevelTwoList v-if="type === 'list'" :level="level"></LevelTwoList>
      <LevelTwoProduct v-else-if="type === 'product'" :level="level"></LevelTwoProduct>
    </div>
  </div>
</template>

<script setup>
import LevelTwoProduct from "./LevelTwoProduct";
import LevelTwoList from "./LevelTwoList";
import SvgIcon from "../../elements/SvgIcon";
import { mapNodes } from "~/utils/helper/magnolia";
const props = defineProps(["level", "isActive"]);
const emit = defineEmits(["level-close"]);

const type = computed(() => {
  const nodes = mapNodes(props.level);
  return nodes.find((subNode) => subNode["@nodeType"] === "nav:productlink") ? "product" : "list";
});
</script>

<style scoped>
.level-two {
  @apply pointer-events-none -translate-y-4 opacity-0 transition-all delay-[0s] duration-300;

  &.is-active {
    @apply pointer-events-auto translate-y-0 opacity-100 delay-300;
  }
}

.is-mobile-open .level-two-header {
  @apply mb-0 h-6;
}
.is-mobile-open .level-close-icon {
  @apply hidden;
}

.is-mobile-open .level-title {
  @apply hidden;
}

.is-mobile-open .level-two {
  @apply pointer-events-auto;
}
</style>
