<template>
  <div class="image-component responsive-gap flex w-full flex-col">
    <VideoPictureElement
      :breakpoints="BREAKPOINTS"
      :img="image"
      :show-video-inline="isDesktop"
      :video="video"
      class="image-rounded safari-overflow-hidden-fix relative mx-auto aspect-2/3 max-h-screen w-full overflow-hidden sm:aspect-video"
      img-class="absolute inset-0 w-full h-full object-cover"
    >
      <div class="relative inset-0 h-full w-full">
        <div
          v-if="opacity > 0"
          :class="contentToTailwindClasses({ opacity: opacity ?? 0 })"
          class="absolute inset-0 bg-black"
        ></div>
        <div
          :class="{
            'items-center justify-center text-center': position === 'center',
            'items-start justify-end': position !== 'center',
          }"
          class="container relative inset-0 flex h-full w-full flex-col gap-4 p-8 xl:gap-8"
        >
          <div v-if="text" class="w-10/12 text-xl font-medium text-white md:text-4xl lg:w-1/2">
            {{ text }}
          </div>

          <button v-if="hasVideo(video)" class="btn btn-circle h-16 w-16 bg-white md:h-24 md:w-24">
            <SvgIcon class="h-6 w-6 text-black xl:h-8 xl:w-8" name="Play"></SvgIcon>
          </button>
        </div>
      </div>
    </VideoPictureElement>

    <div v-if="subline || headline?.text" class="no-nested-container-padding container">
      <HeadlineComponent
        v-if="headline?.text"
        :headline="headline"
        class="text-2xl font-medium"
      ></HeadlineComponent>
      <div v-if="subline" class="mt-2 xl:mt-4">
        {{ subline }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { DIMENSIONS } from "~/utils/helper/imaging";
import { contentToTailwindClasses } from "~/utils/helper/tailwind";
import HeadlineComponent from "./HeadlineComponent";
import SvgIcon from "../../elements/SvgIcon.vue";
import VideoPictureElement from "~/templates/elements/VideoPictureElement.vue";
import { hasVideo } from "~/utils/helper/video";

const { isDesktop } = useDevice();

const BREAKPOINTS = {
  start: DIMENSIONS["start"].ratio["2/3"],
  sm: DIMENSIONS["sm"].ratio.video,
  md: DIMENSIONS["md"].ratio.video,
  xl: DIMENSIONS["xl"].ratio.video,
  container: DIMENSIONS["container"].ratio.video,
};
</script>

<script>
export default {
  name: "ImageComponent",
  props: ["image", "video", "text", "opacity", "position", "headline", "subline"],
};
</script>

<style>
.image-component .image-rounded {
  @apply rounded-xl;
}

.no-container .image-rounded {
  @apply rounded-none;
}
</style>
