import { isEditMode } from "~/utils/content/magnolia";

export const editableComponent = {
  data() {
    return {
      isEditMode: false,
    };
  },
  created() {
    // cannot use the proper async variant inEditorAsync cause async will mess with vues hook execution order
    this.isEditMode = isEditMode();
  },
};
