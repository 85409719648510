const WIDTH = {
  12: "w-full",
  10: "w-10/12",
  8: "w-8/12",
  6: "w-6/12",
};
const RESPONSIVE_WIDTH = {
  md: {
    12: "md:w-full",
    10: "md:w-10/12",
    8: "md:w-8/12",
    6: "md:w-6/12",
  },
};

const COLUMNS = {
  1: "grid-cols-1",
  2: "grid-cols-2",
  3: "grid-cols-3",
  4: "grid-cols-4",
};

const GRID = {
  sm: "sm:grid",
  md: "md:grid",
  lg: "lg:grid",
  xl: "xl:grid",
};

const BG_COLOR = {
  light: "bg-light-beige",
  sand: "bg-sand",
  lightSand: "bg-light-sand",
  blue: "bg-light-blue",
  mediumBlue: "bg-medium-blue",
  darkBlue: "bg-dark-blue",
  lightGrey: "bg-light-grey",
  magenta: "bg-magenta",
  white: "bg-white",
  black: "bg-black",
};

const TEXT_COLOR = {
  black: "text-black",
  white: "text-white",
  blue: "text-dark-blue",
};

const TEXT_ALIGN = {
  left: "text-left",
  center: "text-center",
  right: "text-right",
};

const FLEX_ITEMS = {
  start: "items-start",
  left: "items-start",
  center: "items-center",
  end: "items-end",
  right: "items-end",
};

const GAPS = {
  0: "gap-0",
  2: "gap-2",
  4: "gap-4",
  8: "gap-8",
};

const OPACITY = {
  0: "opacity-0",
  25: "opacity-25",
  35: "opacity-35",
  50: "opacity-50",
  75: "opacity-75",
  100: "opacity-100",
};

const ASPECT_RATIO = {
  square: "aspect-square",
  tv: "aspect-4/3",
  postcard: "aspect-3/2",
  video: "aspect-video",
  original: "aspect-auto",
};

const OBJECT_FIT = {
  cover: "object-cover",
  contain: "object-contain",
};

const RESPONSIVE_SPACING_MARGIN = {
  top: {
    large: "spacing-mt-large",
    medium: "spacing-mt-medium",
    small: "spacing-mt-small",
    xs: "spacing-mt-xs",
    xxs: "spacing-mt-xxs",
  },
  bottom: {
    large: "spacing-mb-large",
    medium: "spacing-mb-medium",
    small: "spacing-mb-small",
    xs: "spacing-mb-xs",
    xxs: "spacing-mb-xxs",
  },
};
const RESPONSIVE_SPACING_GAP = {
  large: "spacing-gap-large",
  medium: "spacing-gap-medium",
  small: "spacing-gap-small",
  xs: "spacing-gap-xs",
  xxs: "spacing-gap-xxs",
};
const RESPONSIVE_SPACING_PADDING = {
  top: {
    large: "spacing-pt-large",
    medium: "spacing-pt-medium",
    small: "spacing-pt-small",
    xs: "spacing-pt-xs",
    xxs: "spacing-pt-xxs",
  },
  bottom: {
    large: "spacing-pb-large",
    medium: "spacing-pb-medium",
    small: "spacing-pb-small",
    xs: "spacing-pb-xs",
    xxs: "spacing-pb-xxs",
  },
};

const FONT_SIZE = {
  0: "text-3xl md:text-4xl lg:text-6xl",
  1: "text-3xl lg:text-4xl",
  2: "text-2xl lg:text-3xl",
  3: "text-lg lg:text-2xl",
};

const FONT_WEIGHT = {
  regular: "font-normal",
  medium: "font-medium",
};

const BTN_STYLES = {
  btnBlack: "btn btn-black",
  btnBlue: "btn btn-blue",
  btnMagenta: "btn btn-magenta",
  btnWhite: "btn btn-white",
  btnGhost: "btn btn-ghost",
  link: "text-link",
};

export const contentToResponsiveMarginClasses = function (content = {}) {
  const classes = [];
  if (content.top && RESPONSIVE_SPACING_MARGIN.top[content.top]) {
    classes.push(RESPONSIVE_SPACING_MARGIN.top[content.top]);
  }
  if (content.bottom && RESPONSIVE_SPACING_MARGIN.bottom[content.bottom]) {
    classes.push(RESPONSIVE_SPACING_MARGIN.bottom[content.bottom]);
  }
  return classes.join(" ");
};
export const contentToResponsiveGapClasses = function (content = {}) {
  const classes = [];
  if (content.gap && RESPONSIVE_SPACING_GAP[content.gap]) {
    classes.push(RESPONSIVE_SPACING_GAP[content.gap]);
  }
  return classes.join(" ");
};
export const contentToResponsivePaddingClasses = function (content = {}) {
  const classes = [];
  if (content.top && RESPONSIVE_SPACING_PADDING.top[content.top]) {
    classes.push(RESPONSIVE_SPACING_PADDING.top[content.top]);
  }
  if (content.bottom && RESPONSIVE_SPACING_PADDING.bottom[content.bottom]) {
    classes.push(RESPONSIVE_SPACING_PADDING.bottom[content.bottom]);
  }

  return classes.join(" ");
};

export const contentToTailwindClasses = function (value = {}) {
  const classes = [];

  if (value.width) {
    if (WIDTH[value.width]) {
      classes.push(WIDTH[value.width]);
    } else if (value.width.md && RESPONSIVE_WIDTH.md[value.width.md]) {
      classes.push(RESPONSIVE_WIDTH.md[value.width.md]);
    }
  }
  if (value.columns && COLUMNS[value.columns]) {
    classes.push(COLUMNS[value.columns]);
  }
  if (value.gap && GAPS[value.gap]) {
    classes.push(GAPS[value.gap]);
  }
  if (value.grid && GRID[value.grid]) {
    classes.push(GRID[value.grid]);
  }

  if (value.textColor && TEXT_COLOR[value.textColor]) {
    classes.push(TEXT_COLOR[value.textColor]);
  }
  if (value.textAlign && TEXT_ALIGN[value.textAlign]) {
    classes.push(TEXT_ALIGN[value.textAlign]);
  }

  if (value.flexItems && FLEX_ITEMS[value.flexItems]) {
    classes.push(FLEX_ITEMS[value.flexItems]);
  }

  if (value.opacity >= 0 && OPACITY[value.opacity]) {
    classes.push(OPACITY[value.opacity]);
  }

  if (value.fontSize >= 0 && FONT_SIZE[parseInt(value.fontSize)]) {
    classes.push(FONT_SIZE[parseInt(value.fontSize)]);
  }

  if (value.fontWeight && FONT_WEIGHT[value.fontWeight]) {
    classes.push(FONT_WEIGHT[value.fontWeight]);
  }

  if (value.backgroundColor && BG_COLOR[value.backgroundColor]) {
    classes.push(BG_COLOR[value.backgroundColor]);
  }

  if (value.aspectRatio && ASPECT_RATIO[value.aspectRatio]) {
    classes.push(ASPECT_RATIO[value.aspectRatio]);
  }

  if (value.objectFit && OBJECT_FIT[value.objectFit]) {
    classes.push(OBJECT_FIT[value.objectFit]);
  }
  if (value.btnStyle && BTN_STYLES[value.btnStyle]) {
    classes.push(BTN_STYLES[value.btnStyle]);
  }
  return classes.join(" ");
};
