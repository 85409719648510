import type { LineItem } from "@shopware-pwa/types";
import { createSharedComposable } from "@vueuse/core";

function bwfUseCartInformation() {
  const { cartItems } = useCart();

  const count = computed(() => {
    const validItemTypes = ["product", "container"];
    return cartItems?.value?.reduce(
      (accumulator: number, lineItem: LineItem) =>
        validItemTypes.includes(lineItem.type) ? lineItem.quantity + accumulator : accumulator,
      0,
    );
  });

  const isEmpty = computed(() => count.value <= 0);

  return {
    count,
    isEmpty,
  };
}

export const useCartInformation = createSharedComposable(bwfUseCartInformation);
