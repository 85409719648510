<template>
  <AccountGrid>
    <div class="account-address-book grid gap-y-4 xl:grid-cols-2 xl:gap-x-6 xl:gap-y-6">
      <div class="col-span-full mb-2 md:mb-4 xl:mb-8">
        <h2 class="text-2xl font-medium text-dark-blue xl:text-3xl">
          <CommonLabel path="BWF/shop/account/addressBook/addressBookHeadline"></CommonLabel>
        </h2>
      </div>
      <section class="border-grey flex flex-col rounded-lg border px-4 py-6 xl:px-6">
        <AccountAddressCard
          :address="userDefaultBillingAddress"
          :countries="getCountries"
          :salutations="getSalutations"
          is-billing
        >
          <template #editButton>
            <button class="btn btn-blue w-full xl:mt-auto xl:self-start">
              <CommonLabel path="BWF/shop/shared/edit"></CommonLabel>
            </button>
          </template>
        </AccountAddressCard>
      </section>
      <section class="border-grey flex flex-col rounded-lg border px-4 py-6 xl:px-6">
        <AccountAddressCard :address="userDefaultShippingAddress" is-shipping>
          <template #editButton>
            <button class="btn btn-blue w-full xl:mt-auto xl:self-start">
              <CommonLabel path="BWF/shop/shared/edit"></CommonLabel>
            </button>
          </template>
        </AccountAddressCard>
      </section>

      <EditableArea :content="subscriptionArea" class="col-span-full">
        <section
          v-if="subscriptionArea.headline"
          class="border-grey col-span-full flex flex-col gap-4 rounded-lg border px-4 py-6 xl:px-6"
        >
          <HeadlineComponent
            class="font-medium"
            :headline="subscriptionArea.headline"
          ></HeadlineComponent>
          <RichTextComponent
            v-if="subscriptionArea.richText"
            :rich-text="subscriptionArea.richText"
          ></RichTextComponent>
          <NuxtLink
            v-if="marketSetupLinks.myBritaSubscriptionPage"
            :to="marketSetupLinks.myBritaSubscriptionPage"
            class="btn btn-ghost self-start xl:mt-auto"
            >{{ subscriptionArea.buttonLabel }}</NuxtLink
          >
        </section>
      </EditableArea>
    </div>
  </AccountGrid>
</template>
<script setup lang="ts">
import AccountGrid from "~/templates/components/account/AccountGrid.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import AccountAddressCard from "~/templates/components/shop/account/AccountAddressCard.vue";
import { EditableArea } from "@magnolia/vue-editor";
import HeadlineComponent from "~/templates/components/cms/HeadlineComponent.vue";
import RichTextComponent from "~/templates/components/cms/RichTextComponent.vue";

defineOptions({
  inheritAttrs: false,
});

defineProps(["subscriptionArea"]);

const loadingData = ref(true);
const { getCountries } = useCountries();
const { getSalutations } = useSalutations();
const { loadCustomerAddresses } = useAddress();

const { userDefaultBillingAddress, userDefaultShippingAddress } = useUser();
const { marketSetupLinks } = useMarketSetup();

useBreadcrumbs([
  {
    name: "Account Overview",
    path: "/account",
  },
  {
    name: "Address",
    path: "/account/address",
  },
]);

onBeforeMount(async () => {
  await loadCustomerAddresses();
  loadingData.value = false;
});
</script>
