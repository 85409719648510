<template>
  <div class="grid md:grid-cols-2">
    <div
      class="col-span-full mb-auto grid items-start gap-x-8 gap-y-4 md:grid-cols-2 xl:col-span-1"
    >
      <InputField
        v-model="inputState.searchCode"
        class="form-field-grey"
        :placeholder="productCodeInputPlaceholder ?? 'Enter Product registration Code...*'"
        :label="productCodeInputLabel ?? 'Product Code*'"
        :error-message="productSearchErrors"
        @focusin="inputVuelidate.$reset()"
      />

      <SelectField
        v-model="activeCategoryId"
        class="form-field-grey mb-auto"
        :label="productCategoryLabel ?? 'Category Option*'"
        :options="categoryOptions"
        @change="onCategoryChange()"
      />
    </div>

    <PregProducts
      class="col-span-full mt-12"
      :category-id="activeCategoryId"
      :product-search-result="productSearchResult"
      @selected-product="changeSelectedProduct"
    />

    <button
      class="btn btn-blue col-span-full mt-8 w-full place-self-start md:mt-12 xl:w-1/3"
      @click="onSubmit()"
    >
      <CommonLabel path="BWF/shop/shared/continue" />
    </button>
  </div>
</template>

<script setup>
import { useProductRegistration } from "~/composables/shop/useProductRegistration";
import useProductRegistrationState from "~/composables/shop/useProductRegistrationState";
import InputField from "~/templates/elements/form/InputField.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import SelectField from "~/templates/elements/form/SelectField.vue";
import useValidation from "~/composables/useValidation";
import { useVuelidate } from "@vuelidate/core";

const inputState = reactive({ searchCode: null });

const { productSearchCodeValidator } = await useValidation();
const inputRules = computed(() => ({
  searchCode: { ...productSearchCodeValidator },
}));
const activeCategoryId = ref("");
const registrationProduct = ref(null);
const productSearchResult = ref();
const searchFailed = ref(false);

const { search, categoryOptions } = await useProductRegistration();
const { addProduct } = await useProductRegistrationState();
const { getLabels, getLabelByPath } = useCommonLabels();
const noProductFound = getLabelByPath("BWF/form/shared/validatorMessages/noProductFound");
const onCategoryChange = () => {
  productSearchResult.value = null;
};
const { productCodeInputPlaceholder, productCodeInputLabel, productCategoryLabel } = getLabels(
  "BWF/shop/product/registration/",
  ["productCodeInputPlaceholder", "productCodeInputLabel", "productCategoryLabel"],
);
const emit = defineEmits(["registration-product-selected"]);

const productSearchErrors = computed(() => {
  return inputVuelidate.value?.$errors?.[0]?.$message || (searchFailed.value ? noProductFound : "");
});

const inputVuelidate = useVuelidate(inputRules, inputState);

const onSubmit = () => {
  addProduct(
    registrationProduct?.value.productNumber,
    registrationProduct?.value.id,
    registrationProduct?.value.name,
    inputState?.searchCode,
  );
  emit("registration-product-selected");
};

const changeSelectedProduct = (product) => {
  registrationProduct.value = product;
};

watch([inputState, inputVuelidate], async () => {
  productSearchResult.value = null;
  searchFailed.value = false;

  const valid = await inputVuelidate.value?.$validate();

  if (!valid || inputState.searchCode === "") {
    searchFailed.value = false;
  } else {
    try {
      productSearchResult.value = await search(inputState.searchCode);
      if (productSearchResult.value) {
        searchFailed.value = false;
        activeCategoryId.value = null;
      } else {
        searchFailed.value = true;
      }
    } catch (e) {
      console.error(e);
    }
  }
});
</script>
