<template>
  <div
    :id="isEditMode ? null : anchor"
    :class="{ 'spacing-gap-reset pointer-events-none h-0': !isEditMode }"
    class="anchor-target"
  >
    <div v-if="isEditMode" class="editmode-info-box break-all">ANCHOR-NAME: {{ anchorName }}</div>
  </div>
</template>

<script>
import { editableComponent } from "~/utils/mixins/editableComponent";
export default {
  name: "AnchorComponent",
  mixins: [editableComponent],
  props: ["anchorName"],
  computed: {
    anchor() {
      const anchorName = this.anchorName ?? "";
      return anchorName.startsWith("#") ? anchorName.replace("#", "") : anchorName;
    },
  },
};
</script>

<style>
.anchor-target {
  scroll-margin-top: theme("spacing.28");
}
</style>
