<template>
  <div class="image-teaser-component">
    <HeadlineComponent
      :headline="headline"
      class="mb-8 text-2xl text-dark-blue xl:text-3xl"
      tag="h2"
    ></HeadlineComponent>
    <EditableArea :content="main" :custom-view="ImageTeaserComponentArea" />
  </div>
</template>

<script setup>
import { EditableArea } from "@magnolia/vue-editor";
import ImageTeaserComponentArea from "./ImageTeaserComponentArea";
import HeadlineComponent from "./HeadlineComponent";

const props = defineProps(["main", "headline", "ratio"]);

provide("ratio", props?.ratio);
</script>
