<template>
  <div class="grid gap-14">
    <EditableArea :content="stage">
      <CheckoutSuccessStage
        :content="stage"
        :animate="!isLoading"
        :is-subscription-success="isSubscriptionSuccess"
      ></CheckoutSuccessStage>
    </EditableArea>
    <CheckoutSuccess :order="order" :is-loading="isLoading"></CheckoutSuccess>

    <EditableArea
      v-if="(showNewsletterRegistration && newsletterArea) || isEditMode"
      :content="newsletterArea"
      :custom-view="EmptyArea"
      class="container xl:px-20"
    >
      <NewsletterComponentArea
        v-if="showNewsletterRegistration"
        :skip-doi="true"
        :email="order?.orderCustomer.email"
        :content="newsletterArea"
      />
    </EditableArea>

    <EditableArea v-if="main" :content="main" />
  </div>
</template>
<script setup>
import { EditableArea } from "@magnolia/vue-editor";
import CheckoutSuccessStage from "~/templates/components/shop/checkout/CheckoutSuccessStage.vue";
import useMagnoliaContent from "~/composables/useMagnoliaContent";
import { isEditMode as evaluateEditMode } from "~/utils/content/magnolia";
import EmptyArea from "~/templates/areas/EmptyArea.vue";
import useBritaMiddleware from "~/composables/useBritaMiddleware";
import { ORDER_DETAILS_ASSOCIATIONS } from "~/utils/helper/shop/order";
import { useGuestOrderDetails } from "~/composables/shop/useGuestOrderDetails";
import NewsletterComponentArea from "~/templates/areas/NewsletterComponentArea.vue";
const isEditMode = evaluateEditMode();

defineOptions({
  inheritAttrs: false,
});
const { pushError } = useNotifications();
const props = defineProps(["title", "metadata", "main", "stage", "newsletterArea"]);

const { query } = useRoute();
const { isGuestSession, logout } = useUser();
const { refreshCart } = useCart();
const { getLabelByPath } = useCommonLabels();
const { getDoiState } = useBritaMiddleware();
const { pathVariable } = useMagnoliaContent();
const { clearOrder } = useOrderSession();

const { pushShopwareError } = useShopwareNotifications();
const technicalErrorMessage = getLabelByPath("BWF/newsletter/doi/technicalErrorMessage");

const showNewsletterRegistration = ref(false);
const orderId = pathVariable?.value?.replaceAll("/", "");
const { loadOrderDetails, order: regularOrder } = useOrderDetails(
  orderId,
  ORDER_DETAILS_ASSOCIATIONS,
);
const { loadGuestOrder, guestOrder } = useGuestOrderDetails();
const { trackPurchase } = useCheckoutTracking();

const orderEmail = computed(() => order?.value?.orderCustomer?.email);
const isLoading = ref(true);

const isGuestQuery = !!orderId && !!query.token;

const loadOrder = async () => {
  if (isGuestQuery) {
    // load and provide order as guest
    await loadGuestOrder(orderId, query.token);
  } else {
    // load order as regular customer
    await loadOrderDetails();
  }
};
const order = computed(() => {
  // prefer guest order as provide value over regular
  return guestOrder?.value ?? regularOrder?.value;
});
const hasSubscriptionProduct = computed(() => {
  return order?.value?.lineItems?.some((item) =>
    item.children[0]?.payload?.hasOwnProperty("subscription"),
  );
});

const isSubscriptionSuccess = computed(() => {
  return hasSubscriptionProduct.value && props.stage.subscriptionSubline;
});

const { verifyLogIn } = useMyBritaUser();
// track actual purchase
onMounted(async () => {
  try {
    try {
      await verifyLogIn();
    } catch (e) {
      console.log("CheckoutSuccessPage: could not verify login, will treat user as guest");
    }

    if (!isEditMode) {
      await loadOrder();
      trackPurchase(order.value);
      clearOrder();
    }
  } catch (e) {
    await pushShopwareError(e);
  }
  isLoading.value = false;
  // useUser
  if (isGuestSession.value) {
    await logout();
  }
  await refreshCart();

  if (order.value) {
    try {
      const response = await getDoiState(orderEmail.value);
      showNewsletterRegistration.value = response?.donotbulkemail;
    } catch (e) {
      pushError(technicalErrorMessage);
    }
  }
});
</script>
