<template>
  <dialog
    ref="dialog"
    class="h-full w-full flex-col items-center justify-center bg-transparent p-0"
    :class="{ 'is-open flex': reactiveOpen }"
    @close="onClose"
    @cancel="onClose"
    @click="onClick"
    @mousedown="stopPropagation"
    @touchstart="stopPropagation"
  >
    <form method="dialog">
      <button
        v-if="reactiveOpen"
        class="group fixed right-4 top-4 z-10 flex h-12 w-12 items-center justify-center rounded-full bg-white"
        value="cancel"
      >
        <div class="btn btn-icon group-hover:text-dark-blue">
          <SvgIcon name="icon-menu-close"></SvgIcon>
        </div>
      </button>
      <div class="container">
        <header class="flex justify-end"></header>
        <article>
          <slot></slot>
        </article>
        <footer></footer>
      </div>
    </form>
  </dialog>
</template>

<script setup>
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import { watch, onUpdated } from "vue";

const dialog = ref(null);
const props = defineProps({ open: Boolean });

const emit = defineEmits(["open", "close"]);

const reactiveOpen = ref(props.open);

function onClose() {
  reactiveOpen.value = false;
}

function onClick(event) {
  const { target } = event;
  // event.stopImmediatePropagation();

  if (target.nodeName === "DIALOG") {
    dialog.value.close("dismiss");
  }
}

function stopPropagation(event) {
  if (reactiveOpen.value) {
    event.stopPropagation();
  }
}

watch(reactiveOpen, (isOpen) => {
  if (isOpen) {
    dialog.value.showModal();
    emit("open");
  } else {
    dialog.value.close();
    emit("close");
  }
});
// prop changes need to be watched and copied to internal state
watch(
  () => props.open,
  (isOpen) => {
    reactiveOpen.value = isOpen;
  },
);

onUpdated(() => {
  const video = dialog.value.querySelector("video");
  if (video) {
    video.focus();
  }
});
</script>

<style scoped>
dialog::backdrop {
  @apply bg-black bg-opacity-75;
}

.is-open {
  @apply translate-y-0;
}
</style>
