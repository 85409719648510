<template>
  <AccountGrid>
    <LoadingElement v-if="isLoading" class="aspect-4/3"></LoadingElement>
    <template v-else>
      <BackButton class="mb-2 xl:mb-6"></BackButton>
      <OrderDetails
        :order="order"
        @order-action-cancel="cancellationModal.open()"
        @order-action-return="returnOrder()"
        @order-action-warranty="warrantyOrder()"
        @order-action-service="serviceModal.open()"
      />
    </template>
    <EditableArea :content="cancellationModalArea" :custom-view="EmptyArea" />
    <ModalComponent :content="cancellationModalArea" :controller="cancellationModal">
      <template #headline>Sicher? *</template>

      <template #primaryAction>
        <button class="btn btn-blue w-full min-w-fit" @click="onCancelOrder()">
          <CommonLabel path="BWF/shop/account/orderDetails/modal/confirmCancellation"></CommonLabel>
        </button>
      </template>
      <template #secondaryAction>
        <button class="btn btn-ghost w-full min-w-fit" @click="cancellationModal.close()">
          <CommonLabel path="BWF/shop/account/orderDetails/modal/declineCancellation"></CommonLabel>
        </button>
      </template>
    </ModalComponent>
    <EditableArea :content="serviceModalArea" :custom-view="EmptyArea" />
    <ModalComponent :content="serviceModalArea" :controller="serviceModal">
      <template #headline>Leiner Nein .. ? *</template>

      <template #primaryAction>
        <button class="btn btn-blue w-full min-w-fit" @click="serviceModal.close()">
          <CommonLabel path="BWF/shop/account/orderDetails/modal/confirmCancellation"></CommonLabel>
        </button>
      </template>
    </ModalComponent>
    <ModalComponent :controller="contractCancellationAcknowledgementModal">
      <template #headline>
        <p class="text-center">
          <CommonLabel path="BWF/shop/account/subscriptions/modal/confirmReturnHeadline" />
        </p>
      </template>
      <template #richText>
        <CommonLabel path="BWF/shop/account/subscriptions/modal/confirmReturnDescription"
      /></template>

      <div class="mx-auto grid w-full gap-5 md:grid-cols-2">
        <button class="btn btn-blue" @click="contractCancellation()">
          <CommonLabel path="BWF/shop/account/orderDetails/modal/confirmCancellation" />
        </button>
        <button class="btn btn-ghost" @click="contractCancellationAcknowledgementModal.close()">
          <CommonLabel path="BWF/shop/account/orderDetails/modal/declineCancellation" />
        </button>
      </div>
    </ModalComponent>
  </AccountGrid>
</template>

<script setup>
import { useShopwareContext } from "@shopware-pwa/composables-next";
import useMagnoliaContent from "~/composables/useMagnoliaContent";
import AccountGrid from "~/templates/components/account/AccountGrid.vue";
import LoadingElement from "~/templates/elements/LoadingElement.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import { useModal } from "~/composables/useModal";
import EmptyArea from "~/templates/areas/EmptyArea.vue";
import ModalComponent from "~/templates/components/ModalComponent.vue";
import { EditableArea } from "@magnolia/vue-editor";
import OrderDetails from "~/templates/components/shop/account/OrderDetails.vue";
import BackButton from "~/templates/components/BackButton.vue";
import { isEditMode } from "~/utils/content/magnolia";
import { hasContractPlan, ORDER_DETAILS_ASSOCIATIONS } from "~/utils/helper/shop/order";
import useShopwareNotifications from "~/composables/useShopwareNotifications";

defineOptions({
  inheritAttrs: false,
});

const { pushInfo, pushWarning } = useNotifications();
const { pushShopwareError } = useShopwareNotifications();
const { apiInstance } = useShopwareContext();
const { user } = useUser();
const { marketSetupAdmin } = useMarketSetup();
defineProps(["title", "description", "metadata", "cancellationModalArea", "serviceModalArea"]);
const { pathVariable } = useMagnoliaContent();
const orderId = pathVariable?.value?.replaceAll("/", "");

const { order, loadOrderDetails } = useOrderDetails(orderId, ORDER_DETAILS_ASSOCIATIONS);
const { push } = useRouter();

const isLoading = ref(true);

const returnOrder = async () => {
  try {
    const returnUrl = `${marketSetupAdmin.value?.myBritaOrderReturnPage}/${order?.value?.id}`;
    await push(returnUrl);
  } catch (e) {
    pushWarning(e?.messages?.[0]?.detail);
  }
};

const warrantyOrder = async () => {
  try {
    const warrantyUrl = `${marketSetupAdmin.value?.myBritaOrderWarrantyPage}/${order?.value?.id}`;
    await push(warrantyUrl);
  } catch (e) {
    /* Abbrechen oder Garantie melden? */
    pushWarning(e?.messages?.[0]?.detail);
  }
};
const contractCancellationAcknowledged = ref(false);

const contractCancellation = () => {
  contractCancellationAcknowledged.value = true;
  onCancelOrder();
  contractCancellationAcknowledgementModal.close();
};

const onCancelOrder = async () => {
  cancellationModal.close();
  if (hasContracts.value && !contractCancellationAcknowledged.value) {
    contractCancellationAcknowledgementModal.open();
  } else {
    try {
      const {
        data: {
          translated: { name },
        },
      } = await apiInstance.invoke.post("/store-api/order/cancel", {
        order_id: order?.value?.id,
        customer_email: user?.value?.email,
      });
      pushInfo(name);
      await loadOrderDetails();
    } catch (e) {
      await pushShopwareError(e);
    }
  }
};

const hasContracts = computed(() => hasContractPlan(order?.value?.lineItems));

const cancellationModal = useModal();
const contractCancellationAcknowledgementModal = useModal();
const serviceModal = useModal();

onMounted(async () => {
  if (orderId) {
    try {
      await loadOrderDetails();
    } catch (e) {
      if (!isEditMode()) {
        showError({ statusCode: 404, statusMessage: "not found" });
      }
    }
  }
  isLoading.value = false;
  if (!order?.value?.id && !isEditMode()) {
    // order not found -> error
    showError({ statusCode: 404, statusMessage: "not found" });
  }
});
</script>
