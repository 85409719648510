<template>
  <main>
    <div class="container">
      <div class="relative mb-24 mt-8">
        <BackButton
          v-if="uiState !== UI_STATE_SUCCESS && !isLoading"
          :custom-click="uiState !== UI_STATE_PRODUCT"
          class=""
          @click="setStateBack()"
        />

        <LoadingElement
          v-if="isLoading && !isEvaluatedEditMode"
          :overlay="true"
          class="h-full w-full bg-white/80"
        />

        <template v-if="uiState === UI_STATE_SUCCESS || isEvaluatedEditMode">
          <PregSuccessStep>
            <EditableArea :content="successStep" />
            <PregNewsletterRegistrationComponent
              v-if="!isEvaluatedEditMode"
              ref="newsletterComponent"
              v-model="doiConsent"
              :content="pregNewsletter"
              :ui-state="uiState"
              :email="email"
            />
          </PregSuccessStep>
        </template>

        <template v-if="uiState === UI_STATE_REVIEW || isEvaluatedEditMode">
          <EditableArea :content="reviewStep" />
          <PregReviewStep
            :selected-product="selectedProduct"
            @change-personal-data="editPersonalData = true"
            @submit-registration="submitRegistration()"
          >
            <PregNewsletterRegistrationComponent
              v-if="!isEvaluatedEditMode"
              ref="newsletterComponent"
              v-model="doiConsent"
              :content="pregNewsletter"
              :ui-state="uiState"
              :email="email"
            />
          </PregReviewStep>
        </template>

        <template v-if="uiState === UI_STATE_PERSONAL || isEvaluatedEditMode">
          <EditableArea :content="personalInformationStep" />
          <PregPersonalInformationForm @submit-personal-data="editPersonalData = false">
            <PregNewsletterRegistrationComponent
              v-if="!isEvaluatedEditMode"
              v-model="doiConsent"
              :content="pregNewsletter"
              :ui-state="uiState"
            />
          </PregPersonalInformationForm>
        </template>

        <template v-if="uiState === UI_STATE_LOGIN || isEvaluatedEditMode">
          <EditableArea :content="loginStep" />
          <PregLoginStep @guest-proceed="guestRegistration = true" />
        </template>

        <template v-if="uiState === UI_STATE_PRODUCT || isEvaluatedEditMode">
          <EditableArea :content="productSelectionStep" />
          <PregProductSelectionStep />
        </template>

        <div v-if="isEvaluatedEditMode">
          <EditableArea :content="pregNewsletter">
            <PregNewsletterRegistrationComponent :content="pregNewsletter" />
          </EditableArea>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { EditableArea } from "@magnolia/vue-editor";
import useProductRegistrationState from "~/composables/shop/useProductRegistrationState";
import { useProductRegistration } from "~/composables/shop/useProductRegistration";
import { isEditMode } from "~/utils/content/magnolia";
import usePregPersonalInformation from "~/composables/shop/usePregPersonalInformation";
import useBritaMiddleware from "~/composables/useBritaMiddleware";
import BackButton from "~/templates/components/BackButton.vue";
import PregNewsletterRegistrationComponent from "~/templates/components/cms/PregNewsletterRegistrationComponent.vue";
import LoadingElement from "~/templates/elements/LoadingElement.vue";

const isEvaluatedEditMode = isEditMode();

const props = defineProps([
  "productSelectionStep",
  "reviewStep",
  "personalInformationStep",
  "loginStep",
  "successStep",
  "pregNewsletter",
]);

const { customerType } = await usePregPersonalInformation();
const { allProducts } = await useProductRegistration();
const { createRegistration } = useBritaMiddleware();
const { hasProduct, registrationState, addModelValue, removeProduct, deleteRegistrationState } =
  await useProductRegistrationState();
const { pushError } = useNotifications();
const { verifyLogIn } = useMyBritaUser();

const selectedProduct = computed(() => {
  return allProducts.value?.find((product) => product.id === registrationState.value?.["id"]);
});

const newsletterComponent = ref(null);
const productRegistrationSuccess = ref(false);
const guestRegistration = ref(false);
const editPersonalData = ref(true);
const doiConsent = ref(false);
const isLoading = ref(true);
const email = ref("");

const UI_STATE_PRODUCT = "selection-step";
const UI_STATE_LOGIN = "login-step";
const UI_STATE_PERSONAL = "personal-information-step";
const UI_STATE_REVIEW = "review-step";
const UI_STATE_SUCCESS = "success-step";

const uiState = computed(() => {
  if (productRegistrationSuccess.value) {
    return UI_STATE_SUCCESS;
  }
  if (!hasProduct.value) {
    return UI_STATE_PRODUCT;
  } else if (customerType.value === 0 && !guestRegistration.value) {
    return UI_STATE_LOGIN;
  } else if (editPersonalData.value) {
    return UI_STATE_PERSONAL;
  } else if ((customerType.value === 1 || guestRegistration.value) && hasProduct.value) {
    return UI_STATE_REVIEW;
  }
  return UI_STATE_PRODUCT;
});

const setStateBack = () => {
  if (uiState.value === UI_STATE_LOGIN) {
    removeProduct();
  } else if (uiState.value === UI_STATE_PERSONAL) {
    guestRegistration.value = false;
  } else if (uiState.value === UI_STATE_REVIEW) {
    editPersonalData.value = true;
  }
};

const submitRegistration = async () => {
  isLoading.value = true;
  addModelValue("bri_customertype", customerType?.value);
  email.value = registrationState.value?.bri_email;

  try {
    await createRegistration(registrationState.value);
    productRegistrationSuccess.value = true;

    if (doiConsent.value) {
      if (newsletterComponent.value) {
        await newsletterComponent.value.submitNewsletterRegistration();
      }
    }
    // remove existing local storage item on successful registration
    deleteRegistrationState();
  } catch (e) {
    console.error(e);
    pushError(e);
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  try {
    await verifyLogIn();
  } catch (e) {
    console.log("PregPage: could not verify login, will treat user as guest");
  } finally {
    isLoading.value = false;
  }
});
</script>
