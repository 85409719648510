<template>
  <div v-if="getActiveOptionGroups?.length > 0" class="product-variant-configurator">
    <div
      v-if="isLoading"
      class="absolute inset-0 z-10 flex items-center justify-end bg-white/75"
    ></div>
    <div
      v-for="(optionGroup, index) in getActiveOptionGroups"
      :key="optionGroup.id"
      class="flex flex-col"
    >
      <fieldset class="block flex-1">
        <legend class="sr-only">Choose a {{ optionGroup.name }}</legend>
        <div v-if="showOptionName" class="flex gap-2">
          <p class="text-base font-medium leading-snug text-black">{{ optionGroup.name }}:</p>
          <span v-if="optionGroup.displayType === 'color'">{{ selectedOptionName(index) }}</span>
        </div>
        <div
          :class="{
            'mt-4': showOptionName,
          }"
          class="switch-container"
        >
          <template v-for="option in optionGroup.options" :key="option.id">
            <ProductVariantColorOption
              v-if="optionGroup.displayType === 'color'"
              :option="option"
              @click="handleChange(optionGroup.name, option.id, onHandleChange)"
            ></ProductVariantColorOption>

            <ProductVariantOption
              v-else-if="!colorsOnly"
              :option="option"
              :option-button-default="optionButtonDefault"
              @click="handleChange(optionGroup.name, option.id, onHandleChange)"
            ></ProductVariantOption>
          </template>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ComputedRef } from "vue";
import type { PropertyGroup } from "@shopware-pwa/types";

const props = defineProps({
  showOptionName: { type: Boolean, default: false },
  optionButtonDefault: { type: Boolean, default: false },
  colorsOnly: { type: Boolean, default: false },
});

const emit = defineEmits<{
  (e: "change", selected: never): void;
  (e: "beforeChange", selected: never): void;
}>();
const isLoading = ref<boolean>();
const { handleChange, getOptionGroups, getSelectedOptions, findVariantForSelectedOptions } =
  useProductConfigurator();

const selectedOptions: ComputedRef<string[]> = computed(() =>
  Object.values(unref(getSelectedOptions)),
);

const getActiveOptionGroups = computed<PropertyGroup[]>(() =>
  props.colorsOnly
    ? getOptionGroups.value.filter((group: PropertyGroup) => group.displayType === "color")
    : getOptionGroups.value,
);

const onHandleChange = async () => {
  emit("beforeChange", selectedOptions);
  isLoading.value = true;
  const variantFound = await findVariantForSelectedOptions(unref(selectedOptions));
  emit("change", variantFound);
  isLoading.value = false;
};

const selectedOptionName = (index: number) => {
  const selectedOption = getOptionGroups.value[index].options?.find(
    // @ts-ignores
    (option) => selectedOptions.value?.includes?.(option.id) ?? false,
  );
  return selectedOption?.translated?.name ?? "Nichts ausgewählt";
};
</script>

<style>
.product-variant-configurator {
  @apply relative flex flex-col gap-2;
}

.switch-container {
  @apply flex flex-wrap gap-2 md:gap-3;
}
.switch-btn {
  @apply h-5 w-5 border xl:h-7 xl:w-7;
  .switch-btn-inner {
    @apply h-4 w-4 border xl:h-6 xl:w-6;
  }
}
</style>
