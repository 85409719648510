<template>
  <div
    ref="categoryTile"
    class="safari-overflow-hidden-fix group relative w-full overflow-hidden rounded-2xl"
  >
    <ParallaxContainer class="relative inset-0">
      <PictureElement
        :breakpoints="imageBreakpoints"
        :img="image"
        img-class="aspect-3/4 object-cover transition-transform group-hover:scale-110 duration-300 w-full h-full"
      ></PictureElement>
    </ParallaxContainer>
    <div
      class="absolute inset-x-0 top-0 h-1/3 bg-gradient-to-b from-black to-transparent opacity-60"
    ></div>
    <div
      class="absolute inset-x-0 bottom-0 h-1/3 bg-gradient-to-t from-black to-transparent opacity-60"
    ></div>
    <div
      class="absolute inset-0 flex h-full flex-col items-start justify-between overflow-hidden p-6 text-white"
    >
      <LinkComponent :link="link" class="stretched-link" @click="trackPromoClick">
        <HeadlineComponent
          :headline="headline"
          class="pb-2 text-lg font-medium xl:text-2xl"
          tag="h3"
        ></HeadlineComponent>
      </LinkComponent>
      <TagList :tags="tags"></TagList>
    </div>
  </div>
</template>
<script setup>
import { DIMENSIONS } from "~/utils/helper/imaging";
import TagList from "~/templates/components/TagList.vue";
import HeadlineComponent from "~/templates/components/cms/HeadlineComponent.vue";
import LinkComponent from "~/templates/components/cms/LinkComponent.vue";
import PictureElement from "~/templates/elements/PictureElement.vue";
import ParallaxContainer from "~/templates/elements/ParallaxContainer.vue";
const props = defineProps(["headline", "image", "link", "tags", "metadata"]);

const categoryTile = ref(null);
const { trackPromoView, trackPromoClick } = usePromoTracking({
  metadata: props?.metadata,
  creativeName: props?.headline?.text,
});
const imageBreakpoints = {
  start: DIMENSIONS["start"].width["10/12"].ratio["3/4"].scale["1.5"],
  sm: DIMENSIONS["sm"].width["10/12"].ratio["3/4"].scale["1.5"],
  md: DIMENSIONS["md"].width["1/2"].ratio["3/4"].scale["1.5"],
  xl: DIMENSIONS["xl"].width["1/4"].ratio["3/4"].scale["1.5"],
  container: DIMENSIONS["container"].width["1/4"].ratio["3/4"].scale["1.5"],
};

onMounted(() => {
  trackPromoView(categoryTile);
});
</script>
