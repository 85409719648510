<template>
  <SliderElement
    v-if="isTabletOrMobile"
    :components="mapNodes(content)"
    :swiper-options="swiperOptions"
    class="swiper-btn-disabled"
  ></SliderElement>
  <div v-else class="responsive-gap-x grid grid-cols-1 gap-y-8 xl:grid-cols-2">
    <EditableComponent
      v-for="childContent in mapNodes(content)"
      :key="childContent['@id']"
      :content="childContent"
    />
  </div>
</template>

<script setup>
import { EditableComponent } from "@magnolia/vue-editor";
import { mapNodes } from "~/utils/helper/magnolia";
import SliderElement from "~/templates/elements/SliderElement.vue";
import { screens } from "~/tailwind.config.screens";
const { isTabletOrMobile } = useDevice();
provide("ratio", "square");
provide("columns", 2);

const swiperOptions = {
  slidesPerView: 1.2,
  spaceBetween: 8,
  centeredSlides: false,
  loop: false,
  breakpoints: {
    [parseInt(screens.md)]: {
      slidesPerView: 2,
      spaceBetween: 16,
    },
  },
};
</script>

<script>
export default {
  name: "ProductHeroArea",
  props: ["content"],
};
</script>
