<template>
  <div class="contact-box-tile rounded-2xl bg-light-grey p-6">
    <div
      v-if="headline.text || subline"
      class="mb-6 flex flex-col gap-1.5 border-b border-medium-grey"
    >
      <HeadlineComponent
        v-if="headline.text"
        :headline="headline"
        class="text-lg font-medium text-dark-blue last:mb-6"
      />

      <p v-if="subline" class="last:mb-6">{{ subline }}</p>
    </div>

    <div v-if="hasPhone || hasMail" class="flex flex-col gap-2">
      <LinkComponent
        v-if="hasPhone"
        :link="contact['phone-number']"
        class="text-icon-link gap-2 text-base text-black"
      >
        <SvgIcon class="h-5 w-5" name="Phone" />
        <span class="underline">
          {{ contact["phone-number"]?.label ?? contact["phone-number"]?.phone }}
        </span>
      </LinkComponent>

      <LinkComponent
        v-if="hasMail"
        :link="contact?.email"
        class="text-icon-link gap-2 text-base text-black"
      >
        <SvgIcon class="h-5 w-5" name="Mail" />
        <span class="underline">{{ contact?.email?.label ?? contact?.email?.email }}</span>
      </LinkComponent>
    </div>
    <p
      v-if="contact['additional-info'] && visible?.includes('additional-info')"
      class="mt-4 text-sm only:mt-0"
    >
      {{ contact["additional-info"] }}
    </p>
  </div>
</template>
<script setup>
import HeadlineComponent from "~/templates/components/cms/HeadlineComponent.vue";
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import LinkComponent from "~/templates/components/cms/LinkComponent.vue";
import { hasValue } from "~/utils/helper/magnolia";

const props = defineProps(["headline", "subline", "contact", "visible"]);
const hasPhone = ref(
  hasValue(props?.contact["phone-number"]?.type === "phone") &&
    props?.visible?.includes("phone-number"),
);
const hasMail = ref(
  hasValue(props?.contact?.email?.type === "mail") && props?.visible?.includes("email"),
);
</script>
