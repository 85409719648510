<template>
  <div>
    <EditableArea v-if="main" :content="main" />

    <div class="container mb-20">
      <LoadingElement
        v-if="isLoading && !isEditMode()"
        class="aspect-3/4 w-full lg:aspect-video"
      ></LoadingElement>

      <template v-else>
        <EditableArea v-if="isEmpty || isEditMode()" :content="emptyCartArea" />
        <div v-if="!isEmpty">
          <BackButton class="my-6 hidden xl:flex"></BackButton>
          <div class="my-6 grid gap-4 xl:grid-cols-3 xl:gap-6">
            <div class="flex flex-col gap-4 xl:col-span-2">
              <div class="flex items-center justify-between">
                <HeadlineComponent tag="h1" class="text-lg font-medium text-dark-blue xl:text-4xl">
                  <CommonLabel path="BWF/shop/checkout/cart/cartHeadline"></CommonLabel>
                </HeadlineComponent>
                <div v-if="count > 0" class="text-sm">
                  <CommonLabel class="font-medium" path="BWF/shop/shared/articleCount"></CommonLabel
                  >&nbsp;{{ count }}
                </div>
              </div>
              <hr />

              <ul role="list" class="">
                <li
                  v-for="(cartItem, index) in getLineItemsWithoutPromotion(cartItems)"
                  :key="cartItem.id"
                  role="listitem"
                >
                  <CheckoutCartItem :cart-item="cartItem" :column-names="index === 0" />
                  <hr
                    v-if="index < getLineItemsWithoutPromotion(cartItems).length - 1"
                    class="my-6"
                  />
                </li>
              </ul>
            </div>

            <aside class="flex flex-col gap-4 xl:col-span-1">
              <CheckoutSummary />
            </aside>
          </div>
          <hr class="col-span-full mb-10 hidden xl:block" />

          <div class="grid gap-8 xl:grid-cols-3">
            <div class="flex flex-col gap-10">
              <NuxtLink
                class="btn btn-blue btn-size-normal"
                :external="true"
                :to="marketSetupAdmin?.checkoutPage ?? ''"
              >
                <CommonLabel path="BWF/shop/checkout/cart/continueToCheckout"></CommonLabel>
              </NuxtLink>
              <ShopBenefits class="hidden xl:block"></ShopBenefits>
            </div>
            <div class="xl:order-first xl:col-span-2">
              <button class="text-icon-link mx-auto xl:mx-0" @click="$router.go(-1)">
                <SvgIcon name="Arrow-Up" class="h-4 w-4 -rotate-90"></SvgIcon>
                <CommonLabel path="BWF/shop/checkout/cart/backToShop"></CommonLabel>
              </button>
            </div>
            <ShopBenefits class="xl:hidden"></ShopBenefits>

            <CommonLabel class="mt-4 text-sm xl:mt-14" path="BWF/shared/vatFootnote" />
          </div>
        </div>
      </template>
    </div>

    <EditableArea v-if="bottom" :content="bottom" />
  </div>
</template>

<script setup>
import { EditableArea } from "@magnolia/vue-editor";
import CheckoutCartItem from "../components/shop/checkout/CheckoutCartItem.vue";
import LoadingElement from "~/templates/elements/LoadingElement.vue";
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import HeadlineComponent from "~/templates/components/cms/HeadlineComponent.vue";
import CheckoutSummary from "~/templates/components/shop/checkout/CheckoutSummary.vue";
import { getLineItemsWithoutPromotion } from "~/utils/helper/shop/cart";
import ShopBenefits from "~/templates/components/shop/product/ShopBenefits.vue";
import BackButton from "~/templates/components/BackButton.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import { useCartInformation } from "~/composables/shop/useCartInformation";
import { isEditMode } from "~/utils/content/magnolia";
import useCheckoutTracking from "~/composables/tracking/useCheckoutTracking";
import useShopwareNotifications from "~/composables/useShopwareNotifications";
import { useNotifications } from "@shopware-pwa/composables-next";

defineOptions({
  inheritAttrs: false,
});
const { marketSetupAdmin } = useMarketSetup();

const { cartItems, cart, consumeCartErrors } = useCart();
const { getLabelByPath } = useCommonLabels();
const { isEmpty, count } = useCartInformation();
const { pushShopwareCartErrors } = useShopwareNotifications();
const { pushSuccess } = useNotifications();

const isLoading = ref(true);
defineProps(["title", "metadata", "main", "emptyCartArea", "bottom"]);
const { trackCartView } = useCheckoutTracking();

watch(
  cart,
  () => {
    if (cart?.value) {
      // means loading finally succeeded
      isLoading.value = false;
    }

    const errors = consumeCartErrors();
    if (errors) {
      pushShopwareCartErrors(errors);
      if (
        Object.values(errors).find((error) => error.messageKey.includes("promotion-discount-added"))
      ) {
        const discountAdded = getLabelByPath("BWF/shop/messages/promotion-discount-added");
        pushSuccess(discountAdded);
      }
    }
  },
  { immediate: cart?.value },
);

watch(isLoading, () => {
  if (isLoading.value === false) {
    trackCartView();
  }
});
</script>
