<template>
  <ColorOptionCircleButton
    :is-option-not-available="isOptionNotAvailable || isOptionNotCombinable"
    :is-option-selected="isOptionSelected"
    :option="option"
  />
</template>

<script setup lang="ts">
import ColorOptionCircleButton from "~/templates/elements/ColorOptionCircleButton.vue";

const { product } = useProduct();
const props = defineProps(["option"]);

const isOptionNotCombinable = computed((): boolean => {
  // @ts-ignore
  return props.option?.extensions?.combinable?.combinable === false;
});
const isOptionSelected = computed(() => product.value?.optionIds?.includes(props.option.id));

const isOptionNotAvailable = computed((): boolean => {
  return !product.value.available && isOptionSelected.value;
});
</script>
