<template>
  <div>
    <h4 class="mb-2 font-medium">
      <CommonLabel path="BWF/shop/account/subscriptions/contract/cylinderLabel"></CommonLabel>
    </h4>
    <div class="flex flex-col">
      <CommonLabel
        class="text-medium-grey"
        path="BWF/shop/account/subscriptions/contract/cylinderAtHome"
      ></CommonLabel>
      <CommonLabel
        path="BWF/shop/account/subscriptions/contract/cylindersAtHomeInformation"
        :placeholder="{
          amount: contract?.deposit?.depositItemQuantity,
          label: plan?.product?.customFields?.['contract_deposit_line_item_label'],
          price: getIntlFormattedPrice(contract?.deposit?.depositItemUnitPrice),
        }"
      ></CommonLabel>
      <CommonLabel
        class="text-medium-grey"
        path="BWF/shop/account/subscriptions/contract/contractDeposit"
      ></CommonLabel>
      <SharedPrice :value="contract?.deposit?.contractTotalDepositAmount"></SharedPrice>
      <h4 class="my-2 font-medium">
        <CommonLabel path="BWF/shop/account/subscriptions/contract/lastChangeLabel"></CommonLabel>
      </h4>
      <FormattedDate v-if="lastChangedDate" :date="lastChangedDate"></FormattedDate>
    </div>
  </div>
</template>

<script setup>
import CommonLabel from "~/templates/components/CommonLabel.vue";
import FormattedDate from "~/templates/elements/FormattedDate.vue";

const props = defineProps(["contract", "plan"]);

const { getIntlFormattedPrice } = usePrice();

const lastChangedDate = computed(() => {
  const webContractOrders = props.contract?.webContractOrders ?? [];

  // @ts-ignore
  webContractOrders.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    // @ts-ignore Sort by latest date in descending order
    return dateB - dateA; // @ts-ignore Sort by latest date in descending order
  });

  return webContractOrders[0]?.order?.orderDate;
});
</script>
