<template>
  <footer class="z-10 w-full" role="contentinfo">
    <div
      class="mt-4 flex w-full justify-center gap-8 rounded-t-2xl bg-light-grey p-4 text-center text-xs shadow-md"
    >
      <div class="container py-8 text-left text-black">
        <div v-for="row in rows" :key="getId(row)">
          <FooterGrid :row="row"></FooterGrid>
          <hr class="my-8 border-black" />
        </div>
        <FooterImprint v-if="imprint" :imprint="imprint"></FooterImprint>
      </div>
    </div>
  </footer>
</template>

<script setup>
import FooterImprint from "./footer/FooterImprint";
import FooterGrid from "./footer/FooterGrid";
import { inject } from "vue";
import { filterNodesByType, mapNodes, getId } from "~/utils/helper/magnolia";

import useNavigationTracking, {
  NAVIGATION_FOOTER,
} from "~/composables/tracking/useNavigationTracking";
useNavigationTracking(NAVIGATION_FOOTER);

const pageFooter = inject("pageFooter", "");

const rows = filterNodesByType(mapNodes(pageFooter.value), "footer:row");
const imprint = filterNodesByType(mapNodes(pageFooter.value), "footer:imprint")?.[0];
</script>
