<template>
  <div
    v-for="paymentOption in paymentMethods"
    :key="paymentOption.id"
    class="col-span-full rounded-lg border p-4"
  >
    <RadioField v-model="selectedPaymentMethod" :value="paymentOption.id" name="payment-method">
      <div class="flex w-full gap-2">
        <div class="flex flex-col gap-1">
          {{ paymentOption.name }}
          <div v-if="paymentOption.description" class="text-sm text-medium-grey">
            {{ paymentOption.description }}
          </div>
        </div>
        <img
          v-if="paymentOption?.media?.url"
          :alt="paymentOption.name"
          :src="paymentOption.media?.url"
          class="ml-auto h-8"
        />
      </div>
    </RadioField>

    <div
      v-if="paymentOption.customFields?.mollie_payment_method_name === 'creditcard'"
      :class="{ hidden: !creditCardSelected }"
      class="mt-4"
    >
      <ShopwareFrontendsCreditCard ref="creditCardComponent" />
    </div>
  </div>
</template>

<script setup lang="ts">
import RadioField from "~/templates/elements/form/RadioField.vue";
import ShopwareFrontendsCreditCard from "~/templates/components/shop/checkout/ShopwareFrontendsCreditCard.vue";
import type { PaymentMethod } from "@shopware-pwa/types";
const props = defineProps(["paymentMethods", "selectedMethod"]);
const emit = defineEmits(["change"]);

const creditCardComponent = ref(null);

const selectedPaymentMethod = defineModel("selectedPaymentMethod");

const selectedOption = computed(() => {
  return props.paymentMethods?.find(
    (method: PaymentMethod) => method.id === selectedPaymentMethod.value,
  );
});

const creditCardSelected = computed(() => {
  return selectedOption.value?.customFields?.mollie_payment_method_name === "creditcard";
});

watch(selectedPaymentMethod, () => {
  emit("change", selectedPaymentMethod.value);
});

const validate = async () => {
  let valid = !!selectedPaymentMethod.value;
  if (creditCardSelected.value && creditCardComponent.value?.[0]) {
    const token = await creditCardComponent.value?.[0]?.checkFieldsAndSubmitToken?.();
    valid = !!token;
  }

  return valid;
};

defineExpose({ validate });
</script>
