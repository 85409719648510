import { defineNuxtPlugin } from "#imports";
import useMagnoliaContext from "~/composables/useMagnoliaContext";
import useMarketContent from "~/composables/useMarketContent";
import useMagnoliaContent from "~/composables/useMagnoliaContent";
import { getTemplate } from "~/utils/helper/magnolia";
const noCacheTemplates = [
  "d2c-lm:pages/shop/cart",
  "d2c-lm:pages/shop/checkout",
  "d2c-lm:pages/shop/checkout.success",
  "d2c-lm:pages/shop/order-lookup",
  "d2c-lm:pages/shop/order-payment",
];

export default defineNuxtPlugin({
  name: "magnolia-content-plugin",
  enforce: "pre", // or 'post'
  async setup(nuxtApp) {
    const {
      public: { debugDisableSsr },
    } = useRuntimeConfig();

    const { pathname } = useRequestURL();
    if (import.meta.server || debugDisableSsr) {
      if (
        pathname?.startsWith("/r/") ||
        pathname?.startsWith("/static-") ||
        pathname?.startsWith("/adb2c")
      ) {
        // TODO remove static and adb2c once they are separated into their own workspace
        // plugin should never run for redirect pages as they have a dedicated logic to load market setup
        return;
      }
    }

    const { initMagnoliaContext, verifyMagnoliaContext, contentPath } = useMagnoliaContext();
    const { loadMarketContent } = useMarketContent();
    const { initLanguages } = useMagnoliaLanguage();
    const { loadContent, content, pageHeader, pageFooter, pageBanner } = useMagnoliaContent();

    // exposes data globally via e.g. useNuxtApp().$content
    nuxtApp.provide("content", content);
    nuxtApp.provide("pageHeader", pageHeader);
    nuxtApp.provide("pageFooter", pageFooter);
    nuxtApp.provide("pagePromoBanner", pageBanner);

    // starts injection chain globally on vue level with named provides
    nuxtApp.vueApp.provide("content", content);
    nuxtApp.vueApp.provide("pageHeader", pageHeader);
    nuxtApp.vueApp.provide("pageFooter", pageFooter);
    nuxtApp.vueApp.provide("pagePromoBanner", pageBanner);

    if (import.meta.server || debugDisableSsr) {
      // fetch data on server or if SSR is disabled explicitly
      try {
        // init and verify magnolia context
        await verifyMagnoliaContext();
        // do this only on first request or when ssr is disabled
        await initLanguages();
        // init magnolia context from current url
        await initMagnoliaContext();
        // load market content
        await loadMarketContent(contentPath.value);
        // load page content
        await loadContent(contentPath.value);

        const template = getTemplate(content?.value ?? {});
        if (import.meta.server && noCacheTemplates.includes(template)) {
          const myHeader = useResponseHeader("Cache-Control");
          myHeader.value = "no-cache, no-store";
        }
      } catch (e) {
        // show the actual error
        showError(e);
      }
    }
  },
});
