/**
 *
 * filters {{ key: value }} pairs with empty values from formData Objects
 *
 * trims trailing white spaces by iterating through every {{ key: 'value' }} pair and returns a clean formData Object
 *
 * e.g.:
 * data: { foo: 'bar   ', foo2: '', foo3: 'bar3' }
 *
 * returns: { foo: 'bar', foo3: 'bar3' }
 *
 *
 */

export const cleanupFormData = (data) => {
  return Object.fromEntries(
    Object.entries(data)
      .filter(([, value]) => value !== "")
      .map(([key, value]) => [key, value.trim()]),
  );
};
