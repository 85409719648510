<template>
  <p
    :class="
      contentToTailwindClasses({
        fontSize,
      })
    "
    class="hyphens-auto whitespace-pre-wrap font-medium"
  >
    {{ text }}
  </p>
</template>

<script>
import { contentToTailwindClasses } from "~/utils/helper/tailwind";

export default {
  name: "EditorialStoryTextComponent",
  props: ["text", "fontSize"],
  methods: { contentToTailwindClasses },
};
</script>
