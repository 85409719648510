<template>
  <div class="container">
    <div v-if="isLoading || inAuthenticationProgress || sessionOrderId" class="z-10 bg-white">
      <LoadingElement class="aspect-video w-full"></LoadingElement>
    </div>

    <div v-else-if="hasItems" class="checkout-inner mb-20 mt-8">
      <BackButton class="my-6 hidden xl:flex" :to="marketSetupLinks.cartPage"></BackButton>

      <CheckoutMyBritaDecision
        v-if="!isConsciousGuest && !isLoggedIn"
        @guest="isConsciousGuest = true"
      ></CheckoutMyBritaDecision>

      <CheckoutAddressForm
        v-else-if="(!isGuestSession && !isLoggedIn) || isIncomplete"
        ref="registerForm"
      ></CheckoutAddressForm>
      <CheckoutComplete v-else></CheckoutComplete>
    </div>
    <div v-else class="flex aspect-video flex-col items-center justify-center text-center">
      <h3 class="m-10 text-2xl font-medium text-dark-blue xl:text-4xl">
        <CommonLabel path="BWF/shop/checkout/cart/emptyLabel"></CommonLabel>
      </h3>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "CheckoutPage",
};
</script>

<script setup lang="ts">
import LoadingElement from "~/templates/elements/LoadingElement.vue";
import CheckoutAddressForm from "~/templates/components/shop/checkout/CheckoutAddressForm.vue";
import CheckoutComplete from "~/templates/components/shop/checkout/CheckoutComplete.vue";
import CheckoutMyBritaDecision from "~/templates/components/shop/checkout/CheckoutMyBritaDecision.vue";
import BackButton from "~/templates/components/BackButton.vue";
import { isDummyUser } from "~/utils/helper/shop/user";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import useCheckoutTracking from "~/composables/tracking/useCheckoutTracking";

defineOptions({
  inheritAttrs: false,
});
const { isLoggedIn, user, isGuestSession, userDefaultBillingAddress, userDefaultShippingAddress } =
  useUser();

const { clearOrder, sessionOrderId, getQueryString } = useOrderSession();
const { inAuthenticationProgress } = useMyBritaUser();
const { cartItems, refreshCart } = useCart();

const { marketSetupLinks } = useMarketSetup();

defineProps(["title", "description"]);

const isLoading = ref(true);
const isConsciousGuest = ref(false);

const registerForm = ref(null);

const isIncomplete = computed(() => {
  // show the form if user is either anonymus OR incomplete (missing an address)
  const hasInvalidShipping = userDefaultShippingAddress.value?.country?.shippingAvailable !== true;
  return (
    (isLoggedIn.value && !userDefaultBillingAddress?.value?.countryId) ||
    isDummyUser(user.value) ||
    hasInvalidShipping
  );
});
const { trackBeginCheckout } = useCheckoutTracking();
const { verifyLogIn } = useMyBritaUser();
onMounted(async () => {
  /*
   * This is needed to force the page to reevaluate state after potentially coming back from payment.
   *
   * Browsers may store the last state (before redirect) in memory
   * https://web.dev/articles/bfcache?hl=de
   *
   * This is basically a "browser back" edge case
   * */
  window.addEventListener("pageshow", (evt) => {
    if (evt.persisted) {
      reloadNuxtApp({ force: true });
    }
  });
  // we need to await login state here to determine wether user was a guest or not
  try {
    await verifyLogIn();
  } catch (e) {
    console.log("checkout: could not verify login, will treat user as guest");
  }

  await refreshCart();

  if (!hasItems.value && sessionOrderId.value) {
    // has unprocessed order
    if (isLoggedIn.value) {
      // goto error page
      await navigateTo(`${marketSetupLinks.value?.orderPaymentPage}/${sessionOrderId.value}`, {
        external: true,
      });
    } else {
      await navigateTo(
        `${marketSetupLinks.value?.orderPaymentPage}/${sessionOrderId.value}?${getQueryString()}`,
        { external: true },
      );
    }
    // need a return here as otherwise loading is set to false before navigation
    return;
  } else if (sessionOrderId.value) {
    clearOrder();
  }
  isLoading.value = false;
  trackBeginCheckout();
});

const hasItems = computed(() => cartItems.value.length > 0);

watch([isGuestSession, isConsciousGuest, isLoggedIn, isIncomplete], () => {
  window.scrollTo(0, 0);
});
</script>
