const usePregPersonalInformation = async () => {
  const { getLabels } = useCommonLabels();

  const personalInformationLabels = getLabels("/BWF/shop/shared/addressForm/", [
    "chooseSalutationLabel",
    "firstNameLabel",
    "firstNamePlaceholder",
    "lastNameLabel",
    "lastNamePlaceholder",
    "streetAndHouseNoLabel",
    "streetAndHouseNoPlaceholder",
    "postalCodeLabel",
    "postalCodePlaceholder",
    "cityLabel",
    "cityPlaceholder",
    "addressSuffixLabel",
    "addressSuffixPlaceholder",
    "countryLabel",
    "eMailAddressLabel",
    "eMailAddressPlaceholder",
  ]);

  const {
    firstNameValidators,
    lastNameValidators,
    emailValidators,
    streetNumberValidators,
    addressAdditionalValidators,
    cityValidators,
    zipCodeValidators,
    requiredValidator,
  } = await useValidation();

  const salutationLabels = getLabels("/BWF/form/shared/salutations/", [
    "not_specified",
    "mr",
    "mrs",
    "diverse",
  ]);
  const selectableSalutations = [
    ...(Object.entries(salutationLabels ?? {})?.map(([key, value]) => ({
      value: key,
      label: value,
    })) ?? []),
  ];

  const countryLabels = getLabels("/BWF/form/shared/country/", ["DE", "FR", "BE"]);
  const selectableCountries = [
    ...(Object.entries(countryLabels ?? {})?.map(([key, value]) => ({
      value: key,
      label: value,
    })) ?? []),
  ];

  const pregPersonalInformationRules = computed(() => ({
    salutation: {
      ...requiredValidator,
    },
    firstName: {
      ...requiredValidator,
      ...firstNameValidators,
    },
    lastName: {
      ...requiredValidator,
      ...lastNameValidators,
    },
    email: {
      ...requiredValidator,
      ...emailValidators,
    },
    street: {
      ...streetNumberValidators,
    },
    postalCode: {
      ...zipCodeValidators,
    },
    city: {
      ...cityValidators,
    },
    additionalAddressLine1: {
      ...addressAdditionalValidators,
    },
  }));

  const { azureUser } = useAzureUser();
  const customerType = computed(() => {
    // 1 is logged in
    // 0 is guest
    return azureUser.value?.homeAccountId ? 1 : 0;
  });

  return {
    customerType,
    personalInformationLabels,
    selectableSalutations,
    selectableCountries,
    pregPersonalInformationRules,
  };
};

export default createSharedComposable(usePregPersonalInformation);
