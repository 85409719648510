import { ref, type Ref } from "vue";
import type { ShopwareApiInstance } from "@shopware-pwa/api-client";
import type { EntityResult, Order, ShopwareSearchParams } from "@shopware-pwa/types";
import {
  useCustomerOrders as useCoreCustomerOrders,
  type UseCustomerOrdersReturn,
} from "@shopware-pwa/composables-next";

export type UseCustomerOrdersReturnExtras = {
  /**
   * total count
   */
  total: Ref<number>;
};

type CustomerOrdersResponse = {
  apiAlias: "order-route-response-struct";
  orders: EntityResult<"order", Order>;
  paymentChangeable: Array<unknown>;
};

const getCustomerOrders = async function (
  parameters: ShopwareSearchParams = {},
  contextInstance: ShopwareApiInstance,
) {
  const resp = await contextInstance.invoke.post<CustomerOrdersResponse>(
    `/store-api/order-history`,
    parameters,
  );
  return resp.data.orders;
};

/**
 * Composable for fetching the orders list.
 * @public
 * @category Customer & Account
 */
export function useCustomerOrders(): UseCustomerOrdersReturn & UseCustomerOrdersReturnExtras {
  const { orders, changeCurrentPage } = useCoreCustomerOrders();
  const { apiInstance } = useShopwareContext();

  const total: Ref<number> = ref(0);

  const loadOrders = async (parameters: ShopwareSearchParams = {}): Promise<void> => {
    const fetchedOrders = await getCustomerOrders(parameters, apiInstance);
    orders.value = fetchedOrders?.elements;
    total.value = fetchedOrders?.total ?? 0;
  };

  return {
    orders,
    total,
    changeCurrentPage,
    loadOrders,
  };
}
