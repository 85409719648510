<template>
  <div>
    <h4 class="mb-2 font-medium">
      <CommonLabel path="BWF/shop/account/subscriptions/contract/nextDeliveryLabel"></CommonLabel>
    </h4>
    <div class="flex flex-col">
      <CommonLabel
        class="text-medium-grey"
        path="BWF/shop/account/subscriptions/contract/nextExecutionDate"
      ></CommonLabel>
      <FormattedDate
        v-if="nextExecutionDate"
        :date="nextExecutionDate"
        pattern="dddd DD.MM.YY"
      ></FormattedDate>

      <CommonLabel
        class="text-medium-grey"
        path="BWF/shop/account/subscriptions/contract/costLabel"
      ></CommonLabel>

      <SharedPrice v-if="subscriptionUnitPrice" :value="subscriptionUnitPrice"></SharedPrice>

      <CommonLabel
        class="mt-4 font-medium"
        path="BWF/shop/account/subscriptions/contract/deliveryIntervalLabel"
      ></CommonLabel>
      <p>
        <CommonLabel path="BWF/shop/account/subscriptions/contract/every"></CommonLabel
        ><span>{{ ` ${contract.interval} ${intervalUnitLabel}` }}</span>
      </p>
    </div>
  </div>
</template>

<script setup>
import CommonLabel from "~/templates/components/CommonLabel.vue";
import FormattedDate from "~/templates/elements/FormattedDate.vue";

const { getLabels } = useCommonLabels();
const props = defineProps(["contract"]);

const intervalLabels = getLabels("BWF/shop/account/subscriptions/contract", [
  "days",
  "weeks",
  "months",
  "years",
]);

const nextExecutionDate = computed(() => {
  return props.contract?.nextExecutionDate;
});

const intervalUnitLabel = intervalLabels[`${props.contract.intervalUnit}`];

const subscriptionUnitPrice = computed(() => {
  return props.contract?.contractProducts[0]?.productUnitGrossPrice;
});
</script>
