<template>
  <div class="story-overview-component">
    <HeadlineComponent
      v-if="headline"
      :headline="headline"
      class="text-2xl font-medium text-dark-blue md:text-3xl"
    ></HeadlineComponent>
    <EditableArea v-if="isEditMode" :content="filter" class="story-overview-filter" />
    <div class="mt-4 flex flex-col gap-4">
      <p class="text-base font-normal leading-6">
        <CommonLabel path="BWF/components/story-overview/filterBy"></CommonLabel>
      </p>
      <FilterButtons
        :all-label="filter?.allLabel ?? 'All'"
        :filters="categoryFilterButtons"
        @filter-change="onFilter"
      />
    </div>
    <!--      article cards-->
    <div class="mt-8">
      <div
        :class="{
          'grid-cols-1': parseInt(columns) === 1,
          'grid-cols-2': parseInt(columns) === 2,
        }"
        class="responsive-gap grid hyphens-auto sm:grid-cols-2 xl:grid-cols-4"
      >
        <article
          v-for="article in visibleArticles"
          :key="article.id"
          class="relative flex flex-col items-start justify-between overflow-hidden rounded-2xl"
        >
          <div class="relative w-full">
            <PictureElement
              :breakpoints="BREAKPOINTS"
              :img="article?.image"
              img-class="aspect-3/2 w-full h-full object-cover xl:aspect-video"
            ></PictureElement>
          </div>
          <div
            :class="{
              'p-6 pb-8': parseInt(columns) === 1,
              'p-3 pb-4': parseInt(columns) === 2,
            }"
            class="w-full flex-1 bg-light-grey md:p-6 md:pb-8"
          >
            <LinkComponent
              :link="article?.link"
              optional-link-classes="stretched-link"
              @click="() => trackArticleSelect(article?.title)"
            >
              <h3
                :class="{
                  'text-lg leading-6': parseInt(columns) === 1,
                  'text-sm leading-5 md:text-lg md:leading-6': parseInt(columns) === 2,
                }"
                class="font-medium"
              >
                {{ article?.title }}
              </h3>
            </LinkComponent>
            <p
              :class="{
                'text-base leading-snug': parseInt(columns) === 1,
                'text-sm leading-4 xl:text-base xl:leading-snug': parseInt(columns) === 2,
              }"
              class="mt-2 font-normal"
            >
              {{ article?.description }}
            </p>
          </div>
        </article>
      </div>
    </div>
    <div class="mx-auto mt-12 flex max-w-md flex-col gap-4 lg:mt-16">
      <p class="text-center">
        <CommonLabel path="BWF/components/story-overview/seenArticleLeading"></CommonLabel>
        <span class="font-medium">&nbsp;{{ visibleArticles.length }}&nbsp;</span>
        <CommonLabel path="BWF/components/story-overview/outOf"></CommonLabel>
        <span class="font-medium">&nbsp;{{ totalSize }}&nbsp;</span>
        <CommonLabel path="BWF/components/story-overview/seenArticleTailing"></CommonLabel>
      </p>
      <button
        :disabled="visibleArticles.length >= totalSize"
        class="btn btn-ghost self-center"
        @click="loadMore()"
      >
        <CommonLabel path="BWF/components/story-overview/displayMore"></CommonLabel>
      </button>
    </div>
  </div>
</template>

<script setup>
import HeadlineComponent from "./HeadlineComponent";
import { EditableArea } from "@magnolia/vue-editor";
import PictureElement from "~/templates/elements/PictureElement.vue";
import { DIMENSIONS } from "~/utils/helper/imaging";
import LinkComponent from "~/templates/components/cms/LinkComponent.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import FilterButtons from "~/templates/components/FilterButtons.vue";
import useMagnoliaSite from "~/composables/useMagnoliaSite";
import { isEditMode as evaluateEditMode } from "~/utils/content/magnolia";
import useFilterTracking from "~/composables/tracking/useFilterTracking";

const FILTER_TYPE = "article_filter";
const { trackArticleSelect } = useArticleTracking();

const props = defineProps(["headline", "filter", "columns", "page", "metadata"]);
const visibleArticles = ref([]);
const isEditMode = evaluateEditMode();
const activeFilters = ref([]);
const offset = ref(0);
const totalSize = ref(0);

const {
  public: { mgnlApi },
} = useRuntimeConfig();

const { currentLanguage } = useMagnoliaLanguage();
const BREAKPOINTS = {
  start:
    props.columns === 2
      ? DIMENSIONS["start"].width["1/2"].ratio["3/2"]
      : DIMENSIONS["start"].ratio["3/2"],
  sm: DIMENSIONS["sm"].width["1/2"].ratio["3/2"],
  md: DIMENSIONS["md"].width["1/2"].ratio["3/2"],
  xl: DIMENSIONS["xl"].width["1/4"].ratio["3/2"],
};

const { mgnlSiteHeaders } = useMagnoliaSite();

const { data, error } = await useFetch(`${mgnlApi}/.rest/article/overview/v1/${props.page}`, {
  query: { lang: currentLanguage, filters: activeFilters, offset, limit: 10 },
  headers: mgnlSiteHeaders,
  key: props.metadata["@id"],
});

watch(
  data,
  async () => {
    if (data?.value?.articles?.length === 0) {
      visibleArticles.value = [];
    } else {
      if (data?.value?.offset === 0) {
        visibleArticles.value = data?.value?.articles ?? [];
      } else {
        visibleArticles.value = [...visibleArticles.value, ...data?.value?.articles];
      }
    }
    totalSize.value = data.value?.totalSize;
  },
  { immediate: !error.value },
);

const categoryFilterButtons = [
  ...(props.filter?.tags?.map((tag) => ({
    label: tag["value"],
    value: tag["@id"],
  })) ?? []),
];

useFilterTracking({
  type: FILTER_TYPE,
  filters: categoryFilterButtons,
  activeFilters: activeFilters,
});

const onFilter = (currentFilter) => {
  restoreDefaultPagination();
  activeFilters.value = currentFilter;
};

const restoreDefaultPagination = () => {
  offset.value = 0;
};

const loadMore = () => {
  offset.value = visibleArticles.value.length;
};
</script>
