<template>
  <EditableArea v-if="main" :content="main" />
</template>

<script setup>
defineOptions({
  inheritAttrs: false,
});
</script>
<script>
import { EditableArea } from "@magnolia/vue-editor";

export default {
  name: "ErrorPage",
  components: {
    EditableArea,
  },
  props: ["title", "metadata", "main"],

  data() {
    return {
      context: null,
    };
  },
};
</script>
