import { cleanFullPath } from "~/utils/content/magnolia";

const useMagnoliaContext = function () {
  const contentPath = useState("magnolia-content-path", () => "");
  const { currentLanguage, availableLanguages } = useMagnoliaLanguage();
  const {
    public: { mgnlSitePath, mgnlAuthor, mgnlPublicHost, mgnlApi },
  } = useRuntimeConfig();
  const route = useRoute();

  const magnoliaAuthorRedirect = async () => {
    const isOnAuthorInstance = !!mgnlAuthor;
    if (isOnAuthorInstance) {
      const authorRootNodeCookie = useCookie("authorRootNode");
      if (authorRootNodeCookie.value) {
        // author cannot load languages without rootnode / context path. redirect to last known rootnode

        await navigateTo(`/${authorRootNodeCookie.value}${route.hash}`, {
          replace: true,
          external: true,
          redirectCode: 302,
        });
      }
    }
  };

  /**
   * does a basic check if mgn is properly configured
   */
  const verifyMagnoliaContext = async () => {
    if (mgnlApi?.length === 0) {
      // misconfigured env
      throw createError({
        statusCode: 503,
        statusMessage: "No configuration for MGNL Public Host",
      });
    }
    const isOnAuthorInstance = !!mgnlAuthor;
    if (!isOnAuthorInstance && !mgnlSitePath) {
      throw createError({ statusCode: 503, statusMessage: "MGNL SitePath is missing" });
    }
  };

  /**
   * @param staticPath { String} can be used to overwrite current request path.
   */
  const initMagnoliaContext = async (staticPath = "") => {
    const isOnAuthorInstance = !!mgnlAuthor;
    // bwf request path
    const fullPath = staticPath || cleanFullPath();
    if (isOnAuthorInstance) {
      const authorRootNodeCookie = useCookie("authorRootNode", { secure: true });
      if (fullPath.length > 1) {
        // if on author, consider first url part as rootnode and cache in cookie
        const [firstRootNode, secondRootNode] = fullPath.split("/").filter((entry) => !!entry);
        const rootNode = availableLanguages?.value?.find(
          (lang) => lang.toLowerCase() === firstRootNode.toLowerCase(),
        )
          ? secondRootNode
          : firstRootNode;
        if (rootNode.length >= 2 && rootNode.length <= 3) {
          // set last market as the last-edited marked
          // ignore /r/ and other technical pages that differs in length
          authorRootNodeCookie.value = rootNode;
        }
      }
    }

    let processedContentPath = fullPath;
    const requestLocale = fullPath.split("/")[1];
    // AUTHOR -> [locale_LOCALE] / [mgnlSitePath] / [contentPath]
    // PUBLIC -> [locale_LOCALE] / [contentPath]
    if (
      availableLanguages.value?.find(
        (language) => language.toLowerCase() === requestLocale?.toLowerCase(),
      ) &&
      requestLocale?.toLowerCase() === currentLanguage?.value?.toLowerCase()
    ) {
      // remove locale from content path
      processedContentPath = processedContentPath.slice(requestLocale?.length + 1);
    }
    if (!isOnAuthorInstance) {
      // Is Public
      processedContentPath = `${mgnlSitePath}${processedContentPath}`;
    }

    contentPath.value = processedContentPath;
  };

  const loadTemplateAnnotations = async () => {
    const templateAnnotationsApi = `${mgnlPublicHost}/.rest/template-annotations/v1`;
    const templateAnnotationsRes = await fetch(templateAnnotationsApi + contentPath.value, {
      credentials: "include",
    });
    return await templateAnnotationsRes.json();
  };

  return {
    initMagnoliaContext,
    contentPath,
    loadTemplateAnnotations,
    magnoliaAuthorRedirect,
    verifyMagnoliaContext,
  };
};

export default useMagnoliaContext;
