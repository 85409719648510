//
import { iframeResize } from "iframe-resizer";
//
const resize = {
  beforeMount: function (el, { value = {} }) {
    el.addEventListener("load", () => iframeResize(value, el));
  },
  unmounted: function (el) {
    el.iFrameResizer?.removeListeners();
  },
};
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("iframe-resize", resize);
});
