<template>
  <div :class="{ 'gap-32 md:gap-48': !isEditMode, 'gap-12': isEditMode }" class="flex flex-col">
    <EditableComponent
      v-for="(childContent, index) in contentMap"
      :key="childContent['@id']"
      :component-index="index"
      :content="childContent"
    />
  </div>
</template>
<script setup>
import { inject } from "vue";
import { mapNodes } from "~/utils/helper/magnolia";
import { EditableComponent } from "@magnolia/vue-editor";
import { isEditMode as evaluateEditMode } from "~/utils/content/magnolia";
const isEditMode = evaluateEditMode();

const props = defineProps(["content"]);
const contentMap = mapNodes(props.content);

const activeIndex = inject("activeIndex");
const componentPercentageIndex = ref({});
provide("componentPercentageIndex", componentPercentageIndex);

watch(
  componentPercentageIndex,
  () => {
    const REFERENCE = 0.5;
    let bestDistance = 1;
    let bestIndex = -1;
    Object.entries(componentPercentageIndex.value).forEach(([index, percentage]) => {
      // find item closest to the reference point
      const distance = Math.abs(percentage - REFERENCE);
      if (distance < REFERENCE && distance <= bestDistance) {
        bestDistance = distance;
        bestIndex = index;
      }
    });
    activeIndex.value = parseInt(bestIndex);
  },
  { deep: true },
);
</script>
