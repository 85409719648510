<template>
  <div class="mask" :class="{ 'is-visible': isVisible }">
    <slot></slot>
    <div
      v-if="isVisible"
      class="dot-pattern pointer-events-none absolute inset-0"
      :class="[overflowVisible ? 'overflow-visible' : 'overflow-hidden']"
    >
      <component
        :is="mobilePattern"
        alt="dot-mobile-pattern"
        class="absolute inset-0 h-auto min-h-full w-full object-cover object-top md:hidden"
      ></component>
      <component
        :is="desktopPattern"
        alt="dot-pattern"
        class="absolute inset-0 hidden h-auto min-h-full w-full object-cover object-top md:block"
      ></component>
    </div>
  </div>
</template>

<script setup>
import DesktopOne from "~/templates/elements/pattern/DesktopOne.vue";
import DesktopTwo from "~/templates/elements/pattern/DesktopTwo.vue";
import DesktopThree from "~/templates/elements/pattern/DesktopThree.vue";
import DesktopFour from "~/templates/elements/pattern/DesktopFour.vue";
import DesktopBlue from "~/templates/elements/pattern/DesktopBlue.vue";
import MobileOne from "~/templates/elements/pattern/MobileOne.vue";
import MobileTwo from "~/templates/elements/pattern/MobileTwo.vue";
import MobileFour from "~/templates/elements/pattern/MobileFour.vue";
import MobileThree from "~/templates/elements/pattern/MobileThree.vue";
import MobileBlue from "~/templates/elements/pattern/MobileBlue.vue";
import { hasValue } from "~/utils/helper/magnolia";

const DesktopPattern = [DesktopOne, DesktopTwo, DesktopThree, DesktopFour, DesktopBlue];
const MobilePattern = [MobileOne, MobileTwo, MobileThree, MobileFour, MobileBlue];

const props = defineProps(["variant", "bluePattern", "overflowVisible"]);

let desktopPattern, mobilePattern;
if (props.bluePattern) {
  mobilePattern = MobileBlue;
  desktopPattern = DesktopBlue;
} else if (hasValue(props.variant)) {
  const variant = Math.max(0, Math.min(parseInt(props.variant) - 1, DesktopPattern.length));
  desktopPattern = DesktopPattern[variant];
  mobilePattern = MobilePattern[variant];
}
</script>

<script>
import { intersectingComponent } from "~/utils/mixins/intersectingComponent";

const VISIBLE_THRESHOLD = 0.5;

export default {
  name: "DotPattern",
  components: {},
  mixins: [intersectingComponent],

  data() {
    return {
      loading: "lazy",
      isVisible: false,
      circleStyles: [],
      imageVariant: Math.round(1 + Math.random() * 3),
    };
  },
  computed: {
    intersectionOptions() {
      return {
        rootMargin: "0px",
        threshold: [0, VISIBLE_THRESHOLD],
      };
    },
  },

  mounted() {
    this.enableObserver();
  },
  methods: {
    onIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio >= VISIBLE_THRESHOLD) {
          this.isVisible = true;
        } else if (!entry.isIntersecting) {
          this.isVisible = false;
        }
      });
    },
  },
};
</script>
