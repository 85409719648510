<template>
  <div
    class="relative flex items-start gap-4 rounded-2xl bg-light-blue p-4 transition-colors duration-300 hover:bg-medium-blue"
  >
    <div class="flex min-h-[6rem] flex-col gap-1">
      <LinkComponent class="stretched-link text-sm font-medium text-black" :link="link">
        {{ link?.label }}
      </LinkComponent>
      <div class="text-sm font-normal leading-snug">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
import LinkComponent from "../../components/cms/LinkComponent";

export default {
  name: "ProductOverviewLink",
  components: { LinkComponent },
  props: ["text", "link"],
};
</script>
