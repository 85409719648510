<template>
  <component
    :is="isEditMode || !isLink ? 'span' : NuxtLink"
    ref="downloadLinkRef"
    :class="[
      { 'cursor-pointer': isLink, 'stretched-link': isLink && stretchLink },
      isLink ? optionalLinkClasses : '',
      link?.style ? contentToTailwindClasses({ btnStyle: link?.style }) : '',
    ]"
    :download="isDownload || null"
    :external="!isInternal"
    :target="optionTarget"
    :title="link?.label"
    :to="optionHref"
    @click="onClick"
  >
    <slot></slot>
  </component>
</template>

<script setup>
import { getId, isDownloadLink, linkOptionHref, linkOptionTarget } from "~/utils/helper/magnolia";
import { isEditMode as evaluateEditMode } from "~/utils/content/magnolia";
import NuxtLink from "#app/components/nuxt-link";
import useDownloadTracking from "~/composables/tracking/useDownloadTracking";
import { contentToTailwindClasses } from "~/utils/helper/tailwind";
const { trackNavigation } = useNavigationTracking();
const { trackDownload } = useDownloadTracking();
const downloadLinkRef = ref(null);
const isEditMode = evaluateEditMode();
const props = defineProps(["link", "target", "href", "optionalLinkClasses", "stretchLink"]);

const optionHref = computed(() => {
  return linkOptionHref(props?.link) || props?.href;
});

const optionTarget = computed(() => {
  return linkOptionTarget(props?.link) || props?.target || "_self";
});
const isLink = computed(() => {
  return !!optionHref.value;
});
const isDownload = computed(() => {
  return isDownloadLink(props.link);
});
const isInternal = computed(() => {
  return isLink.value && props?.link?.type === "intern";
});

const onClick = () => {
  if (isLink.value) {
    trackNavigation(props.link?.label ?? getId(props.link));
  }
  if (isDownload.value) {
    trackDownload(props.link, downloadLinkRef);
  }
};
</script>
