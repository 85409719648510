<template>
  <div class="bwf-root">
    <NuxtLoadingIndicator
      :height="4"
      class="over bg-gradient-to-r from-light-blue via-medium-blue to-dark-blue"
      color="false"
    />

    <div class="bwf-content">
      <NuxtPage />
    </div>
  </div>
</template>

<script setup>
import { useShopwareContext } from "@shopware-pwa/composables-next";
import { getSessionContext } from "@shopware-pwa/api-client";
import useMyBritaUser from "~/composables/useMyBritaUser";
import { onMounted } from "vue";

const { apiInstance } = useShopwareContext();

/**
 * THIS is the version we are living in for now
 * https://github.com/shopware/frontends/blob/%40shopware-pwa/api-client%400.7.0/templates/vue-demo-store/app.vue
 */

/**
 * init shop session
 * apparently this does not have something to do with retrieving the session (which we need for auth purposes) but how complete the session is.
 * meaning we can probably simply use the anonymus session for the initialization
 */

const sessionContextData = ref();
try {
  // this crashes during static builds.
  // can be removed once the BWF gets split into multiple workspaces
  sessionContextData.value = await getSessionContext(apiInstance);
} catch (e) {
  console.log("APP: could not init session context (probably during static build)");
}

const session = useSessionContext(sessionContextData.value);
const { refreshCart } = useCart();
/**
 *  pass session to myBritaUser
 *  will observe and marry session, refresh and observe changes
 *  pretty important to pass the context here as the context above will become available to nested components through injection but not on the same level / composable siblings
 */
useMyBritaUser(session);

// will handle login state and session marriage

const { verifyLogIn } = useMyBritaUser();
onMounted(async () => {
  try {
    // cart impressions are hard reloads, we need to verify login before fetching the cart
    await verifyLogIn();
  } catch (e) {
    console.log("APP: could not verify login");
  }
  try {
    // refresh cart once everey hard load after login verification
    await refreshCart();
  } catch (e) {
    console.log("APP: could not refresh cart");
  }
});
</script>

<style>
.bwf-content {
  @apply transition-opacity duration-700;
}

.bwf-root.is-loading .bwf-content {
  @apply pointer-events-none opacity-80;
}

.bwf-root.is-loading {
  @apply cursor-wait;
}
</style>
