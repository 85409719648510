<template>
  <div>
    <p class="text-sm font-medium text-black">
      <CommonLabel path="BWF/shop/productDetailPage/productDeliveryScope"></CommonLabel>
    </p>
    <div class="mt-2">
      <div class="prose" v-html="props.productDeliveryScope"></div>
    </div>
    <hr class="mt-6" />
  </div>
</template>

<script setup lang="ts">
import CommonLabel from "~/templates/components/CommonLabel.vue";
const props = defineProps<{
  productDeliveryScope: string;
}>();
</script>
