<template>
  <div class="safari-overflow-hidden-fix group relative overflow-hidden rounded-2xl">
    <parallax-container class="relative inset-0">
      <div
        :class="ratio === 'postcard' ? 'xl:aspect-3/2' : 'xl:aspect-square'"
        class="aspect-3/4 transition-transform duration-300 group-hover:scale-110"
      >
        <PictureElement
          :breakpoints="BREAKPOINTS"
          :class="{
            hidden: containsImage(imageMobile),
            'xl:inline': containsImage(imageMobile),
          }"
          :img="image"
          img-class="block object-cover w-full h-full"
        ></PictureElement>
        <PictureElement
          v-if="containsImage(imageMobile)"
          :breakpoints="BREAKPOINTS"
          :img="imageMobile"
          class="xl:hidden"
          img-class="block object-cover w-full h-full"
        ></PictureElement>
      </div>
    </parallax-container>
    <div
      :class="contentToTailwindClasses({ opacity: opacity ?? 75 })"
      class="absolute inset-x-0 top-0 h-1/3 bg-gradient-to-b from-black to-transparent"
    ></div>
    <div
      :class="contentToTailwindClasses({ opacity: opacity ?? 75 })"
      class="absolute inset-x-0 bottom-0 h-1/3 bg-gradient-to-t from-black to-transparent"
    ></div>
    <div class="absolute inset-0 flex flex-col items-start justify-between p-6 text-white xl:p-10">
      <div>
        <HeadlineComponent
          :headline="headline"
          class="mb-4 text-2xl font-medium xl:text-3xl"
          tag="h3"
        ></HeadlineComponent>
        <p>{{ subline }}</p>
      </div>
      <CallToActionComponent :link="link" :stretch-link="stretchLink"></CallToActionComponent>
    </div>
  </div>
</template>

<script setup>
import { contentToRatio, DIMENSIONS } from "~/utils/helper/imaging";
import { containsImage } from "~/utils/helper/magnolia";
import { contentToTailwindClasses } from "~/utils/helper/tailwind";
import ParallaxContainer from "../../elements/ParallaxContainer";
import HeadlineComponent from "./HeadlineComponent";
import PictureElement from "../../elements/PictureElement";
import CallToActionComponent from "./CallToActionComponent";
defineProps(["headline", "subline", "image", "imageMobile", "link", "opacity", "stretchLink"]);

const ratio = inject("ratio");
const BREAKPOINTS = {
  start: DIMENSIONS["start"]?.ratio["3/4"]?.scale["1.5"],
  sm: DIMENSIONS["sm"].ratio["3/4"]?.scale["1.5"],
  md: DIMENSIONS["md"].width["1/2"]?.ratio["3/4"]?.scale["1.5"],
  xl: DIMENSIONS["xl"].width["1/2"]?.ratio[contentToRatio(ratio ?? "square")]?.scale["1.5"],
};
</script>
