<template>
  <div>
    <form class="relative flex flex-col gap-4 xl:gap-6" @submit.prevent="invokeReturn">
      <slot name="back-button"></slot>
      <div class="text-lg font-medium xl:text-4xl">
        <h2 class="text-dark-blue">
          <CommonLabel path="BWF/shop/account/orderHistory/returnHeadline"></CommonLabel>
        </h2>
        <h3 class="flex flex-wrap items-center gap-1 gap-x-4 xl:gap-4">
          <span>
            <CommonLabel path="BWF/shop/account/orderHistory/orderNumber"></CommonLabel>
            <span>&nbsp;{{ order?.orderNumber }}</span>
          </span>
          <AccountOrderStateLabel :state="order?.stateMachineState"></AccountOrderStateLabel>
        </h3>
      </div>
      <template v-if="!isEditMode()">
        <LoadingElement v-if="isLoading" class="bg-white/70" overlay="true"></LoadingElement>

        <template v-if="order">
          <hr class="hidden xl:block" />
          <OrderSelectableItems v-if="!showReturnSummary" :order="order"></OrderSelectableItems>

          <OrderLineItem
            v-for="selectedItem in selectedLineItems"
            v-else
            :key="selectedItem.id"
            :is-configured="true"
            :line-item="selectedItem"
            :quantity-change="false"
          />

          <hr class="hidden xl:block" />
          <div class="mt-4 flex flex-col">
            <div class="font-medium xl:text-xl">
              <CommonLabel path="BWF/shop/account/orderHistory/returnReasons"></CommonLabel>
            </div>
            <SelectField
              v-model="returnFormState.internalComment"
              :error-message="returnForm$?.internalComment?.$errors?.[0]?.$message"
              :label="reasonLabel"
              :options="returnReasons"
              class="form-field-grey xl:w-1/2"
              name="returnReason"
              required="required"
            ></SelectField>
          </div>
          <div v-if="maxParcelCount.length > 1" class="mt-4 flex flex-col">
            <div class="font-medium xl:text-xl">
              <CommonLabel path="BWF/shop/account/orderHistory/parcelCount"></CommonLabel>
            </div>
            <SelectField
              v-model="parcelCount"
              :label="parcelCountLabel"
              :options="maxParcelCount"
              class="form-field-grey xl:w-1/2"
              name="parcelCount"
              required="required"
            ></SelectField>
          </div>
        </template>
        <hr />
      </template>

      <div class="flex flex-col items-start gap-4 xl:flex-row">
        <div class="w-full xl:w-8/12">
          <CommonLabel path="BWF/shop/shared/mandatoryFieldsLabel"></CommonLabel>

          <EditableArea :content="hintBox" :custom-view="EmptyArea" />
          <div v-if="hintBox?.showHintBox" class="rounded bg-light-grey p-4">
            <div class="font-medium">
              {{ hintBox.headline }}
            </div>
            <div class="prose" v-html="hintBox?.richText"></div>
            <ContactElement
              v-if="hintBox.contact"
              :contact="hintBox.contact"
              class="mt-4"
            ></ContactElement>
          </div>
        </div>
        <button :disabled="buttonDisabled" class="btn btn-blue w-full xl:ml-auto xl:w-4/12">
          <CommonLabel
            v-if="showReturnSummary"
            path="BWF/shop/account/orderHistory/confirmReturn"
          ></CommonLabel>
          <CommonLabel v-else path="BWF/shop/shared/continue"></CommonLabel>
        </button>
      </div>
    </form>
  </div>

  <ModalComponent :controller="contractCancellationAcknowledgementModal">
    <template #headline>
      <p class="text-center">
        <CommonLabel path="BWF/shop/account/subscriptions/modal/confirmReturnHeadline" />
      </p>
    </template>
    <template #richText>
      <CommonLabel path="BWF/shop/account/subscriptions/modal/confirmReturnDescription"
    /></template>

    <div class="mx-auto grid w-full gap-5 md:grid-cols-2">
      <button class="btn btn-blue" @click="contractCancellation()">
        <CommonLabel path="BWF/shop/account/orderDetails/modal/confirmCancellation" />
      </button>
      <button class="btn btn-ghost" @click="contractCancellationAcknowledgementModal.close()">
        <CommonLabel path="BWF/shop/account/orderDetails/modal/declineCancellation" />
      </button>
    </div>
  </ModalComponent>
</template>

<script setup>
import { isEditMode } from "~/utils/content/magnolia";
import EmptyArea from "~/templates/areas/EmptyArea.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import OrderSelectableItems from "~/templates/components/shop/account/OrderSelectableItems.vue";
import { EditableArea } from "@magnolia/vue-editor";
import SelectField from "~/templates/elements/form/SelectField.vue";
import ContactElement from "~/templates/elements/ContactElement.vue";
import LoadingElement from "~/templates/elements/LoadingElement.vue";
import useCommonLabels from "~/composables/useCommonLabels";
import { containsReturnRestricted, hasContractPlan } from "~/utils/helper/shop/order";
import { useShopwareContext } from "@shopware-pwa/composables-next";
import { useOrderSelection } from "~/composables/shop/useOrderSelection";
import { useVuelidate } from "@vuelidate/core";
import ModalComponent from "~/templates/components/ModalComponent.vue";
import { useModal } from "~/composables/useModal";
import OrderLineItem from "~/templates/components/shop/account/OrderLineItem.vue";

const { getLabelByPath, getLabels } = useCommonLabels();
const props = defineProps([
  "showHintBox",
  "hintBox",
  "confirmModalArea",
  "serviceModalArea",
  "bottom",
  "isLoading",
]);

const { pushError } = useNotifications();

const emit = defineEmits(["confirm-modal", "service-modal", "end-loading"]);

const { order } = useOrderDetails();
const { apiInstance } = useShopwareContext();
const reasonLabel = getLabelByPath("BWF/shop/account/orderHistory/reasonLabel");
const { selectedItems, selectedLineItems } = useOrderSelection(order);

const parcelCount = ref(1);
const maxParcelCount = computed(() => {
  const trackingCodeAmount = order?.value?.deliveries.flatMap(
    (delivery) => delivery.trackingCodes,
  ).length;
  const options = [];
  for (let i = 1; i <= trackingCodeAmount; i++) {
    options.push({ value: i });
  }
  return options;
});

const contractCancellationAcknowledgementModal = useModal();
const showReturnSummary = ref(false);

const contractCancellation = () => {
  showReturnSummary.value = true;
  contractCancellationAcknowledgementModal.close();
};

const { returnOf, failed } = getLabels("BWF/shop/account/orderDetails/", ["returnOf", "failed"]);

const { requiredValidator, oneItemRequired } = await useValidation();

const returnFormState = reactive({
  lineItems: selectedItems,
  internalComment: "",
  parcelCount: parcelCount,
});
const formIsLocked = ref(false);
const buttonDisabled = computed(() => {
  return props.isLoading || formIsLocked.value;
});

const returnFormRules = computed(() => ({
  internalComment: {
    ...requiredValidator,
  },
  lineItems: {
    ...oneItemRequired,
  },
}));
const returnForm$ = useVuelidate(returnFormRules, returnFormState);

const selectedHasContracts = computed(() => hasContractPlan(selectedLineItems?.value));
const invokeReturn = async () => {
  if (selectedHasContracts.value && !showReturnSummary.value) {
    contractCancellationAcknowledgementModal.open();
  } else {
    const valid = await returnForm$.value.$validate();
    if (valid) {
      if (containsReturnRestricted(selectedLineItems.value)) {
        emit("service-modal", "end-loading");
        return;
      }

      try {
        formIsLocked.value = true;
        const {
          data: {
            translated: {},
          },
          error,
        } = await apiInstance.invoke.post(`/store-api/order/${order?.value?.id}/partial/return`, {
          ...returnFormState,
          parcelCount: parseInt(returnFormState.parcelCount),
        });
        if (error?.value) {
          pushError(error?.value?.messages?.[0]?.detail);
        }
        formIsLocked.value = false;
        emit("confirm-modal");
      } catch (e) {
        if (e?.messages?.[0]?.detail) {
          pushError(e?.messages?.[0]?.detail);
        } else {
          pushError(`${returnOf} (${order?.value?.orderNumber}) ${failed}`);
        }
      }
    } else if (returnForm$?.value?.lineItems?.$errors?.[0]?.$message) {
      pushError(returnForm$?.value?.lineItems?.$errors?.[0]?.$message);
    }
  }
};

const { data: shopReturnReasons } = await useAsyncData(
  "swReturnReasons",
  async () => {
    const {
      data: { elements },
    } = await apiInstance.invoke.get("/store-api/web/order/return/reason");
    return elements;
  },
  { lazy: true },
);

const returnReasons = computed(() => {
  return (
    shopReturnReasons?.value?.map((reason) => ({
      label: reason?.name ?? "",
      value: reason?.id,
    })) ?? []
  );
});
</script>
