<template>
  <div>
    <EditableArea :content="main" />

    <div class="lg:spacing-mt-small container">
      <div v-if="isEditMode" class="editmode-info-box break-all">Product ID: {{ sku }}</div>

      <ProductStage v-if="productFound" @variant-change="onVariantChange">
        <template v-if="exchangeBoxArea?.introTextAbstract" #introText>
          <DetailsElement class="mt-6 lg:mt-8">
            <template #summary>
              <RichTextComponent :rich-text="exchangeBoxArea?.introTextAbstract" />
            </template>
            <template #content>
              <RichTextComponent :rich-text="exchangeBoxArea?.introTextDetail ?? ''" />
            </template>
          </DetailsElement>
          <div class="mb-6 mt-4 border-b lg:hidden"></div>
        </template>
        <template v-if="exchangeBoxArea?.howToBoxText" #howTo>
          <div class="notification is-blue mt-4 items-center gap-4 lg:mt-8">
            <SvgIcon class="notification-icon flex-shrink-0" name="Tooltip" />
            <span
              >{{ exchangeBoxArea?.howToBoxText }}&nbsp;<button
                class="text-link text-black"
                @click="howToModal.open()"
              >
                {{ exchangeBoxArea?.howToModalLink }}
              </button>
            </span>
          </div>
        </template>

        <template #priceInfo>
          <button
            class="text-icon-link col-span-full flex items-start text-left text-xs"
            @click="priceTooltipModal.open()"
          >
            <SvgIcon class="mr-1.5 h-4 w-4" name="Info" />
            <span>{{ exchangeBoxArea?.priceModalLink }}</span>
          </button>
        </template>
        <template #depositInfo>
          <button
            class="text-icon-link col-span-full flex items-start text-left text-xs"
            @click="depositTooltipModal.open()"
          >
            <SvgIcon class="mr-1.5 h-4 w-4" name="Info" />
            <span>{{ exchangeBoxArea?.depositModalLink }}</span>
          </button>
        </template>
      </ProductStage>
      <div v-else>No Product found 🫧</div>

      <EditableArea
        :class="[{ 'flex flex-col': isEditMode }]"
        :content="subscriptionModalArea"
        :custom-view="EmptyArea"
      >
        <p v-if="isEditMode" class="editmode-text text-2xl font-medium text-green">Modal:</p>
        <ModalComponent :controller="subscriptionModal">
          <template v-if="subscriptionModalArea.headline" #headline
            ><HeadlineComponent
              :text="subscriptionModalArea?.headline"
              class="text-center text-3xl"
          /></template>
          <template v-if="subscriptionModalArea.richText" #richText
            ><RichTextComponent :rich-text="subscriptionModalArea?.richText"
          /></template>
          <button
            v-if="subscriptionModalArea.subscriptionModalCta"
            class="btn btn-blue self-start"
            @click="
              subscriptionChooserActive = true;
              subscriptionModal.close();
            "
          >
            {{ subscriptionModalArea?.subscriptionModalCta }}
          </button>
        </ModalComponent>
      </EditableArea>
      <EditableArea
        :class="[{ 'flex flex-col gap-2 md:grid md:grid-cols-3': isEditMode }]"
        :content="exchangeBoxArea"
        :custom-view="EmptyArea"
        class="exchange-box-content space-y-4"
      >
        <p v-if="isEditMode" class="editmode-text col-span-full text-2xl font-medium text-green">
          Modals:
        </p>
        <ModalComponent :controller="howToModal">
          <template #headline
            ><HeadlineComponent
              :text="exchangeBoxArea?.howToModalHeadline"
              class="text-center text-lg"
          /></template>
          <template #richText
            ><RichTextComponent :rich-text="exchangeBoxArea?.howToModalSubline" class="text-center"
          /></template>
        </ModalComponent>
        <ModalComponent :controller="depositTooltipModal">
          <template #headline
            ><HeadlineComponent
              :text="exchangeBoxArea?.depositModalHeadline"
              class="text-center text-lg"
          /></template>
          <template #richText
            ><RichTextComponent
              :rich-text="exchangeBoxArea?.depositModalSubline"
              class="text-center"
          /></template>
        </ModalComponent>
        <ModalComponent :controller="priceTooltipModal">
          <template #headline>
            <HeadlineComponent
              :text="exchangeBoxArea?.priceModalHeadline"
              class="text-center text-lg"
          /></template>
          <template #richText
            ><RichTextComponent :rich-text="exchangeBoxArea?.priceModalSubline" class="text-center"
          /></template>
        </ModalComponent>
      </EditableArea>

      <EditableArea :content="technicalSpecsArea" :custom-view="EmptyArea">
        <ProductTechnicalSpecs
          :content="technicalSpecsArea"
          :downloads="technicalSpecsArea?.downloads"
          :product="product"
          :visible-specs="technicalSpecsArea?.visibleSpecs"
          class="mx-auto my-4 md:w-8/12 xl:my-8"
        />
      </EditableArea>
    </div>
    <EditableArea v-if="bottom" :content="bottom" />
  </div>
</template>
<script setup>
import { EditableArea } from "@magnolia/vue-editor";
import { useProductSearch } from "@shopware-pwa/composables-next";
import useMagnoliaContent from "~/composables/useMagnoliaContent";
import ProductTechnicalSpecs from "~/templates/components/shop/product/ProductTechnicalSpecs.vue";
import EmptyArea from "~/templates/areas/EmptyArea.vue";
import { isEditMode as evaluateEditMode } from "~/utils/content/magnolia";
import ModalComponent from "~/templates/components/ModalComponent.vue";
import RichTextComponent from "~/templates/components/cms/RichTextComponent.vue";
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import DetailsElement from "~/templates/elements/DetailsElement.vue";
import HeadlineComponent from "~/templates/components/cms/HeadlineComponent.vue";

defineOptions({
  inheritAttrs: false,
});

import useD2CStructuredData from "~/composables/useD2CStructuredData";
import { getMainImageUrl, getTranslatedProperty } from "@shopware-pwa/helpers-next";
import { containsImage, imageResizerPath } from "~/utils/helper/magnolia";
import { PRODUCT_DETAILS_ASSOCIATIONS } from "~/utils/helper/shop/product";
import { useProductSubscriptionConfigurator } from "~/composables/shop/useProductSubscriptionConfigurator";
const isEditMode = evaluateEditMode();

const howToModal = useModal();
const subscriptionModal = useModal();
const depositTooltipModal = useModal();
const priceTooltipModal = useModal();
provide("subscriptionModal", subscriptionModal);

const props = defineProps([
  "navigationId",
  "title",
  "description",
  "ogImage",
  "ogTitle",
  "ogType",
  "metadata",
  "main",
  "bottom",
  "sku",
  "technicalSpecsArea",
  "exchangeBoxArea",
  "subscriptionModalArea",
]);

provide("hasSubscriptionModalArea", !!props.subscriptionModalArea?.richText);
provide("exchangeBoxArea", props.exchangeBoxArea);

let productId = toRaw(props.sku);
const { pathVariable } = useMagnoliaContent();

if (pathVariable.value) {
  // assume variable and overwrite configured sku to load a variant
  const { resolvePath } = useNavigationSearch();
  const { data: seoResult } = await useAsyncData("cmsResponse" + pathVariable.value, async () => {
    return await resolvePath(pathVariable.value);
  });

  if (seoResult.value) {
    // means a proper formed product link was clicked
    productId = useNavigationContext(seoResult)?.foreignKey?.value;
  } else {
    // assume that pathvariable is the product id
    productId = pathVariable.value.replace("/", "");
  }
}

const productFound = ref(false);
const isLoading = ref(true);
provide("isLoading", isLoading);

const { search } = useProductSearch();
const { data: productResponse, error } = await useAsyncData(
  "product-detail-" + productId,
  async () => {
    return await search(productId, PRODUCT_DETAILS_ASSOCIATIONS);
  },
);

productFound.value = !!productResponse.value?.product;
const { product, configurator, changeVariant, changeConfigurator } = productResponse.value?.product
  ? useProduct(productResponse.value?.product, productResponse.value?.configurator)
  : {};

const { subscriptionChooserActive } = useProductSubscriptionConfigurator(product);

if (error.value && !isEditMode) {
  // product lookup error show 404
  showError({ statusCode: 404, statusMessage: "not found" });
}

// provide new product
isLoading.value = false;
const onVariantChange = async (changedVariant) => {
  if (!changedVariant?.id) {
    return;
  }

  const { data: productResponse } = await useAsyncData(
    "product-detail-" + changedVariant.id,
    async () => {
      return await search(changedVariant.id, PRODUCT_DETAILS_ASSOCIATIONS);
    },
  );
  // patch the current product with the new variant
  changeVariant(productResponse.value?.product);
  // patch the current configurator to patch combinability
  changeConfigurator(productResponse.value?.configurator);

  isLoading.value = false;
};

const { marketSetup } = useMarketSetup();
const metaDescription =
  props.description ||
  getTranslatedProperty(product?.value, "metaDescription") ||
  marketSetup.value?.fallbackDescription;

const title =
  props.title ||
  getTranslatedProperty(product?.value, "metaTitle") ||
  getTranslatedProperty(product?.value, "name") ||
  marketSetup.value?.fallbackTitle;
const ogTitle = props.ogTitle || title;

const mainImg = containsImage(props.ogImage)
  ? imageResizerPath(props?.ogImage, 1200, 630)
  : getMainImageUrl(product?.value);

useHead({
  title,
  meta: [
    ...(metaDescription ? [{ name: "description", content: metaDescription }] : []),
    ...(metaDescription ? [{ name: "og:description", content: metaDescription }] : []),
    ...(ogTitle ? [{ name: "og:title", content: ogTitle }] : []),
    ...(mainImg ? [{ name: "og:image", content: mainImg }] : []),
  ],
});

const { productSchema } = useD2CStructuredData();
useJsonld(productSchema(product?.value, configurator?.value));
</script>
