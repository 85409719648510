<template>
  <div class="image-text-area-component relative flex flex-col gap-4 md:gap-6">
    <div
      :class="
        contentToTailwindClasses({
          aspectRatio,
        })
      "
      class="safari-overflow-fix relative overflow-hidden rounded-xl"
    >
      <VideoPictureElement
        :breakpoints="BREAKPOINTS"
        :img="image"
        :img-class="`absolute inset-0 w-full h-full
                ${contentToTailwindClasses({ objectFit: fit ?? 'cover' })}`"
        :video="video"
        class="absolute inset-0 h-full w-full"
      ></VideoPictureElement>
    </div>

    <div :class="{ 'md:gap-4': columns < 6 }" class="flex flex-col gap-2">
      <HeadlineComponent
        v-if="headline"
        :class="[columns < 6 ? 'text-lg xl:text-2xl' : 'text-lg']"
        :headline="headline"
        class="break-words font-medium"
      ></HeadlineComponent>

      <div v-if="copy" class="whitespace-pre-wrap break-words text-base leading-snug">
        {{ copy }}
      </div>
    </div>
    <LinkComponent
      :link="link"
      optional-link-classes="stretched-link absolute inset-0"
    ></LinkComponent>
  </div>
</template>

<script setup>
import { inject } from "vue";
import { contentToTailwindClasses } from "~/utils/helper/tailwind";
import { DIMENSIONS, contentToRatio, columnToWidth } from "~/utils/helper/imaging";
import VideoPictureElement from "~/templates/elements/VideoPictureElement.vue";
import HeadlineComponent from "~/templates/components/cms/HeadlineComponent.vue";
import LinkComponent from "~/templates/components/cms/LinkComponent.vue";

const columns = inject("columns");
const aspectRatio = inject("ratio");
const imagingRatio = contentToRatio(aspectRatio);
const columnWidth = columnToWidth(columns);

const props = defineProps(["image", "video", "fit", "headline", "copy", "link"]);

const isContain = props.fit === "contain";

const BREAKPOINTS = {
  start: DIMENSIONS["start"]?.ratio[isContain ? "original" : imagingRatio],
  sm: DIMENSIONS["sm"]?.ratio[isContain ? "original" : imagingRatio],
  md: DIMENSIONS["md"]?.width[columnWidth]?.ratio[isContain ? "original" : imagingRatio],
  xl: DIMENSIONS["xl"]?.width[columnWidth]?.ratio[isContain ? "original" : imagingRatio],
  container:
    DIMENSIONS["container"]?.width[columnWidth]?.ratio[isContain ? "original" : imagingRatio],
};
</script>

<script>
import { editableComponent } from "~/utils/mixins/editableComponent";

export default {
  name: "MultipleImageTextComponent",
  mixins: [editableComponent],
};
</script>
