<template>
  <div>
    <img
      v-if="thumbnailUrl && !productImageLoadingError"
      :src="thumbnailUrl"
      :srcset="thumbnailSrcSet"
      :alt="productMedia?.alt || productName || 'product-image'"
      :title="productMedia?.title || productName || 'product-image'"
      :class="mainClass"
      :width="widthImage"
      :height="heightImage"
      :loading="loading"
      data-testid="product-box-img"
      @error="$emit('product-image-error')"
    />
    <img
      v-else
      :src="fallbackSrc"
      :alt="productMedia?.alt || productName || 'fallback-product-image'"
      :title="productMedia?.title || productName || 'fallback-product-image'"
      :class="fallbackClass ?? mainClass"
      :width="widthFallback"
      :height="heightFallback"
      :loading="loading"
      data-testid="product-box-img"
    />
  </div>
</template>

<script setup>
import { getSmallestThumbnailUrl, getSrcSetForMedia } from "@shopware-pwa/helpers-next";
import { getSrcSetForMediaUntil } from "~/utils/helper/shop/media";
import logoInverted from "~/assets/img/logo/logo-inverted.svg";
import logoPrimary from "~/assets/img/logo/logo-primary.svg";

const props = defineProps([
  "heightImage",
  "heightFallback",
  "widthImage",
  "widthFallback",
  "loading",
  "productImageLoadingError",
  "mainClass",
  "fallbackClass",
  "productName",
  "productMedia",
  "srcSetUntil",
  "logoStyle",
]);

defineEmits(["product-image-error"]);

const fallbackSrc = computed(() => {
  const style = props.logoStyle ?? "inverted";
  return style === "inverted" ? logoInverted : logoPrimary;
});

const thumbnailUrl = computed(() => getSmallestThumbnailUrl(props.productMedia));
const thumbnailSrcSet = computed(() =>
  props.srcSetUntil
    ? getSrcSetForMediaUntil(props.productMedia, props.srcSetUntil)
    : getSrcSetForMedia(props.productMedia),
);
</script>
