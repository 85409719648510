<template>
  <div
    :class="[contentToTailwindClasses({ backgroundColor })]"
    class="newsletter-registration-component relative overflow-hidden rounded-xl"
  >
    <DotPattern v-if="dotPattern" :variant="dotPattern"></DotPattern>
    <div
      :class="{
        'md:px-16 xl:w-full xl:px-12': hasImage,
      }"
      class="relative grid gap-6 px-4 py-8 md:mx-auto md:w-10/12 md:gap-y-8 md:px-0 md:py-12 xl:grid-cols-5"
    >
      <PictureElement
        v-if="hasImage"
        :class="{ 'order-last': !imageFirst && isTabletOrMobile }"
        :img="image"
        class="z-10 xl:order-first xl:col-span-2 xl:col-start-4 xl:row-start-1"
        img-class="object-cover aspect-4/3 rounded-xl overflow-hidden mx-auto"
      ></PictureElement>
      <div
        ref="contentGroup"
        :class="[hasImage && isDesktop ? 'col-span-3' : 'col-span-full']"
        class="content-group flex flex-col gap-6"
      >
        <TransitionGroup name="content" @enter="onEnter">
          <div
            v-if="(!isAlreadyRegistered && !isSubmitted) || isEditMode()"
            class="editmode-info-box flex flex-col"
          >
            <div v-if="isEditMode()" class="editmode-text">[EDITOR VIEW] Registration Content:</div>
            <HeadlineComponent
              v-if="registrationHeadline?.text"
              :headline="registrationHeadline"
              class="mb-4 text-2xl font-medium text-dark-blue xl:text-3xl"
            ></HeadlineComponent>
            <p v-if="registrationSubline" class="mb-6 text-base text-black">
              {{ registrationSubline }}
            </p>
            <NewsletterBenefits v-if="hasNodes(benefits)" :benefits="benefits" />
            <form @submit.prevent="onSubmitNewsletterRegistration()">
              <InputField
                v-model="newsletterFormState.email"
                :class="{ hidden: !!props.email }"
                :error-message="newsletterVuelidate?.email?.$errors[0]?.$message"
                :label="emailFormLabels?.emailAddressLabel"
                :placeholder="emailFormLabels?.emailInputPlaceholder"
                class="form-field-white mb-4 mt-2 block w-full md:w-96"
                name="email"
                required="required"
                type="email"
                @blur="newsletterVuelidate?.email.$touch()"
              />

              <InputField
                v-model="newsletterFormState.middlename"
                autocomplete="off"
                class="form-field-umbrella"
                label="middlename"
                name="middlename"
                placeholder="middlename"
                type="text"
              />
              <CheckboxField
                v-model="newsletterFormState.consent"
                :error-message="newsletterVuelidate?.consent?.$errors[0]?.$message"
                class="text-sm"
                name="consent"
                @change="newsletterVuelidate.consent.$touch()"
              >
                <CommonLabel path="/BWF/components/newsletter/newsletterConsent"></CommonLabel>
              </CheckboxField>
              <button class="btn btn-blue z-10 mt-4 md:mt-6 md:self-start">
                <CommonLabel path="BWF/components/newsletter/submit"></CommonLabel>
              </button>
            </form>
          </div>

          <div v-if="isEditMode() || isSubmitted" class="editmode-info-box flex flex-col">
            <p v-if="isEditMode()" class="editmode-text">[EDITOR VIEW] Confirmation Content:</p>
            <HeadlineComponent
              v-if="confirmationHeadline?.text"
              :headline="confirmationHeadline"
              class="mb-4 text-2xl font-medium text-dark-blue xl:text-3xl"
            ></HeadlineComponent>
            <p v-if="confirmationSubline" class="mb-6 text-base text-black">
              {{ confirmationSubline }}
            </p>
            <NewsletterBenefits v-if="hasNodes(confirmationSteps)" :benefits="confirmationSteps" />
          </div>

          <div v-if="isEditMode() || isAlreadyRegistered" class="editmode-info-box flex flex-col">
            <p v-if="isEditMode()" class="editmode-text">
              [EDITOR VIEW] Already Registered Content:
            </p>
            <HeadlineComponent
              v-if="informationHeadline?.text"
              :headline="informationHeadline"
              class="mb-4 text-2xl font-medium text-dark-blue xl:text-3xl"
            ></HeadlineComponent>
            <p v-if="informationSubline" class="mb-6 text-base text-black">
              {{ informationSubline }}
            </p>
            <NewsletterBenefits v-if="hasNodes(informationSteps)" :benefits="informationSteps" />
          </div>
        </TransitionGroup>
      </div>
    </div>

    <Transition mode="out-in" name="loading">
      <LoadingElement v-if="isLoading" class="bg-white/70" overlay="true"></LoadingElement>
    </Transition>
  </div>
</template>

<script setup>
import HeadlineComponent from "./HeadlineComponent";
import PictureElement from "~/templates/elements/PictureElement.vue";
import CheckboxField from "~/templates/elements/form/CheckboxField.vue";
import InputField from "~/templates/elements/form/InputField.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import { containsImage, hasNodes } from "~/utils/helper/magnolia";
import { contentToTailwindClasses } from "~/utils/helper/tailwind";
import DotPattern from "~/templates/elements/DotPattern.vue";
import { isEditMode } from "~/utils/content/magnolia";
import useBritaMiddleware from "~/composables/useBritaMiddleware";
import LoadingElement from "~/templates/elements/LoadingElement.vue";
import { useVuelidate } from "@vuelidate/core";
import NewsletterBenefits from "~/templates/components/shop/product/NewsletterBenefits.vue";

const props = defineProps([
  "backgroundColor",
  "registrationHeadline",
  "registrationSubline",
  "image",
  "imageFirst",
  "benefits",
  "confirmationHeadline",
  "confirmationSubline",
  "confirmationSteps",
  "informationHeadline",
  "informationSubline",
  "informationSteps",
  "dotPattern",
  "interactionId",
  "actionId",
  "skipDoi",
  "email",
]);

const { isDesktop, isTabletOrMobile } = useDevice();
const { user } = useUser();
const { register, getDoiState } = useBritaMiddleware();
const { pushError } = useNotifications();

const { getLabelByPath, getLabels } = useCommonLabels();

const hasImage = containsImage(props.image);

const newsletterFormState = reactive({
  email: props?.email ?? user?.value?.email ?? "",
  middlename: "",
  consent: null,
});

watch(
  () => props.email,
  () => {
    if (props?.email) {
      newsletterFormState.email = props?.email;
    }
  },
);

const contentMinHeight = ref("0px");

const { emailValidators, consentValidators, requiredValidator } = await useValidation();

const rules = computed(() => ({
  email: {
    ...requiredValidator,
    ...emailValidators,
  },
  consent: {
    ...consentValidators,
  },
}));
const newsletterVuelidate = useVuelidate(rules, newsletterFormState);

const isSubmitted = ref(false);
const isAlreadyRegistered = ref(false);
const isLoading = ref(false);

const emailFormLabels = getLabels("/BWF/shared/", ["emailAddressLabel", "emailInputPlaceholder"]);
const technicalErrorMessage = getLabelByPath("/BWF/newsletter/doi/technicalErrorMessage");

const contentGroup = ref();
onMounted(() => {
  contentMinHeight.value = `${contentGroup?.value?.offsetHeight}px`;
});
const onEnter = async (el) => {
  contentMinHeight.value = `${el.offsetHeight}px`;
};

const onSubmitNewsletterRegistration = async () => {
  isLoading.value = true;
  newsletterVuelidate.value.$touch();
  const valid = await newsletterVuelidate.value.$validate();
  if (valid) {
    try {
      const data = props?.skipDoi === true ? {} : await getDoiState(newsletterFormState.email);
      if (data?.donotbulkemail === false) {
        // user has already opted in
        isAlreadyRegistered.value = true;
        isLoading.value = false;
        return;
      }
      try {
        await register(
          newsletterFormState?.email,
          props.interactionId,
          props.actionId,
          newsletterFormState.middlename,
        );
        isSubmitted.value = true;
      } catch (e) {
        if (registerError?.value?.statusCode === 406) {
          // 406
          isAlreadyRegistered.value = true;
        } else if (error?.value) {
          pushError(technicalErrorMessage);
        } else {
        }
      }
    } catch (e) {
      pushError(technicalErrorMessage);
    }
  } else {
    pushError(technicalErrorMessage);
  }
  isLoading.value = false;
};
</script>

<style scoped>
.content-group {
  position: relative;
  @apply relative transition-all duration-1000;
  min-height: v-bind(contentMinHeight);
}
</style>

<style>
.newsletter-registration-component {
  /* TODO why is this a different than elsewhere*/

  .checkbox-border {
    @apply rounded-none border-none border-transparent;
  }

  .checkbox-border,
  .has-error {
    input {
      @apply rounded-sm bg-white;
    }
  }

  .editmode-text {
    @apply mb-4 border-b-2 text-xl font-bold text-green;
  }

  .content-enter-active,
  .content-leave-active {
    @apply transition-all duration-700;
  }

  .content-enter-from,
  .content-leave-to {
    position: absolute;
    opacity: 0;
    transform: translateX(30px);
  }

  .loading-enter-active,
  .loading-leave-active {
    @apply transition-opacity duration-500;
  }

  .loading-enter-from,
  .loading-leave-to {
    opacity: 0;
  }
}
</style>
