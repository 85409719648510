import { isEditMode as evaluateEditMode } from "~/utils/content/magnolia";

function useMainNavigationState() {
  const isEditMode = evaluateEditMode();
  const { isTabletOrMobile } = useDevice();
  // these vars need to be state as device is guessed in SSR. Therefore not useing useState will cause hydration issues
  const isMobileMode = useState("navigationIsMobileMode", () => isTabletOrMobile.value);
  const isMobileOpen = useState("navigationIsMobileOpen", () => false);
  const activeMenu = useState("navigationActiveMenu", () => null);
  const activeMenuIndex = useState("navigationActiveMenuIndex", () => null);
  const isAccordionOpen = useState("navigationIsAccordionOpen", () => false);
  const evaluateMobileMode = (list, listItems) => {
    if (!list.value || isEditMode) {
      isMobileMode.value = false;
      return isMobileMode.value;
    }
    const { gap, paddingLeft, paddingRight } = getComputedStyle(list.value);

    let itemWidth = 0;

    if (listItems.value?.length > 0) {
      const gapWidth = parseInt(gap);
      listItems.value?.forEach((item) => {
        itemWidth += item.scrollWidth;
      });
      itemWidth += (listItems.value?.length - 1) * gapWidth;
      itemWidth += parseInt(paddingLeft) + parseInt(paddingRight);
    }
    isMobileMode.value = itemWidth > list.value?.offsetWidth || isTabletOrMobile.value;

    return !!isMobileMode.value;
  };

  const resetState = () => {
    isMobileOpen.value = false;
    activeMenu.value = null;
    activeMenuIndex.value = null;
    isAccordionOpen.value = false;
  };

  const isMenuOpen = computed(() =>
    isMobileMode.value ? isMobileOpen.value : activeMenuIndex.value !== null,
  );

  return {
    isMobileOpen,
    isMenuOpen,
    activeMenuIndex,
    isMobileMode,
    activeMenu,
    isAccordionOpen,
    evaluateMobileMode,
    resetState,
  };
}

export default useMainNavigationState;
