<template>
  <div v-if="contactSuccess || isEditMode()">
    <EditableArea :content="successArea" :custom-view="FeedbackArea" />

    <div
      v-if="isEditMode() || newsletterSubmitted"
      :class="[contentToTailwindClasses({ backgroundColor })]"
      class="relative mt-6 overflow-hidden rounded-xl md:mt-10"
    >
      <div
        v-if="isEditMode()"
        :class="
          newsletterActivated
            ? 'editmode-info-box bg-green text-green'
            : 'editmode-warning-box bg-red-500 text-red-500'
        "
        class="w-min rounded-br-2xl p-2.5 font-medium"
      >
        <span v-if="newsletterActivated">[NEWSLETTER_IS_ACTIVATED]</span>
        <span v-else>[NEWSLETTER_IS_DEACTIVATED]</span>
        <span v-if="newsletterActivated && !interactionId" class="p-1 text-red-500"
          >[INTERACTION_ID_MISSING]</span
        >
      </div>
      <DotPattern v-if="dotPattern" :variant="dotPattern"></DotPattern>
      <div
        :class="{
          'md:px-16 xl:w-full xl:px-12': hasImage,
        }"
        class="relative grid gap-6 px-4 py-8 md:mx-auto md:w-10/12 md:gap-y-8 md:px-0 md:py-12 xl:grid-cols-5"
      >
        <PictureElement
          v-if="hasImage"
          :class="{ 'order-last': !imageFirst && isTabletOrMobile }"
          :img="image"
          class="z-10 xl:order-first xl:col-span-2 xl:col-start-4 xl:row-start-1"
          img-class="object-cover aspect-4/3 rounded-xl overflow-hidden mx-auto"
        ></PictureElement>

        <div
          ref="contentGroup"
          :class="[hasImage && isDesktop ? 'col-span-3' : 'col-span-full']"
          class="content-group flex flex-col gap-6"
        >
          <HeadlineComponent
            v-if="confirmationHeadline?.text"
            :headline="confirmationHeadline"
            class="mb-4 text-2xl font-medium text-dark-blue xl:text-3xl"
          ></HeadlineComponent>
          <p v-if="confirmationSubline" class="mb-6 text-base text-black">
            {{ confirmationSubline }}
          </p>
          <NewsletterBenefits v-if="hasNodes(confirmationSteps)" :benefits="confirmationSteps" />
        </div>
      </div>
    </div>
  </div>
  <form
    v-if="!contactSuccess || isEditMode()"
    class="contact-form-component relative"
    @submit.prevent="onSubmit()"
  >
    <LoadingElement v-if="isLoading" class="bg-white/70" overlay="true"></LoadingElement>
    <div class="xl:responsive-gap relative grid gap-y-6 xl:grid-cols-12">
      <div class="flex flex-col gap-4 md:gap-6 xl:col-span-5">
        <div class="mb-2 text-lg font-medium xl:mb-2">
          <template v-if="primaryHeadline">
            {{ primaryHeadline }}
          </template>
          <span v-else class="hidden xl:block">&nbsp;</span>
        </div>

        <SelectField
          v-if="isEditMode() || selectableReasons.length > 1"
          v-model="formState.subject"
          :error-message="contactFormVuelidate?.subject?.$errors?.[0]?.$message"
          :label="contactFormLabels?.['subject'] ?? 'subject'"
          :options="selectableReasons"
          :placeholder="
            contactFormPlaceholder?.['subject'] ?? contactFormLabels?.['subject'] ?? 'subject'
          "
          :required="formRules?.subject?.hasOwnProperty('required')"
          class="form-field-grey"
          name="subject"
          @blur="contactFormVuelidate?.subject?.$touch()"
        ></SelectField>

        <TextAreaField
          v-model="formState.message"
          :error-message="contactFormVuelidate?.message?.$errors?.[0]?.$message"
          :label="contactFormLabels?.['message'] ?? 'message'"
          :maxlength="messageMaxLength"
          :placeholder="
            contactFormPlaceholder?.['message'] ?? contactFormLabels?.['message'] ?? 'message'
          "
          :required="formRules?.message?.hasOwnProperty('required')"
          name="message"
          @blur="contactFormVuelidate?.message?.$touch()"
        ></TextAreaField>
      </div>
      <div class="flex flex-col gap-4 md:gap-6 xl:col-span-6 xl:col-start-7">
        <div class="mb-2 text-lg font-medium xl:mb-2">
          <template v-if="secondaryHeadline">
            {{ secondaryHeadline }}
          </template>
          <span v-else class="hidden xl:block">&nbsp;</span>
        </div>

        <div
          v-if="isEditMode() && selectableCountries.length === 0"
          class="flex rounded-xl bg-red-500 p-4"
        >
          <span class="m-auto font-medium">[COUNTRY_IS_MISSING_IN_DIALOG]</span>
        </div>
        <div class="grid gap-4 md:grid-cols-2 md:gap-6">
          <template v-for="(value, key) in formState" :key="key">
            <!-- filter primary fields-->
            <template v-if="!['message', 'subject', 'country'].includes(key)">
              <template v-if="key === 'salutation'">
                <SelectField
                  v-model="formState[key]"
                  :error-message="contactFormVuelidate?.[key]?.$errors?.[0]?.$message"
                  :label="contactFormLabels?.[key] ?? key"
                  :options="selectableSalutations"
                  :placeholder="contactFormPlaceholder?.[key] ?? contactFormLabels?.[key] ?? key"
                  :required="formRules[key]?.hasOwnProperty('required')"
                  class="form-field-grey col-start-1"
                  @blur="contactFormVuelidate?.[key]?.$touch()"
                ></SelectField>
              </template>
              <InputField
                v-else
                v-model="formState[key]"
                :autocomplete="['middlename'].includes(key) ? 'off' : null"
                :class="{
                  'col-start-1': ['company', 'first_name', 'salutation'].includes(key),
                  'form-field-umbrella': ['middlename'].includes(key),
                }"
                :error-message="contactFormVuelidate?.[key]?.$errors?.[0]?.$message"
                :label="contactFormLabels?.[key] ?? key"
                :min="fieldTypes[key] === 'date' ? minDate : null"
                :name="key"
                :placeholder="contactFormPlaceholder?.[key] ?? contactFormLabels?.[key] ?? key"
                :required="formRules?.[key]?.hasOwnProperty('required')"
                :type="fieldTypes[key] ?? 'text'"
                class="form-field-grey"
                @blur="contactFormVuelidate?.[key]?.$touch()"
              ></InputField>
            </template>
          </template>
          <SelectField
            v-if="isEditMode() || selectableCountries.length > 1"
            v-model="formState.country"
            :label="contactFormLabels?.['country'] ?? 'country'"
            :options="selectableCountries"
            :placeholder="
              contactFormPlaceholder?.['country'] ?? contactFormLabels?.['country'] ?? 'country'
            "
            class="form-field-grey"
            name="country"
            required
            @blur="contactFormVuelidate?.country?.$touch()"
          ></SelectField>
        </div>
        <div class="prose prose-sm prose-black max-w-none hyphens-auto">
          <CommonLabel path="/BWF/form/shared/requiredFieldLabel"></CommonLabel>
          <div class="mt-2">
            <CommonLabel path="/BWF/form/shared/privacyLinkLabel"> </CommonLabel>
          </div>
        </div>
      </div>
      <div v-if="showNewsletterRegistration" class="col-span-full">
        <p class="text-sm font-medium md:text-base">{{ newsletterHeadline }}</p>
        <CheckboxField v-model="newsletter.newsletterConsent" class="mt-4 text-xs md:text-sm">
          <CommonLabel path="/BWF/components/newsletter/newsletterConsent"></CommonLabel>
        </CheckboxField>
      </div>

      <button
        :disabled="deactivateButton"
        class="btn btn-blue col-span-full place-self-start"
        type="submit"
      >
        <CommonLabel path="BWF/form/contact/label/sendButtonLabel"></CommonLabel>
      </button>
    </div>
  </form>
</template>

<script setup>
import useBritaMiddleware from "~/composables/useBritaMiddleware";
import SelectField from "~/templates/elements/form/SelectField.vue";
import TextAreaField from "~/templates/elements/form/TextAreaField.vue";
import InputField from "~/templates/elements/form/InputField.vue";
const { isDesktop, isTabletOrMobile } = useDevice();

const props = defineProps([
  "confirmationHeadline",
  "confirmationSubline",
  "confirmationSteps",
  "backgroundColor",
  "dotPattern",
  "image",
  "imageFirst",
  "successArea",
  "commonLabel",
  "selectableCountries",
  "visible",
  "required",
  "messageMaxLength",
  "primaryHeadline",
  "secondaryHeadline",
  "confirmModalArea",
  "errorModalArea",
  "newsletterHeadline",
  "newsletterActivated",
  "interactionId",
  "actionId",
]);

const { pushError, pushSuccess } = useNotifications();

const { user, isLoggedIn } = useUser();
const { contactForm, register, getDoiState } = useBritaMiddleware();
const { getLabels, getLabelByPath } = useCommonLabels();
const { currentLanguage } = useMagnoliaLanguage();

import { useVuelidate } from "@vuelidate/core";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import CheckboxField from "~/templates/elements/form/CheckboxField.vue";
import { isEditMode } from "~/utils/content/magnolia";
import FeedbackArea from "~/templates/areas/FeedbackArea.vue";
import { EditableArea } from "@magnolia/vue-editor";
import { hasNodes, containsImage } from "~/utils/helper/magnolia";
import HeadlineComponent from "~/templates/components/cms/HeadlineComponent.vue";
import { contentToTailwindClasses } from "~/utils/helper/tailwind";
import DotPattern from "~/templates/elements/DotPattern.vue";
import NewsletterBenefits from "~/templates/components/shop/product/NewsletterBenefits.vue";
import PictureElement from "~/templates/elements/PictureElement.vue";
import LoadingElement from "~/templates/elements/LoadingElement.vue";

const hasImage = containsImage(props.image);
const contactSuccess = ref(false);
const newsletterSubmitted = ref(false);
const isLoading = ref(false);

const selectableReasons =
  props.commonLabel?.map(({ name, value }) => ({
    value: name,
    label: value,
  })) ?? [];

const selectableCountries =
  props.selectableCountries?.map(({ name, value }) => ({
    value: name,
    label: value,
  })) ?? [];

const today = new Date();
const minDate = today.toISOString().slice(0, -14);

const fieldTypes = { language: "hidden", email: "email", cancellation_date: "date" };
const formState = reactive({
  subject: selectableReasons?.length === 1 ? selectableReasons?.[0]?.value : "",
  message: "",
  ...(props.visible?.includes("company") ? { company: "" } : {}),
  ...(props.visible?.includes("salutation")
    ? { salutation: user.value?.salutation?.salutationKey ?? "" }
    : {}),
  ...(props.visible?.includes("title") ? { title: "" } : {}),
  first_name: user.value?.firstName ?? "",
  last_name: user.value?.lastName ?? "",
  email: user.value?.email ?? "",
  ...(props.visible?.includes("contract_number") ? { contract_number: "" } : {}),
  ...(props.visible?.includes("cancellation_date") ? { cancellation_date: "" } : {}),
  ...(props.visible?.includes("phone") ? { phone: "" } : {}),
  ...(props.visible?.includes("street_number") ? { street_number: "" } : {}),
  ...(props.visible?.includes("address_additional_info") ? { address_additional_info: "" } : {}),
  ...(props.visible?.includes("post_code") ? { post_code: "" } : {}),
  ...(props.visible?.includes("city") ? { city: "" } : {}),
  country: selectableCountries?.length === 1 ? selectableCountries?.[0]?.value : "",
  ...(props.visible?.includes("state") ? { state: "" } : {}),
  // TODO figure out what to do with theses ?
  language: currentLanguage.value,
  middlename: "",
});

const {
  firstNameValidators,
  lastNameValidators,
  emailValidators,
  streetNumberValidators,
  addressAdditionalValidators,
  cityValidators,
  phoneValidators,
  zipCodeValidators,
  requiredValidator,
  messageValidators,
  contractNumberValidators,
} = await useValidation();

// merge with mgnl config
const formRules = computed(() => ({
  subject: {
    ...requiredValidator,
  },
  email: {
    ...requiredValidator,
    ...emailValidators,
  },
  last_name: {
    ...requiredValidator,
    ...lastNameValidators,
  },
  first_name: {
    ...requiredValidator,
    ...firstNameValidators,
  },
  message: {
    ...requiredValidator,
    ...messageValidators,
  },
  post_code: {
    ...(props.visible?.includes("post_code") && props.required?.includes("post_code")
      ? { ...requiredValidator }
      : {}),
    ...zipCodeValidators,
  },
  contract_number: {
    ...contractNumberValidators,
    ...(props.visible?.includes("contract_number") && props.required?.includes("contract_number")
      ? { ...requiredValidator }
      : {}),
  },
  cancellation_date: {
    ...(props.visible?.includes("cancellation_date") &&
    props.required?.includes("cancellation_date")
      ? { ...requiredValidator }
      : {}),
  },
  street_number: {
    ...(props.visible?.includes("street_number") && props.required?.includes("street_number")
      ? { ...requiredValidator }
      : {}),
    ...streetNumberValidators,
  },
  address_additional_info: {
    ...(props.visible?.includes("address_additional_info") &&
    props.required?.includes("address_additional_info")
      ? { ...requiredValidator }
      : {}),
    ...addressAdditionalValidators,
  },
  city: {
    ...(props.visible?.includes("city") && props.required?.includes("city")
      ? { ...requiredValidator }
      : {}),
    ...cityValidators,
  },
  country: { ...requiredValidator },
  phone: {
    ...(props.visible?.includes("phone") && props.required?.includes("phone")
      ? { ...requiredValidator }
      : {}),
    ...phoneValidators,
  },
  ...(props.visible?.includes("salutation") && props.required?.includes("salutation")
    ? { salutation: { ...requiredValidator } }
    : {}),
  ...(props.visible?.includes("company") && props.required?.includes("company")
    ? { company: { ...requiredValidator } }
    : {}),
  ...(props.visible?.includes("state") && props.required?.includes("state")
    ? { state: { ...requiredValidator } }
    : {}),
  ...(props.visible?.includes("title") && props.required?.includes("title")
    ? { title: { ...requiredValidator } }
    : {}),
}));

// Build a flat Map of potential field labels and placeholders
const fieldLabelKeys = Object.keys(formState)
  .map((key) => key)
  .filter((key) => fieldTypes[key] !== "hidden");
//
const contactFormLabels = getLabels("/BWF/form/contact/label/", fieldLabelKeys);
const contactFormPlaceholder = getLabels("/BWF/form/contact/placeholder/", fieldLabelKeys);
const salutationLabels = getLabels("/BWF/form/shared/salutations/", [
  "not_specified",
  "mr",
  "mrs",
  "diverse",
]);
const selectableSalutations = [
  ...(Object.entries(salutationLabels ?? {})?.map(([key, value]) => ({
    value: key,
    label: value,
  })) ?? []),
];

const contactFormPushLabels = getLabels("BWF/form/contact/push/", [
  "newsletterSuccess",
  "newsletterError",
  "contactFormSuccess",
  "contactFormError",
]);

const zipCodePushLabel = getLabelByPath("BWF/shared/middleware/errors/zipCodeError");

const newsletter = reactive({
  newsletterConsent: null,
});

const newsletterAlreadyRegistered = ref(false);
const showNewsletterRegistration = computed(() => {
  return props?.newsletterActivated && (isEditMode() || !newsletterAlreadyRegistered.value);
});

onMounted(async () => {
  if (!isEditMode() && isLoggedIn.value) {
    try {
      const doiState = await getDoiState(user.value?.email);
      newsletterAlreadyRegistered.value = doiState?.donotbulkemail === false;
    } catch (e) {
      console.error(e);
    }
  }
});
const deactivateButton = ref(false);
const contactFormVuelidate = useVuelidate(formRules, formState);
const onSubmit = async () => {
  deactivateButton.value = true;
  if (formState.salutation === "") {
    formState.salutation = selectableSalutations?.[0]?.value;
  }
  contactFormVuelidate.value.$touch();
  const valid = await contactFormVuelidate.value.$validate();
  if (!valid) {
    // show generic error or modal !?
    pushError(contactFormPushLabels?.contactFormError);
    deactivateButton.value = false;
    return;
  }
  try {
    isLoading.value = true;
    await contactForm(formState);
    pushSuccess(contactFormPushLabels?.contactFormSuccess);
    contactSuccess.value = true;
    useContactformTracking(formState?.subject);
  } catch (e) {
    if (e.data === "postalcode_validation_failed") {
      pushError(zipCodePushLabel);
      deactivateButton.value = false;
    } else {
      pushError(contactFormPushLabels?.contactFormError);
      deactivateButton.value = false;
    }
    isLoading.value = false;
    return;
  }
  isLoading.value = false;
  if (newsletter?.newsletterConsent) {
    try {
      await register(
        formState?.email,
        props?.interactionId,
        props?.actionId,
        formState?.middlename,
      );
      newsletterSubmitted.value = true;
    } catch (e) {
      pushError(contactFormPushLabels?.newsletterError);
    }
  }
};
</script>
