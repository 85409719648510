<template>
  <div class="mask" :class="{ 'overflow-visible': debug, 'overflow-hidden': !debug }">
    <div class="content" :class="{ 'reverse-animation': reverseAnimation }">
      <slot></slot>
    </div>
    <div
      v-if="debug"
      class="border-green-900 text-green-900 absolute inset-0 flex items-center justify-center border-2 border-dashed text-2xl"
    >
      <span>{{ percentage }}, {{ reverseAnimation }}</span>
    </div>
  </div>
</template>

<script>
import { scrollableComponent } from "~/utils/mixins/scrollableComponent";

export default {
  name: "ParallaxMove",
  components: {},
  mixins: [scrollableComponent],
  props: {
    contentMovePercentage: {
      type: String,
      default: "25%",
    },
    debug: {
      type: Boolean,
      default: false,
    },
    reverseAnimation: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      animationsDelay: "-50s",
      percentage: 0.5,
    };
  },
  watch: {
    // whenever question changes, this function will run
    percentage() {
      this.animationsDelay = `${this.percentage * -100}s`;
    },
  },

  methods: {
    onScrollUpdate() {
      const progress = this.getViewPortIntersectionRatio(this.$el);
      this.percentage = Math.min(Math.max(progress, 0), 1);
    },
    setPercentage(percentage) {
      this.percentage = percentage;
    },
  },
};
</script>

<style scoped>
.content {
  @apply h-full w-full;
  animation-name: scrollParallaxY;
  animation-duration: 100s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-play-state: paused;
  animation-delay: v-bind(animationsDelay);
  &.reverse-animation {
    animation-direction: reverse;
  }
}

@keyframes scrollParallaxY {
  0% {
    transform: translateY(v-bind(contentMovePercentage));
  }
  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(calc(v-bind(contentMovePercentage) * -1));
  }
}
</style>
