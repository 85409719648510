import { getImageAlt, getImageTitle, hasValue, imagePath } from "./magnolia";

export const getYouTubeId = (url) => {
  try {
    const VID_REGEX = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    return url.match(VID_REGEX)[2];
  } catch (e) {
    return url;
  }
};

export const getVideoSrc = (video) => {
  return imagePath(video);
};

export const getVideoAlt = (video) => {
  return getImageAlt(video);
};

export const getVideoTitle = (video) => {
  return getImageTitle(video);
};

export const hasVideo = (video) => {
  return hasValue(video?.field);
};

export const isAsset = (video) => {
  return video?.field === "dam";
};

export const isYoutube = (video) => {
  return video?.field === "youtube";
};

export const isExternal = (video) => {
  return video?.field === "external";
};
