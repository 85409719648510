<template>
  <div v-if="editMode">
    <slot></slot>
  </div>
</template>

<script setup>
import { isEditMode } from "~/utils/content/magnolia";
const editMode = isEditMode();
</script>
