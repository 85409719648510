<template>
  <div class="relative flex h-full flex-col gap-4">
    <h3 v-if="showTitle" class="m-0 font-medium">
      <slot name="title">{{ addressTitle }}</slot>
    </h3>
    <div v-if="!address?.customFields?.dummyAddress">
      <h5>{{ `${address?.firstName} ${address?.lastName}` }}</h5>
      <div>{{ address?.street }}</div>
      <div>{{ address?.additionalAddressLine1 }}</div>

      <div>{{ address?.zipcode }} {{ address?.city }}</div>
      <div>{{ address?.country?.name }}</div>
    </div>
    <div v-if="canEdit" class="mt-auto xl:place-self-start" @click.prevent="addressModal.open()">
      <slot name="editButton">
        <button
          class="text-icon-link absolute right-0 top-0 xl:relative xl:right-auto xl:top-auto xl:mt-auto"
        >
          <CommonLabel
            v-if="isShipping"
            class="hidden xl:block"
            path="BWF/shop/shared/shippingAddressEdit"
          ></CommonLabel>
          <CommonLabel
            v-else
            class="hidden xl:block"
            path="BWF/shop/shared/billingAddressEdit"
          ></CommonLabel>

          <CommonLabel class="xl:hidden" path="BWF/shop/shared/edit"></CommonLabel>

          <SvgIcon class="h-4 w-4" name="help-circle-outline"></SvgIcon>
        </button>
      </slot>
    </div>
    <ModalComponent v-if="canEdit" :controller="addressModal">
      <template #headline>
        <p class="block text-center text-lg md:text-2xl">
          <span> {{ addressTitle }}&nbsp;</span>
          <CommonLabel path="BWF/shop/shared/edit"></CommonLabel>
        </p>
      </template>
      <AccountBillingAddressForm
        v-if="isBilling"
        :address="address as CustomerAddress"
        @close-modal="addressModal.close()"
      ></AccountBillingAddressForm>
      <AccountShippingAddressForm
        v-else-if="isShipping"
        :address="address as CustomerAddress"
        @close-modal="addressModal.close()"
      ></AccountShippingAddressForm>
      <AccountAddressForm
        v-else
        :address="address as CustomerAddress"
        @close-modal="addressModal.close()"
      ></AccountAddressForm>
    </ModalComponent>
  </div>
</template>

<script setup lang="ts">
import type { CustomerAddress } from "@shopware-pwa/types";
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import ModalComponent from "~/templates/components/ModalComponent.vue";
import { useModal } from "~/composables/useModal";
import AccountBillingAddressForm from "~/templates/components/shop/account/AccountBillingAddressForm.vue";
import AccountShippingAddressForm from "~/templates/components/shop/account/AccountShippingAddressForm.vue";
import AccountAddressForm from "~/templates/components/shop/account/AccountAddressForm.vue";

const addressModal = useModal();

const props = withDefaults(
  defineProps<{
    address: CustomerAddress | null;
    canEdit?: boolean;
    showTitle?: boolean;
    isShipping?: boolean;
    isBilling?: boolean;
  }>(),
  {
    canEdit: true,
    showTitle: true,
  },
);

const { getLabels } = useCommonLabels();
const { shippingAddressLabel, billingAddressLabel, addressLabel } = getLabels("BWF/shop/shared/", [
  "shippingAddressLabel",
  "billingAddressLabel",
  "addressLabel",
]);

const addressTitle = props.isShipping
  ? shippingAddressLabel
  : props.isBilling
    ? billingAddressLabel
    : addressLabel;
</script>
