<template>
  <img
    v-if="column?.differentMobileContent?.icon || column?.icon"
    :src="
      imagePath(
        isMobile && column?.differentMobileContent?.icon
          ? column?.differentMobileContent?.icon
          : column?.icon,
      )
    "
    :alt="
      getImageAlt(
        isMobile && column?.differentMobileContent?.icon
          ? column?.differentMobileContent?.icon
          : column?.icon,
      )
    "
    class="h-5 w-5"
  />
  <LinkComponent
    ref="promoBannerColumn"
    :link="
      isMobile && column?.differentMobileContent?.link
        ? column?.differentMobileContent?.link
        : column?.link
    "
    class="ml-2"
    @click="isLink && trackPromoClick()"
  >
    {{
      isMobile && column?.differentMobileContent?.commonLabel
        ? column?.differentMobileContent?.commonLabel?.value
        : column?.commonLabel?.value
    }}</LinkComponent
  >
</template>

<script setup>
import { getImageAlt, imagePath } from "~/utils/helper/magnolia";
import LinkComponent from "~/templates/components/cms/LinkComponent.vue";
import { containsLink } from "~/utils/helper/magnolia";
const props = defineProps(["column"]);
const { isMobile } = useDevice();

useTrackingContext("PromoBanner");

const promoBannerColumn = ref(null);
const { trackPromoView, trackPromoClick } = usePromoTracking({
  promoIndex: props?.column["@name"],
  creativeName: props?.column?.commonLabel["@name"],
  promoName: "promo_banner",
});

const isLink = computed(() => {
  return (
    containsLink(props.column?.differentMobileContent?.link) || containsLink(props.column?.link)
  );
});

onMounted(() => {
  if (isLink.value) {
    trackPromoView(promoBannerColumn);
  }
});
</script>
