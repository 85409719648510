export default function () {
  const trackAccordionExpand = (topic) => {
    try {
      const gtm = useGtm(); // auto-imported by the module

      gtm?.trackEvent({
        event: "gx.faq_expand",
        event_name: "faq_expand",
        event_source: "source_code",
        faq_expand: { faq_topic: topic },
      });
    } catch (e) {
      console.error("gx.faq_expand", e);
    }
  };

  return { trackAccordionExpand };
}
