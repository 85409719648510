import { computed, ref, inject, provide } from "vue";
import { useShopwareContext, useMarketContent } from "#imports";
import type { Product, Category } from "@shopware-pwa/types";

export async function useProductRegistration() {
  const { apiInstance } = useShopwareContext();
  const { marketSetupAdmin } = useMarketContent();
  const _registrationData = inject("swProductRegistration", ref());
  provide("swProductRegistration", _registrationData);

  if (Object.keys(_registrationData.value ?? {}).length === 0) {
    const { data: registrationData, error } = await useAsyncData(`registrationData`, async () => {
      const response = await apiInstance.invoke.post(
        `/store-api/product-registration-listing/${marketSetupAdmin.value?.pregListingId}`,
        {},
      );
      return response.data;
    });
    _registrationData.value = registrationData.value;
  }

  const categoryOptions = computed(() => {
    return _registrationData.value?.elements?.map((category: Category) => ({
      value: category.id,
      label: category.name,
    }));
  });

  const allProducts = computed(() => {
    const products: Product[] = [];
    _registrationData.value?.elements?.forEach(
      //@ts-ignore
      (category: Category[]) => products.push(...(category.extensions?.registerableProducts ?? [])),
    );
    return products;
  });
  // const selectedProduct = computed(() => {
  //     // return allProducts.value?.find(product => product.id === registrationState?.value?.id) ?? {};

  // });

  // Working search with single code:

  const search = useDebounceFn((searchValue: string) => {
    return allProducts.value?.find((product) =>
      //@ts-ignore
      product?.extensions?.registrationCodes?.some((codeEntity) => codeEntity.code === searchValue),
    );
  }, 400);

  return {
    registrationData: computed(() => _registrationData.value),
    allProducts,
    categoryOptions,
    search,
  };
}
