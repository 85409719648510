<template>
  <div class="form-field input-field">
    <div
      :class="{
        'has-error': hasError,
        hidden: attrs?.type === 'hidden',
      }"
      class="form-field-wrapper"
    >
      <input
        :id="`input-${attrs?.name}`"
        :autocomplete="attrs?.autocomplete"
        :class="{ 'has-label': !!label, 'has-error': hasError }"
        :disabled="attrs?.disabled"
        :max="attrs?.max"
        :min="attrs?.min"
        :name="attrs?.name"
        :pattern="attrs?.pattern"
        :placeholder="attrs.placeholder"
        :readonly="attrs?.readonly"
        :type="attrs?.type ?? 'text'"
        :value="modelValue"
        class="h-10 w-full appearance-none rounded-t-sm bg-transparent px-2"
        @blur="$emit('blur', $event)"
        @change="$emit('change', $event)"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <label v-if="label" :class="{ 'border-signal-red': hasError }" :for="`input-${attrs?.name}`">
        {{ label }}
        <span v-if="label && attrs.required">{{ requiredChar }}</span>
      </label>
    </div>
    <div v-if="errorMessage" class="pointer-events-none px-2 text-xs text-signal-red">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup>
const props = defineProps(["modelValue", "label", "errorMessage"]);
const attrs = useAttrs();
const hasError = computed(() => !!props.errorMessage);

const requiredChar = inject("input-required-char", "*");

defineEmits(["update:modelValue", "blur", "change"]);
</script>
