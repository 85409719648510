<template>
  <nav
    ref="nav"
    class="main-navigation w-full overflow-hidden px-8"
    :class="{
      hidden: !isMounted,
      'xl:block': !isMounted,
    }"
    role="navigation"
  >
    <ul
      ref="list"
      v-scroll-lock="isMobileMode && isMobileOpen"
      role="list"
      class="navigation-list container flex content-center items-center gap-4 text-sm text-black transition-all"
      :class="[
        isMobileMode ? 'is-mobile' : 'is-desktop',
        {
          'is-mobile-open': isMobileOpen,
        },
      ]"
    >
      <li v-for="(entry, index) in entries" :key="entry['@id']" ref="listItems" class="group">
        <LinkComponent
          v-if="entry['@nodeType'] === 'nav:link'"
          class="level-one-link z-10 cursor-pointer whitespace-nowrap font-medium transition-colors hover:text-deep-blue"
          :link="entry.link"
        >
          {{ entry.link?.label }}
        </LinkComponent>
        <AccordionComponent
          v-else
          :id="`nav-${index}`"
          ref="accordions"
          :is-active="isMobileMode && isMobileOpen"
          @on-open="onAccordionOpen"
          @on-toggle="onAccordionToggle"
        >
          <template #header>
            <button
              class="level-one-link z-10 cursor-pointer whitespace-nowrap font-medium transition-colors hover:text-deep-blue"
              :class="{ 'text-deep-blue': index === activeMenuIndex }"
              :data-index="index"
              @click="toggleSubMenu"
            >
              {{ entry.title }}
            </button>
          </template>
          <template #content>
            <!--                            TODO check hydration-->
            <LevelTwo
              v-if="entry['@nodeType'] === 'nav:main'"
              ref="submenuRefs"
              class="absolute left-0 right-0 top-full"
              :class="{ invisible: !isMobileMode && index !== activeMenuIndex }"
              :is-active="index === activeMenuIndex"
              :data-index="index"
              :level="entry"
              @level-close="close"
            ></LevelTwo>
          </template>
        </AccordionComponent>
      </li>
      <li v-if="containsLink(header?.shopLink)">
        <ShopButton :link="header?.shopLink" class="flex flex-shrink-0 md:hidden"></ShopButton>
      </li>
      <li
        v-if="isMobileMode && isMobileOpen && !isAccordionOpen && header.teaserHeadline"
        class="mt-8 w-full"
      >
        <MenuTeaser
          :headline="header.teaserHeadline"
          :subline="header.teaserSubline"
          :link="header.teaserLink"
          :img="header.image"
        ></MenuTeaser>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import LevelTwo from "./LevelTwo";
import LinkComponent from "../../components/cms/LinkComponent";
import MenuTeaser from "./MenuTeaser";
import AccordionComponent from "../../elements/AccordionComponent";
import { mapNodes, filterNodesByType, containsLink } from "~/utils/helper/magnolia";
import ShopButton from "~/templates/partials/header/ShopButton.vue";
import { ref } from "vue";

const props = defineProps(["header"]);
const emit = defineEmits(["submenu-toggle", "mobile-change"]);

const entries = computed(() => {
  return filterNodesByType(mapNodes(props.header), "nav:");
});
const { isTabletOrMobile } = useDevice();

const {
  isMobileOpen,
  activeMenuIndex,
  isMobileMode,
  isAccordionOpen,
  activeMenu,
  evaluateMobileMode,
  resetState,
} = useMainNavigationState();
const nav = ref(null);
const list = ref(null);
const listItems = ref(null);
const accordions = ref(null);
const submenuRefs = ref(null);

const isMounted = ref(false);

const close = () => {
  if (activeMenu.value) {
    activeMenu.value = null;
    activeMenuIndex.value = null;
    emit("submenu-toggle");
  }
};

const toggleSubMenu = (event) => {
  const { index } = event.currentTarget.dataset;
  const submenu = submenuRefs.value[index];
  const oldActiveIndex = activeMenu.value?.$el.dataset.index ?? -1;
  const closeActive = index === oldActiveIndex;
  activeMenu.value = closeActive ? null : submenu;
  activeMenuIndex.value = activeMenu.value ? parseInt(index) : null;

  emit("submenu-toggle");
};

const evaluateMobile = () => {
  evaluateMobileMode(list, listItems);
  if (isMobileMode.value) {
    close();
  }
  return isMobileMode.value;
};

const onAccordionOpen = (event) => {
  accordions.value?.forEach((accordion) => {
    if (accordion.id !== event.id) {
      // close others that have not just been opened
      accordion.isOpen = false;
    }
  });
  list.value?.scrollTo({ behavior: "smooth", top: 0 });
};

const onAccordionToggle = () => {
  // set state
  isAccordionOpen.value = accordions.value?.some((acc) => acc.isOpen) ?? false;
};
const setMobileOpen = (value) => {
  isMobileOpen.value = value;
};

onMounted(() => {
  isMounted.value = true;
  resetState();
  watch(
    [isMobileOpen, isTabletOrMobile],
    () => {
      evaluateMobile();
    },
    { immediate: true },
  );
});

defineExpose({ close, evaluateMobile, activeMenu, setMobileOpen });
</script>

<style scoped>
.is-mobile {
  @apply h-4 opacity-0;

  .level-two {
    @apply top-0 transform-none p-0 transition-none;
  }

  .navigation-list {
    @apply transition-none;
  }

  .group {
    @apply w-full;
  }

  .level-one-link {
    @apply block truncate;
  }
}

.is-desktop {
  @apply opacity-100;
}

.is-mobile-open {
  --top-offset: theme("spacing.16");
  --bottom-offset: theme("spacing.4");
  @screen md {
    --top-offset: theme("spacing.20");
    --bottom-offset: theme("spacing.10");
  }
  @apply absolute inset-0 flex h-screen scroll-p-24 flex-col items-start overflow-auto pb-32 opacity-100;
  top: var(--top-offset);
  height: calc(100vh - var(--top-offset) - var(--bottom-offset));

  .level-one-link {
    @apply text-lg;
  }

  .level-two {
    @apply relative opacity-100;
  }
}
</style>
