<template>
  <div
    class="grid grid-cols-2 gap-2 hyphens-auto text-sm xl:grid-cols-2 xl:gap-x-2 xl:gap-y-1 xl:text-base"
  >
    <div class="text-sm font-medium">
      <CommonLabel path="BWF/shop/shared/shippingAddress"></CommonLabel>
    </div>
    <div class="xl:row-start-2">
      <div>
        {{ shippingAddress?.firstName }}
        {{ shippingAddress?.lastName }}
      </div>
      <div>
        {{ shippingAddress?.street }}
      </div>
      <div v-if="shippingAddress?.additionalAddressLine1">
        {{ shippingAddress?.additionalAddressLine1 }}
      </div>
      <div>
        {{ shippingAddress?.zipcode }}
        {{ shippingAddress?.city }}
      </div>
      <div
        v-if="
          //@ts-ignore
          shippingAddress?.countryState?.name
        "
      >
        {{
          //@ts-ignore
          shippingAddress?.countryState?.name
        }}
      </div>
      <div v-if="shippingAddress?.country">
        {{ shippingAddress?.country?.name }}
      </div>
    </div>
    <div class="text-sm font-medium">
      <CommonLabel path="BWF/shop/shared/billingAddress"></CommonLabel>
    </div>
    <div class="xl:row-start-2">
      <div>
        {{ billingAddress?.firstName }}
        {{ billingAddress?.lastName }}
      </div>
      <div>
        {{ billingAddress?.street }}
      </div>
      <div v-if="billingAddress?.additionalAddressLine1">
        {{ billingAddress?.additionalAddressLine1 }}
      </div>
      <div>
        {{ billingAddress?.zipcode }}
        {{ billingAddress?.city }}
      </div>
      <div v-if="billingAddress?.countryState?.name">
        {{ billingAddress.countryState?.name }}
      </div>
      <div v-if="billingAddress?.country">
        {{ billingAddress?.country?.name }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { useOrderDetails } from "@shopware-pwa/composables-next";

import CommonLabel from "~/templates/components/CommonLabel.vue";
const { order } = useOrderDetails();

const billingAddress = computed(() => order.value?.billingAddress);
const shippingAddress = computed(() => order.value?.deliveries?.[0]?.shippingOrderAddress);
</script>
