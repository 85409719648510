<template>
  <div class="accordion-component">
    <div
      v-if="isActive"
      class="header flex cursor-pointer items-center justify-between"
      @click="toggleOpen"
    >
      <slot name="header"></slot>
      <button ref="mobileBtn" class="btn btn-icon">
        <SvgIcon
          name="Arrow-Down"
          class="transition-transform duration-300"
          :class="{ 'rotate-180': isOpen }"
        ></SvgIcon>
      </button>
    </div>
    <div v-if="isActive" ref="content" class="content" :class="{ 'is-open': isOpen }">
      <div ref="contentMeasure">
        <slot name="content"></slot>
      </div>
    </div>

    <slot v-if="!isActive" name="header"></slot>
    <slot v-if="!isActive" name="content"></slot>
  </div>
</template>

<script setup>
import SvgIcon from "../elements/SvgIcon";

const props = defineProps({
  isActive: {
    type: Boolean,
    default: true,
  },
  open: {
    type: Boolean,
    default: false,
  },
  updateHash: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["on-toggle", "on-open", "on-close"]);

const isOpen = ref(props.open);
const contentHeight = ref(0);
const content = ref();
const contentMeasure = ref();
const attrs = useAttrs();
const hash = `#${attrs?.id ?? ""}`;

useResizeObserver(contentMeasure, (entries) => {
  const entry = entries[0];
  if (isOpen.value) {
    const { height } = entry.contentRect;
    contentHeight.value = `${height}px`;
  }
});

const toggleOpen = () => {
  contentHeight.value = `${contentMeasure.value?.offsetHeight}px`;
  isOpen.value = !isOpen.value;
  emit("on-toggle", { isOpen: isOpen.value, id: attrs.id });
  if (isOpen.value) {
    emit("on-open", { isOpen: isOpen.value, id: attrs.id });
  } else {
    emit("on-close", { isOpen: isOpen.value, id: attrs.id });
  }

  if (props.updateHash && hash.length > 1) {
    if (isOpen.value) {
      history.replaceState({}, "", hash);
    } else if (location.hash === hash) {
      history.replaceState({}, "", "#");
    }
  }
};

watch(
  () => props.open,
  () => {
    toggleOpen();
  },
);
defineExpose({ open: props.open, isOpen, id: attrs.id });

onMounted(() => {
  if (props.updateHash && location.hash === hash) {
    toggleOpen();
  }
});
</script>

<style scoped>
.accordion-component {
  scroll-margin-top: theme("spacing.28");
}

.content {
  @apply h-0 overflow-hidden transition-all duration-500;
  /*Hacky solution to fix half bullets in Safari (D2CMVP-4252)*/
  @apply -mx-1 w-auto px-1;
}

.is-open {
  height: v-bind(contentHeight);
}
</style>
