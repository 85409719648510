import revive_payload_client_4sVQNw7RlN from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import magnolia_ZKHxZZfrvT from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/plugins/magnolia.ts";
import mollie_client_1xXKLvxZ4k from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/plugins/mollie.client.ts";
import payload_client_yVLowv6hDl from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_iGLjBbor9q from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import shopware_plugin_txRT4f0fRY from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/.nuxt/runtime/shopware.plugin.mjs";
import msal_YjX35aVrLC from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/plugins/msal.ts";
import ssr_plugin_B4ptqVdIfe from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import body_scroll_lock_SsvxcizeVQ from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/plugins/body-scroll-lock.js";
import iframe_resize_WEVHnTd9Ij from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/plugins/iframe-resize.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  magnolia_ZKHxZZfrvT,
  mollie_client_1xXKLvxZ4k,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_iGLjBbor9q,
  shopware_plugin_txRT4f0fRY,
  msal_YjX35aVrLC,
  ssr_plugin_B4ptqVdIfe,
  body_scroll_lock_SsvxcizeVQ,
  iframe_resize_WEVHnTd9Ij
]