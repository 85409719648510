<template>
  <div class="form-field">
    <label :class="{ 'has-error': hasError }" class="form-field-wrapper">
      <select
        v-if="options?.length > 0"
        :id="`select-${attrs.name}`"
        :autocomplete="attrs.autocomplete"
        :disabled="disabled"
        :name="attrs.name"
        :value="modelValue"
        class="absolute inset-0 w-full opacity-0"
        @input="onInput($event.target.value)"
      >
        <option
          ref="firstOptionRef"
          :disabled="firstOptionDisabled"
          :label="firstOptionLabel ?? label ?? ' '"
          :value="firstOptionValue"
          selected
        >
          {{ firstOptionLabel ?? label ?? "" }}
        </option>

        <option
          v-for="{ value, label: optionLabel } in options"
          :key="value"
          :selected="`${value}` === `${modelValue}`"
          :value="value"
        >
          {{ optionLabel ?? value ?? "" }}
        </option>
      </select>

      <span
        :class="[{ 'has-label': !!label, 'has-value': !!modelValue }, !!label ? 'h-14' : 'h-10']"
        class="pointer-events-none relative flex w-full items-center justify-between rounded-t-sm border border-light-grey px-2"
      >
        <span class="py-2">{{ activeLabel }}</span>
        <SvgIcon
          class="pointer-events-none h-4 w-4 text-dark-blue transition-all"
          name="Arrow-Down"
        ></SvgIcon>
      </span>

      <span
        v-if="label"
        class="floating-label pointer-events-none absolute left-0 top-4 flex h-10 items-center px-2 text-medium-grey transition-all"
      >
        {{ label }}
        <span v-if="label && attrs.required">{{ requiredChar }}</span>
      </span>
    </label>
    <div v-if="errorMessage" class="pointer-events-none px-2 text-xs text-signal-red">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup>
import SvgIcon from "~/templates/elements/SvgIcon.vue";

const props = defineProps({
  modelValue: [Object, String, Number, null],
  options: Array,
  label: [String, null],
  errorMessage: [String, null],
  disabled: [Boolean, null],
  firstOptionValue: [Number, String, null],
  firstOptionDisabled: {
    type: [Boolean, null],
    default: true,
  },
  firstOptionLabel: {
    type: [String, null],
    default: "",
  },
});
const attrs = useAttrs();
const emit = defineEmits(["update:modelValue"]);
const hasError = computed(() => !!props.errorMessage);

const requiredChar = inject("input-required-char", "*");

const activeLabel = computed(() => {
  const activeOption = props.options.find((option) => `${option.value}` === `${props.modelValue}`);

  return activeOption ? (activeOption.label ?? activeOption.value) : "";
});

const onInput = (value) => {
  emit("update:modelValue", value);
};

const firstOptionRef = ref(null);
const optionRefs = ref([]);
const reset = () => {
  optionRefs.value.forEach((option) => {
    option.selected = false;
  });
  firstOptionRef.value.selected = true;
  emit("update:modelValue", "");
};
defineExpose({ reset });
</script>

<style scoped>
.form-field {
  select {
    @apply appearance-none opacity-0;
  }
  label {
    @apply block;
  }
}

.has-label {
  @apply pt-4;
}

.has-value + .floating-label {
  @apply top-1 h-4 text-xs font-medium text-deep-blue;
}

.has-error {
  .floating-label {
    @apply text-signal-red;
  }
}
</style>
