<template>
  <div class="text-icon-link text-medium-grey" @click="customClick ? null : onClick()">
    <SvgIcon class="h-3 w-3 -rotate-90" name="Arrow-Up"></SvgIcon>
    <CommonLabel path="BWF/shared/back"></CommonLabel>
  </div>
</template>
<script setup>
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";

const props = defineProps(["tags", "tagClass", "gapClass", "customClick", "to"]);
const router = useRouter();

const onClick = async () => {
  if (props.to) {
    await navigateTo(props.to);
  } else {
    router.go(-1);
  }
};
</script>
