<template>
  <Suspense>
    <ProductTilesComponent v-bind="props"></ProductTilesComponent>
    <template #fallback>
      <LoadingElement class="container aspect-2/3 lg:aspect-video" />
    </template>
  </Suspense>
</template>

<script setup>
import ProductTilesComponent from "~/templates/components/cms/ProductTilesComponent.vue";
import LoadingElement from "~/templates/elements/LoadingElement.vue";

const props = defineProps([
  "main",
  "headline",
  "text",
  "link",
  "slider",
  "mobileSlider",
  "metadata",
  "directToCart",
]);
defineOptions({
  inheritAttrs: false,
});
</script>
