<template>
  <div class="relative text-base">
    <textarea
      :id="`input-${attrs?.name}`"
      :aria-label="attrs?.label"
      :autocomplete="attrs?.autocomplete"
      :class="{ 'has-error': hasError }"
      :disabled="attrs?.disabled"
      :maxlength="attrs?.maxlength"
      :name="attrs?.name"
      :placeholder="
        attrs?.placeholder && attrs?.required
          ? `${attrs.placeholder}${requiredChar}`
          : attrs.placeholder
      "
      :readonly="attrs?.readonly"
      :value="modelValue"
      class="h-48 w-full appearance-none rounded-t-sm border border-light-grey bg-white p-2"
      @blur="$emit('blur', $event)"
      @change="$emit('change', $event)"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div v-if="attrs?.maxlength >= 0" class="prose prose-sm">
      <CommonLabel
        :placeholder="{ remainingChars: Math.max(0, attrs?.maxlength - modelValue.length) }"
        path="BWF/form/shared/remainingCharsLabel"
      ></CommonLabel>
    </div>
    <div v-if="errorMessage" class="pointer-events-none px-2 text-xs text-signal-red">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup>
import CommonLabel from "~/templates/components/CommonLabel.vue";

const props = defineProps(["modelValue", "label", "errorMessage"]);
const attrs = useAttrs();
const hasError = computed(() => !!props.errorMessage);

const requiredChar = inject("input-required-char", "*");

defineEmits(["update:modelValue", "blur", "change"]);
</script>

<style scoped>
[readonly],
[disabled] {
  @apply cursor-not-allowed opacity-60 grayscale;
}

textarea::placeholder {
  @apply text-medium-grey;
}

textarea:focus {
  @apply border-b-deep-blue outline-none;
}

textarea.has-error {
  @apply border-signal-red;
}
</style>
