export default function (data) {
  try {
    const gtm = useGtm(); // auto-imported by the module
    gtm?.trackEvent({
      event: "gx.contact_submit",
      event_name: "contact_submit",
      event_source: "source_code",
      contact_submit: {
        name: data,
      },
    });
  } catch (e) {
    console.error(e);
  }
}
