<template>
  <div
    class="-mx-[var(--container-padding)] flex gap-x-2 overflow-x-auto px-[var(--container-padding)] pb-4"
  >
    <button
      v-if="showAllFilter"
      :class="{ 'border-light-blue bg-light-blue': activeFilters.length < 1 }"
      class="filter-button btn btn-ghost btn-size-xs xl:btn-size-normal shrink-0"
      @click="onAllFilter()"
    >
      {{ allLabel }}
    </button>
    <template v-for="filter in filters" :key="filter.value">
      <button
        v-if="filter.value"
        :class="{
          'border-light-blue bg-light-blue': activeFilters.includes(filter.value),
        }"
        class="filter-button btn btn-ghost btn-size-xs xl:btn-size-normal"
        @click="onFilter(filter.value)"
      >
        {{ filter?.label }}
      </button>
    </template>
  </div>
</template>

<script setup>
const props = defineProps({
  filters: {
    type: Array,
    default: () => [],
  },
  allLabel: String,
  multiSelect: { type: Boolean, default: true },
  showAllFilter: { type: Boolean, default: true },
});
const activeFilters = ref([]);
const emit = defineEmits(["filter-change"]);

watch(
  activeFilters,
  () => {
    emit("filter-change", activeFilters.value);
  },
  { deep: true },
);

const onAllFilter = () => {
  activeFilters.value = [];
};

const onFilter = (value) => {
  if (props.multiSelect) {
    if (activeFilters.value?.includes(value)) {
      const index = activeFilters.value.indexOf(value);
      activeFilters.value.splice(index, 1);
    } else {
      activeFilters.value?.push(value);
    }
  } else {
    activeFilters.value = [value];
  }
};
</script>

<style>
.filter-button {
  @apply xl:px-5;
}
</style>
