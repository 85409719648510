<template>
  <div class="flex flex-col gap-6 md:gap-12">
    <div class="col-span-full grid gap-y-6 md:grid-cols-2 xl:col-span-1 xl:grid-cols-4">
      <div v-if="selectedProduct" class="flex flex-col gap-y-4">
        <CommonLabel
          path="BWF/shop/product/registration/productReviewProductLabel"
          class="font-medium"
        />
        <PregProductCard class="w-60" :registration-product="selectedProduct" />
        <div>
          <button class="text-link flex items-center justify-center gap-x-2" @click="removeProduct">
            <CommonLabel path="BWF/shop/product/registration/registrationChangeLabel" />
            <SvgIcon name="Edit" class="h-3 w-3" />
          </button>
        </div>
      </div>

      <div class="flex flex-col gap-y-4">
        <CommonLabel
          path="BWF/shop/product/registration/productReviewAddressLabel"
          class="font-medium"
        />
        <div>
          <p>
            <span v-if="activeSalutation?.value?.includes('mr')"
              >{{ activeSalutation?.label }}&nbsp;</span
            >
            <span>{{ registrationState?.["bri_firstname"] }}&nbsp;</span>
            <span>{{ registrationState?.["bri_lastname"] }}</span>
          </p>
          <p>
            <span>{{ registrationState?.["bri_email"] }}</span>
          </p>
          <p>
            <span>{{ registrationState?.["bri_street1"] }}</span>
          </p>
          <p>
            <span>{{ registrationState?.["bri_postalcode"] }}&nbsp;</span>
            <span>{{ registrationState?.["bri_city"] }}</span>
          </p>
          <p v-if="activeCountry">
            <span>{{ activeCountry.label }}</span>
          </p>
        </div>
        <div>
          <button
            class="text-link flex items-center justify-center gap-x-2"
            @click="$emit('change-personal-data')"
          >
            <CommonLabel path="BWF/shop/product/registration/registrationChangeLabel" />
            <SvgIcon name="Edit" class="h-3 w-3" />
          </button>
        </div>
      </div>
    </div>

    <slot></slot>

    <CheckboxField v-model="registrationConsent">
      <CommonLabel path="BWF/shop/product/registration/registrationConsent"></CommonLabel>
    </CheckboxField>

    <button
      class="btn btn-blue w-full self-start md:w-auto"
      :disabled="!registrationConsent"
      @click="$emit('submit-registration')"
    >
      <CommonLabel path="BWF/shop/product/registration/productRegistrationCompleteLabel" />
    </button>
  </div>
</template>

<script setup>
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import useProductRegistrationState from "~/composables/shop/useProductRegistrationState";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import usePregPersonalInformation from "~/composables/shop/usePregPersonalInformation";
import CheckboxField from "~/templates/elements/form/CheckboxField.vue";

defineEmits(["change-personal-data", "submit-registration"]);

const registrationConsent = ref(false);

const { selectableSalutations, selectableCountries } = await usePregPersonalInformation();

const { allProducts } = await useProductRegistration();
const selectedProduct = computed(() => {
  return allProducts.value?.find((product) => product.id === registrationState?.value?.["id"]);
});
const { registrationState, removeProduct } = await useProductRegistrationState();

const activeSalutation = computed(() => {
  return selectableSalutations?.find(
    (salutation) => salutation.value === registrationState?.value?.["bri_salutation_opt"],
  );
});
const activeCountry = computed(() => {
  return selectableCountries.find(
    (country) => country?.value === registrationState?.value?.["bri_country"],
  );
});
</script>
