<template>
  <pre v-if="isEditMode || isPreview" class="editmode-info-box break-all">
        <div>URL: {{ url }}</div>
        <div>Resize: {{ resize }}</div>
        <template v-if="resize  === false">
          <div>Max-Width: {{ width }}</div>
          <div>Height: {{ height }}</div>
        </template>
    </pre>
  <template v-else-if="mounted">
    <iframe v-if="resize" v-iframe-resize="{ log: false }" :src="url" class="w-full"></iframe>
    <iframe
      v-else
      :src="url"
      :style="{ maxWidth: configuredWidth, height: configuredHeight }"
      class="mx-auto w-full"
    ></iframe>
  </template>
</template>

<script setup>
import { isComponentPreview } from "~/utils/content/magnolia";
const isPreview = isComponentPreview();
</script>

<script>
import "../../../plugins/iframe-resize";

import { editableComponent } from "~/utils/mixins/editableComponent";

export default {
  name: "IFameComponent",
  mixins: [editableComponent],
  props: ["url", "resize", "width", "height"],
  data() {
    return {
      mounted: false,
    };
  },
  computed: {
    configuredHeight() {
      const height = parseInt(this.height);
      return height ? `${height}px` : null;
    },
    configuredWidth() {
      const width = parseInt(this.width);
      return width ? `${width}px` : null;
    },
  },

  async mounted() {
    this.mounted = true;
  },
};
</script>
