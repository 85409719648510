<template>
  <AccountGrid>
    <OrderReturnForm
      :is-loading="isLoading"
      @confirm-modal="confirmModal.open()"
      @service-modal="serviceModal.open()"
    >
    </OrderReturnForm>
    <EditableArea :content="confirmModalArea" :custom-view="EmptyArea" />
    <ModalComponent :content="confirmModalArea" :controller="confirmModal">
      <template #headline>Die Rückgabe ist eingegangen *</template>
      <template #richText>
        Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut.
      </template>
      <template #primaryAction>
        <button
          class="btn btn-blue w-full min-w-fit"
          @click="push(marketSetupAdmin.myBritaOrderPage)"
        >
          <CommonLabel path="BWF/shop/account/orderCancel/modal/overviewButton"></CommonLabel>
        </button>
      </template>
      <template #secondaryAction>
        <button class="btn btn-ghost w-full min-w-fit" @click="push(marketSetup.startPage)">
          <CommonLabel path="BWF/shop/account/orderCancel/modal/shopButton"></CommonLabel>
        </button>
      </template>
    </ModalComponent>
    <EditableArea :content="serviceModalArea" :custom-view="EmptyArea" />
    <ModalComponent :content="serviceModalArea" :controller="serviceModal">
      <template #headline>Leiner Nein .. ? *</template>

      <template #primaryAction>
        <button class="btn btn-blue w-full min-w-fit" @click="serviceModal.close()">
          <span v-if="serviceModalArea.primary?.label">{{ serviceModalArea.primary?.label }}</span>
          <CommonLabel
            v-else
            path="BWF/shop/account/orderDetails/modal/confirmReturn"
          ></CommonLabel>
        </button>
      </template>
    </ModalComponent>
  </AccountGrid>
</template>

<script setup>
import AccountGrid from "~/templates/components/account/AccountGrid.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import useMagnoliaContent from "~/composables/useMagnoliaContent";
import { EditableArea } from "@magnolia/vue-editor";
import { isEditMode } from "~/utils/content/magnolia";
import ModalComponent from "~/templates/components/ModalComponent.vue";
import EmptyArea from "~/templates/areas/EmptyArea.vue";
import { ORDER_DETAILS_ASSOCIATIONS } from "~/utils/helper/shop/order";
import { useModal } from "~/composables/useModal";
import OrderReturnForm from "~/templates/components/shop/account/OrderReturnForm.vue";

defineOptions({
  inheritAttrs: false,
});
defineProps(["title", "description", "metadata", "confirmModalArea", "serviceModalArea", "bottom"]);
const { pathVariable } = useMagnoliaContent();
 
const orderId = pathVariable?.value?.replaceAll("/", "");

const { order, loadOrderDetails } = useOrderDetails(orderId, ORDER_DETAILS_ASSOCIATIONS);

const isLoading = ref(true);
const { push } = useRouter();
const { marketSetup, marketSetupAdmin } = useMarketSetup();

const confirmModal = useModal();
const serviceModal = useModal();

watch(confirmModal.isOpen, (state) => {
  if (!state) {
    // means user cancelled modal and dismissed modal -> order details page
    push(`${marketSetupAdmin.value?.myBritaOrderDetailsPage}/${order?.value?.id}`);
  }
});

watch(serviceModal.isOpen, (state) => {
  if (!state) {
    // means user cancelled modal and dismissed modal -> order details page
    push(`${marketSetupAdmin.value?.myBritaOrderDetailsPage}/${order?.value?.id}`);
  }
});

onMounted(async () => {
  if (!isEditMode()) {
    await loadOrderDetails();
  }
  isLoading.value = false;
});
</script>
