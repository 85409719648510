export const mapNodes = (node) => {
  return node?.["@nodes"]?.map((subNode) => node[subNode]) ?? [];
};

export const hasNodes = (node) => {
  return node?.["@nodes"]?.length > 0;
};

export const hasValue = (value) => {
  return value && value !== "none";
};
export const containsNodesOfType = (nodes = [], containTypes = []) => {
  const nodeTypes = nodes.map((node) => node["@nodeType"]);
  return containTypes.some((containType) => nodeTypes.includes(containType));
};

export const filterNodesByType = (nodes = [], type = "") => {
  return nodes.filter((node) => node["@nodeType"].includes(type));
};
export const filterNodesByTemplate = (nodes = [], type = "") => {
  return nodes.filter((node) => node["mgnl:template"]?.includes(type));
};

export const filterNodesByTypes = (nodes = [], containTypes = []) => {
  return nodes.filter((node) => containTypes.includes(node["@nodeType"]));
};
export const getId = (node) => {
  return node?.["@id"] ?? "@id";
};
export const getTemplate = (node) => {
  return node?.["mgnl:template"] ?? "mgnl:template";
};

export const imagePath = (image) => {
  const {
    public: { mgnlPublicHost },
  } = useRuntimeConfig();
  return image && image["@id"] ? mgnlPublicHost + "/dam/" + image["@id"] + image["@path"] : "";
};

export const imageName = (image) => {
  return image?.metadata?.fileName ?? "";
};

export const getImageWidth = (image) => {
  const metadata = getImageMetaData(image);
  return metadata?.width > 0 ? metadata?.width : null;
};

export const getImageHeight = (image) => {
  const metadata = getImageMetaData(image);
  return metadata?.height > 0 ? metadata?.height : null;
};

export const getImageMetaData = (image) => {
  // should return description
  return isResizerImage(image) ? image?.damID?.metadata : image?.metadata;
};

export const getImageAlt = (image) => {
  // should return description
  return getImageMetaData(image)?.description ?? "";
};
export const getImageTitle = (image) => {
  // should return title
  return getImageMetaData(image)?.title ?? "";
};

export const getImageSrc = (image, width = 0, height = 0, webp = false) => {
  // should return title
  return isResizerImage(image) ? imageResizerPath(image, width, height, webp) : imagePath(image);
};

export const isResizableImage = (image) =>
  ["image/png", "image/jpeg", "image/webp"].includes(image?.metadata?.mimeType ?? `no-svg-support`);

export const isResizerImage = (image) =>
  image?.["@nodeType"] === "mgnl:contentNode" && !!image?.["damID"];

export const isDamImage = (image) =>
  image?.["@nodeType"] !== "mgnl:contentNode" && !!image?.["@id"];

export const containsImage = (image) => isResizerImage(image) || isDamImage(image);

export const containsLink = (link) => hasValue(link?.type);

export const linkOptionHref = (link) => {
  let href = "";
  let anchor = "";

  switch (link?.type) {
    case "intern":
      try {
        href = new URL(link?.link ?? "").pathname;
      } catch (e) {
        href = link?.link ?? "";
      }
      anchor = link?.anchor;
      break;
    case "extern":
      href = link.url ?? "";
      const pattern = /(.*):\/\//;
      if (!href.startsWith("//") && !pattern.test(href) && href.startsWith("#") === false) {
        // protocol missing
        href = `//${href}`;
      }
      break;
    case "mail":
      if (link.email) href = `mailto://${link.email}`;
      break;
    case "phone":
      if (link.phone) href = `tel:${link.phone}`;
      break;
    case "anchor":
      anchor = link.anchor;
      break;
    case "download":
      href = link.asset?.["@link"] ?? "";
      break;

    case "downloadApp":
      href = link.download?.download_file?.["@link"] ?? "";
      break;
  }
  anchor = anchor && anchor.length > 1 ? (anchor?.startsWith("#") ? "" : "#") + anchor : "";

  return `${href}${anchor}`;
};

export const linkOptionTarget = (link) => {
  let target = "";

  switch (link?.type) {
    case "intern":
    case "extern":
    case "download":
    case "downloadApp":
      target = link.target;
      break;
    case "mail":
      target = "_blank";
      break;
    case "phone":
      target = "_blank";
      break;
  }
  return target;
};

export const imageResizerPath = (image, width = 0, height = 0, webp = false) => {
  if (!isResizerImage(image)) return "";
  const asset = image["damID"];
  const focusHorizontal = image["focusHorizontal"] ? image["focusHorizontal"] : 50;
  const focusVertical = image["focusVertical"] ? image["focusVertical"] : 50;
  const {
    public: { mgnlPublicHost },
  } = useRuntimeConfig();

  let fileName = imageResizerAssetPath(image) || asset["@path"];

  if (webp && !fileName?.endsWith(".webp")) {
    fileName = fileName + ".webp";
  }

  return `${mgnlPublicHost}/.imaging/resizer/${
    webp ? "webp/" : ""
  }${width}/${height}/${focusHorizontal}-${focusVertical}/dam/${asset["@id"]}${fileName}`;
};

export const imageResizerAssetPath = (image) => {
  return image?.damID?.["@path"] ?? "";
};

export const imageResizerName = (image) => {
  return image?.damID?.metadata?.fileName ?? "";
};

export const imageResizerType = (image) => {
  return image?.damID?.metadata?.mimeType ?? "";
};

export const isDownloadLink = (link) => {
  return link?.type === "download" || link?.type === "downloadApp";
};

/**
 * just remove lm name aka d2c-lm...
 */
export const cleanName = (name = "") => {
  return name.replaceAll("d2c-lm:", "");
};
