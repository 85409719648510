<template>
  <p v-if="content.filterHeadline" class="text-base font-normal leading-snug">
    {{ content.filterHeadline }}
  </p>
  <FilterButtons
    v-if="content.showFilter"
    :all-label="content.filterAllLabel ?? 'Alle'"
    :filters="categoryFilterButtons"
    :show-all-filter="content.showAllFilter"
    class="mb-8 mt-4 md:mb-12 xl:mt-5"
    @filter-change="onFilter"
  ></FilterButtons>
  <div>
    <div class="md:responsive-gap grid gap-4 md:grid-cols-2">
      <EditableComponent
        v-for="childContent in visibleComponents"
        :key="childContent['@id']"
        :content="childContent"
      />
    </div>
  </div>
</template>

<script setup>
import FilterButtons from "~/templates/components/FilterButtons.vue";
import { EditableComponent } from "@magnolia/vue-editor";
import { mapNodes } from "~/utils/helper/magnolia";
import useFilterTracking from "~/composables/tracking/useFilterTracking";

const FILTER_TYPE = "category_filter";

const props = defineProps(["content"]);
const activeFilters = ref([]);

const uniqueFilters = mergeArraysOfObjectsToOneArray(mapNodes(props.content));
const categoryFilterButtons = [
  ...uniqueFilters?.map((filter) => ({ label: filter["value"], value: filter["@id"] })),
];

useFilterTracking({
  type: FILTER_TYPE,
  filters: categoryFilterButtons,
  activeFilters: activeFilters,
});

const visibleComponents = computed(() => {
  return activeFilters.value.length === 0
    ? mapNodes(props.content)
    : mapNodes(props.content).filter((node) =>
        node.filterTags?.some((filter) => activeFilters.value?.includes(filter["@id"])),
      );
});

const onFilter = (currentFilter) => {
  activeFilters.value = currentFilter;
};

function mergeArraysOfObjectsToOneArray(nodes) {
  /**
   * .flatMap flatten the filters arrays
   * .map convert each object to a string fpr comparison
   * .map convert each unique string back to an object
   * */
  return Array.from(new Set(nodes.flatMap((node) => node?.filterTags.map(JSON.stringify)))).map(
    JSON.parse,
  );
}
</script>
