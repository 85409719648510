import type { LineItem, Product } from "@shopware-pwa/types";
import { useProductPrice } from "@shopware-pwa/composables-next";
import type { Ref } from "vue";
import { _useContext } from "~/composables/shop/_useContext";

export type ProductBulkPrice = {
  quantity?: number;
  listPrice?: {
    apiAlias?: string;
    discount?: number;
    percentage?: number;
    price?: number;
  };
  displayDiscountRate?: number;
  totalPriceWithoutDiscount?: number;
  totalPrice?: number;
  unitPrice?: number;
};

export function useProductQuantityOptions(lineItem: LineItem) {
  const maxQty = computed(() => {
    return lineItem.quantityInformation?.maxPurchase ?? 1;
  });

  const minQty = computed(() => {
    return lineItem.quantityInformation?.minPurchase ?? 1;
  });
  const steps = computed(() => {
    return lineItem.quantityInformation?.purchaseSteps ?? 1;
  });

  const quantityOptions = computed(() => {
    const options: Array<object> = [];
    for (let i = minQty.value; i <= maxQty.value; i = i + steps.value) {
      options.push({ value: i });
    }
    return options;
  });
  return { quantityOptions, maxQty };
}

export function useProductBulkPrices(product: Ref<Product>) {
  const { price } = useProductPrice(product);
  const hasBulkPrice = computed(() => {
    return product.value?.extensions?.packagingUnit?.packagingUnits?.length > 0;
  });

  const bulkChooserOptions = computed(() => {
    return product.value?.extensions?.packagingUnit?.packagingUnits?.map(
      (unit: ProductBulkPrice) => ({
        value: unit?.quantity,
        label:
          unit?.quantity +
          " " +
          (unit?.quantity === 1
            ? (product.value?.packUnit ?? "")
            : (product.value?.packUnitPlural ?? "")),
      }),
    );
  });

  const productQuantity: Ref<number | null> = _useContext("productQuantity");
  if (!productQuantity.value) {
    productQuantity.value =
      product.value?.extensions?.packagingUnit?.packagingUnits[0]?.quantity ??
      price.value?.quantity ??
      1;
  }
  const activeBulkChild = computed(() => {
    return (
      product.value?.extensions?.packagingUnit?.packagingUnits?.find(
        (unit: ProductBulkPrice) => unit.quantity === parseInt(productQuantity.value),
      ) || {}
    );
  });

  const firstBulkUnit = computed(() => {
    return product.value?.extensions?.packagingUnit?.packagingUnits[0];
  });

  const isBulkListPrice = computed(() => {
    return firstBulkUnit.value?.totalPriceWithoutDiscount !== firstBulkUnit.value?.totalPrice;
  });

  const bulkProductUnitLabel = computed(() => {
    return (firstBulkUnit.value?.quantity || product.value?.minPurchase) > 1 &&
      product.value?.translated?.packUnitPlural
      ? product.value?.translated?.packUnitPlural
      : product.value?.translated?.packUnit
        ? product.value?.translated?.packUnit
        : "x";
  });

  return {
    hasBulkPrice,
    activeBulkChild,
    bulkChooserOptions,
    productQuantity,
    firstBulkUnit,
    isBulkListPrice,
    bulkProductUnitLabel,
  };
}
