<template>
  <div>
    <div class="flex items-end gap-3">
      <SharedPrice
        v-if="unitPrice"
        :class="{
          'text-signal-red': isListPrice,
        }"
        :value="unitPrice"
        class="text-2xl font-medium lg:text-3xl"
      />
      <SharedPrice
        v-if="isListPrice"
        :value="price?.listPrice?.price"
        class="text-base font-normal leading-snug line-through lg:text-lg"
      />
    </div>
    <div v-if="isListPrice" class="flex gap-1 text-sm font-normal leading-tight">
      <CommonLabel path="BWF/shop/productDetailPage/priceSaving" />
      <SharedPrice v-if="isListPrice" :value="price?.listPrice?.discount" />
      ({{ Math.abs(price?.listPrice?.percentage ?? 0) + "%" }})
    </div>
    <div class="mt-1 text-xxxs font-normal leading-tight text-medium-grey lg:text-sm">
      <CommonLabel
        v-if="containsExchangeBox"
        :placeholder="{ amountCylinders }"
        path="BWF/shop/exchangeBox/priceCardExchangeBoxHint"
      ></CommonLabel>
      <span v-if="containsExchangeBox">&nbsp;</span>
      <span v-if="product?.unit?.name">
        <span v-if="product?.packUnit">{{ product?.packUnit }}&nbsp;</span>
        <span v-else>{{ product?.purchaseUnit }}&nbsp;{{ product?.unit?.shortCode }}&nbsp;</span>({{
          getIntlFormattedPrice(product?.calculatedPrice.referencePrice?.price)
        }}&nbsp;/&nbsp;{{ product?.calculatedPrice.referencePrice?.referenceUnit
        }}{{ product?.unit?.shortCode }})&nbsp;·&nbsp;</span
      >
      <CommonLabel path="BWF/shop/productDetailPage/plus"></CommonLabel>
      <span>&nbsp;</span>
      <CommonLabel path="BWF/shop/checkout/cart/taxes" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProductPrice } from "@shopware-pwa/composables-next";
import type { Product } from "@shopware-pwa/types";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import { useExchangeBox } from "~/composables/shop/useExchangeBoxProduct";
import { usePrice } from "~/composables/shop/usePrice";

const props = defineProps<{
  product: Product;
}>();
const { product } = toRefs(props);

const { price, unitPrice, isListPrice } = useProductPrice(product);
const { getIntlFormattedPrice } = usePrice();

const { containsExchangeBox, amountCylinders } = await useExchangeBox();
</script>
