<template>
  <div ref="productListing" class="flex flex-col gap-4">
    <template v-if="categoryFilterButtons?.length > 0">
      <p v-if="filterHint" class="text-base font-normal leading-snug">{{ filterHint }}</p>
      <FilterButtons
        :all-label="filterAllLabel ?? 'All'"
        :filters="categoryFilterButtons"
        :multi-select="false"
        @filter-change="onFilter"
      ></FilterButtons>
    </template>
    <div
      v-if="getElements?.length"
      :class="{ 'animate-pulse': loading || loadingMore }"
      class="responsive-gap flex flex-col"
    >
      <div class="responsive-gap-x grid grid-cols-2 gap-y-6 lg:grid-cols-4 xl:gap-y-8">
        <div
          v-if="containsLink(infoLink) || infoTitle"
          class="relative flex flex-col gap-2 rounded-lg bg-light-blue p-4 xl:gap-4 xl:rounded-xl xl:p-6"
        >
          <span class="xl:text-lg">
            {{ infoTitle }}
          </span>

          <LinkComponent
            :link="infoLink"
            class="text-link stretched-link text-sm text-black xl:text-base"
          >
            {{ infoLink?.label ?? "" }}
          </LinkComponent>
        </div>

        <!--the div above is needed as order was messed up after updates (filter)-->
        <div v-for="product in getElements" :key="product.id">
          <ProductListingTile
            :configurator="
              //@ts-ignore
              product.extensions?.groups
            "
            :product="product"
          />
        </div>
      </div>
      <div
        v-if="getTotalPagesCount > 1 && getElements.length < getTotal"
        class="flex justify-center"
      >
        <button class="btn btn-blue" @click="loadMore()">
          <CommonLabel path="BWF/shop/productListing/loadMore"></CommonLabel>
        </button>
      </div>
    </div>
    <div v-else>
      <h2 class="mx-auto text-center">
        <CommonLabel path="BWF/shop/productListing/noProducts"></CommonLabel>
      </h2>
    </div>
  </div>
</template>
<script setup lang="ts">
import FilterButtons from "~/templates/components/FilterButtons.vue";
import { useShopwareContext } from "@shopware-pwa/composables-next";
import type { Category, ShopwareSearchParams } from "@shopware-pwa/types";
import { containsLink } from "~/utils/helper/magnolia";
import LinkComponent from "~/templates/components/cms/LinkComponent.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import useD2CStructuredData from "~/composables/useD2CStructuredData";
import type { ItemList, WithContext } from "schema-dts";
const { apiInstance } = useShopwareContext();
import useFilterTracking from "~/composables/tracking/useFilterTracking";
import { PRODUCT_CARD_ASSOCIATIONS } from "~/utils/helper/shop/product";
import ProductListingTile from "~/templates/components/cms/ProductListingTile.vue";

const props = defineProps([
  "categoryId",
  "categoryPageSize",
  "filterAllLabel",
  "filterHint",
  "infoLink",
  "infoTitle",
  "metadata",
]);

const { data: categoryData } = await useAsyncData(`subCategories-${props.categoryId}`, async () => {
  const response = await apiInstance.invoke.post("/store-api/category", {
    filter: [
      {
        type: "equals",
        field: "id",
        value: props.categoryId,
      },
    ],
    associations: {
      children: {
        associations: {},
      },
    },
  });
  return response.data;
});

interface CategoryButton {
  label: string;
  value: string;
  afterCategoryId: string;
}
const categoryFilterButtons: CategoryButton[] = [];
try {
  const {
    elements: [category],
  } = categoryData.value;

  const orderMap: CategoryButton[] = category?.children?.map((item: Category) => ({
    label: item.name,
    value: item.id,
    afterCategoryId: item.afterCategoryId,
  }));
  orderMap?.forEach(() => {
    const previousElement = categoryFilterButtons[categoryFilterButtons.length - 1];
    let nextElement;
    if (!previousElement) {
      nextElement = orderMap.find((item) => !item.afterCategoryId);
    } else {
      nextElement = orderMap.find(
        (orderItem) => orderItem.afterCategoryId === previousElement.value,
      );
    }
    if (nextElement) {
      categoryFilterButtons.push(nextElement);
    }
  });
} catch (e) {
  console.log("no elements in category");
}

const activeFilters = ref([{}]);
const onFilter = async (filter: Array<string>) => {
  const parameter: Partial<ShopwareSearchParams> = {};

  if (filter.length > 0) {
    parameter.filter = [
      {
        type: "equalsAny",
        field: "categoryIds",
        value: filter,
      },
    ];
  }
  activeFilters.value = filter;
  await search(parameter);
};

const {
  getElements,
  loadMore,
  search,
  loading,
  loadingMore,
  getTotal,
  getTotalPagesCount,
  setInitialListing,
  getCurrentListing,
} = useListing({
  categoryId: props.categoryId,
  listingType: "categoryListing",
  defaultSearchCriteria: {
    limit: props.categoryPageSize ?? 24,
    associations: PRODUCT_CARD_ASSOCIATIONS.criteria?.associations,
  },
});
//

const { data: productSearch } = await useAsyncData(`listing-${props.categoryId}`, async () => {
  await search({});
  return getCurrentListing.value;
});
if (productSearch.value) setInitialListing(productSearch.value);

const productListing = ref(null);

useItemListTracking({
  metadata: props.metadata,
  // @ts-ignore
  items: getElements,
  rootElement: productListing,
});

const FILTER_TYPE = "product_filter";

useFilterTracking({
  type: FILTER_TYPE,
  filters: categoryFilterButtons,
  activeFilters: activeFilters,
});

const { productListingSchema } = useD2CStructuredData();
useJsonld(productListingSchema(getElements?.value) as WithContext<ItemList>);
</script>
