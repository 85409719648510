<template>
  <div class="grid grid-cols-2 items-center gap-1 hyphens-auto text-sm xl:grid-cols-6 xl:gap-x-2">
    <div class="contents flex-col items-start gap-2 gap-y-1 xl:flex">
      <CommonLabel class="font-medium" path="BWF/shop/account/orderHistory/dateOfOrder">
      </CommonLabel>
      <span class="">{{ orderDate }}</span>
    </div>
    <div class="contents flex-col items-start gap-2 xl:flex">
      <CommonLabel class="font-medium" path="BWF/shop/account/orderHistory/status"></CommonLabel>
      <span class="">{{ order.deliveries?.[0]?.stateMachineState?.name }}</span>
    </div>
    <div class="contents flex-col items-start gap-2 xl:flex">
      <CommonLabel class="font-medium" path="BWF/shop/account/orderHistory/paymentStatus">
      </CommonLabel>
      <span class="">{{ order?.transactions?.[0]?.stateMachineState?.name }}</span>
    </div>
    <div class="contents flex-col items-start gap-2 xl:flex">
      <CommonLabel class="font-medium" path="BWF/shop/account/orderHistory/paymentMethod">
      </CommonLabel>
      <span class="">{{ order?.transactions?.[0]?.paymentMethod?.name }}</span>
    </div>
    <div class="contents flex-col items-start gap-2 xl:flex">
      <CommonLabel class="font-medium" path="BWF/shop/account/orderHistory/shippingMethod">
      </CommonLabel>
      <span class="">{{ order?.deliveries?.[0]?.shippingMethod?.name }}</span>
    </div>
  </div>
  <hr class="mt-2 xl:my-0" />

  <div class="mt-2 flex flex-col gap-4">
    <div class="text-base font-medium xl:text-2xl">
      <CommonLabel path="BWF/shop/account/orderHistory/returnArticles"></CommonLabel>
    </div>
    <OrderLineItems :order="order" :selectable="order.lineItems?.length > 0" />
  </div>
</template>

<script setup>
import CommonLabel from "~/templates/components/CommonLabel.vue";
import OrderLineItems from "~/templates/components/shop/account/OrderLineItems.vue";

const props = defineProps(["order", "selectable"]);

const orderDate = computed(() => {
  return props.order?.orderDate
    ? new Date(props.order?.orderDate ?? "").toLocaleDateString(
        (typeof navigator !== "undefined" && navigator.language) || "de-DE",
      )
    : "";
});
</script>
