<template>
  <div>
    <div v-if="unitPrice && !hasEShopSet" class="flex items-end gap-2">
      <SharedPrice
        :class="{ 'text-signal-red lg:text-3xl': isListPrice }"
        :value="unitPrice"
        class="mt-2 text-left text-base font-medium"
      />
      <SharedPrice
        v-if="isListPrice"
        :value="price?.listPrice?.price"
        class="mt-1 text-sm font-normal text-medium-grey line-through lg:mt-2"
      />
    </div>
    <CommonLabel
      v-if="price && containsExchangeBox"
      :placeholder="{ amountCylinders }"
      class="text-xxxs font-normal leading-tight text-medium-grey lg:text-sm"
      path="BWF/shop/exchangeBox/priceCardExchangeBoxHint"
    ></CommonLabel>
    <template v-if="hasEShopSet">
      <div v-if="hasBestPrice" class="flex items-end gap-2">
        <SharedPrice
          :value="eShopSet?.eshopSetPriceTotal"
          class="mt-1 text-base font-medium text-signal-red lg:mt-2 lg:text-3xl"
        />
        <SharedPrice
          :value="eShopSet?.bestEshopPriceFromHistory"
          class="mt-1 text-sm font-normal text-medium-grey line-through lg:mt-2"
        />
      </div>
      <SharedPrice
        v-else
        :value="eShopSet?.eshopSetPriceTotal"
        class="mt-1 text-base font-medium lg:mt-2"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Product } from "@shopware-pwa/types";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import { useExchangeBox } from "~/composables/shop/useExchangeBoxProduct";
import { useProductEShopSetConfigurator } from "~/composables/shop/useProductEShopSetConfigurator";

const props = defineProps<{
  product: Product;
}>();
const { product } = toRefs(props);

const { eShopSet, hasBestPrice, hasEShopSet } = await useProductEShopSetConfigurator();
const { isListPrice, unitPrice, price } = useProductPrice(product);
const { containsExchangeBox, amountCylinders } = await useExchangeBox();
</script>
