<template>
  <div class="my-4 grid gap-4 xl:grid-cols-3 xl:gap-8">
    <div class="flex flex-col gap-4 xl:col-span-2 xl:gap-2">
      <div class="mb-8 grid gap-6">
        <div>
          <HeadlineComponent
            class="text-lg font-medium text-dark-blue xl:mb-6 xl:text-4xl"
            tag="h1"
          >
            <CommonLabel path="BWF/shop/checkout/addressForm/headline"></CommonLabel>
          </HeadlineComponent>
          <div v-if="!isAuthenticated" class="flex flex-col items-center gap-4 xl:flex-row">
            <hr class="mt-4 w-full xl:hidden" />
            <CommonLabel
              class="text-center xl:text-left"
              path="BWF/shop/checkout/addressForm/alreadyRegisteredCTA"
            ></CommonLabel>
            <button class="text-link hidden xl:flex" @click="loginMsal">
              <CommonLabel path="BWF/shop/shared/loginNow"></CommonLabel>
            </button>
            <button class="btn btn-blue w-full xl:hidden" @click="loginMsal">
              <CommonLabel path="BWF/shop/checkout/addressForm/loginAccount"></CommonLabel>
            </button>
          </div>
        </div>

        <hr />

        <form
          id="checkout-billing-address"
          ref="addressForm"
          class="grid gap-6"
          method="post"
          name="checkout-billing-address"
          @submit.prevent="invokeSubmit"
        >
          <div v-if="registerErrors?.length" class="notification is-red" role="alert">
            <ul>
              <li v-for="(error, i) in registerErrors" :key="i">
                {{ error }}
              </li>
            </ul>
          </div>

          <div class="grid grid-cols-6 gap-4 xl:gap-6">
            <fieldset class="contents">
              <legend class="col-span-6 block text-lg font-medium">
                <CommonLabel path="BWF/shop/checkout/addressForm/personals"></CommonLabel>
              </legend>

              <div class="col-span-full xl:col-span-3">
                <SelectField
                  v-model="checkoutFormState.salutationId"
                  :error-message="checkoutForm$?.salutationId?.$errors?.[0]?.$message"
                  :label="checkoutFormLabels?.salutationLabel"
                  :options="salutationOptions(getSalutations)"
                  class="form-field-grey"
                  name="salutationId"
                ></SelectField>
              </div>
              <div class="hidden xl:col-span-3 xl:block"></div>

              <div class="col-span-6 xl:col-span-3">
                <InputField
                  v-model="checkoutFormState.firstName"
                  :error-message="checkoutForm$?.firstName?.$errors?.[0]?.$message"
                  :label="checkoutFormLabels?.firstNameLabel"
                  :placeholder="checkoutFormLabels?.firstNamePlaceholder"
                  class="form-field-grey"
                  name="first-name"
                  required="required"
                  @blur="checkoutForm$.firstName.$touch()"
                ></InputField>
              </div>

              <div class="col-span-6 xl:col-span-3">
                <InputField
                  v-model="checkoutFormState.lastName"
                  :error-message="checkoutForm$?.lastName?.$errors?.[0]?.$message"
                  :label="checkoutFormLabels?.lastNameLabel"
                  :placeholder="checkoutFormLabels?.lastNamePlaceholder"
                  class="form-field-grey"
                  name="last-name"
                  required="required"
                  @blur="checkoutForm$.lastName.$touch()"
                ></InputField>
              </div>

              <div class="col-span-6 xl:col-span-3">
                <InputField
                  v-model="checkoutFormState.email"
                  :error-message="checkoutForm$?.email?.$errors?.[0]?.$message"
                  :label="checkoutFormLabels?.eMailAddressLabel"
                  :placeholder="checkoutFormLabels?.eMailAddressPlaceholder"
                  :readonly="!!user"
                  autocomplete="off"
                  class="form-field-grey"
                  name="email-address"
                  required="required"
                  type="email"
                  @blur="checkoutForm$.email.$touch()"
                ></InputField>
              </div>
            </fieldset>
          </div>
          <hr />
          <div class="grid grid-cols-12 gap-4 xl:gap-6">
            <fieldset class="contents">
              <legend class="col-span-full block text-lg font-medium">
                <CommonLabel path="BWF/shop/shared/address"></CommonLabel>
              </legend>

              <div class="col-span-full xl:col-span-6">
                <InputField
                  v-model="checkoutFormState.billingAddress.street"
                  :error-message="checkoutForm$?.billingAddress?.street?.$errors?.[0]?.$message"
                  :label="checkoutFormLabels?.streetAndHouseNoLabel"
                  :placeholder="checkoutFormLabels?.streetAndHouseNoPlaceholder"
                  autocomplete="street-address"
                  class="form-field-grey"
                  name="street-address"
                  required="required"
                  @blur="checkoutForm$.billingAddress.street.$touch()"
                ></InputField>
              </div>
              <div class="col-span-full xl:col-span-3">
                <InputField
                  v-model="checkoutFormState.billingAddress.zipcode"
                  :error-message="checkoutForm$?.billingAddress?.zipcode?.$errors?.[0]?.$message"
                  :label="checkoutFormLabels?.postalCodeLabel"
                  :placeholder="checkoutFormLabels?.postalCodePlaceholder"
                  autocomplete="postal-code"
                  class="form-field-grey"
                  name="postal-code"
                  required="required"
                  @blur="checkoutForm$.billingAddress.zipcode.$touch()"
                ></InputField>
              </div>
              <div class="col-span-full xl:col-span-3">
                <InputField
                  v-model="checkoutFormState.billingAddress.city"
                  :error-message="checkoutForm$?.billingAddress?.city?.$errors?.[0]?.$message"
                  :label="checkoutFormLabels?.cityLabel"
                  :placeholder="checkoutFormLabels?.cityPlaceholder"
                  class="form-field-grey"
                  name="city"
                  required="required"
                  @blur="checkoutForm$.billingAddress.city.$touch()"
                ></InputField>
              </div>
              <div class="col-span-full">
                <InputField
                  v-model="checkoutFormState.billingAddress.additionalAddressLine1"
                  :error-message="
                    checkoutForm$?.billingAddress?.additionalAddressLine1?.$errors?.[0]?.$message
                  "
                  :label="checkoutFormLabels?.addressSuffixLabel"
                  :placeholder="checkoutFormLabels?.addressSuffixPlaceholder"
                  class="form-field-grey"
                  name="additionalAddressLine1"
                  @blur="checkoutForm$.billingAddress.additionalAddressLine1.$touch()"
                ></InputField>
              </div>

              <div class="col-span-full xl:col-span-6">
                <SelectField
                  v-model="checkoutFormState.billingAddress.countryId"
                  :error-message="checkoutForm$?.billingAddress?.countryId?.$errors?.[0]?.$message"
                  :label="checkoutFormLabels?.countryLabel"
                  :options="countryOptions(getCountries, 'id', 'name')"
                  autocomplete="country-name"
                  class="form-field-grey"
                  name="country"
                  required="required"
                  @blur="checkoutForm$.billingAddress.countryId.$touch()"
                ></SelectField>
              </div>
            </fieldset>
          </div>

          <div
            class="col-span-full"
            @click="forceShippingAddress && pushWarning(notifications.forceDeliveryAddress)"
          >
            <CheckboxField
              v-model="customShipping"
              :disabled="forceShippingAddress ? 'disabled' : null"
              :label="checkoutFormLabels.differentShippingAddressLabel"
            ></CheckboxField>
          </div>

          <div v-if="customShipping" class="grid grid-cols-12 gap-4 xl:gap-6">
            <fieldset class="contents">
              <legend class="col-span-full block text-lg font-medium">
                <CommonLabel path="BWF/shop/checkout/addressForm/altAddress"> </CommonLabel>
              </legend>

              <div class="col-span-full xl:col-span-6">
                <SelectField
                  v-model="shippingFormState.salutationId"
                  :label="checkoutFormLabels?.salutationLabel"
                  :options="salutationOptions(getSalutations)"
                  class="form-field-grey"
                  name="salutationId"
                ></SelectField>
              </div>
              <div class="col-span-6 hidden xl:flex"></div>
              <div class="col-span-full xl:col-span-6">
                <InputField
                  v-model="shippingFormState.firstName"
                  :error-message="shippingForm$.firstName?.$errors?.[0]?.$message"
                  :label="checkoutFormLabels?.firstNameLabel"
                  :placeholder="checkoutFormLabels?.firstNamePlaceholder"
                  class="form-field-grey"
                  name="first-name"
                  required="required"
                  @blur="shippingForm$.firstName.$touch()"
                ></InputField>
              </div>

              <div class="col-span-full xl:col-span-6">
                <InputField
                  v-model="shippingFormState.lastName"
                  :error-message="shippingForm$.lastName?.$errors?.[0]?.$message"
                  :label="checkoutFormLabels?.lastNameLabel"
                  :placeholder="checkoutFormLabels?.lastNamePlaceholder"
                  class="form-field-grey"
                  name="last-name"
                  required="required"
                  @blur="shippingForm$.lastName.$touch()"
                ></InputField>
              </div>

              <div class="col-span-full xl:col-span-6">
                <InputField
                  v-model="shippingFormState.street"
                  :error-message="shippingForm$?.street?.$errors?.[0]?.$message"
                  :label="checkoutFormLabels?.streetAndHouseNoLabel"
                  :placeholder="checkoutFormLabels?.streetAndHouseNoPlaceholder"
                  autocomplete="street-address"
                  class="form-field-grey"
                  name="street-address"
                  required="required"
                  @blur="shippingForm$.street.$touch()"
                ></InputField>
              </div>
              <div class="col-span-full xl:col-span-3">
                <InputField
                  v-model="shippingFormState.zipcode"
                  :error-message="shippingForm$?.zipcode?.$errors?.[0]?.$message"
                  :label="checkoutFormLabels?.postalCodeLabel"
                  :placeholder="checkoutFormLabels?.postalCodePlaceholder"
                  autocomplete="postal-code"
                  class="form-field-grey"
                  name="postal-code"
                  required="required"
                  @blur="shippingForm$.zipcode.$touch()"
                ></InputField>
              </div>
              <div class="col-span-full xl:col-span-3">
                <InputField
                  v-model="shippingFormState.city"
                  :error-message="shippingForm$?.city?.$errors?.[0]?.$message"
                  :label="checkoutFormLabels?.cityLabel"
                  :placeholder="checkoutFormLabels?.cityPlaceholder"
                  class="form-field-grey"
                  name="city"
                  required="required"
                  @blur="shippingForm$.city.$touch()"
                ></InputField>
              </div>
              <div class="col-span-full xl:col-span-full">
                <InputField
                  v-model="shippingFormState.additionalAddressLine1"
                  :error-message="shippingForm$?.additionalAddressLine1?.$errors?.[0]?.$message"
                  :label="checkoutFormLabels?.addressSuffixLabel"
                  :placeholder="checkoutFormLabels?.addressSuffixPlaceholder"
                  class="form-field-grey"
                  name="additionalAddressLine1"
                  @blur="shippingForm$.additionalAddressLine1.$touch()"
                ></InputField>
              </div>

              <div class="col-span-full xl:col-span-6">
                <SelectField
                  v-model="shippingFormState.countryId"
                  :error-message="shippingForm$?.countryId?.$errors?.[0]?.$message"
                  :label="checkoutFormLabels?.countryLabel"
                  :options="countryOptions(getDeliveryCountries, 'id', 'name')"
                  autocomplete="country-name"
                  class="form-field-grey"
                  name="country"
                  @blur="shippingForm$.countryId.$touch()"
                ></SelectField>
              </div>
            </fieldset>
          </div>
        </form>
      </div>
    </div>
    <div v-if="isDesktop" class="align-items-start mt-5 flex flex-col gap-8 md:col-span-1 md:mt-0">
      <CheckoutSummary />
      <div class="grid gap-4">
        <div class="flex items-end justify-between">
          <h3 class="text-2xl font-medium">
            <CommonLabel path="BWF/shop/shared/cartHeadline"></CommonLabel>
          </h3>

          <div v-if="cartItems.length > 0" class="text-sm">
            <CommonLabel class="font-medium" path="BWF/shop/shared/tableQuantity"></CommonLabel
            >&nbsp;{{ count }}
          </div>
        </div>
        <ul class="-my-4 divide-y divide-gray-200 pl-0" role="list">
          <li
            v-for="cartItem in getLineItemsWithoutPromotion(cartItems)"
            :key="cartItem.id"
            class="flex py-6"
          >
            <CheckoutCartItem :cart-item="cartItem" :child-variant="true" />
          </li>
        </ul>
      </div>
    </div>
  </div>
  <hr class="col-span-full my-10 hidden xl:block" />

  <div class="grid gap-8 xl:grid-cols-3">
    <button
      class="btn btn-blue btn-size-normal mt-4 xl:mt-0"
      form="checkout-billing-address"
      type="submit"
    >
      <span>
        <CommonLabel path="BWF/shop/checkout/addressForm/continueToCheckout"></CommonLabel>
      </span>
    </button>
    <div class="xl:order-first xl:col-span-2">
      <button class="text-icon-link mx-auto xl:mx-0" @click="$router.go(-1)">
        <SvgIcon class="h-3 w-3 -rotate-90" name="Arrow-Up"></SvgIcon>
        <span><CommonLabel path="BWF/shop/shared/backToShop"></CommonLabel> </span>
      </button>
    </div>
    <div class="mt-4 xl:mt-14">
      <CommonLabel class="text-sm" path="BWF/shared/vatFootnote" /><br />
      <CommonLabel class="mt-2 text-sm" path="BWF/shop/shared/mandatoryFieldsLabelGuestCheckout" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, watch } from "vue";

const { $msal } = useNuxtApp();
import HeadlineComponent from "~/templates/components/cms/HeadlineComponent.vue";
import SelectField from "~/templates/elements/form/SelectField.vue";
import InputField from "~/templates/elements/form/InputField.vue";
import CheckboxField from "~/templates/elements/form/CheckboxField.vue";
import SvgIcon from "~/templates/elements/SvgIcon.vue";

import { getLineItemsWithoutPromotion } from "~/utils/helper/shop/cart";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import { useCartInformation } from "~/composables/shop/useCartInformation";
import { isDummyUser } from "~/utils/helper/shop/user";
import { salutationOptions } from "~/utils/helper/shop/salutations";
import { countryOptions } from "~/utils/helper/shop/countries";

const { trackPersonalInfo } = useCheckoutTracking();

const { count } = useCartInformation();

const { checkoutFormState, shippingFormState, checkoutForm$, shippingForm$ } =
  await useCheckoutFormState();

provide("input-required-char", "**");

const { isDesktop } = useDevice();

const loginMsal = () => {
  $msal.signIn();
};
const isAuthenticated = computed(() => $msal.isAuthenticated());

const { getCountries } = useCountries();
const { getSalutations } = useSalutations();

const { getLabels } = useCommonLabels();

const checkoutFormLabels = getLabels("/BWF/shop/shared/addressForm/", [
  "salutationLabel",
  "chooseSalutationLabel",
  "firstNameLabel",
  "firstNamePlaceholder",
  "lastNameLabel",
  "lastNamePlaceholder",
  "streetAndHouseNoLabel",
  "streetAndHouseNoPlaceholder",
  "eMailAddressLabel",
  "eMailAddressPlaceholder",
  "postalCodeLabel",
  "postalCodePlaceholder",
  "cityLabel",
  "cityPlaceholder",
  "addressSuffixLabel",
  "addressSuffixPlaceholder",
  "countryLabel",
]);

const { register, user, userDefaultBillingAddress } = useUser();
const { countryId, refreshSessionContext } = useSessionContext();
// preselect country select with default id
checkoutFormState.billingAddress.countryId = countryId.value ?? "";
shippingFormState.countryId = countryId.value ?? "";

const { cartItems } = useCart();

const { pushWarning } = useNotifications();
const { pushShopwareError, processShopwareErrors } = useShopwareNotifications();

const customShipping = ref(false);

const getDeliveryCountries = computed(() =>
  getCountries.value.filter((country) => country.shippingAvailable),
);

const notifications = getLabels("BWF/shop/checkout/addressForm/notifications", [
  "forceDeliveryAddress",
]);
const forceShippingAddress = computed(() => {
  const selectedCountry = getCountries.value.find(
    (country) => country.id === checkoutFormState.billingAddress.countryId,
  );
  return selectedCountry?.shippingAvailable === false;
});
watch(forceShippingAddress, () => {
  if (forceShippingAddress.value) {
    customShipping.value = true;
    pushWarning(notifications?.forceDeliveryAddress ?? "");
  }
});

watch(cartItems, () => {
  isStillLoading.value = false;
});

const isStillLoading = ref(true);

const emit = defineEmits<{
  (e: "loadingStart"): void;
  (e: "loadingEnd"): void;
}>();

onMounted(async () => {
  // id user is authenticated
  emit("loadingStart");
  trackPersonalInfo();
  try {
    if (user.value?.email) {
      // Prefill dumy user data
      checkoutFormState.firstName = user.value?.firstName ?? "";
      checkoutFormState.lastName = user.value?.lastName ?? "";
      checkoutFormState.email = user.value?.email ?? "";
      if (!isDummyUser(user.value)) {
        checkoutFormState.billingAddress.street = userDefaultBillingAddress.value?.street ?? "";
        checkoutFormState.billingAddress.zipcode = userDefaultBillingAddress.value?.zipcode ?? "";
        checkoutFormState.billingAddress.city = userDefaultBillingAddress.value?.city ?? "";
        checkoutFormState.billingAddress.countryId =
          userDefaultBillingAddress.value?.countryId ?? "";
        checkoutFormState.billingAddress.additionalAddressLine1 =
          userDefaultBillingAddress.value?.additionalAddressLine1 ?? "";
      }
      // @ts-ignore
      if (user.value?.salutation?.salutationKey !== "not_specified") {
        checkoutFormState.salutationId = user.value?.salutationId ?? "";
      }
    }
  } catch (e: unknown) {
    console.log(e?.message);
  }

  emit("loadingEnd");
});

const {
  createCustomerAddress,
  updateCustomerAddress,
  setDefaultCustomerShippingAddress,
  setDefaultCustomerBillingAddress,
} = useAddress();
const addressForm = ref(null);
const registerErrors = ref();

const invokeSubmit = async () => {
  checkoutForm$.value.$touch();

  const valid = await checkoutForm$.value.$validate();
  const shippingValid = customShipping.value === false || (await shippingForm$.value.$validate());

  if (valid && shippingValid) {
    try {
      if (user.value && isAuthenticated.value) {
        // incomplete user exists create / edit addresses and set as default
        const { firstName, lastName, salutationId } = checkoutFormState;

        const formData = {
          firstName,
          lastName,
          salutationId,
        };
        const performUpdate = !!userDefaultBillingAddress?.value?.id;
        const saveAddress = performUpdate ? updateCustomerAddress : createCustomerAddress;
        const billingResponse = await saveAddress({
          ...(performUpdate ? { id: userDefaultBillingAddress?.value?.id } : {}),
          ...formData,
          ...checkoutFormState.billingAddress,
        });

        await setDefaultCustomerBillingAddress(billingResponse.id);

        //
        if (customShipping.value) {
          const shippingResponse = await createCustomerAddress(shippingFormState);
          await setDefaultCustomerShippingAddress(shippingResponse.id);
        }
      } else {
        // guest checkout
        const formData = {
          ...checkoutFormState,
          ...(customShipping.value ? { shippingAddress: shippingFormState } : {}),
        };
        // @ts-ignore
        await register(formData);
      }

      await refreshSessionContext();
    } catch (error) {
      registerErrors.value = processShopwareErrors(error);
      await pushShopwareError(error);
    }
  }
};
</script>
