<template>
  <div class="product-exchange-box-information flex flex-col gap-4">
    <div class="flex flex-col gap-1">
      <CommonLabel
        class="font-medium xl:text-2xl"
        path="BWF/shop/productDetailPage/exchangeBox/summaryHeadline"
      ></CommonLabel>
      <CommonLabel
        class="text-sm text-medium-grey"
        path="BWF/shop/productDetailPage/exchangeBox/cylinderAmountHint"
      ></CommonLabel>
    </div>

    <div class="grid grid-cols-4 gap-4">
      <div class="contents text-sm text-black">
        <div class="col-span-3">
          <CommonLabel
            :placeholder="{ amountCylinders }"
            path="BWF/shop/productDetailPage/exchangeBox/summaryCylinders"
          ></CommonLabel>
        </div>
        <div class="col-span-1 font-medium">
          <SharedPrice :value="tauschboxPrice"></SharedPrice>
        </div>
        <div class="col-span-3">
          <CommonLabel
            :placeholder="{ amountCylinders, depositLineItemLabel }"
            path="BWF/shop/productDetailPage/exchangeBox/summaryDeposit"
          ></CommonLabel>
        </div>
        <div class="col-span-1 font-medium">
          <SharedPrice :value="totalDepositPrice"></SharedPrice>
        </div>
        <slot name="depositInfo"></slot>
        <hr class="col-span-full my-2" />
        <div class="col-span-3 text-base text-black">
          <CommonLabel
            :placeholder="{ nextAmount }"
            path="BWF/shop/productDetailPage/exchangeBox/nextPrice"
          ></CommonLabel>
        </div>
        <div class="col-span-1 text-base font-medium text-black">
          <SharedPrice :value="nextPrice"></SharedPrice>
        </div>
        <slot name="priceInfo"></slot>

        <hr class="col-span-full mt-4 hidden xl:block" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useExchangeBox } from "~/composables/shop/useExchangeBoxProduct";
import CommonLabel from "~/templates/components/CommonLabel.vue";

const {
  amountCylinders,
  totalDepositPrice,
  tauschboxPrice,
  nextAmount,
  nextPrice,
  depositLineItemLabel,
} = await useExchangeBox();
</script>

<style>
.product-variant-configurator {
  @apply relative flex flex-col gap-2;
}

.switch-container {
  @apply flex flex-wrap gap-2 md:gap-3;
}
.switch-btn {
  @apply h-5 w-5 border xl:h-7 xl:w-7;
  .switch-btn-inner {
    @apply h-4 w-4 border xl:h-6 xl:w-6;
  }
}
</style>
