<template>
  <div>
    <div v-if="!isAuthenticated" class="mb-12 flex flex-col gap-2">
      <CommonLabel path="BWF/shop/checkout/addressForm/alreadyRegisteredCTA" class="font-medium" />
      <button class="text-link flex" @click="loginMsal()">
        <CommonLabel path="BWF/shop/shared/loginNow" />
      </button>
    </div>

    <form @submit.prevent="submitPersonalData()">
      <div class="flex flex-col gap-8 md:gap-12">
        <div class="grid gap-4 xl:w-7/12 xl:grid-cols-2 xl:gap-6">
          <CommonLabel
            path="BWF/shop/checkout/addressForm/personals"
            class="col-start-1 font-medium"
          />

          <SelectField
            v-model="personalDataState.salutation"
            class="form-field-grey col-start-1"
            name="salutationId"
            required="required"
            :label="personalInformationLabels?.chooseSalutationLabel"
            :options="selectableSalutations"
            :error-message="personalFormVuelidate?.salutation?.$errors?.[0]?.$message"
            @blur="personalFormVuelidate?.salutation?.$touch()"
          />

          <InputField
            v-model="personalDataState.firstName"
            class="form-field-grey col-start-1"
            name="first-name"
            required="required"
            :placeholder="personalInformationLabels?.firstNamePlaceholder"
            :label="personalInformationLabels?.firstNameLabel"
            :error-message="personalFormVuelidate?.firstName?.$errors?.[0]?.$message"
            @blur="personalFormVuelidate?.firstName?.$touch()"
          />
          <InputField
            v-model="personalDataState.lastName"
            class="form-field-grey"
            name="last-name"
            required="required"
            :placeholder="personalInformationLabels?.lastNamePlaceholder"
            :label="personalInformationLabels?.lastNameLabel"
            :error-message="personalFormVuelidate?.lastName?.$errors?.[0]?.$message"
            @blur="personalFormVuelidate?.lastName?.$touch()"
          />
          <InputField
            v-model="personalDataState.email"
            class="form-field-grey col-start-1"
            name="email-address"
            required="required"
            :placeholder="personalInformationLabels?.eMailAddressPlaceholder"
            :label="personalInformationLabels?.eMailAddressLabel"
            :error-message="personalFormVuelidate?.email?.$errors?.[0]?.$message"
            @blur="personalFormVuelidate?.email?.$touch()"
          />

          <CommonLabel path="BWF/shop/shared/address" class="col-start-1 font-medium" />

          <InputField
            v-model="personalDataState.street"
            class="form-field-grey col-start-1"
            name="street-address"
            :placeholder="personalInformationLabels?.streetAndHouseNoPlaceholder"
            :label="personalInformationLabels?.streetAndHouseNoLabel"
            :error-message="personalFormVuelidate?.street?.$errors?.[0]?.$message"
            @blur="personalFormVuelidate?.street?.$touch()"
          />

          <InputField
            v-model="personalDataState.additionalAddressLine1"
            class="form-field-grey col-start-1"
            name="additionalAddressLine1"
            :placeholder="personalInformationLabels?.addressSuffixPlaceholder"
            :label="personalInformationLabels?.addressSuffixLabel"
            :error-message="personalFormVuelidate?.additionalAddressLine1?.$errors?.[0]?.$message"
            @blur="personalFormVuelidate?.additionalAddressLine1?.$touch()"
          />

          <InputField
            v-model="personalDataState.postalCode"
            class="form-field-grey col-start-1"
            name="postal-code"
            :placeholder="personalInformationLabels?.postalCodePlaceholder"
            :label="personalInformationLabels?.postalCodeLabel"
            :error-message="personalFormVuelidate?.postalCode?.$errors?.[0]?.$message"
            @blur="personalFormVuelidate?.postalCode?.$touch()"
          />

          <InputField
            v-model="personalDataState.city"
            class="form-field-grey"
            name="city"
            :placeholder="personalInformationLabels?.cityPlaceholder"
            :label="personalInformationLabels?.cityLabel"
            :error-message="personalFormVuelidate?.city?.$errors?.[0]?.$message"
            @blur="personalFormVuelidate?.city?.$touch()"
          />

          <SelectField
            v-model="personalDataState.country"
            class="form-field-grey col-start-1"
            name="country"
            :label="personalInformationLabels?.countryLabel"
            :options="selectableCountries"
          />
        </div>

        <slot></slot>

        <button class="btn btn-blue w-full self-start md:w-auto" type="submit">
          <CommonLabel path="BWF/shop/product/registration/productRegistrationCompleteLabel" />
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import useProductRegistrationState from "~/composables/shop/useProductRegistrationState";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import InputField from "~/templates/elements/form/InputField.vue";
import SelectField from "~/templates/elements/form/SelectField.vue";
import { useVuelidate } from "@vuelidate/core";
import usePregPersonalInformation from "~/composables/shop/usePregPersonalInformation";

const { $msal } = useNuxtApp();
const loginMsal = () => {
  $msal.signIn();
};

const { isAuthenticated } = useMyBritaUser();
const emit = defineEmits(["submit-personal-data"]);

const {
  personalInformationLabels,
  selectableCountries,
  selectableSalutations,
  pregPersonalInformationRules,
} = await usePregPersonalInformation();

const { addMultipleValues, registrationState } = await useProductRegistrationState();

const personalDataState = reactive({
  salutation: "",
  firstName: "",
  lastName: "",
  email: "",
  street: "",
  additionalAddressLine1: "",
  postalCode: "",
  city: "",
  country: "",
});

const selectedSalutation = computed(() => {
  return selectableSalutations.find(
    (salutation) => salutation?.value === personalDataState?.salutation,
  )?.value;
});

const selectedCountry = computed(() => {
  return selectableCountries.find((country) => country?.value === personalDataState?.country)
    ?.value;
});

const personalFormVuelidate = useVuelidate(pregPersonalInformationRules, personalDataState);

const submitPersonalData = async () => {
  personalFormVuelidate.value.$touch();
  try {
    if (await personalFormVuelidate.value.$validate()) {
      addMultipleValues({
        bri_salutation_opt: selectedSalutation.value,
        bri_firstname: personalDataState.firstName,
        bri_lastname: personalDataState.lastName,
        bri_email: personalDataState.email,
        bri_street1: personalDataState.street,
        bri_postalcode: personalDataState.postalCode,
        bri_city: personalDataState.city,
        bri_country: selectedCountry.value,
      });
      emit("submit-personal-data");
    }
  } catch (e) {
    console.error("Error submitting personal data:", e);
  }
};

onMounted(() => {
  personalDataState.salutation = registrationState.value.bri_salutation_opt;
  personalDataState.firstName = registrationState.value.bri_firstname;
  personalDataState.lastName = registrationState.value.bri_lastname;
  personalDataState.email = registrationState.value.bri_email;
  personalDataState.street = registrationState.value.bri_street1;
  personalDataState.postalCode = registrationState.value.bri_postalcode;
  personalDataState.city = registrationState.value.bri_city;
  personalDataState.country = registrationState.value.bri_country;
});
</script>
