<template>
  <img
    v-if="image"
    :alt="imageName(image)"
    :height="getImageHeight(image) ?? 24"
    :src="imagePath(image)"
    :width="getImageWidth(image) ?? 24"
    class="w-16 md:w-24"
  />
</template>

<script setup>
import { imagePath, imageName, getImageWidth, getImageHeight } from "~/utils/helper/magnolia";
</script>

<script>
export default {
  name: "EditorialStoryImageComponent",
  props: ["image"],
};
</script>
