<template>
  <div class="relative" :class="{ 'z-[1] cursor-pointer': hasVideo(video) }" @click="onVideoClick">
    <component
      :is="showVideoInline ? 'div' : ModalElement"
      v-if="hasVideo(video)"
      :open="showVideo"
      :class="[
        {
          'absolute inset-0 z-[2] h-full w-full': showVideoInline,
        },
        showVideoInline && showVideo ? 'translate-y-0' : 'translate-y-full',
      ]"
      @close="showVideo = false"
    >
      <VideoComponent
        v-if="showVideo"
        class="h-full w-full bg-black"
        :video="video"
        :autoplay="true"
        :image="img"
        :load="showVideo"
        @ended="showVideo = false"
      ></VideoComponent>
    </component>

    <PictureElement
      :img="img"
      :img-src="imgSrc"
      :img-class="imgClass"
      :breakpoints="breakpoints"
    ></PictureElement>

    <div v-if="slots.default" class="absolute inset-0 h-full w-full">
      <slot></slot>
    </div>

    <VideoPlayButton v-else-if="hasVideo(video)" />
  </div>
</template>

<script setup>
import PictureElement from "~/templates/elements/PictureElement.vue";
import ModalElement from "~/templates/elements/ModalElement.vue";
import VideoComponent from "~/templates/components/cms/VideoComponent.vue";
import { hasVideo } from "~/utils/helper/video";
import { isEditMode } from "~/utils/content/magnolia";
import VideoPlayButton from "~/templates/elements/VideoPlayButton.vue";

const props = defineProps([
  "img",
  "imgSrc",
  "breakpoints",
  "imgClass",
  "loading",
  "video",
  "showVideoInline",
]);

const showVideo = ref(false);
const showVideoInline = toRef(props, "showVideoInline");

const slots = useSlots();

const onVideoClick = (e) => {
  if (hasVideo(props.video) && !isEditMode()) {
    // e.preventDefault();
    e.stopPropagation();
    showVideo.value = true;
  }
};
</script>
