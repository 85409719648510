// pages
import BasicPage from "../../templates/pages/BasicPage";
import ArticlePage from "~/templates/pages/ArticlePage.vue";
import FAQPage from "~/templates/pages/FAQPage.vue";
import ErrorPage from "../../templates/pages/ErrorPage.vue";
import CartPage from "../../templates/pages/CartPage.vue";
import NewsletterUnsubscribe from "~/templates/pages/middleware/NewsletterUnsubscribe.vue";
import PregPage from '~/templates/pages/shop/PregPage.vue';
import OrderLookupPage from "~/templates/pages/shop/OrderLookupPage.vue";
import OrderPaymentPage from "~/templates/pages/shop/OrderPaymentPage.vue";
import CommonLabelsOverview from "~/templates/pages/author/CommonLabelsOverview.vue";
import CheckoutPage from "~/templates/pages/checkout/index.vue";
import CheckoutSuccessPage from "~/templates/pages/checkout/CheckoutSuccessPage.vue";
import NewsletterDoiPage from "~/templates/pages/middleware/NewsletterDoiPage.vue";
// components
import SingleHeadlineComponent from "../../templates/components/cms/SingleHeadlineComponent";
import RichTextComponent from "../../templates/components/cms/RichTextComponent";
import CodeComponent from "../../templates/components/cms/CodeComponent";
import IFrameComponent from "../../templates/components/cms/IFrameComponent";
import SectionComponent from "../../templates/components/cms/SectionComponent";
import ImageComponent from "../../templates/components/cms/ImageComponent";
import ImageTextComponent from "../../templates/components/cms/ImageTextComponent";
import EditorialStoryComponent from "../../templates/components/cms/EditorialStoryComponent";
import EditorialStorySlideComponent from "../../templates/components/cms/EditorialStorySlideComponent";
import EditorialStoryHeadlineComponent from "../../templates/components/cms/EditorialStoryHeadlineComponent";
import ImageTeaserTileComponent from "../../templates/components/cms/ImageTeaserTileComponent";
import ImageTeaserComponent from "../../templates/components/cms/ImageTeaserComponent";
import StoryTeaserComponent from "../../templates/components/cms/StoryTeaserComponent";
import StorySliderComponent from "../../templates/components/cms/StorySliderComponent";
import CategoryTileComponent from "../../templates/components/cms/CategoryTileComponent";
import CategorySliderComponent from "../../templates/components/cms/CategorySliderComponent";
import StageCarouselComponent from "../../templates/components/cms/StageCarouselComponent";
import StageCarouselSlideComponent from "../../templates/components/cms/StageCarouselSlideComponent";
import StageContentComponent from "~/templates/components/cms/StageContentComponent.vue";
import StageStoryComponent from "~/templates/components/cms/StageStoryComponent.vue";
import CallToActionComponent from "../../templates/components/cms/CallToActionComponent";
import QuoteComponent from "../../templates/components/cms/QuoteComponent";
import MultipleImageTextComponent from "~/templates/components/cms/MultipleImageTextComponent.vue";
import MultipleImageTextAreaComponent from "~/templates/components/cms/MultipleImageTextAreaComponent.vue";
import DownloadListComponent from "../../templates/components/cms/DownloadListComponent";
import AnchorComponent from "~/templates/components/cms/AnchorComponent.vue";
import CMSProductListing from "~/templates/components/cms/CMSProductListing.vue";
import CMSProductHero from "~/templates/components/cms/CMSProductHero.vue";
import CMSProductTilesComponent from "~/templates/components/cms/CMSProductTilesComponent.vue";
import ContactFormComponent from "~/templates/components/cms/ContactFormComponent.vue";
import ContactBox from "~/templates/components/cms/ContactBox.vue";
import ContactBoxTile from "~/templates/components/ContactBoxTile.vue";
import FAQComponent from "~/templates/components/cms/FAQComponent.vue";
import FAQWithDirectoryComponent from "~/templates/components/cms/FAQWithDirectoryComponent.vue";
// shop components
import ProductTile from "~/templates/components/cms/ProductTile.vue";

import ProductDetailPage from "~/templates/pages/ProductDetailPage.vue";
import AccountOverview from "~/templates/pages/account/AccountOverview.vue";
import AccountRegister from "~/templates/pages/account/AccountRegister.vue";
import AccountProfile from "~/templates/pages/account/AccountProfile.vue";
import AccountOrderHistory from "~/templates/pages/account/AccountOrderHistory.vue";
import AccountAddressBook from "~/templates/pages/account/AccountAddressBook.vue";
import AccountOrderReturn from "~/templates/pages/account/AccountOrderReturn.vue";
import AccountOrderWarranty from "~/templates/pages/account/AccountOrderWarranty.vue";
import AccountOrderDetails from "~/templates/pages/account/AccountOrderDetails.vue";
import AccountOrderSubscriptions from "~/templates/pages/account/AccountOrderSubscriptions.vue";
import BenefitTile from "~/templates/elements/BenefitTile.vue";
import EditorialStoryImageComponent from "~/templates/components/cms/EditorialStoryImageComponent.vue";
import EditorialStoryTextComponent from "~/templates/components/cms/EditorialStoryTextComponent.vue";
import StoryOverviewComponent from "~/templates/components/cms/StoryOverviewComponent.vue";
import CategoryOverviewComponent from "~/templates/components/cms/CategoryOverviewComponent.vue";
import CategoryOverviewTileComponent from "~/templates/components/cms/CategoryOverviewTileComponent.vue";
import ScrollableTextImageVideoComponent from "~/templates/components/cms/ScrollableTextImageVideoComponent.vue";
import ScrollableTextImageVideo from "~/templates/components/cms/ScrollableTextImageVideo.vue";
import ScrollableTextComponent from "~/templates/components/cms/ScrollableTextComponent.vue";
import NewsletterRegistrationComponent from "~/templates/components/cms/NewsletterRegistrationComponent.vue";
import BenefitsComponent from "~/templates/components/cms/BenefitsComponent.vue";
import ImageTilesComponent from "~/templates/components/cms/ImageTilesComponent.vue";
import ImageTile from "~/templates/elements/ImageTile.vue";
// debug components
import AzureAdB2CTestComponent from "~/templates/components/auth/AzureAdB2CTestComponent.vue";
import YourceMigrationPage from "~/templates/pages/migration/YourceMigrationPage.vue";
import YourceMigrationSuccessPage from "~/templates/pages/migration/YourceMigrationSuccessPage.vue";

const componentMappings = {
  // pages
  "d2c-lm:pages/basic": BasicPage,
  "d2c-lm:pages/error": ErrorPage,
  "d2c-lm:pages/article": ArticlePage,
  "d2c-lm:pages/faq": FAQPage,
  // shop pages
  "d2c-lm:pages/shop/cart": CartPage,
  "d2c-lm:pages/shop/checkout": CheckoutPage,
  "d2c-lm:pages/shop/checkout.success": CheckoutSuccessPage,
  "d2c-lm:pages/shop/product": ProductDetailPage,
  "d2c-lm:pages/shop/product-exchange-box": ProductDetailPage,
  'd2c-lm:pages/shop/preg': PregPage,
  "d2c-lm:pages/shop/product-subscription": ProductDetailPage,
  "d2c-lm:pages/shop/order-lookup": OrderLookupPage,
  "d2c-lm:pages/shop/order-payment": OrderPaymentPage,
  // account
  "d2c-lm:pages/account/register": AccountRegister,
  "d2c-lm:pages/account/overview": AccountOverview,
  "d2c-lm:pages/account/order": AccountOrderHistory,
  "d2c-lm:pages/account/order-details": AccountOrderDetails,
  "d2c-lm:pages/account/order-return": AccountOrderReturn,
  "d2c-lm:pages/account/order-warranty": AccountOrderWarranty,
  "d2c-lm:pages/account/address": AccountAddressBook,
  "d2c-lm:pages/account/profile": AccountProfile,
  "d2c-lm:pages/account/subscriptions": AccountOrderSubscriptions,
  // middleware
  "d2c-lm:pages/middleware/newsletter-doi": NewsletterDoiPage,
  "d2c-lm:pages/middleware/newsletter-unsubscribe": NewsletterUnsubscribe,
  // middleware
  "d2c-lm:pages/migration/yource-migration-page": YourceMigrationPage,
  "d2c-lm:pages/migration/yource-migration-success-page": YourceMigrationSuccessPage,
  // author only
  "d2c-lm:pages/author/common-labels-overview": CommonLabelsOverview,
  // components
  "d2c-lm:components/headline": SingleHeadlineComponent,
  "d2c-lm:components/image": ImageComponent,
  "d2c-lm:components/image-text": ImageTextComponent,
  "d2c-lm:components/multiple-image-text": MultipleImageTextComponent,
  "d2c-lm:components/multiple-image-text-component": MultipleImageTextAreaComponent,
  "d2c-lm:components/code": CodeComponent,
  "d2c-lm:components/iframe": IFrameComponent,
  "d2c-lm:components/section": SectionComponent,
  "d2c-lm:components/editorial-story": EditorialStoryComponent,
  "d2c-lm:components/editorial-story-slide": EditorialStorySlideComponent,
  "d2c-lm:components/editorial-story-headline": EditorialStoryHeadlineComponent,
  "d2c-lm:components/editorial-story-image": EditorialStoryImageComponent,
  "d2c-lm:components/editorial-story-text": EditorialStoryTextComponent,
  "d2c-lm:components/image-teaser": ImageTeaserComponent,
  "d2c-lm:components/image-teaser-tile": ImageTeaserTileComponent,
  "d2c-lm:components/story-teaser": StoryTeaserComponent,
  "d2c-lm:components/category-slider": CategorySliderComponent,
  "d2c-lm:components/story-slider": StorySliderComponent,
  "d2c-lm:components/category-tile": CategoryTileComponent,
  "d2c-lm:components/call-to-action": CallToActionComponent,
  "d2c-lm:components/rich-text": RichTextComponent,
  "d2c-lm:components/stage-carousel": StageCarouselComponent,
  "d2c-lm:components/stage-content": StageContentComponent,
  "d2c-lm:components/stage-story": StageStoryComponent,
  "d2c-lm:components/carousel-slide": StageCarouselSlideComponent,
  "d2c-lm:components/quote": QuoteComponent,
  "d2c-lm:components/download-list": DownloadListComponent,
  "d2c-lm:components/anchor": AnchorComponent,
  "d2c-lm:components/product-tiles": CMSProductTilesComponent,
  "d2c-lm:components/product-tile": ProductTile,
  "d2c-lm:components/shop/product-listing": CMSProductListing,
  "d2c-lm:components/product-hero": CMSProductHero,
  "d2c-lm:components/newsletter-registration": NewsletterRegistrationComponent,
  "d2c-lm:components/benefits": BenefitsComponent,
  "d2c-lm:components/benefit-tile": BenefitTile,
  "d2c-lm:components/story-overview": StoryOverviewComponent,
  "d2c-lm:components/category-overview": CategoryOverviewComponent,
  "d2c-lm:components/category-overview-tile": CategoryOverviewTileComponent,
  "d2c-lm:components/scrollable-text-image-video": ScrollableTextImageVideo,
  "d2c-lm:components/scrollable-text-image-video-component": ScrollableTextImageVideoComponent,
  "d2c-lm:components/scrollable-text": ScrollableTextComponent,
  "d2c-lm:components/contact-form": ContactFormComponent,
  "d2c-lm:components/faq": FAQComponent,
  "d2c-lm:components/faq-with-directory": FAQWithDirectoryComponent,
  "d2c-lm:components/image-tiles": ImageTilesComponent,
  "d2c-lm:components/image-tile": ImageTile,
  "d2c-lm:components/contact-box": ContactBox,
  "d2c-lm:components/contact-box-tile": ContactBoxTile,

  // Auth
  "d2c-lm:components/auth/azure-ad-b2c-test": AzureAdB2CTestComponent,
};

export { componentMappings };
