<template>
  <div v-if="order" class="grid grid-cols-2 gap-2 hyphens-auto text-sm xl:grid-cols-6 xl:gap-x-2">
    <div class="contents xl:order-last xl:flex xl:flex-col xl:items-start xl:gap-1">
      <CommonLabel class="font-medium" path="BWF/shop/account/orderDetails/articles"></CommonLabel>
      <span>{{ orderArticleCount(order) ?? "" }}</span>
    </div>
    <div class="contents xl:flex xl:flex-col xl:items-start xl:gap-1 xl:gap-y-1">
      <CommonLabel
        class="font-medium"
        path="BWF/shop/account/orderDetails/dateOfOrder"
      ></CommonLabel>
      <span>{{ orderDate }}</span>
    </div>
    <div class="contents xl:flex xl:flex-col xl:items-start xl:gap-1">
      <CommonLabel class="font-medium" path="BWF/shop/account/orderDetails/status"></CommonLabel>
      <span>{{ order?.deliveries?.[0]?.stateMachineState?.name }}</span>
    </div>
    <div class="contents xl:flex xl:flex-col xl:items-start xl:gap-1">
      <CommonLabel
        class="font-medium"
        path="BWF/shop/account/orderDetails/paymentStatus"
      ></CommonLabel>
      <span :class="{ 'text-signal-red': paymentCancelled }">{{
        order?.transactions?.[0]?.stateMachineState?.name
      }}</span>
    </div>
    <div class="contents xl:flex xl:flex-col xl:items-start xl:gap-1">
      <CommonLabel class="font-medium" path="BWF/shop/account/orderDetails/paymentMethod">
      </CommonLabel>
      <span>{{ order?.transactions?.[0]?.paymentMethod?.name }}</span>
    </div>
    <div class="contents xl:flex xl:flex-col xl:items-start xl:gap-1">
      <CommonLabel class="font-medium" path="BWF/shop/account/orderDetails/shippingMethod">
      </CommonLabel>
      <span>{{ order?.deliveries?.[0]?.shippingMethod?.name }}</span>
    </div>
  </div>
</template>

<script setup>
import { useOrderDetails } from "@shopware-pwa/composables-next";

import CommonLabel from "~/templates/components/CommonLabel.vue";
import { hasFailedPayment, orderArticleCount } from "~/utils/helper/shop/order";

const { order } = useOrderDetails();

const paymentCancelled = computed(() => hasFailedPayment(order.value));

const orderDate = computed(() => {
  return order.value?.orderDate
    ? new Date(order.value?.orderDate ?? "").toLocaleDateString(
        (typeof navigator !== "undefined" && navigator.language) || "de-DE",
      )
    : "";
});
</script>
