<template>
  <div
    :class="[
      contentToResponsiveGapClasses({
        gap: spacing,
      }),
    ]"
    class="flex flex-col"
  >
    <template v-for="childContent in mapNodes(content)" :key="getId(childContent)">
      <div :id="`faq-navigation-${getId(childContent)}`" class="faq-navigation-target">
        <EditableComponent :content="childContent" />
      </div>
    </template>
  </div>
</template>

<script setup>
import { EditableComponent } from "@magnolia/vue-editor";
import { inject } from "vue";
import { getId, mapNodes } from "~/utils/helper/magnolia";
import { contentToResponsiveGapClasses } from "~/utils/helper/tailwind";

const spacing = inject("spacing");
defineProps(["content"]);
</script>

<style scoped>
.faq-navigation-target {
  scroll-margin-top: theme("spacing.28");
}
</style>
