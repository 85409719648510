import { getVideoSrc } from "~/utils/helper/video";

export default function (media, video) {
  const progress = ref(0);

  let progress25 = false;
  let progress50 = false;
  let progress75 = false;

  const videoProvider = computed(() => {
    return video?.asset ? "Magnolia" : "BOA";
  });
  const trackOnVideoProgress = () => {
    progress.value = media?.value?.currentTime / media?.value?.duration;
    if (progress25 === false && progress.value >= 0.25) {
      progress25 = true;
      trackProgressVideoEvent("25");
    }

    if (progress50 === false && progress.value >= 0.5) {
      progress50 = true;
      trackProgressVideoEvent("50");
    }

    if (progress75 === false && progress.value >= 0.75) {
      progress75 = true;
      trackProgressVideoEvent("75");
    }
  };

  const trackPlayVideoEvent = () => {
    try {
      const gtm = useGtm(); // auto-imported by the module
      gtm?.trackEvent({
        event: "gx.video_play",
        event_name: "video_play",
        event_source: "source_code",
        video_play: {
          duration: media?.value?.duration,
          provider: videoProvider.value,
          url: video.url ?? getVideoSrc(video?.asset),
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  const trackStartVideo = ref(false);

  watchOnce(trackStartVideo, () => {
    if (trackStartVideo.value === true) {
      trackPlayVideoEvent();
    }
  });

  const trackCompletedVideoEvent = () => {
    try {
      const gtm = useGtm(); // auto-imported by the module
      gtm?.trackEvent({
        event: "gx.video_completed",
        event_name: "video_completed",
        event_source: "source_code",
        video_completed: {
          duration: media?.value?.duration,
          provider: videoProvider.value,
          url: video.url ?? getVideoSrc(video?.asset),
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const trackProgressVideoEvent = (percent) => {
    try {
      const gtm = useGtm(); // auto-imported by the module
      gtm?.trackEvent({
        event: "gx.video_progress",
        event_name: "video_progress",
        event_source: "source_code",
        video_progress: {
          duration: media?.value?.duration,
          percentage: percent,
          provider: videoProvider.value,
          url: video.url ?? getVideoSrc(video?.asset),
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  return {
    trackPlayVideoEvent,
    trackCompletedVideoEvent,
    trackProgressVideoEvent,
    trackOnVideoProgress,
    trackStartVideo,
  };
}
