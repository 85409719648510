<template>
  <div class="relative z-[1] cursor-pointer" @click="onVideoClick">
    <ModalElement :open="showVideo" @close="showVideo = false">
      <video
        v-if="showVideo"
        ref="media"
        class="h-full w-full bg-black"
        :src="src"
        :poster="getSmallestThumbnailUrl(thumbnailMedia)"
        controls
        autoplay
        playsinline
        @ended="showVideo = false"
      ></video>
    </ModalElement>
    <img
      class="h-full w-full object-contain"
      :src="getSmallestThumbnailUrl(thumbnailMedia) ?? thumbnailMedia?.url"
      :srcset="getSrcSetForMedia(thumbnailMedia)"
      :alt="thumbnailMedia?.alt"
      :title="thumbnailMedia?.title"
      :loading="loading ?? 'lazy'"
    />

    <VideoPlayButton />
  </div>
</template>

<script setup>
import ModalElement from "~/templates/elements/ModalElement.vue";
import { isEditMode } from "~/utils/content/magnolia";
import { getSmallestThumbnailUrl, getSrcSetForMedia } from "@shopware-pwa/helpers-next";
import VideoPlayButton from "~/templates/elements/VideoPlayButton.vue";

defineProps(["thumbnailMedia", "src", "loading"]);

const showVideo = ref(false);

const onVideoClick = (e) => {
  if (!isEditMode()) {
    // e.preventDefault();
    e.stopPropagation();
    showVideo.value = true;
  }
};
</script>
