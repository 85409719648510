<template>
  <div
    class="safari-overflow-hidden-fix group relative flex h-full w-full flex-col overflow-hidden rounded-2xl bg-light-grey"
  >
    <div class="overflow-hidden bg-light-beige">
      <PictureElement
        v-if="image"
        :breakpoints="imageBreakpoints"
        :img="image"
        img-class="Image aspect-3/2 gradient object-cover transition-transform group-hover:scale-110 duration-300 w-full h-full"
      ></PictureElement>
    </div>
    <div class="flex min-h-32 flex-grow flex-col justify-between overflow-hidden p-6">
      <div>
        <component
          :is="isDownloadLink(link) ? 'div' : LinkComponent"
          :link="link"
          optional-link-classes="stretched-link"
        >
          <HeadlineComponent
            :headline="headline"
            class="pb-2 text-2xl font-medium"
            tag="h3"
          ></HeadlineComponent>
        </component>

        <p>{{ text }}</p>
      </div>
      <DownloadLinkComponent
        v-if="isDownloadLink(link)"
        :label="link?.label"
        :link="link"
        class="stretched-link mt-12"
      ></DownloadLinkComponent>
    </div>
  </div>
</template>

<script setup>
import { DIMENSIONS } from "~/utils/helper/imaging";
import { isDownloadLink } from "~/utils/helper/magnolia";
import DownloadLinkComponent from "~/templates/components/cms/DownloadLinkComponent.vue";
import HeadlineComponent from "./HeadlineComponent";
import PictureElement from "../../elements/PictureElement";
import LinkComponent from "~/templates/components/cms/LinkComponent.vue";

defineProps(["text", "headline", "image", "link"]);

const imageBreakpoints = {
  start: DIMENSIONS["start"].width["10/12"].ratio["3/2"],
  sm: DIMENSIONS["sm"].width["10/12"].ratio["3/2"],
  md: DIMENSIONS["md"].width["1/2"].ratio["3/2"],
  xl: DIMENSIONS["xl"].width["1/3"].ratio["3/2"],
};
</script>
