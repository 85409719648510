<template>
  <section
    :id="anchorName"
    :class="[
      contentToTailwindClasses({ backgroundColor: backgroundColor }),
      hasValue(backgroundColor)
        ? contentToResponsivePaddingClasses({
            top: spacingTop,
            bottom: spacingBottom,
          })
        : contentToResponsiveMarginClasses({
            top: spacingTop,
            bottom: spacingBottom,
          }),
    ]"
    class="section-component"
  >
    <EditableArea
      :class="[
        {
          container: container,
          'no-container': !container,
        },
      ]"
      :content="main"
      :custom-view="SectionComponentArea"
    />
  </section>
</template>
<script setup>
import {
  contentToResponsivePaddingClasses,
  contentToResponsiveMarginClasses,
  contentToTailwindClasses,
} from "~/utils/helper/tailwind";
import SectionComponentArea from "./SectionComponentArea";
import { hasValue } from "~/utils/helper/magnolia";
</script>
<script>
import { EditableArea } from "@magnolia/vue-editor";
import { editableComponent } from "~/utils/mixins/editableComponent";

export default {
  name: "SectionComponent",
  components: {
    EditableArea,
  },
  mixins: [editableComponent],
  provide() {
    return {
      content: this.content,
      spacing: this.spacing,
      contentWidth: this.contentWidth,
    };
  },
  props: [
    "container",
    "main",
    "anchorName",
    "spacingBottom",
    "spacingTop",
    "backgroundColor",
    "spacing",
    "contentWidth",
  ],
};
</script>
