import {
  useNotifications as coreUseNotifications,
  type UseNotificationsReturn,
} from "@shopware-pwa/composables-next";
const DEFAULT_NOTIFICATION_OPTIONS = { timeout: 5000 };
export function useNotifications(): UseNotificationsReturn {
  const swUseNotification = coreUseNotifications();
  return {
    ...swUseNotification,
    pushInfo: (message, options) =>
      swUseNotification.pushInfo(message, { ...DEFAULT_NOTIFICATION_OPTIONS, ...options }),
    pushSuccess: (message, options) =>
      swUseNotification.pushSuccess(message, { ...DEFAULT_NOTIFICATION_OPTIONS, ...options }),
    pushWarning: (message, options) =>
      swUseNotification.pushWarning(message, { ...DEFAULT_NOTIFICATION_OPTIONS, ...options }),
    pushError: (message, options) =>
      swUseNotification.pushError(message, { ...DEFAULT_NOTIFICATION_OPTIONS, ...options }),
  };
}
