import { isEditMode as evaluateEditMode } from "~/utils/content/magnolia";
import { replacePattern } from "~/utils/helper/string";
import { COMMON_LABEL_DEFAULTS } from "~/utils/content/CommonLabelDefaults";

const useMgnlCommonLabels = function () {
  const missingLabels = useState("missing-labels", () => new Set([]));
  const editedLabels = useState("edited-labels", () => new Set([]));
  const commonLabels = useState("common-labels", () => ({}));
  const isEditMode = evaluateEditMode();
  const { marketSetupLinks } = useMarketSetup();

  /**
   * returns a single value by its path
   * if a value is missing in magnolia the default value is returned
   *
   * @param {string} pathToLabel - path to label in commonLabels App
   * @param {object} [placeholder] -  an optional key value map which will be replaced in the string
   * @returns {string}
   * */
  const getLabelByPath = (pathToLabel, placeholder) => {
    const cleanPath = cleanLabelPath(pathToLabel);
    const parts = cleanPath.split("/");
    const key = parts.pop();
    const labelsInPath = getValuesInFolder(parts.join("/"), commonLabels.value);
    if (isEditMode) {
      if (!labelsInPath?.[key]) {
        missingLabels.value.add(pathToLabel);
      } else {
        editedLabels.value.add(pathToLabel);
      }
    }

    const label = labelsInPath?.[key] ?? getDefaultValueByPath(pathToLabel);
    if (placeholder) {
      return replacePattern(label, placeholder, marketSetupLinks.value);
    }

    return label;
  };

  /**
   * @param path to the folder that holds the values
   * @param keys labels that are being used in the view. render missing notifications when missing
   * @returns {{[key: string]: string}}
   */
  const getLabels = (path, keys = []) => {
    try {
      const cleanPath = cleanLabelPath(path);
      const defaultValues = getDefaultValuesInFolder(cleanPath) ?? {};
      const loadedLabels = getValuesInFolder(cleanPath, commonLabels.value);
      if (isEditMode) {
        keys.forEach((labelKey) => {
          if (!loadedLabels?.[labelKey]) {
            missingLabels.value.add(`${cleanPath}/${labelKey}`);
          } else {
            editedLabels.value.add(`${cleanPath}/${labelKey}`);
          }
        });
      }

      return { ...defaultValues, ...loadedLabels };
    } catch (e) {
      console.log("CL: getLabels Error while loading common labels / defaults", path);
      return {};
    }
  };

  const isLabelDefaultLabel = (label, path) => {
    // remove leading and trailing slashes
    const cleanPath = cleanLabelPath(path);
    const defaultLabel = COMMON_LABEL_DEFAULTS[cleanPath];
    return defaultLabel && defaultLabel === label;
  };

  /*
   * remove double slashes and leading / trailing slashes to normalize input values for defaul lookups and rest calls
   * */
  const cleanLabelPath = (path = "") => path.replace("//", "/").replace(/^\/|\/$/g, "");
  /**
   * load one distinct value by its path
   * @param path
   * @returns {*}
   */
  const getDefaultValueByPath = (path) => {
    // remove leading and trailing slashes
    const cleanPath = cleanLabelPath(path);
    return COMMON_LABEL_DEFAULTS[cleanPath];
  };
  /**
   * filter CommonLabel Default Values by using the path as a prefix
   * split the path to return the last path segment as a key, value map
   *
   * @param pathToFolder
   * @returns {*}
   */
  const getDefaultValuesInFolder = (pathToFolder) => {
    return getValuesInFolder(pathToFolder, COMMON_LABEL_DEFAULTS);
  };

  /**
   * filter CommonLabels by using the path as a prefix
   * split the path to return the last path segment as a key, value map
   *
   * @param pathToFolder
   * @param labels : Object
   * @returns {*}
   */
  const getValuesInFolder = (pathToFolder, labels) => {
    const cleanPath = cleanLabelPath(pathToFolder);
    return Object.entries(labels ?? {})
      .filter(([key]) => {
        // value must be direct child as otherwise key namings can collide
        const isDirectChild = key.replace(`${pathToFolder}/`, "").includes("/") === false;
        return key.startsWith(cleanPath) && isDirectChild;
      })
      .reduce((acc, [key, value]) => {
        const lastSegmentKey = key.split("/").pop();
        acc[lastSegmentKey] = value;
        return acc;
      }, {});
  };

  return {
    getLabels,
    getLabelByPath,
    missingLabels,
    editedLabels,
    isLabelDefaultLabel,
    commonLabels,
  };
};

export default useMgnlCommonLabels;
