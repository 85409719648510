
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93KIdBYEYtgbMeta } from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/pages/[...slug].vue?macro=true";
import { default as adb2cvdUtRcWxDqMeta } from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/pages/adb2c.vue?macro=true";
import { default as _91_46_46_46marketSetupLink_93AFjTeUN9RiMeta } from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/pages/r/[...marketSetupLink].vue?macro=true";
import { default as mybrita_45redirectqZACadAkw4Meta } from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/pages/r/mybrita-redirect.vue?macro=true";
import { default as _91_46_46_46productId_939DbOPlGwISMeta } from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/pages/r/product/[...productId].vue?macro=true";
import { default as static_45errorbH1CwARMfuMeta } from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/pages/static-error.vue?macro=true";
import { default as static_45maintenancegU5cXpWMp9Meta } from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/pages/static-maintenance.vue?macro=true";
import { default as static_45yource_45maintenanceF0RxVLpQYhMeta } from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/pages/static-yource-maintenance.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/pages/[...slug].vue")
  },
  {
    name: "adb2c",
    path: "/adb2c",
    component: () => import("/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/pages/adb2c.vue")
  },
  {
    name: "r-marketSetupLink",
    path: "/r/:marketSetupLink(.*)*",
    component: () => import("/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/pages/r/[...marketSetupLink].vue")
  },
  {
    name: "r-mybrita-redirect",
    path: "/r/mybrita-redirect",
    component: () => import("/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/pages/r/mybrita-redirect.vue")
  },
  {
    name: "r-product-productId",
    path: "/r/product/:productId(.*)*",
    component: () => import("/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/pages/r/product/[...productId].vue")
  },
  {
    name: "static-error",
    path: "/static-error",
    component: () => import("/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/pages/static-error.vue")
  },
  {
    name: "static-maintenance",
    path: "/static-maintenance",
    component: () => import("/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/pages/static-maintenance.vue")
  },
  {
    name: "static-yource-maintenance",
    path: "/static-yource-maintenance",
    component: () => import("/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/pages/static-yource-maintenance.vue")
  }
]