<template>
  <div v-if="column.displayAs === 'button'" class="flex h-full flex-col justify-between">
    <!--        <div class="font-book cursor-pointer hover:text-dark-blue whitespace-normal">-->
    <!--            {{ column.title }}-->
    <!--        </div>-->

    <div class="mb-4 flex flex-wrap gap-2 md:mb-6 xl:mb-0">
      <div v-for="col in filterNodesByType(mapNodes(column), 'nav:link')" :key="col.name">
        <LinkComponent
          v-if="col.image"
          :link="col.link"
          :aria-label="containsLink(col.link) ? (col.link?.label ?? 'icon-link') : null"
          class="btn min-h-0 border-0 bg-white p-0"
        >
          <img
            class="aspect-2/1 h-8 object-contain p-2 xl:h-10"
            :src="imagePath(col.image)"
            :alt="getImageAlt(col.image)"
            :title="getImageTitle(col.image)"
          />
        </LinkComponent>
        <LinkComponent v-else :link="col.link" class="btn btn-blue btn-size-small text-sm">
          {{ col?.link?.label }}
        </LinkComponent>
      </div>
    </div>
  </div>

  <div v-else class="flex flex-col gap-2">
    <div
      v-for="col in filterNodesByTypes(mapNodes(column), ['nav:link', 'footer:col'])"
      :key="col.name"
    >
      <LinkComponent
        v-if="col['@nodeType'] === 'nav:link'"
        class="cursor-pointer whitespace-nowrap"
        optional-link-classes="hover:text-dark-blue"
        :link="col.link"
      >
        <div>{{ col?.link?.label }}</div>
      </LinkComponent>

      <div v-else-if="col['@nodeType'] === 'footer:col'" class="flex flex-col gap-2">
        <LinkComponent
          :link="col.link"
          class="whitespace font-medium"
          optional-link-classes="hover:text-dark-blue"
        >
          {{ col.title }}
        </LinkComponent>
        <LinkComponent
          v-for="link in filterNodesByType(mapNodes(col), 'nav:link')"
          :key="link['@id']"
          class="cursor-pointer whitespace-nowrap"
          optional-link-classes="hover:text-dark-blue"
          :link="link.link"
        >
          <div>{{ link?.link?.label }}</div>
        </LinkComponent>
      </div>
    </div>
  </div>
</template>

<script>
import LinkComponent from "../../components/cms/LinkComponent";
import {
  filterNodesByType,
  imagePath,
  getImageAlt,
  getImageTitle,
  mapNodes,
  containsLink,
  filterNodesByTypes,
} from "~/utils/helper/magnolia";

export default {
  name: "FooterColumnContent",
  components: { LinkComponent },
  props: { column: Object },
  methods: {
    filterNodesByTypes,
    imagePath,
    mapNodes,
    getImageAlt,
    getImageTitle,
    filterNodesByType,
    containsLink,
  },
};
</script>

<style scoped></style>
