export const getCurrentLanguage = function (url, languages, defaultLanguage) {
  for (let i = 0; i < languages.length; i++) {
    const language = languages[i];
    if (url.toLowerCase().indexOf("/" + language.toLowerCase()) > -1) return language;
  }
  return defaultLanguage;
};

export const setURLSearchParams = function (url, param) {
  return url + (url.indexOf("?") > -1 ? "&" : "?") + param;
};

export const isComponentPreview = function () {
  const url = useRoute().fullPath;
  return url.includes("isComponentPreview=true");
};

export const isEditMode = function () {
  const fullPath = useRoute().fullPath;
  return fullPath.includes("mgnlPreview=false");
};

export const isMagnoliaPreview = function () {
  const fullPath = useRoute().fullPath;
  return fullPath.includes("mgnlPreview=true");
};

export const mgnlVersionedRequest = function () {
  const query = useRoute().query;
  if (query.mgnlVersion !== undefined) return query.mgnlVersion;
};

export const cleanFullPath = () => {
  let fullPath = useRoute().fullPath;
  // remove file endings
  fullPath = fullPath.replace(".html", "");
  // remove hashbang parameters
  fullPath = fullPath.split("#")?.[0];
  // remove get parameters
  fullPath = fullPath.split("?")?.[0];
  // remove potential double slashes
  fullPath = fullPath.replace("//", "/");
  // remove trailing slashes
  fullPath = fullPath.endsWith("/") ? fullPath.substring(0, fullPath.length - 1) : fullPath;
  return fullPath;
};
