<template>
  <div
    :class="
      contentToTailwindClasses({
        flexItems: alignment,
        alignment: alignment,
      })
    "
    class="flex flex-col"
  >
    <DownloadLinkComponent
      v-if="isDownloadLink(link)"
      :label="link?.label"
      :link="link"
    ></DownloadLinkComponent>
    <LinkComponent v-else :class="cssClasses" :link="link" :stretch-link="stretchLink">
      {{ link?.label ?? "" }}
    </LinkComponent>
  </div>
</template>

<script setup>
import { contentToTailwindClasses } from "~/utils/helper/tailwind";
import { isDownloadLink } from "~/utils/helper/magnolia";
import LinkComponent from "./LinkComponent";
import DownloadLinkComponent from "./DownloadLinkComponent";
</script>

<script>
const BTN_STYLES = {
  btnBlue: "btn btn-blue",
  btnMagenta: "btn btn-magenta",
  btnBlack: "btn btn-black",
  btnWhite: "btn btn-white",
  btnGhost: "btn btn-ghost",
  link: "text-link",
};

export default {
  name: "CallToActionComponent",
  props: ["link", "alignment", "stretchLink"],
  computed: {
    cssClasses() {
      if (this.link?.label) {
        return BTN_STYLES[this.link?.style ?? false];
      }
      return ``;
    },
  },
};
</script>
