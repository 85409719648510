import { ref, onMounted } from "vue";
import { createSharedComposable } from "@vueuse/core";

function singleUseWindow() {
  const viewPortWidth = ref(0);
  const scrollY = ref(0);

  const evaluateSize = () => {
    viewPortWidth.value = window.innerWidth ?? 0;
  };

  const evaluateScrollY = () => {
    scrollY.value = window.scrollY ?? 0;
  };

  onMounted(() => {
    window.addEventListener("resize", evaluateSize);
    document.addEventListener("scroll", evaluateScrollY, { passive: true });
    // init
    evaluateSize();
  });

  // expose managed state as return value
  return { viewPortWidth, scrollY };
}

export default createSharedComposable(singleUseWindow);
