<template>
  <div class="grid xl:grid-cols-3">
    <div class="w-full xl:hidden">
      <button class="text-icon-link" @click="$router.go(-1)">
        <SvgIcon class="h-3 w-3 -rotate-90" name="Arrow-Up"></SvgIcon>
        <CommonLabel path="BWF/shop/shared/backToShop" />
      </button>

      <hr class="mb-6 mt-4 xl:my-12" />
    </div>
    <div class="mx-auto flex flex-col xl:col-span-2 xl:w-1/2">
      <CheckInComponent />

      <hr class="my-6 xl:my-12" />
      <div>
        <h3 class="mb-1 text-lg font-medium md:mb-4 md:text-2xl">
          <CommonLabel path="BWF/shop/checkout/mybrita/guestProceed" />
        </h3>
        <div
          v-if="containsExchangeBoxProduct(cartItems) || containsSubscriptionProduct(cartItems)"
          class="notification"
          data-type="danger"
        >
          <SvgIcon class="notification-icon" name="Error-Circle"></SvgIcon>
          <div>
            <CommonLabel
              class="font-medium"
              path="BWF/shop/checkout/mybrita/myBritaOnlyHeadline"
              tag="div"
            ></CommonLabel>
            <CommonLabel path="BWF/shop/checkout/mybrita/myBritaOnlyDescription"></CommonLabel>
          </div>
        </div>
        <template v-else>
          <div class="mb-4 text-sm">
            <CommonLabel path="BWF/shop/checkout/mybrita/notReadyToRegister" />
          </div>
          <button
            class="btn btn-ghost w-full"
            @click="
              $emit('guest');
              trackGuestCheckout();
            "
          >
            <CommonLabel path="BWF/shop/checkout/cart/toCheckout" />
          </button>
        </template>
      </div>
      <div class="mt-14"><CommonLabel path="BWF/shared/vatFootnote"></CommonLabel></div>
    </div>
    <div v-if="isDesktop" class="grid gap-8">
      <CheckoutSummary />
      <div class="grid gap-4">
        <div class="flex items-end justify-between">
          <h3 class="text-2xl font-medium"><CommonLabel path="BWF/shop/shared/cartHeadline" /></h3>

          <div v-if="cartItems.length > 0" class="text-sm">
            <CommonLabel class="font-medium" path="BWF/shop/shared/tableQuantity" />&nbsp;{{
              count
            }}
          </div>
        </div>
        <ul class="-my-4 divide-y divide-gray-200 pl-0" role="list">
          <li
            v-for="cartItem in getLineItemsWithoutPromotion(cartItems)"
            :key="cartItem.id"
            class="flex py-6"
          >
            <CheckoutCartItem :cart-item="cartItem" :child-variant="true" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonLabel from "~/templates/components/CommonLabel.vue";
import CheckInComponent from "~/templates/components/shop/account/CheckInComponent.vue";
import {
  containsExchangeBoxProduct,
  getLineItemsWithoutPromotion,
  containsSubscriptionProduct,
} from "~/utils/helper/shop/cart";
import { useCartInformation } from "~/composables/shop/useCartInformation";
import SvgIcon from "~/templates/elements/SvgIcon.vue";
const { trackGuestCheckout } = useCheckoutTracking();
const { count } = useCartInformation();
const emit = defineEmits<{
  (e: "guest"): void;
  (e: "loadingStart"): void;
  (e: "loadingEnd"): void;
}>();

const { cartItems } = useCart();
const { isDesktop } = useDevice();

onMounted(() => {
  emit("loadingEnd");
});
</script>
