import type { LineItem, OrderLineItem } from "@shopware-pwa/types";

export const getLineItemsWithoutPromotion = (cartItems: LineItem[] | OrderLineItem[] | null) => {
  return cartItems?.filter((item) => item?.type !== "promotion");
};

export const getPromotiontLineItems = (cartItems: LineItem[]) => {
  return cartItems.filter((item) => item?.type === "promotion");
};

export const containsExchangeBoxProduct = (cartItems: LineItem[]) => {
  // @ts-ignore - extension
  return cartItems.some((item) => item.children?.[0]?.extensions?.hasOwnProperty("tauschbox"));
};
export const containsSubscriptionProduct = (cartItems: LineItem[]) => {
  // @ts-ignore - extension
  return cartItems.some((item) => item.children?.[0]?.extensions?.hasOwnProperty("subscription"));
};
