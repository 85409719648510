<template>
  <NuxtLink :to="marketSetupAdmin?.cartPage ?? ''" :external="true" class="btn btn-icon relative">
    <SvgIcon name="Cart"></SvgIcon>

    <div
      v-if="count > 0"
      class="absolute bottom-1 right-0 flex h-4 w-4 items-center justify-center rounded-full bg-light-blue text-xxs text-black"
    >
      <span>
        {{ count }}
      </span>
    </div>
  </NuxtLink>
</template>

<script setup>
import SvgIcon from "../../elements/SvgIcon";
import { useCartInformation } from "~/composables/shop/useCartInformation";
const { count } = useCartInformation();

const emit = defineEmits(["cartCountChange"]);

const { marketSetupAdmin } = useMarketSetup();

watch(count, () => {
  emit("cartCountChange");
});
</script>
