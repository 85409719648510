<template>
  <div
    :class="[
      { 'mx-auto': (contentWidth ?? content.contentWidth) < 12 },
      contentToTailwindClasses({
        width: { md: contentWidth ?? content.contentWidth },
      }),
      contentToResponsiveGapClasses({
        gap: spacing ?? content.spacing,
      }),
    ]"
    class="flex flex-col"
  >
    <EditableComponent
      v-for="childContent in mapNodes(content)"
      :key="childContent['@id']"
      :content="childContent"
    />
  </div>
</template>
<script setup>
import { inject } from "vue";
import { contentToResponsiveGapClasses, contentToTailwindClasses } from "~/utils/helper/tailwind";
import { mapNodes } from "~/utils/helper/magnolia";
const spacing = inject("spacing");
const contentWidth = inject("contentWidth");
</script>

<script>
import { EditableComponent } from "@magnolia/vue-editor";

export default {
  name: "SectionArea",
  components: { EditableComponent },
  props: ["content"],
};
</script>
