<template>
  <div class="relative text-base">
    <label
      :class="{ 'has-label': !!label, 'border-signal-red': hasError }"
      class="flex items-start gap-2"
    >
      <span class="relative h-5 w-5 shrink-0 rounded-full border border-dark-blue p-1">
        <input
          :id="attrs.value"
          :checked="modelValue === attrs.value"
          :class="{ 'has-label': !!label, 'has-error': hasError }"
          :disabled="attrs.disabled"
          :name="attrs.name"
          :placeholder="attrs.placeholder"
          :required="attrs.required"
          :value="attrs.value"
          class="absolute inset-0 h-full w-full appearance-none"
          type="radio"
          @blur="$emit('blur', $event)"
          @change="$emit('change', $event)"
          @input="$emit('update:modelValue', attrs.value)"
        />

        <span class="circle absolute inset-0.5 hidden rounded-full bg-dark-blue"></span>
      </span>
      <span class="w-full leading-5">
        {{ label }}
        <slot />
      </span>
    </label>
    <div
      v-if="errorMessage"
      class="pointer-events-none absolute bottom-0 left-0 translate-y-full px-2 text-sm text-signal-red"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup>
const props = defineProps(["modelValue", "label", "errorMessage"]);
const attrs = useAttrs();
const hasError = computed(() => !!props.errorMessage);

defineEmits(["update:modelValue", "blur", "change"]);
</script>

<style scoped>
input {
}

input:checked + .circle {
  @apply block;
}
input.has-error {
  @apply border-signal-red;
}
</style>
