<template>
  <!--    Grid Header-->
  <div
    v-if="columnNames"
    class="cart-grid hidden gap-4 border-b py-2 text-xs font-medium xl:mb-6 xl:grid"
  >
    <div>{{ cartLabels.tableProduct }}</div>
    <div class="col-start-3 text-center">{{ sharedLabels.tableQuantity }}</div>
    <div class="col-start-4 text-end">{{ cartLabels.tableTotal }}</div>
    <div></div>
  </div>

  <!--      Default Card Layout with simple variant_  -->
  <div class="cart-grid align-start relative grid">
    <NuxtLink
      v-if="!isEshopSet"
      :to="pdpUrl"
      class="col-span-4 row-span-3 xl:col-span-1 xl:row-span-6"
    >
      <ProductImage
        v-if="!isEshopSet"
        :product-media="thumbnailUrl"
        :product-name="cartItem?.label"
        fallback-class="w-full h-full object-center rounded-lg bg-light-grey aspect-[74/100] p-4"
        main-class="product-image object-contain object-center rounded-lg bg-light-grey aspect-[74/100]"
        src-set-until="800"
      >
      </ProductImage>
    </NuxtLink>
    <div
      :class="[
        { 'col-span-9 xl:col-span-2': isEshopSet, 'col-span-8 xl:col-span-4': childVariant },
      ]"
      class="col-span-5 flex flex-col gap-1 hyphens-auto xl:col-span-1"
    >
      <div v-if="isSubscriptionProduct" class="badge is-highlight-green is-large self-start">
        <CommonLabel path="BWF/shop/productDetailPage/subscriptionProduct/subscriptionLabel" />
      </div>
      <NuxtLink :to="pdpUrl">
        <h3 class="product-title text-base" data-testid="cart-product-name">
          {{ cartItem.children?.[0]?.label ?? cartItem.label }}
        </h3>
      </NuxtLink>
      <div class="space-y-1 text-sm leading-tight text-medium-grey">
        <p v-if="productNumber" class="inline-flex gap-1">
          <CommonLabel path="BWF/shop/productDetailPage/productNumber"></CommonLabel>
          <span>
            {{ productNumber }}
          </span>
        </p>
        <p v-if="isSubscriptionProduct">
          <CommonLabel
            path="BWF/shop/productDetailPage/subscriptionProduct/subscriptionIntervalDropdownLabel"
          />:
          {{ cartItem?.label }}
        </p>

        <dl v-if="itemOptions" class="product-options">
          <template v-for="option in itemOptions" :key="(option as PropertyGroupOptionCart).group">
            <dd>{{ (option as PropertyGroupOptionCart).option }}</dd>
          </template>
        </dl>
      </div>
      <span v-if="cartItem?.price?.referencePrice?.unitName" class="text-sm text-medium-grey">
        <span
          >{{ cartItem?.price?.referencePrice?.purchaseUnit }}
          {{ cartItem?.price?.referencePrice?.unitName }}&nbsp;</span
        >({{ getIntlFormattedPrice(cartItem?.price?.referencePrice?.price) }}&nbsp;/&nbsp;{{
          cartItem?.price?.referencePrice?.referenceUnit
        }}
        {{ cartItem?.price?.referencePrice?.unitName }})</span
      >
    </div>
    <div v-if="!childVariant" class="col-span-3 ml-auto flex flex-col items-end xl:col-span-1">
      <SharedPrice
        v-if="cartItem.price?.totalPrice >= 0 && !isExchangeBox"
        :class="{
          'hidden xl:block': childVariant,
          'font-medium': !childVariant,
          'text-signal-red': !childVariant && isListPrice,
        }"
        :value="cartItem.price?.totalPrice"
        class="product-total-price"
        has-asterisk
      />

      <SharedPrice
        v-if="!childVariant && isListPrice"
        :value="totalListPrice"
        class="text-base font-normal leading-snug line-through"
        has-asterisk
      />
      <div v-if="hasDiscount" class="text-md text-medium-grey">
        {{ `(-${discountPercentage}%)` }}
      </div>
    </div>
    <CheckoutCartItemExchangeBoxChildren
      v-if="isExchangeBox"
      :cart-item="cartItem"
      class="col-span-full col-start-5 row-start-2 grid auto-rows-min grid-cols-[2fr_1fr] text-sm xl:contents"
    ></CheckoutCartItemExchangeBoxChildren>

    <ul v-if="isEshopSet" class="col-span-full grid gap-2">
      <!--          NESTED PRODUCTS-->
      <li v-for="(child, index) in cartItem.children" :key="child.id">
        <CheckoutCartItem
          v-if="!isChildHidden(index)"
          :cart-item="child"
          :child-variant="true"
          :parent-pdp-url="pdpUrl"
        />
      </li>
    </ul>

    <div
      v-if="!childVariant"
      :class="{ 'invisible h-0': isExchangeBox || isSubscriptionProduct }"
      class="product-amount col-span-9 w-full text-sm xl:col-span-1 xl:col-start-3 xl:row-start-1"
    >
      <SelectField
        v-model="quantity"
        :disabled="maxQty > 1 ? null : true"
        :options="quantityOptions"
        :readonly="maxQty > 1 ? null : true"
        name="quantity"
      ></SelectField>
    </div>

    <div
      v-if="!childVariant"
      :class="[isSubscriptionProduct ? 'col-span-full' : 'col-span-3']"
      class="product-remove self-center justify-self-end xl:col-span-1 xl:col-start-5 xl:row-start-1 xl:self-start"
    >
      <button class="text-black" @click="removeCartItem">
        <SvgIcon
          :class="{ 'animate-pulse': isLoading }"
          class="text-link h-4 w-4 text-black"
          name="Remove-Item"
          type="button"
        >
        </SvgIcon>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { LineItem, PropertyGroupOptionCart } from "@shopware-pwa/types";
import SharedPrice from "~/templates/components/shop/shared/SharedPrice.vue";
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import SelectField from "~/templates/elements/form/SelectField.vue";
import useCommonLabels from "~/composables/useCommonLabels";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import CheckoutCartItemExchangeBoxChildren from "~/templates/components/shop/checkout/CheckoutCartItemExchangeBoxChildren.vue";
import { usePrice } from "~/composables/shop/usePrice";
import ProductImage from "~/templates/elements/ProductImage.vue";
import useShopwareNotifications from "~/composables/useShopwareNotifications";
import { useProductQuantityOptions } from "~/composables/shop/useProductBulkPrices";

const { getIntlFormattedPrice } = usePrice();
const props = defineProps<{
  cartItem: LineItem;
  columnNames?: boolean;
  childVariant?: boolean;
  parentPdpUrl?: string;
}>();
const isLoading = ref(false);

const { pushShopwareError } = useShopwareNotifications();
const { getLabels } = useCommonLabels();
const cartLabels = getLabels("BWF/shop/checkout/cart");
const sharedLabels = getLabels("BWF/shop/shared");

const { cartItem } = toRefs(props);
const { itemOptions, removeItem, itemQuantity, changeItemQuantity } = useCartItem(cartItem);

const quantity = ref(0);
syncRefs(itemQuantity, quantity);

const { quantityOptions, maxQty } = useProductQuantityOptions(cartItem.value);

const updateQuantity = async (updatedQuantity: number | undefined) => {
  if (updatedQuantity === itemQuantity.value) return;
  isLoading.value = true;
  try {
    await changeItemQuantity(Number(updatedQuantity));
    if (quantity.value !== itemQuantity.value) {
      quantity.value = Number(itemQuantity.value);
    }
  } catch (e) {
    await pushShopwareError(e);
  }
  isLoading.value = false;
};

const thumbnailUrl = computed(() => {
  if (cartItem.value.cover) {
    return cartItem?.value?.cover;
  } else {
    return cartItem.value?.children?.[0]?.cover;
  }
});

//@ts-ignore
const isEshopSet = computed(
  () => cartItem?.value?.extensions?.hasOwnProperty("eshopSetData") ?? false,
);
//@ts-ignore
const isExchangeBox = computed(
  () => cartItem?.value?.children?.[0]?.extensions?.hasOwnProperty("tauschbox") ?? false,
);
const isSubscriptionProduct = computed(
  //@ts-ignore
  () => cartItem?.value?.children?.[0]?.extensions?.hasOwnProperty("subscription") ?? false,
);

const isListPrice = computed(
  () =>
    cartItem.value?.children?.[0]?.priceDefinition?.listPrice || cartItem?.value?.price?.listPrice,
);
const hasDiscount = computed(() => {
  return cartItem.value?.price?.listPrice?.percentage;
});

const discountPercentage = computed(() => {
  return Math.round(cartItem.value?.price?.listPrice?.percentage || 0);
});

/*
 * checks wether a child is the first item AND should be hidden
 * OR
 * ist a hidden Item/ SKU
 * */
const isChildHidden = (index: number) => {
  const child = cartItem.value?.children?.[index];
  return !!(
    ((index === 0 &&
      (cartItem?.value?.extensions?.brita_eshop_set_hide_main_product_image?.value ?? false)) ||
      child?.extensions?.eshopSet?.eshopSetProductIsHidden) ??
    false
  );
};
// TODO: only temporary, rebuild if backend provides an extra property for this.
//@ts-ignore
const totalListPrice = computed(
  () =>
    cartItem?.value?.children?.[0]?.priceDefinition?.listPrice ??
    cartItem?.value?.price?.listPrice?.price * (cartItem?.value?.price?.quantity ?? 0),
);
const productNumber = computed(() => {
  if (isSubscriptionProduct.value) {
    //@ts-ignore
    return cartItem.value?.children?.[0]?.payload?.productNumber;
  } else {
    //@ts-ignore
    return cartItem.value?.payload?.productNumber;
  }
});

watch(quantity, () => updateQuantity(quantity.value));

const { trackItemRemoveFromCart } = useCheckoutTracking();
const removeCartItem = async () => {
  isLoading.value = true;
  // track before remove, otherwise item is gone in the next tick
  trackItemRemoveFromCart(cartItem);
  try {
    await removeItem();
  } catch (e) {
    await pushShopwareError(e);
  }
  isLoading.value = false;
};

const { getPdpUrlByProduct, getEShopSetUrlByProduct } = useMagnoliaShopPages();

const pdpUrl = computed(() => {
  let payload = {};
  let pathVariable = null;
  if (props.parentPdpUrl) {
    return toRaw(props.parentPdpUrl);
  } else if (isEshopSet.value) {
    // set url search payload
    return getEShopSetUrlByProduct(cartItem?.value);
    // @ts-ignore
  } else if (isSubscriptionProduct.value || isExchangeBox.value) {
    // set url search payload
    payload = cartItem?.value?.children[0]?.payload;
    // @ts-ignore
    pathVariable = payload?.productId || payload?.productNumber;
  }
  // @ts-ignore
  else if (cartItem.value?.payload?.parentId || cartItem.value?.payload?.referencedId) {
    // variant urls
    payload = cartItem?.value?.payload;
    pathVariable = cartItem.value?.id;
  } else {
    // no variant url
    payload = cartItem?.value;
  }
  return getPdpUrlByProduct(payload, pathVariable);
});
</script>

<style>
.cart-grid {
  @apply grid-cols-12 gap-4 xl:grid-cols-5 xl:gap-y-0.5;
  @screen xl {
    grid-template-columns: 3fr 5fr 1.2fr 2fr 1fr;
  }
}
</style>
