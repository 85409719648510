<template>
  <div>
    <EditableArea
      :class="
        contentToTailwindClasses({
          flexItems: horizontal,
        })
      "
      :content="main"
      class="mg:gap-8 flex flex-col gap-6"
    />
  </div>
</template>

<script>
import { editableComponent } from "~/utils/mixins/editableComponent";
import { EditableArea } from "@magnolia/vue-editor";
import { contentToTailwindClasses } from "~/utils/helper/tailwind";

export default {
  name: "EditorialStorySlideComponent",
  components: { EditableArea },
  mixins: [editableComponent],
  inject: ["horizontal"],
  props: ["main"],
  methods: { contentToTailwindClasses },
};
</script>
