<template>
  <div
    :class="{
      '-mt-4': !isEditMode,
    }"
    class="stage-story-component relative"
  >
    <div
      class="fixed-header-offset responsive-gap container relative grid w-full py-8 md:grid-cols-2 lg:py-16"
    >
      <div
        class="responsive-gap relative order-2 flex flex-col items-start justify-center border-b border-b-medium-grey/50 py-6 md:order-1 md:h-full md:pb-8 md:pt-0"
      >
        <div v-if="subline" class="text-sm font-medium text-black xl:text-lg">
          {{ subline }}
        </div>
        <HeadlineComponent
          v-if="headline"
          :headline="headline"
          class="text-3xl font-medium text-dark-blue md:text-4xl xl:text-6xl"
        ></HeadlineComponent>

        <div
          v-if="readingTime.trim()"
          class="flex items-center gap-2 rounded-full bg-light-grey p-2 text-sm"
        >
          <SvgIcon class="h-4 w-4" name="icon-clock"></SvgIcon>
          <span> {{ readingTime }} </span>
        </div>
      </div>

      <div
        :class="{ 'z-[1]': foregroundImage }"
        class="align-start relative order-1 aspect-3/2 items-end overflow-hidden rounded-xl md:order-2"
      >
        <PictureElement
          :breakpoints="BREAKPOINTS"
          :img="image"
          img-class="absolute inset-0 block object-cover w-full h-full"
          loading="eager"
        ></PictureElement>
      </div>
    </div>
    <DotPattern
      v-if="showDotPattern ?? true"
      ref="dots"
      :blue-pattern="true"
      :overflow-visible="true"
      class="pointer-events-none absolute inset-0 block h-full w-full"
    ></DotPattern>
  </div>
</template>

<script setup>
import { DIMENSIONS } from "~/utils/helper/imaging";
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import DotPattern from "~/templates/elements/DotPattern.vue";

const BREAKPOINTS = {
  start: DIMENSIONS["start"].ratio["3/2"],
  sm: DIMENSIONS["sm"].ratio["3/2"],
  md: DIMENSIONS["md"].width["1/2"].ratio["3/2"],
  xl: DIMENSIONS["xl"].width["1/2"].ratio["3/2"],
};
</script>
<script>
import HeadlineComponent from "./HeadlineComponent";
import PictureElement from "../../elements/PictureElement";
import { editableComponent } from "~/utils/mixins/editableComponent";

export default {
  name: "StageStoryComponent",
  components: { HeadlineComponent, PictureElement },
  mixins: [editableComponent],
  props: [
    "subline",
    "headline",
    "time",
    "commonLabel",
    "image",
    "link",
    "showDotPattern",
    "backgroundColor",
    "foregroundImage",
  ],
  data: () => ({
    dotsVisible: false,
  }),
  computed: {
    readingTime() {
      return `${this.time ?? ""} ${this.commonLabel?.value ?? ""}`;
    },
  },
};
</script>

<style scoped>
.fixed-header-offset {
  @apply transition-all duration-700;
  margin-top: var(--fixed-header-height);
}
</style>
