<template>
  <div
    ref="slide"
    :class="{
      'is-active': isActiveOrEditMode,
      'is-editmode': isEditMode,
    }"
    class="carousel-slide"
    @transitionend="onSlideTransitionEnd"
  >
    <div class="relative overflow-hidden">
      <div class="img-container relative aspect-[6/7] max-h-screen w-full md:aspect-video">
        <PictureElement
          :breakpoints="BREAKPOINTS"
          :class="{
            hidden: containsImage(imageMobile),
            'md:inline': containsImage(imageMobile),
          }"
          :img="image"
          :loading="isActive ? 'eager' : 'lazy'"
          img-class=" absolute inset-0  block object-cover w-full h-full"
        ></PictureElement>
        <PictureElement
          v-if="containsImage(imageMobile)"
          :breakpoints="BREAKPOINTS"
          :img="imageMobile"
          :loading="isActive ? 'eager' : 'lazy'"
          class="md:hidden"
          img-class=" absolute inset-0 block object-cover w-full h-full"
        ></PictureElement>
        <div
          :class="contentToTailwindClasses({ opacity: opacity })"
          class="invisible absolute inset-x-0 left-0 top-0 h-full w-8/12 bg-gradient-to-r from-black via-black to-transparent md:visible md:w-2/3"
        ></div>
        <DotPattern
          v-if="dotsVisible || isEditMode"
          ref="dots"
          :variant="dotPattern"
          class="absolute inset-0 block h-full w-full"
        >
        </DotPattern>
      </div>
    </div>
    <div class="bg-light-beige md:absolute md:inset-0 md:bg-transparent">
      <div
        class="text-container container flex flex-col items-center justify-center py-8 text-center md:h-full md:items-start md:pb-0 md:text-left"
      >
        <div class="mb-6 text-3xl xl:text-6xl">
          <HeadlineComponent
            :headline="headline"
            class="font-medium text-dark-blue md:text-white"
          ></HeadlineComponent>
          <HeadlineComponent :headline="text" class="text-black md:text-white"></HeadlineComponent>
        </div>
        <CallToActionComponent
          v-if="link?.label ?? false"
          :link="link"
          :stretch-link="stretchLink"
          class="text-sm lg:py-6 lg:text-base"
          @click="trackPromoClick"
        ></CallToActionComponent>
      </div>
    </div>
  </div>
</template>

<script setup>
import { DIMENSIONS } from "~/utils/helper/imaging";
import DotPattern from "~/templates/elements/DotPattern.vue";
import { containsImage } from "~/utils/helper/magnolia";
import { contentToTailwindClasses } from "~/utils/helper/tailwind";
import { isComponentPreview, isEditMode as evaluateEditMode } from "~/utils/content/magnolia";
import HeadlineComponent from "~/templates/components/cms/HeadlineComponent.vue";
import CallToActionComponent from "~/templates/components/cms/CallToActionComponent.vue";
import PictureElement from "~/templates/elements/PictureElement.vue";
import usePromoTracking from "~/composables/tracking/usePromoTracking";

const props = defineProps([
  "text",
  "headline",
  "image",
  "imageTablet",
  "imageMobile",
  "link",
  "isActive",
  "dotPattern",
  "opacity",
  "navText",
  "metadata",
  "stretchLink",
]);

const isEditMode = evaluateEditMode();
const dotsVisible = ref(false);
const slide = ref(null);
const isActive = toRef(() => props.isActive);

const { trackViewPromotionIfVisibleAndActive, trackPromoClick } = usePromoTracking({
  metadata: props.metadata,
  promoIndex: props.index,
  creativeName: props.navText,
});
const onSlideTransitionEnd = () => {
  dotsVisible.value = props.isActive;
};
const isActiveOrEditMode = computed(() => {
  return props.isActive || isEditMode || isComponentPreview();
});

const BREAKPOINTS = {
  start: DIMENSIONS["start"].ratio["6/7"],
  sm: DIMENSIONS["sm"].ratio["6/7"],
  md: DIMENSIONS["md"].ratio.video,
  xl: DIMENSIONS["xl"].ratio.video,
  container: DIMENSIONS["container"].ratio.video,
  page: DIMENSIONS["page"].ratio["video"],
};

onMounted(() => {
  trackViewPromotionIfVisibleAndActive(slide, isActive);
});
</script>

<style scoped>
.carousel-slide {
  @apply pointer-events-none opacity-0 transition-opacity duration-2000;
  @apply absolute inset-0;
}

.img-container {
  @apply scale-105 transition-all duration-2000;
}

.text-container {
  @apply transition-all duration-300 md:-translate-x-4;
  @apply opacity-0;

  @screen md {
    padding-top: var(--fixed-header-height, 0px);
  }
}

.carousel-slide.is-active,
.carousel-slide.is-editmode {
  @apply pointer-events-auto z-10 opacity-100;
  @apply relative;

  .img-container {
    @apply scale-100;
    @apply delay-300;
  }

  .text-container {
    @apply transition-all;
    @apply md:delay-300;
    @apply md:duration-700;
    @apply md:translate-x-0;
    @apply opacity-100;
  }
}
</style>
