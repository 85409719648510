<template>
  <div>
    <template v-if="hasEShopSet">
      <div v-if="hasBestPrice" class="flex items-end gap-2">
        <SharedPrice
          :value="eShopSet?.eshopSetPriceTotal"
          class="text-base font-medium text-signal-red"
        />
        <SharedPrice
          :value="eShopSet?.bestEshopPriceFromHistory"
          class="text-base font-normal text-medium-grey line-through"
        />
      </div>
      <SharedPrice
        v-else
        :value="eShopSet?.eshopSetPriceTotal || price?.totalPrice"
        class="text-left text-base font-medium"
      />
    </template>

    <ProductCardBulkPrice v-else-if="hasBulkPrice" />

    <template v-else>
      <div class="flex flex-wrap items-center gap-3 xl:flex-nowrap">
        <SharedPrice
          v-if="unitPrice"
          :class="{
            'text-signal-red': isListPrice,
          }"
          :value="unitPrice"
          class="text-left text-base font-medium"
        />
        <SharedPrice
          v-if="isListPrice"
          :value="price?.listPrice?.price"
          class="text-base font-normal leading-snug line-through"
        />
      </div>
      <div v-if="isListPrice" class="flex gap-1 text-sm font-normal leading-tight">
        <CommonLabel path="BWF/shop/productDetailPage/priceSaving" />
        <SharedPrice v-if="isListPrice" :value="price?.listPrice?.discount" />
        ({{ Math.abs(price?.listPrice?.percentage ?? 0) + "%" }})
      </div>
      <CommonLabel
        v-if="tauschbox"
        :placeholder="{ amountCylinders }"
        class="text-sm font-normal text-medium-grey"
        path="BWF/shop/exchangeBox/priceCardExchangeBoxHint"
      ></CommonLabel>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useProductEShopSetConfigurator } from "~/composables/shop/useProductEShopSetConfigurator";
import { useProductPrice } from "@shopware-pwa/composables-next";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import { useExchangeBox } from "~/composables/shop/useExchangeBoxProduct";
import ProductCardBulkPrice from "~/templates/components/shop/product/ProductCardBulkPrice.vue";

const { product } = useProduct();
const { eShopSet, hasEShopSet, hasBestPrice } = await useProductEShopSetConfigurator();
const { hasBulkPrice } = useProductBulkPrices(product);

const { price, unitPrice, isListPrice } = useProductPrice(product);
const { tauschbox, amountCylinders } = await useExchangeBox();
</script>
