<template>
  <AccountGrid>
    <div class="flex flex-col gap-4 xl:gap-12">
      <div class="flex w-full flex-col gap-4 xl:flex-row xl:items-center xl:justify-between">
        <h2 class="text-lg font-medium text-dark-blue xl:text-4xl">
          <CommonLabel path="BWF/shop/account/orderHistory/myOrders"></CommonLabel>
        </h2>
        <SearchField
          v-model="searchValue"
          class="xl:w-5/12"
          :placeholder="searchFieldPlaceholder"
        ></SearchField>
      </div>

      <div
        class="flex flex-col gap-4"
        :class="{ 'animate-pulse': isLoading && visibleOrders.length > 1 }"
      >
        <LoadingElement v-if="isLoading" class="aspect-3/2"></LoadingElement>
        <EditableArea
          v-else-if="visibleOrders.length === 0 || isEditMode()"
          :content="empty"
          :custom-view="EmptyArea"
        />
        <AccountOrder v-for="order in visibleOrders" v-else :key="order.id" :order="order" />
      </div>

      <div
        v-if="visibleOrders.length < total"
        class="mt-8 flex flex-col items-center gap-4"
        :class="{ 'animate-pulse': isLoading }"
      >
        <div class="flex">
          <span class="font-medium">{{ visibleOrders.length }}&nbsp;</span>
          <CommonLabel path="BWF/shop/account/orderHistory/from"></CommonLabel>
          <span class="font-medium">&nbsp;{{ total }}&nbsp;</span>
          <CommonLabel path="BWF/shop/account/orderHistory/orders"></CommonLabel>
        </div>
        <button class="btn btn-blue" @click="page++">
          <CommonLabel path="BWF/shop/account/orderHistory/showMoreOrders"></CommonLabel>
        </button>
      </div>
    </div>
  </AccountGrid>
</template>

<script setup lang="ts">
import { EditableArea } from "@magnolia/vue-editor";
import AccountGrid from "~/templates/components/account/AccountGrid.vue";
import SearchField from "~/templates/elements/form/SearchField.vue";
import { type Ref, watch } from "vue";
import { useDebounceFn } from "@vueuse/core";
import type { Order } from "@shopware-pwa/types";
import LoadingElement from "~/templates/elements/LoadingElement.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import { isEditMode } from "~/utils/content/magnolia";

defineOptions({
  inheritAttrs: false,
});

const { getLabelByPath } = useCommonLabels();

const searchFieldPlaceholder = getLabelByPath(
  "BWF/shop/account/orderHistory/searchAllOrdersPlaceholder",
);

const { orders, total, loadOrders } = useCustomerOrders();
defineProps(["title", "description", "metadata", "empty"]);

const LIMIT = 10;
const visibleOrders: Ref<Order[]> = ref([]);
const searchValue = ref("");
const page = ref(1);
const isLoading = ref(true);

const searchOrders = useDebounceFn(async () => {
  // do something
  const parameter: never = {
    limit: LIMIT,
    associations: {
      lineItems: { associations: { cover: {}, children: { associations: { cover: {} } } } },
      transactions: {
        sort: [
          {
            field: "createdAt",
            order: "DESC",
          },
        ],
        associations: {
          paymentMethod: {},
        },
      },
      deliveries: { associations: { shippingMethod: {} } },
    },
    "total-count-mode": 1,
  };
  if (searchValue.value) {
    parameter.filter = [
      {
        type: "multi",
        operator: "or",
        queries: [
          {
            type: "contains",
            field: "orderNumber",
            value: searchValue.value,
          },
          {
            type: "contains",
            field: "lineItems.label",
            value: searchValue.value,
          },
        ],
      },
    ];
  }
  parameter.page = page.value;

  // the sw interface does not type the method correct
  try {
    // @ts-ignore
    await loadOrders(parameter);
  } catch (e) {
    console.error(e);
  }
  isLoading.value = false;
}, 300);

watch(searchValue, () => {
  isLoading.value = true;
  page.value = 1;
  searchOrders();
});

watch(page, () => {
  isLoading.value = true;
  searchOrders();
});

watch(orders, () => {
  visibleOrders.value = [...(page.value > 1 ? visibleOrders.value : []), ...orders.value];
  // full page assume there is more
});

const { isLoggedIn } = useUser();

onMounted(() => {
  watch(
    isLoggedIn,
    () => {
      isLoading.value = true;
      if (isLoggedIn.value) {
        searchOrders();
      }
    },
    { immediate: isLoggedIn.value },
  );
});
</script>
