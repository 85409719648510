<template>
  <div
    :class="[contentToTailwindClasses({ backgroundColor: content?.backgroundColor })]"
    class="newsletter-registration-component relative overflow-hidden rounded-xl"
  >
    <DotPattern v-if="content.dotPattern" :variant="content.dotPattern"></DotPattern>
    <div
      class="relative grid gap-6 px-4 py-8 md:mx-auto md:w-10/12 md:gap-y-8 md:px-0 md:py-12 xl:grid-cols-5"
    >
      <div ref="contentGroup" class="content-group col-span-full flex flex-col gap-6">
        <TransitionGroup name="content" @enter="onEnter">
          <div v-if="!successStep || isEditMode()" class="editmode-info-box flex flex-col">
            <div v-if="isEditMode()" class="editmode-text">[EDITOR VIEW] Registration Content:</div>
            <NewsletterRegistrationComponentContent
              :headline="content.registrationStepHeadline"
              :subline="content.registrationStepsubline"
              :benefits="content.benefits"
            />
          </div>

          <div
            v-if="isEditMode() || (successStep && !doiAlreadyRegistered)"
            class="editmode-info-box flex flex-col"
          >
            <p v-if="isEditMode()" class="editmode-text">
              [EDITOR VIEW] Success Step Registration Content:
            </p>
            <NewsletterRegistrationComponentContent
              :headline="content.successStepHeadline"
              :subline="content.successStepSubline"
              :benefits="content.benefits"
            />
          </div>

          <div
            v-if="isEditMode() || (doiAlreadyRegistered && successStep)"
            class="editmode-info-box flex flex-col"
          >
            <p v-if="isEditMode()" class="editmode-text">
              [EDITOR VIEW] Already Registered Content:
            </p>

            <NewsletterRegistrationComponentContent
              :headline="content.informationHeadline"
              :subline="content.informationSubline"
              :benefits="content.informationSteps"
            >
              <p>
                <CommonLabel
                  path="BWF/shop/product/registration/newsletterNoResponseNotice"
                />&nbsp;
                <button class="font-medium underline" @click="submitNewsletterRegistration()">
                  <CommonLabel path="BWF/shop/product/registration/resendConfirmationMessageLink" />
                </button>
              </p>
            </NewsletterRegistrationComponentContent>
          </div>

          <CheckboxField
            v-if="!doiAlreadyRegistered"
            v-model="consentCheck"
            class="text-sm"
            name="consent"
          >
            <CommonLabel path="/BWF/components/newsletter/newsletterConsent" />
          </CheckboxField>
          <button
            v-if="(successStep && !doiAlreadyRegistered) || isEditMode()"
            :disabled="!consentCheck"
            class="btn btn-blue self-start"
            @click="submitNewsletterRegistration()"
          >
            <CommonLabel path="/BWF/components/newsletter/submit" />
          </button>
        </TransitionGroup>
        <slot />
      </div>
    </div>

    <Transition mode="out-in" name="loading">
      <LoadingElement v-if="isLoading" class="bg-white/70" overlay="true"></LoadingElement>
    </Transition>
  </div>
</template>

<script setup>
import { contentToTailwindClasses } from "~/utils/helper/tailwind";
import DotPattern from "~/templates/elements/DotPattern.vue";
import { isEditMode } from "~/utils/content/magnolia";
import useBritaMiddleware from "~/composables/useBritaMiddleware";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import CheckboxField from "~/templates/elements/form/CheckboxField.vue";
import LoadingElement from "~/templates/elements/LoadingElement.vue";
import NewsletterRegistrationComponentContent from "~/templates/components/cms/NewsletterRegistrationComponentContent.vue";
const { getLabelByPath } = useCommonLabels();

const { register, getDoiState } = useBritaMiddleware();
const technicalErrorMessage = getLabelByPath("/BWF/newsletter/doi/technicalErrorMessage");

const { pushError } = useNotifications();
const props = defineProps(["content", "email", "uiState", "modelValue"]);
const contentMinHeight = ref("0px");
const successStep = computed(() => {
  return props.uiState === "success-step";
});
const contentGroup = ref();
const consentCheck = ref(props.modelValue ?? false);
const isLoading = ref(false);
const doiAlreadyRegistered = ref(false);

const emit = defineEmits(["update:modelValue"]);

watch(consentCheck, () => {
  emit("update:modelValue", consentCheck.value);
});

const submitNewsletterRegistration = useDebounceFn(
  async () => {
    isLoading.value = true;
    const doiEmail = props.email;
    try {
      // check if email is already registered
      const data = await getDoiState(doiEmail);
      if (data?.donotbulkemail === false) {
        // user has already opted in
        doiAlreadyRegistered.value = true;
      } else {
        await register(doiEmail, props.content?.interactionId, props.content?.actionId);
        doiAlreadyRegistered.value = true;
      }
    } catch (e) {
      pushError(technicalErrorMessage);
      console.error("doi registration failed!", e);
    }
    isLoading.value = false;
  },
  300,
  { maxWait: 4000 },
);

defineExpose({ submitNewsletterRegistration });

onMounted(() => {
  contentMinHeight.value = `${contentGroup?.value?.offsetHeight}px`;
});
const onEnter = async (el) => {
  contentMinHeight.value = `${el.offsetHeight}px`;
};
</script>

<style scoped>
.content-group {
  position: relative;
  @apply relative transition-all duration-1000;
  min-height: v-bind(contentMinHeight);
}
</style>

<style>
.newsletter-registration-component {
  /* TODO why is this a different than elsewhere*/

  .checkbox-border {
    @apply rounded-none border-none border-transparent;
  }

  .checkbox-border,
  .has-error {
    input {
      @apply rounded-sm bg-white;
    }
  }

  .editmode-text {
    @apply mb-4 border-b-2 text-xl font-bold text-green;
  }

  .content-enter-active,
  .content-leave-active {
    @apply transition-all duration-700;
  }

  .content-enter-from,
  .content-leave-to {
    position: absolute;
    opacity: 0;
    transform: translateX(30px);
  }

  .loading-enter-active,
  .loading-leave-active {
    @apply transition-opacity duration-500;
  }

  .loading-enter-from,
  .loading-leave-to {
    opacity: 0;
  }
}
</style>
