import { defineNuxtPlugin, useRuntimeConfig } from "#imports";
import type { CreateLocaleInstanceArgs, MollieLocale, MolliePlugin } from "~/plugins/mollie.types";

export default defineNuxtPlugin({
  name: "mollie-instance",
  enforce: "pre",

  async setup(nuxtApp) {
    const runtimeConfig = useRuntimeConfig();

    const mollieOptions = runtimeConfig?.public?.mollie;

    async function loadMollie() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        if (globalThis.Mollie) {
          resolve(true);
          return;
        }

        const src = "https://js.mollie.com/v1/mollie.js";
        const script = document.createElement("script");
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          reject(false);
        };
        script.src = src;
        document.body.appendChild(script);
      });
    }

    function createLocaleInstance({
      profileId = mollieOptions.profileId,
      testMode = mollieOptions.testMode,
      locale = mollieOptions.defaultLocale as MollieLocale,
    }: CreateLocaleInstanceArgs) {
      return window.Mollie(profileId, {
        locale: locale,
        testmode: testMode,
      });
    }

    const Mollie: MolliePlugin = {
      mollieInstance: null,
      createMollieInstance: function (args: CreateLocaleInstanceArgs) {
        this.mollieInstance = this.mollieInstance || createLocaleInstance(args);
        return this.mollieInstance;
      },
      loadMollie,
    };

    nuxtApp.provide("mollie", Mollie);
  },
});
