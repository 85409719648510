import { computed, ref, onMounted, inject, provide } from "vue";
import { useShopwareContext } from "#imports";

import type { UseCountriesReturn } from "@shopware-pwa/composables-next";
import type { Country } from "@shopware-pwa/types";

/**
 * Composable to manage countries
 * @public
 * @category Context & Language
 */
export function useCountries(): UseCountriesReturn {
  const { apiInstance } = useShopwareContext();

  const _sharedCountries = inject("swCountries", ref());
  provide("swCountries", _sharedCountries);

  async function fetchCountries() {
    const result = await apiInstance.invoke.post(`/store-api/country`, {
      associations: {
        states: {},
      },
      sort: [
        {
          field: "position",
          order: "asc",
          naturalSorting: true,
        },
      ],
    });

    _sharedCountries.value = result.data.elements;
  }

  const getCountries = computed(() => {
    return _sharedCountries.value ?? [];
  });

  const mountedCallback = async () => {
    if (!_sharedCountries.value) {
      await fetchCountries();
    }
  };

  const getStatesForCountry = (countryId: string) => {
    return (
      getCountries.value.find((element: Country) => {
        return element.id === countryId;
      })?.states || null
    );
  };

  onMounted(mountedCallback);

  return {
    mountedCallback,
    fetchCountries,
    getStatesForCountry,
    getCountries,
  };
}
