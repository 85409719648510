<template>
  <section
    class="account-newsletter-box border-grey relative flex flex-col rounded-lg border px-4 py-6 xl:px-6"
  >
    <HeadlineComponent :text="content?.registrationHeadline" class="text-lg font-medium" tag="h3" />

    <p class="mt-4">{{ content?.newsletterSubline }}</p>

    <form class="mt-4" @submit.prevent="onSubmit()">
      <TransitionGroup name="content">
        <div v-if="(!isAlreadyRegistered && !isPending) || isEditMode()" class="editmode-info-box">
          <p v-if="isEditMode()" class="editmode-text">[EDITOR VIEW] Registration Content:</p>
          <CheckboxField
            v-model="newsletterFormState.consent"
            :error-message="newsletterVuelidate?.consent?.$errors[0]?.$message"
            class="font-medium"
            name="consent"
            @change="newsletterVuelidate?.consent?.$touch()"
          >
            <CommonLabel path="/BWF/components/newsletter/newsletterConsent"></CommonLabel>
          </CheckboxField>

          <button class="btn btn-blue btn-size-sm xl:btn-size-normal mt-6 md:self-start">
            <CommonLabel
              path="BWF/shop/account/overview/newsletterRegistrationButtonLabel"
            ></CommonLabel>
          </button>
        </div>

        <div v-if="isEditMode() || isPending" class="editmode-info-box relative items-center">
          <p v-if="isEditMode()" class="editmode-text">[EDITOR VIEW] confirmation Content:</p>
          <div class="notification is-blue gap-x-4">
            <SvgIcon
              class="notification-icon flex-shrink-0 text-black"
              name="Checkmark-Circle"
            ></SvgIcon>
            <div>
              <p class="flex-shrink-0 hyphens-auto text-base">
                {{ content?.confirmationText ?? "edit confirmation text..." }}
                <button class="font-medium underline">
                  <span>{{ content?.resendEmail ?? "Erneut senden *" }}</span>
                </button>
              </p>
            </div>
          </div>
        </div>
        <div v-if="isEditMode() || isAlreadyRegistered" class="editmode-info-box flex flex-col">
          <p v-if="isEditMode()" class="editmode-text">[EDITOR VIEW] Already registered content:</p>
          <span>{{ content?.informationHeadline }}</span>

          <NuxtLink
            v-if="marketSetupLinks?.newsletterUnsubscribePage"
            :to="unsubscribeLink"
            class="btn btn-blue btn-size-sm xl:btn-size-normal mt-6 place-self-start md:self-start"
          >
            <CommonLabel
              path="BWF/shop/account/overview/newsletterUnsubscribeButtonLabel"
            ></CommonLabel>
          </NuxtLink>
        </div>
      </TransitionGroup>
    </form>
    <Transition class="absolute" mode="out-in" name="loading">
      <LoadingElement v-if="isLoading" class="bg-white/70" overlay="true"></LoadingElement>
    </Transition>
  </section>
</template>
<script setup lang="ts">
import CommonLabel from "~/templates/components/CommonLabel.vue";
import CheckboxField from "~/templates/elements/form/CheckboxField.vue";
import HeadlineComponent from "~/templates/components/cms/HeadlineComponent.vue";
import useBritaMiddleware from "~/composables/useBritaMiddleware";
import { isEditMode } from "~/utils/content/magnolia";
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import LoadingElement from "~/templates/elements/LoadingElement.vue";

import { useVuelidate } from "@vuelidate/core";

const { marketSetupLinks } = useMarketSetup();

const { user, isLoggedIn } = useUser();
const { register, getDoiState } = useBritaMiddleware();
const { getLabelByPath } = useCommonLabels();
const { pushError } = useNotifications();
const technicalErrorMessage = getLabelByPath("/BWF/newsletter/doi/technicalErrorMessage");
const isAlreadyRegistered = ref(false);
const isPending = ref(false);
const isLoading = ref(false);

const props = defineProps(["content"]);

onMounted(async () => {
  if (isLoggedIn && !isEditMode()) {
    isLoading.value = true;
    try {
      const data: never = await getDoiState(user?.value?.email);

      if (data?.mailDoiState === "PENDING") {
        isPending.value = true;
      }
      if (data?.donotbulkemail === false) {
        // user has already opted in
        isAlreadyRegistered.value = true;
      }
    } catch (e) {
      console.error(e);
    }
    isLoading.value = false;
  }
});

const { consentValidators } = await useValidation();

const newsletterFormState = reactive({
  consent: null,
});

const rules = computed(() => ({
  consent: {
    ...consentValidators,
  },
}));

const unsubscribeLink = computed(() => {
  const token = btoa(user?.value?.email ?? "");
  return `${marketSetupLinks?.value?.newsletterUnsubscribePage}?token=${token}&source=mybrita`;
});

const newsletterVuelidate = useVuelidate(rules, newsletterFormState);

const onSubmit = async () => {
  isLoading.value = true;
  newsletterVuelidate.value.$touch();
  const valid = await newsletterVuelidate.value.$validate();
  if (valid || isPending.value) {
    try {
      await register(user?.value?.email, props?.content?.interactionId, props?.content?.actionId);
      // email is sent user is registered but pending
      isPending.value = true;
    } catch (e) {
      pushError(technicalErrorMessage);
    }
  }

  isLoading.value = false;
};
</script>

<style>
.account-newsletter-box {
  .editmode-text {
    @apply mb-4 border-b-2 text-xl font-bold text-green;
  }

  .content-enter-active,
  .content-leave-active {
    @apply transition-all duration-700;
  }
  .content-enter-from,
  .content-leave-to {
    position: absolute;
    opacity: 0;
    transform: translateX(30px);
  }

  .loading-enter-active,
  .loading-leave-active {
    @apply transition-opacity duration-500;
  }
  .loading-enter-from,
  .loading-leave-to {
    opacity: 0;
  }
}
</style>
