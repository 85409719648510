<template>
  <button
    :aria-label="`option-${option.name}`"
    :class="[
      {
        'border-2 border-dark-blue': isOptionSelected,
        'border-beige/50': !isOptionSelected,
        'btn-size-xxs md:btn-size-xs': !optionButtonDefault,
        'text-medium-grey opacity-50': isOptionNotAvailable || isOptionNotCombinable,
      },
    ]"
    class="btn btn-ghost min-w-content relative flex-1 overflow-hidden"
  >
    <span :id="`${option.id}-choice-label`" data-testid="product-variant-text">
      {{ option.name }}
    </span>
  </button>
</template>

<script setup lang="ts">
const props = defineProps(["option", "optionButtonDefault"]);

const { product } = useProduct();
const isOptionNotCombinable = computed((): boolean => {
  // @ts-ignore
  return props.option?.extensions?.combinable?.combinable === false;
});

const isOptionNotAvailable = computed((): boolean => {
  return !product.value.available && isOptionSelected.value;
});

const isOptionSelected = computed(() => product.value?.optionIds?.includes(props.option.id));
</script>
