<template>
  <div v-if="order" class="flex flex-col gap-2 xl:gap-4">
    <div class="flex flex-row gap-2 xl:gap-4">
      <CheckboxField v-if="selectable" class="invisible"></CheckboxField>
      <div class="xl:order-grid hidden w-full text-sm font-medium">
        <div class="col-span-2 xl:mb-2">
          <CommonLabel path="BWF/shop/account/orderHistory/productLabel"></CommonLabel>
        </div>

        <div class="xl:text-center">
          <CommonLabel path="BWF/shop/shared/tableQuantity"></CommonLabel>
        </div>

        <div class="text-dark justify-self-end">
          <CommonLabel path="BWF/shop/account/orderHistory/total"></CommonLabel>
        </div>
        <hr class="col-span-full hidden xl:block" />
      </div>
    </div>

    <template
      v-for="lineItem in showReturns ? returnedLineItems : lineItems"
      :key="lineItem.identifier"
    >
      <div class="flex flex-row gap-2 xl:gap-4">
        <CheckboxField
          v-if="orderConfiguration[lineItem.id]"
          ref="checkboxes"
          v-model="orderConfiguration[lineItem.id].selected"
          :name="lineItem.id"
          :value="lineItem.id"
          class="w-full"
        >
          <OrderLineItem
            :is-configured="true"
            :line-item="lineItem"
            :quantity-change="true"
            class="w-full xl:ml-4"
          />
        </CheckboxField>
        <OrderLineItem
          v-else
          :line-item="lineItem"
          :return-item="showReturns ? getReturnItemById(lineItem.id) : null"
          class="w-full"
        />
      </div>
      <hr class="mb-2 mt-6 last:hidden xl:my-0" />
    </template>
  </div>
</template>
<script setup lang="ts">
import CommonLabel from "~/templates/components/CommonLabel.vue";
import type { Order } from "@shopware-pwa/types";
import OrderLineItem from "~/templates/components/shop/account/OrderLineItem.vue";
import CheckboxField from "~/templates/elements/form/CheckboxField.vue";
import { useOrderSelection } from "~/composables/shop/useOrderSelection";
import { getCreditLineItems, getLineItemsWithoutCredit } from "~/utils/helper/shop/order";

const props = defineProps<{
  order?: Order;
  selectable?: boolean;
  showReturns?: boolean;
}>();

const checkboxes = ref<HTMLInputElement[]>([]);

const getReturnItemById = (itemId: string) => {
  return returnedItems.value.find((item) => item.orderLineItemId === itemId);
};

const lineItems = computed(() => {
  return getLineItemsWithoutCredit(props.order?.lineItems);
});

const creditLineItems = computed(() => {
  return getCreditLineItems(props.order?.lineItems);
});

const returnedItems = computed(() => {
  // @ts-ignore flattens all returned lineitems
  return props.order?.extensions?.returns?.flatMap((item) => item.lineItems) ?? [];
});

const returnedLineItems = computed(() => {
  if (creditLineItems.value.length > 0) {
    return creditLineItems.value;
  }

  return lineItems?.value?.filter((item) =>
    returnedItems.value.find((returnItem) => returnItem.orderLineItemId === item.id),
  );
});

const { orderConfiguration } = useOrderSelection();
</script>
