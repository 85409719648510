import { screens } from "../../tailwind.config.screens";

const sm = parseInt(screens.sm);
const md = parseInt(screens.md);
const xl = parseInt(screens.xl);
const container = parseInt(screens.container);
const page = parseInt(screens.page);
const utlrawide = 3000;

const getScale = (width, height = 0) => ({
  1.5: { width: parseInt(width * 1.5), height: parseInt(height * 1.5) },
});

const getRatios = (base) => ({
  original: { width: parseInt(base), scale: getScale(parseInt(base)) },
  square: {
    width: parseInt(base),
    height: parseInt(base),
    scale: getScale(parseInt(base), parseInt(base)),
  },
  video: {
    width: parseInt(base),
    height: parseInt(base / (16 / 9)),
    scale: getScale(parseInt(base), parseInt(base / (16 / 9))),
  },
  "3/4": {
    width: parseInt(base),
    height: parseInt(base / (3 / 4)),
    scale: getScale(parseInt(base), parseInt(base / (3 / 4))),
  },
  "4/3": {
    width: parseInt(base),
    height: parseInt(base / (4 / 3)),
    scale: getScale(parseInt(base), parseInt(base / (4 / 3))),
  },
  "3/2": {
    width: parseInt(base),
    height: parseInt(base / (3 / 2)),
    scale: getScale(parseInt(base), parseInt(base / (3 / 2))),
  },
  "2/1": {
    width: parseInt(base),
    height: parseInt(base / (2 / 1)),
    scale: getScale(parseInt(base), parseInt(base / (2 / 1))),
  },
  "6/7": {
    width: parseInt(base),
    height: parseInt(base / (6 / 7)),
    scale: getScale(parseInt(base), parseInt(base / (6 / 7))),
  },
  "7/4": {
    width: parseInt(base),
    height: parseInt(base / (7 / 4)),
    scale: getScale(parseInt(base), parseInt(base / (7 / 4))),
  },
  "1/2": {
    width: parseInt(base),
    height: parseInt(base / (1 / 2)),
    scale: getScale(parseInt(base), parseInt(base / (1 / 2))),
  },
  "2/3": {
    width: parseInt(base),
    height: parseInt(base / (2 / 3)),
    scale: getScale(parseInt(base), parseInt(base / (2 / 3))),
  },
});

export const BREAKPOINTS = { start: 0, sm, md, xl, container, page };

export const DIMENSIONS_ORIGINAL = {
  width: 0,
  height: 0,
};

export const DIMENSIONS_FIXED = {
  "200x200": {
    width: 200,
    height: 200,
  },
  "1200x630": {
    width: 1200,
    height: 630,
  },
};

export const DIMENSIONS_START = {
  ratio: getRatios(sm),
  width: {
    "1/2": { ratio: getRatios(sm / 2) },
    "10/12": { ratio: getRatios(sm * (10 / 12)) },
  },
};

export const DIMENSIONS_SM = {
  ratio: getRatios(md),
  width: {
    "1/2": { ratio: getRatios(md / 2) },
    "10/12": { ratio: getRatios(md * (10 / 12)) },
  },
};

export const DIMENSIONS_MD = {
  ratio: getRatios(xl),
  width: {
    "1/2": { ratio: getRatios(xl / 2) },
    "1/3": { ratio: getRatios(xl / 3) },
    "1/4": { ratio: getRatios(xl / 4) },
    "1/6": { ratio: getRatios(xl / 4) },
  },
};

export const DIMENSIONS_XL = {
  ratio: getRatios(container),
  width: {
    "1/2": { ratio: getRatios(container / 2) },
    "1/3": { ratio: getRatios(container / 3) },
    "1/4": { ratio: getRatios(container / 4) },
    "1/6": { ratio: getRatios(container / 4) },
  },
};
export const DIMENSIONS_CONTAINER = {
  ratio: getRatios(page),
  width: {
    "1/2": { ratio: getRatios(page / 2) },
    "1/3": { ratio: getRatios(page / 3) },
    "1/4": { ratio: getRatios(page / 4) },
    "1/6": { ratio: getRatios(page / 4) },
  },
};

export const DIMENSIONS_PAGE = {
  ratio: getRatios(utlrawide),
  width: {
    "1/2": { ratio: getRatios(utlrawide / 2) },
    "1/3": { ratio: getRatios(utlrawide / 3) },
    "1/4": { ratio: getRatios(utlrawide / 4) },
  },
};

export const DIMENSIONS = {
  original: DIMENSIONS_ORIGINAL,
  fixed: DIMENSIONS_FIXED,
  start: DIMENSIONS_START,
  sm: DIMENSIONS_SM,
  md: DIMENSIONS_MD,
  xl: DIMENSIONS_XL,
  container: DIMENSIONS_CONTAINER,
  page: DIMENSIONS_PAGE,
};

export const contentToRatio = (contentRatio) => {
  switch (contentRatio) {
    case "square":
      return "square";
    case "video":
      return "video";
    case "postcard":
      return "3/2";
    case "tv":
      return "4/3";
    case "original":
      return "original";
  }
};

export const columnToWidth = (columnCount) => {
  return `1/${columnCount}`;
};
