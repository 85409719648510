import useMagnoliaRootNode from "~/composables/useMagnoliaRootNode";

const SITE_NAME_SUFFIX = "_site";
const useMagnoliaSite = function () {
  let rootNode = useMagnoliaRootNode();
  rootNode = rootNode.replace("/", "");
  const mgnlSiteName = `${rootNode}${SITE_NAME_SUFFIX}`;
  return {
    mgnlSiteName,
    mgnlSiteHeaders: { "MGNL-Site": mgnlSiteName },
  };
};

export default useMagnoliaSite;
