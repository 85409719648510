<template>
  <div>
    <p class="text-base font-medium leading-snug">{{ eshopSetConfigurator?.title }}</p>
    <div class="mt-4 grid gap-y-10 xl:mt-6">
      <div
        v-for="(eshopSetConfiguratorStep, stepIndex) in sortedEshopSetConfiguratorSteps"
        :key="stepIndex"
      >
        <p class="text-base font-medium leading-snug">
          {{ eshopSetConfiguratorStep?.title
          }}<span
            v-if="
              transformedViewTitle(
                eshopSetConfiguratorStep?.eshopSetConfiguratorView?.title ?? '',
              ) === 'product-color'
            "
            class="font-normal"
            >:&nbsp;{{
              eshopSetConfiguratorStep?.eshopSetConfiguratorStepProducts
                .find((product) => product.selected)
                ?.product.options?.find((option) => option.group.displayType === "color")
                ?.translated.name
            }}</span
          >
        </p>
        <div
          v-if="
            transformedViewTitle(
              eshopSetConfiguratorStep?.eshopSetConfiguratorView?.title ?? '',
            ) === 'product-color'
          "
          class="mt-4 flex flex-row gap-6"
        >
          <ProductEshopsetColorOption
            v-for="(
              eshopSetConfiguratorStepProduct, stepProductIndex
            ) in eshopSetConfiguratorStep?.eshopSetConfiguratorStepProducts"
            :key="stepProductIndex"
            :disabled="!eshopSetConfiguratorStepProduct?.product?.available"
            :step-product="eshopSetConfiguratorStepProduct"
            @click="handleChange(stepIndex, eshopSetConfiguratorStepProduct?.id ?? '')"
          />
        </div>

        <div
          v-else
          :class="[
            transformedViewTitle(
              eshopSetConfiguratorStep?.eshopSetConfiguratorView?.title ?? '',
            ) === 'product-simple'
              ? 'grid auto-cols-auto auto-rows-fr gap-2 md:grid-cols-2 xl:gap-6'
              : 'grid gap-4',
          ]"
          class="mt-4 grid-cols-1"
        >
          <button
            v-for="(
              eshopSetConfiguratorStepProduct, stepProductIndex
            ) in eshopSetConfiguratorStep?.eshopSetConfiguratorStepProducts"
            :key="stepProductIndex"
            :class="{
              'border-dark-blue outline outline-1 outline-dark-blue':
                eshopSetConfiguratorStepProduct?.selected,
              'opacity-50': !eshopSetConfiguratorStepProduct?.product?.available,
            }"
            :disabled="!eshopSetConfiguratorStepProduct?.product?.available"
            class="relative rounded-md border text-left"
            @click="handleChange(stepIndex, eshopSetConfiguratorStepProduct?.id ?? '')"
          >
            <ProductSimpleOption
              v-if="
                transformedViewTitle(
                  eshopSetConfiguratorStep?.eshopSetConfiguratorView?.title ?? '',
                ) === 'product-simple'
              "
              :step-product="eshopSetConfiguratorStepProduct"
            />
            <ProductFullOption
              v-else-if="
                transformedViewTitle(
                  eshopSetConfiguratorStep?.eshopSetConfiguratorView?.title ?? '',
                ) === 'product-full'
              "
              :step-product="eshopSetConfiguratorStepProduct"
            />
            <ProductDescriptionOption v-else :step-product="eshopSetConfiguratorStepProduct" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useProductEShopSetConfigurator } from "~/composables/shop/useProductEShopSetConfigurator";
import ProductSimpleOption from "./ProductSimpleOption.vue";
import ProductFullOption from "~/templates/components/shop/product/EShopSets/ProductFullOption.vue";
import ProductDescriptionOption from "~/templates/components/shop/product/EShopSets/ProductDescriptionOption.vue";
import ProductEshopsetColorOption from "~/templates/components/shop/product/EShopSets/ProductEshopsetColorOption.vue";

const {
  eshopSetConfigurator,
  eshopSetConfiguratorSteps,
  updateEShopSet,
  handleChange,
  hasEShopSet,
  eShopSet,
} = await useProductEShopSetConfigurator();
if (hasEShopSet && !eShopSet.value?.eshopSetConfiguratorId) {
  // seems to be empty -> init load
  await updateEShopSet();
}

const transformedViewTitle = (title: string) => title?.toLowerCase().replaceAll(" ", "-") ?? "";
const sortedEshopSetConfiguratorSteps = computed(
  () => eshopSetConfiguratorSteps.value?.sort((a, b) => a.position - b.position) ?? [],
);
</script>
