<template>
  <div>
    <div
      class="reset-container-padding mt-10 rounded-2xl bg-light-grey p-4 pb-8 pt-6 lg:mx-0 lg:p-6"
    >
      <div class="mb-10 lg:w-3/4">
        <RadioField v-model="subscriptionChooserActive" :value="false">
          <div class="flex flex-row text-base font-medium">
            <CommonLabel
              path="BWF/shop/productDetailPage/subscriptionProduct/singlePurchaseLabel"
            ></CommonLabel
            >&nbsp;<SharedPrice :value="singlePurchasePrice" class="ml-auto" />
          </div>
        </RadioField>

        <RevealComponent
          v-if="configurator?.length > 0 || hasBulkPrice"
          :open="!subscriptionChooserActive"
        >
          <div v-if="hasBulkPrice" class="space-y-2 pt-8">
            <SelectField
              v-model="productQuantity"
              :label="quantityLabel"
              :options="bulkChooserOptions"
            ></SelectField>
          </div>

          <div v-else-if="configurator?.length > 0" class="space-y-4 pt-8">
            <CommonLabel
              class="text-base font-medium"
              path="BWF/shop/productDetailPage/subscriptionProduct/packSizeLabel"
            ></CommonLabel>
            <ProductVariantConfigurator
              :option-button-default="optionButtonDefault"
              :show-option-name="false"
              @change="subscriptionChange"
              @before-change="$emit('beforeSubscriptionChange')"
            />
          </div>
        </RevealComponent>
      </div>

      <div class="relative rounded-2xl bg-white p-8 pt-7">
        <div
          v-if="activeSubscriptionOption?.subscriptionPrice?.displayDiscountRate"
          class="badge is-highlight-green lg:is-large absolute right-8 top-0 shrink-0 -translate-y-1/2"
        >
          <CommonLabel
            path="BWF/shop/productDetailPage/subscriptionProduct/savingsBadgeLabel"
          /><span>{{
            ` ${activeSubscriptionOption?.subscriptionPrice?.displayDiscountRate}%`
          }}</span>
        </div>

        <div class="mb-8">
          <CommonLabel
            class="text-base font-medium"
            path="BWF/shop/productDetailPage/subscriptionProduct/recommendationLabel"
          ></CommonLabel>
        </div>

        <RadioField v-model="subscriptionChooserActive" :value="true" class="mb-6">
          <div class="flex flex-row text-base font-medium">
            <CommonLabel
              path="BWF/shop/productDetailPage/subscriptionProduct/subscriptionLabel"
            ></CommonLabel>
            <SharedPrice
              v-if="hasSubscriptionListPrice"
              :value="subscriptionListPrice"
              class="ml-auto font-medium text-medium-grey line-through"
            />&nbsp;<SharedPrice
              :class="{ 'ml-auto': !hasSubscriptionListPrice }"
              :value="subscriptionTotalPrice"
              class="text-signal-red"
            />
          </div>
          <RichTextComponent
            v-if="activeSubscriptionOption?.benefits"
            :rich-text="activeSubscriptionOption?.benefits"
            class="subscription-option-benefits mt-2 text-sm text-medium-grey"
          ></RichTextComponent>
        </RadioField>

        <RevealComponent :open="subscriptionChooserActive">
          <div class="space-y-4">
            <SelectField
              v-model="activeSubscriptionIntervalId"
              :label="subscriptionIntervalLabel"
              :options="subscriptionChooserOptions"
              class="form-field-grey"
            ></SelectField>
            <RichTextComponent
              :rich-text="activeSubscriptionOption?.description"
              class="pb-6 text-medium-grey"
            ></RichTextComponent>
          </div>
        </RevealComponent>
        <button
          v-if="hasSubscriptionModalArea"
          class="text-link text-start text-black"
          @click="subscriptionModal.open()"
        >
          <CommonLabel
            class="font-medium underline"
            path="BWF/shop/productDetailPage/subscriptionProduct/subscriptionModalLinkLabel"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import RadioField from "~/templates/elements/form/RadioField.vue";
import { useProductSubscriptionConfigurator } from "~/composables/shop/useProductSubscriptionConfigurator";
import SelectField from "~/templates/elements/form/SelectField.vue";

import type { Product } from "@shopware-pwa/types";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import RevealComponent from "~/templates/elements/RevealComponent.vue";
import RichTextComponent from "~/templates/components/cms/RichTextComponent.vue";
import { useProductBulkPrices } from "~/composables/shop/useProductBulkPrices";
defineProps(["optionButtonDefault"]);
const { product, configurator } = useProduct();
const { unitPrice } = useProductPrice(product);

const { hasBulkPrice, productQuantity, bulkChooserOptions, activeBulkChild } =
  useProductBulkPrices(product);
const hasSubscriptionListPrice = computed(() => {
  return (
    subscriptionListPrice.value && subscriptionListPrice.value !== subscriptionTotalPrice.value
  );
});

const singlePurchasePrice = computed(() => {
  if (hasBulkPrice.value) {
    return activeBulkChild.value?.totalPrice;
  } else {
    return unitPrice.value;
  }
});

const {
  subscriptionChooserOptions,
  subscriptionChooserActive,
  activeSubscriptionOption,
  activeSubscriptionIntervalId,
  subscriptionTotalPrice,
  subscriptionListPrice,
} = useProductSubscriptionConfigurator(product);

const { getLabelByPath } = useCommonLabels();
const subscriptionIntervalLabel = getLabelByPath(
  "BWF/shop/productDetailPage/subscriptionProduct/subscriptionIntervalDropdownLabel",
);
const quantityLabel = getLabelByPath("BWF/shop/shared/tableQuantity");

const subscriptionModal = inject("subscriptionModal", useModal());
const hasSubscriptionModalArea = inject("hasSubscriptionModalArea");
const emit = defineEmits<{
  (e: "beforeSubscriptionChange"): void;
  (e: "change", changedProduct: Partial<Product>): void;
}>();

const subscriptionChange = (changedProduct: Product) => {
  emit("change", changedProduct);
};
</script>

<style>
.subscription-option-benefits {
  ul {
    list-style-image: url("~/assets/sprite/svg/Checkmark-1.svg");
  }

  li {
    @apply pl-1;
  }
}
</style>
