<template>
  <label class="relative flex items-center gap-4 text-base">
    <SvgIcon
      class="absolute left-2 top-1/2 h-5 w-5 -translate-y-1/2 text-medium-grey xl:left-4"
      name="Search"
    ></SvgIcon>
    <input
      :autocomplete="attrs.autocomplete"
      :class="{ 'has-label': !!label, 'has-error': hasError }"
      :disabled="attrs.disabled"
      :name="attrs.name"
      :pattern="attrs.pattern"
      :placeholder="attrs.placeholder"
      :readonly="attrs.readonly"
      :required="attrs.required"
      :value="modelValue"
      class="flex w-full appearance-none rounded-full bg-light-grey py-2 pl-10 pr-8 xl:py-3 xl:pl-12"
      type="text"
      @blur="$emit('blur', $event)"
      @change="$emit('change', $event)"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <button
      v-if="modelValue?.length > 0"
      aria-label="clear"
      class="absolute right-4 top-1/2 -translate-y-1/2"
      @click="onClear()"
    >
      <SvgIcon class="h-4 w-4 text-medium-grey" name="icon-menu-close"></SvgIcon>
    </button>
  </label>
</template>

<script setup>
import SvgIcon from "~/templates/elements/SvgIcon.vue";

const props = defineProps(["modelValue", "label", "errorMessage"]);
const attrs = useAttrs();
const hasError = computed(() => !!props.errorMessage);
const emits = defineEmits(["update:modelValue", "blur", "change"]);

const onClear = () => {
  emits("update:modelValue", "");
};
</script>
