<template>
  <ColorOptionCircleButton
    :is-option-not-available="isOptionNotAvailable"
    :is-option-selected="isOptionSelected"
    :option="option"
  />
</template>
<script setup lang="ts">
import type { EshopSetConfiguratorStepProduct } from "~/composables/shop/eShopSetConfiguratorTypes";
import type { Product } from "@shopware-pwa/types";
import ColorOptionCircleButton from "~/templates/elements/ColorOptionCircleButton.vue";

const props = defineProps<{ stepProduct: EshopSetConfiguratorStepProduct }>();

const product = computed<Product>(() => props.stepProduct?.product as Product);
const option = computed(() =>
  product?.value?.options?.find(
    (configuratorOption) => configuratorOption.group?.displayType === "color",
  ),
);
const isOptionNotAvailable = computed(() => {
  return !props.stepProduct?.product?.available;
});
const isOptionSelected = computed(() => {
  return props.stepProduct?.selected;
});
</script>
