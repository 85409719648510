<template>
  <div class="flex flex-col gap-8 xl:flex-row xl:gap-0">
    <div class="xl:w-8/12">
      <ul class="grid gap-2 md:grid-cols-2 md:gap-4 xl:grid-cols-3" role="list">
        <li v-for="link in links" :key="link['@id']">
          <ProductLink
            v-if="link['@nodeType'] === 'nav:productlink'"
            class="h-full"
            :product-link="link"
          ></ProductLink>

          <ProductOverviewLink
            v-if="link['@nodeType'] === 'nav:linkbox'"
            class="h-full"
            :link="link.groupLink"
            :text="link.groupText"
          ></ProductOverviewLink>
        </li>
      </ul>
    </div>
    <div v-if="level.teaserHeadline" class="ml-auto w-full xl:w-4/12 xl:pl-14">
      <MenuTeaser
        class="menu-teaser"
        :headline="level.teaserHeadline"
        :subline="level.teaserSubline"
        :link="level.teaserLink"
        :img="level.image"
      ></MenuTeaser>
    </div>
  </div>
</template>

<script>
import MenuTeaser from "./MenuTeaser";
import ProductLink from "./ProductLink";
import ProductOverviewLink from "./ProductOverviewLink";
import { filterNodesByType, mapNodes } from "~/utils/helper/magnolia";
export default {
  name: "LevelTwoProduct",
  components: { ProductOverviewLink, MenuTeaser, ProductLink },
  props: {
    level: Object,
  },
  computed: {
    links() {
      return filterNodesByType(mapNodes(this.level), "nav:");
    },
    productGroup() {
      return this.level["product-group"] || {};
    },
  },
};
</script>

<style scoped>
.is-mobile .menu-teaser {
  @apply my-8;
}
</style>
