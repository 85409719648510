<template>
  <video
    v-if="isAsset(video)"
    ref="media"
    :alt="getVideoAlt(video?.asset)"
    :autoplay="autoplay"
    :poster="
      imageResizerPath(image, DIMENSIONS_XL.ratio.video.width, DIMENSIONS_XL.ratio.video.height)
    "
    :src="load ? getVideoSrc(video?.asset) : null"
    :title="getVideoTitle(video?.asset)"
    controls
    playsinline
    @ended="trackCompletedVideoEvent()"
    @play="trackStartVideo = true"
    @timeupdate="trackOnVideoProgress"
  ></video>
  <video
    v-else-if="isExternal(video)"
    ref="media"
    :autoplay="autoplay"
    :poster="
      imageResizerPath(image, DIMENSIONS_XL.ratio.video.width, DIMENSIONS_XL.ratio.video.height)
    "
    :src="load ? video.url : null"
    controls
    playsinline
    @ended="trackCompletedVideoEvent()"
    @play="trackStartVideo = true"
    @timeupdate="trackOnVideoProgress"
  ></video>
  <iframe
    v-else-if="isYoutube(video)"
    :src="load ? `https://www.youtube-nocookie.com/embed/${getYouTubeId(video?.youtube)}` : null"
    allowfullscreen="allowfullscreen"
    class="aspect-video w-full"
    frameborder="0"
  ></iframe>
</template>

<script setup>
import { imageResizerPath } from "~/utils/helper/magnolia";
import {
  getVideoSrc,
  isAsset,
  isYoutube,
  isExternal,
  getYouTubeId,
  getVideoAlt,
  getVideoTitle,
} from "~/utils/helper/video";
import { DIMENSIONS_XL } from "~/utils/helper/imaging";

const props = defineProps(["image", "video", "load", "autoplay"]);

const media = ref(null);

const { trackCompletedVideoEvent, trackOnVideoProgress, trackStartVideo } = useVideoTracking(
  media,
  props.video,
);
function playVideo() {
  media?.value?.play();
}
function pauseVideo() {
  media?.value?.pause();
}

defineExpose({ playVideo, pauseVideo });
</script>
