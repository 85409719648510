import type { Product } from "@shopware-pwa/types";
import type { PropertyGroupOption } from "@shopware-pwa/types";

export const PRODUCT_DETAILS_ASSOCIATIONS = {
  withCmsAssociations: true,
  criteria: {
    associations: {
      manufacturer: {},
      options: { associations: { group: {} } },
      solidPvEmbeddedVideoMedia: {},
      categories: {},
    },
  },
};

export const PRODUCT_CARD_ASSOCIATIONS = {
  criteria: {
    associations: {
      manufacturer: {},
      options: { associations: { group: {} } },
      categories: {},
    },
  },
};

export const getFirstColorOption = (product: Product) =>
  ((product?.options as PropertyGroupOption[]) ?? []).find(
    (option) => option.group.displayType === "color",
  );

export const getFirstTextOption = (product?: Product) => {
  return ((product?.options as PropertyGroupOption[]) ?? []).find((option) => {
    return option?.group?.displayType === "text";
  });
};

export const hasFirstOption = (product: Product) => {
  return (getFirstTextOption(product) || getFirstColorOption(product)) ?? false;
};
