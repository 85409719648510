<template>
  <div class="flex flex-col">
    <p v-if="hasBulkPrice">
      {{ `${firstBulkUnit?.quantity} ${bulkProductUnitLabel}` }}
    </p>
    <div class="flex flex-row">
      <SharedPrice
        v-if="firstBulkUnit?.totalPrice"
        :class="{
          'text-signal-red': isBulkListPrice,
        }"
        :value="firstBulkUnit?.totalPrice"
        class="text-left text-base font-medium"
      />
      <SharedPrice
        v-if="isBulkListPrice"
        :value="firstBulkUnit?.totalPriceWithoutDiscount"
        class="ml-2 text-base font-normal leading-snug line-through"
      />
    </div>
    <div
      v-if="hasBulkPrice && isBulkListPrice"
      class="flex gap-1 text-xs font-normal leading-tight"
    >
      <CommonLabel path="BWF/shop/productDetailPage/priceSaving" />
      <SharedPrice v-if="firstBulkUnit?.totalPriceWithoutDiscount" :value="bulkProductSavings" />
      {{ `(${firstBulkUnit?.displayDiscountRate}%)` }}
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonLabel from "~/templates/components/CommonLabel.vue";

const { product } = useProduct();
const { hasBulkPrice, firstBulkUnit, isBulkListPrice, bulkProductUnitLabel } =
  useProductBulkPrices(product);

const bulkProductSavings = computed(() => {
  return firstBulkUnit.value?.listPrice?.discount * firstBulkUnit.value?.quantity;
});
</script>
