<template>
  <div class="container grid grid-cols-12 justify-between gap-4 xl:gap-x-6">
    <LoadingElement
      v-if="!isMyBritaLoggedIn && !isEditMode"
      class="col-span-full aspect-3/4 w-full lg:aspect-video"
    >
    </LoadingElement>
    <template v-else>
      <div class="col-span-full mt-12 md:order-2 md:col-span-8 xl:col-start-4">
        <slot></slot>
      </div>
      <menu
        class="account-navi order-1 col-span-full mt-12 flex flex-col rounded-xl md:col-span-3 xl:col-span-2 xl:mt-12"
      >
        <li class="px-4">
          <NuxtLink
            :to="marketSetupAdmin?.myBritaOverviewPage ?? ''"
            class="account-link text-icon-link text-black"
          >
            <SvgIcon class="h-5 w-5" name="Overview"></SvgIcon>
            <CommonLabel class="truncate" path="BWF/shop/account/grid/overview"></CommonLabel>
          </NuxtLink>
        </li>

        <li class="px-4">
          <NuxtLink
            :to="marketSetupAdmin?.myBritaProfilePage ?? ''"
            class="account-link text-icon-link text-black"
          >
            <SvgIcon class="h-5 w-5" name="User"></SvgIcon>
            <CommonLabel class="truncate" path="BWF/shop/account/grid/myProfile"></CommonLabel>
          </NuxtLink>
        </li>

        <li class="px-4">
          <NuxtLink
            :to="marketSetupAdmin?.myBritaAddressPage ?? ''"
            class="account-link text-icon-link text-black"
          >
            <SvgIcon class="h-5 w-5" name="Address-Book"></SvgIcon>
            <CommonLabel path="BWF/shop/account/grid/addressBook"></CommonLabel>
          </NuxtLink>
        </li>

        <li class="px-4">
          <NuxtLink
            :class="{
              'is-active-parent':
                marketSetupAdmin?.myBritaOrderPage &&
                path.includes(marketSetupAdmin?.myBritaOrderPage),
            }"
            :to="marketSetupAdmin?.myBritaOrderPage ?? ''"
            class="account-link text-icon-link text-black"
          >
            <SvgIcon class="h-5 w-5" name="Orders"></SvgIcon>
            <CommonLabel class="truncate" path="BWF/shop/account/grid/orderHistory"></CommonLabel>
          </NuxtLink>
        </li>

        <li class="px-4">
          <NuxtLink
            :to="marketSetupAdmin?.myBritaSubscriptionPage ?? ''"
            class="account-link text-icon-link text-black"
          >
            <SvgIcon class="h-5 w-5 truncate" name="Subscription"></SvgIcon>
            <CommonLabel class="truncate" path="BWF/shop/account/grid/subscriptions"></CommonLabel>
          </NuxtLink>
        </li>

        <li>
          <hr class="my-3" />
        </li>

        <li class="px-4">
          <button class="account-link text-icon-link text-black" @click="onLogout()">
            <SvgIcon class="h-5 w-5" name="Logout"></SvgIcon>
            <CommonLabel path="BWF/shop/account/grid/logout"></CommonLabel>
          </button>
        </li>
      </menu>
    </template>
  </div>
</template>

<script setup>
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import { isEditMode as evaluateEditMode } from "~/utils/content/magnolia";
import LoadingElement from "~/templates/elements/LoadingElement.vue";

const { path } = useRoute();
const { marketSetupAdmin } = useMarketSetup();
const isEditMode = evaluateEditMode();

const { myBritaLogout, isMyBritaLoggedIn } = useMyBritaUser();

const onLogout = async () => {
  await myBritaLogout();
};
</script>

<style scoped>
.account-link {
  @apply relative gap-2 py-3;

  &.router-link-active,
  &.is-active-parent {
    @apply rounded-r-lg bg-pale-blue/30;

    &:before {
      @apply absolute -left-4 h-full w-4 rounded-l-lg bg-pale-blue/30;
      content: "";
    }
  }
}
</style>
