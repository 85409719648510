<template>
  <div class="whitespace-pre-wrap text-3xl font-medium md:text-6xl">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "EditorialStoryHeadlineComponent",
  props: ["text"],
};
</script>
