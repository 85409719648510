<template>
  <div class="relative text-base">
    <label :class="{ 'has-label': !!label }" class="flex w-full gap-2">
      <span
        :class="hasError ? 'has-error' : 'checkbox-border'"
        class="relative h-5 w-5 shrink-0 p-1"
      >
        <input
          :checked="modelValue"
          :class="{ 'has-label': !!label }"
          :disabled="attrs.disabled"
          :name="attrs.name"
          :placeholder="attrs.placeholder"
          :value="modelValue"
          class="absolute inset-0 h-full w-full appearance-none"
          type="checkbox"
          @blur="$emit('blur', $event)"
          @change="$emit('change', $event)"
          @input="$emit('update:modelValue', $event.target.checked)"
        />
        <SvgIcon
          class="pointer-events-none absolute inset-0 hidden h-full w-full p-1 text-dark-blue"
          name="Checkmark-1"
        ></SvgIcon>
      </span>
      <span class="relative w-full leading-5">
        {{ label }}
        <slot />
      </span>
    </label>
    <div v-if="errorMessage" class="pointer-events-none pl-7 pr-2 pt-1 text-sm text-signal-red">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup>
import SvgIcon from "~/templates/elements/SvgIcon.vue";

const props = defineProps(["modelValue", "label", "errorMessage"]);
const attrs = useAttrs();
const hasError = computed(() => !!props.errorMessage);

// TODO check preselection

defineEmits(["update:modelValue", "blur", "change"]);
</script>

<style scoped>
[disabled] {
  @apply cursor-not-allowed opacity-60 grayscale;
}

input:checked + svg {
  @apply block;
}

.has-error {
  @apply rounded border border-signal-red;
}

.checkbox-border {
  @apply rounded border border-dark-blue;
}
</style>
