<template>
  <section class="border-grey flex flex-col gap-6 rounded-lg border px-4 py-6 xl:px-6">
    <HeadlineComponent
      :headline="content?.headline"
      class="pb-4 text-lg font-medium"
    ></HeadlineComponent>

    <p>
      {{ content?.text }}
    </p>

    <button class="btn btn-ghost btn-size-sm md:self-start" @click="emit('delete-account')">
      {{ content?.buttonLabel ?? "" }}
    </button>
  </section>
</template>
<script setup>
import HeadlineComponent from "~/templates/components/cms/HeadlineComponent.vue";
const emit = defineEmits(["delete-account"]);

defineProps(["content"]);
</script>
