<template>
  <div class="flex items-center py-1 md:py-4">
    <div v-if="mainImage" class="w-18 shrink-0 py-1">
      <div class="aspect-square">
        <ProductImage
          :product-media="product?.cover?.media"
          main-class="object-contain w-full h-full"
        ></ProductImage>
      </div>
    </div>
    <div class="flex min-w-0 flex-1 flex-col items-start gap-1 px-2">
      <span class="w-full hyphens-auto text-left text-sm font-medium text-black">{{ title }}</span>
      <span
        v-if="hasFirstOption(product as Product)"
        class="text-sm font-normal text-medium-grey"
        >{{ firstTextOption?.name || firstColorOption?.name }}</span
      >
      <span v-if="absPriceDifference && !stepProduct?.selected" class="inline-flex">
        <SharedPrice :value="absPriceDifference" class="text-sm font-normal text-black">
          <template #beforePrice>{{ priceSign }}</template>
        </SharedPrice>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getSmallestThumbnailUrl, getTranslatedProperty } from "@shopware-pwa/helpers-next";
import type { EshopSetConfiguratorStepProduct } from "~/composables/shop/eShopSetConfiguratorTypes";
import { getValueSign } from "~/utils/helper/number";
import {
  getFirstColorOption,
  getFirstTextOption,
  hasFirstOption,
} from "~/utils/helper/shop/product";
import type { Product } from "@shopware-pwa/types";
import ProductImage from "~/templates/elements/ProductImage.vue";

const props = defineProps<{ stepProduct: EshopSetConfiguratorStepProduct }>();

const product = computed(() => props?.stepProduct?.product);
const title = computed(() => getTranslatedProperty(product.value, "name"));
const firstColorOption = getFirstColorOption(product?.value as Product);
const firstTextOption = getFirstTextOption(product?.value as Product);
const absPriceDifference = computed(() => Math.abs(props?.stepProduct?.differencePrice ?? 0));
const priceSign = computed(() => getValueSign(props?.stepProduct?.differencePrice));
const mainImage = computed(() => getSmallestThumbnailUrl(product?.value?.cover?.media));
</script>
