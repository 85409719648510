import useItemsArray from "~/composables/tracking/useItemsArray";
import useMagnoliaContent from "~/composables/useMagnoliaContent";
import { cleanName, getId, getTemplate } from "~/utils/helper/magnolia";

export default function ({ metadata = {}, items = ref([]), rootElement = ref(null) }) {
  const { pageTemplate } = useMagnoliaContent();
  const gtm = useGtm(); // auto-imported by the module
  // generate page and component name
  const pageTplName = cleanName(pageTemplate.value);
  const componentName = cleanName(getTemplate(metadata));
  const item_list_name = `${pageTplName}__${componentName}`;
  const item_list_id = getId(metadata);
  const { getItemsArray } = useItemsArray();
  const ITEMS_ARRAY = ref([]);

  //

  watch(
    items,
    () => {
      // rebuild items
      ITEMS_ARRAY.value = getItemsArray(items.value, item_list_name, item_list_id) ?? [];
    },
    { immediate: true },
  );

  // provide tracking information and items array
  useTrackingContext({
    item_list_id,
    item_list_name,
    items: ITEMS_ARRAY,
  });
  // or start injection context !?
  const trackViewItemList = () => {
    try {
      gtm?.trackEvent({ ecommerce: null });
      gtm?.trackEvent({
        event: "eec.view_item_list",
        event_name: "view_item_list",
        event_source: "source_code",
        ecommerce: {
          item_list_id,
          item_list_name,
          items: toRaw(ITEMS_ARRAY.value),
        },
      });
    } catch (e) {
      console.error("eec.view_item_list", e);
    }
  };

  const isVisible = useElementVisibility(rootElement);
  watch(
    isVisible,
    () => {
      if (isVisible.value) {
        trackViewItemList();
      }
    },
    { once: true },
  );
  return { trackViewItemList };
}
