<template>
  <svg
    id="e1Czvx9aODM1"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    viewBox="0 0 1600 900"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="e1Czvx9aODM2_ts" transform="translate(880,60) scale(0.2,0.2)">
      <ellipse
        id="e1Czvx9aODM2"
        fill="#fff"
        fill-opacity="0.35"
        opacity="0"
        rx="120"
        ry="120"
        stroke-width="0"
        transform="translate(0,0)"
      />
    </g>
    <g id="e1Czvx9aODM3_ts" transform="translate(1400,320) scale(0.2,0.2)">
      <ellipse
        id="e1Czvx9aODM3"
        fill="#84d0f5"
        fill-opacity="0.65"
        opacity="0"
        rx="120"
        ry="120"
        stroke-width="0"
        transform="translate(0,0)"
      />
    </g>
    <g id="e1Czvx9aODM4_ts" transform="translate(1400,580) scale(0.2,0.2)">
      <ellipse
        id="e1Czvx9aODM4"
        fill="#fff"
        fill-opacity="0.35"
        opacity="0"
        rx="120"
        ry="120"
        stroke-width="0"
        transform="translate(0,0)"
      />
    </g>
    <g id="e1Czvx9aODM5_ts" transform="translate(1660,580) scale(0.2,0.2)">
      <ellipse
        id="e1Czvx9aODM5"
        fill="#fff"
        fill-opacity="0.35"
        opacity="0"
        rx="120"
        ry="120"
        stroke-width="0"
        transform="translate(0,0)"
      />
    </g>
  </svg>
</template>
<style scoped>
#e1Czvx9aODM2_ts {
  animation: e1Czvx9aODM2_ts__ts 2000ms linear 1 normal forwards;
}

@keyframes e1Czvx9aODM2_ts__ts {
  0% {
    transform: translate(880px, 60px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(880px, 60px) scale(1, 1);
  }
  100% {
    transform: translate(880px, 60px) scale(1, 1);
  }
}

#e1Czvx9aODM2 {
  animation: e1Czvx9aODM2_c_o 2000ms linear 1 normal forwards;
}

@keyframes e1Czvx9aODM2_c_o {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

#e1Czvx9aODM3_ts {
  animation: e1Czvx9aODM3_ts__ts 2000ms linear 1 normal forwards;
}

@keyframes e1Czvx9aODM3_ts__ts {
  0% {
    transform: translate(1400px, 320px) scale(0.2, 0.2);
  }
  10% {
    transform: translate(1400px, 320px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  60% {
    transform: translate(1400px, 320px) scale(1, 1);
  }
  100% {
    transform: translate(1400px, 320px) scale(1, 1);
  }
}

#e1Czvx9aODM3 {
  animation: e1Czvx9aODM3_c_o 2000ms linear 1 normal forwards;
}

@keyframes e1Czvx9aODM3_c_o {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

#e1Czvx9aODM4_ts {
  animation: e1Czvx9aODM4_ts__ts 2000ms linear 1 normal forwards;
}

@keyframes e1Czvx9aODM4_ts__ts {
  0% {
    transform: translate(1400px, 580px) scale(0.2, 0.2);
  }
  20% {
    transform: translate(1400px, 580px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  70% {
    transform: translate(1400px, 580px) scale(1, 1);
  }
  100% {
    transform: translate(1400px, 580px) scale(1, 1);
  }
}

#e1Czvx9aODM4 {
  animation: e1Czvx9aODM4_c_o 2000ms linear 1 normal forwards;
}

@keyframes e1Czvx9aODM4_c_o {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

#e1Czvx9aODM5_ts {
  animation: e1Czvx9aODM5_ts__ts 2000ms linear 1 normal forwards;
}

@keyframes e1Czvx9aODM5_ts__ts {
  0% {
    transform: translate(1660px, 580px) scale(0.2, 0.2);
  }
  30% {
    transform: translate(1660px, 580px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  80% {
    transform: translate(1660px, 580px) scale(1, 1);
  }
  100% {
    transform: translate(1660px, 580px) scale(1, 1);
  }
}

#e1Czvx9aODM5 {
  animation: e1Czvx9aODM5_c_o 2000ms linear 1 normal forwards;
}

@keyframes e1Czvx9aODM5_c_o {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
</style>
