import { computed, ref, inject, provide } from "vue";
import { useShopwareContext } from "#imports";

/**
 * Composable to manage order payment
 * @public
 * @category Checkout / Order Payment
 */

import {
  useCheckout as useCoreCheckout,
  type UseCheckoutReturn,
} from "@shopware-pwa/composables-next";
export function useCheckout(): UseCheckoutReturn {
  const { apiInstance } = useShopwareContext();

  const storePaymentMethods = inject("swPaymentMethods", ref());
  provide("swPaymentMethods", storePaymentMethods);

  const coreCheckout = useCoreCheckout();
  const paymentMethods = computed(() => storePaymentMethods.value || []);

  async function getPaymentMethods({ forceReload, orderId } = { forceReload: false, orderId: "" }) {
    if (paymentMethods.value.length && !forceReload) return paymentMethods;
    const { data } = await apiInstance.invoke.post("/store-api/payment-method", {
      onlyAvailable: true,
      ...(orderId ? { orderId } : {}),
    });
    /*
        * might be a good idea to add this as this association when BE sorting is fixed in
        * https://jirard.brita.net/browse/D2CMVP-6463
        * 		{
                "field": "position",
                "order": "ASC",
                "naturalSorting": true
		          }
        * */

    storePaymentMethods.value = [...(data?.elements ?? [])].sort((a, b) => a.position - b.position);
    return paymentMethods;
  }

  return {
    ...coreCheckout,
    getPaymentMethods,
    paymentMethods,
  };
}
