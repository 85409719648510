<template>
  <div
    ref="categoryOverviewTile"
    class="relative flex flex-col overflow-hidden rounded-xl bg-light-grey"
  >
    <div v-if="containsImage(image)" class="relative">
      <PictureElement
        :breakpoints="BREAKPOINTS"
        :img="image"
        img-class="aspect-3/2 object-cover w-full h-full overflow-hidden"
      ></PictureElement>
      <div
        class="absolute inset-x-0 top-0 h-1/3 bg-gradient-to-b from-black to-transparent opacity-60"
      ></div>
      <div
        v-if="imageGradientBottom !== false"
        class="absolute inset-x-0 bottom-0 h-1/3 bg-gradient-to-t from-black to-transparent opacity-60"
      ></div>
      <div class="absolute inset-0 flex flex-col justify-between p-6 xl:p-8 xl:pb-5">
        <HeadlineComponent
          :headline="headline"
          class="text-2xl font-medium leading-7 text-white xl:text-3xl"
          tag="h2"
        ></HeadlineComponent>
        <TagList :tags="tags" />
      </div>
    </div>
    <div class="flex h-full flex-col items-start gap-4 p-6 xl:gap-6 xl:p-8 xl:pt-6">
      <div v-if="subline" class="text-lg font-normal leading-6 xl:text-2xl">
        {{ subline }}
      </div>
      <hr v-if="subline" class="w-full" />
      <ul v-if="hasNodes(benefits)" class="flex-1 space-y-4">
        <li
          v-for="benefit in mapNodes(benefits)"
          :key="benefit['@id']"
          class="relative flex items-start gap-x-2"
        >
          <img
            v-if="benefit.image"
            :alt="getImageAlt(benefit.image)"
            :height="getImageHeight(benefit.image) ?? 24"
            :src="imagePath(benefit.image)"
            :title="getImageTitle(benefit.image)"
            :width="getImageWidth(benefit.image) ?? 24"
            class="w-6"
          />
          <div class="flex flex-col">
            <HeadlineComponent
              :text="benefit.headline"
              class="text-base font-medium leading-snug text-dark-blue"
              tag="h3"
            ></HeadlineComponent>

            <span class="text-base font-normal leading-snug">{{ benefit.description }}</span>
          </div>
        </li>
      </ul>
      <CallToActionComponent
        v-if="link?.label"
        :link="link"
        :stretch-link="stretchLink"
        class="mt-2"
        @click="trackPromoClick"
      />
    </div>
  </div>
</template>
<script setup>
import HeadlineComponent from "~/templates/components/cms/HeadlineComponent.vue";
import PictureElement from "~/templates/elements/PictureElement.vue";
import TagList from "~/templates/components/TagList.vue";
import {
  containsImage,
  getImageHeight,
  getImageWidth,
  hasNodes,
  imagePath,
  mapNodes,
  getImageAlt,
  getImageTitle,
} from "~/utils/helper/magnolia";
import CallToActionComponent from "~/templates/components/cms/CallToActionComponent.vue";
import { DIMENSIONS } from "~/utils/helper/imaging";

const props = defineProps([
  "headline",
  "subline",
  "benefits",
  "image",
  "imageGradientBottom",
  "tags",
  "filterTags",
  "link",
  "metadata",
  "stretchLink",
]);

const categoryOverviewTile = ref(null);
const { trackPromoView, trackPromoClick } = usePromoTracking({
  metadata: props?.metadata,
  creativeName: props?.headline?.text,
});

const BREAKPOINTS = {
  start: DIMENSIONS["start"]?.ratio["3/2"],
  sm: DIMENSIONS["sm"].ratio["3/2"],
  md: DIMENSIONS["md"].width["3/2"],
  xl: DIMENSIONS["xl"].width["1/2"]?.ratio["3/2"],
};

onMounted(() => {
  trackPromoView(categoryOverviewTile);
});
</script>
