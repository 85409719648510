import { maxLength, minLength, email, helpers, sameAs, required } from "@vuelidate/validators";
import useCommonLabels from "~/composables/useCommonLabels";

export default async function () {
  const { getLabels } = useCommonLabels();
  const errorMessageLabels = getLabels("BWF/form/shared/validatorMessages", [
    "email",
    "minLengthValue",
    "maxLengthValue",
    "sameAs",
    "regex",
    "phone",
    "contractNumber",
    "required",
    "message",
    "birthdate",
    "doesNotContainNumber",
  ]);

  const withMessage = (label, validator) => {
    return helpers.withMessage(`${errorMessageLabels[label]}`, validator);
  };
  const name = helpers.regex(/^(?!.* {2,})[\p{L}\p{M}'\\,. -]{2,35}$/u);

  const streetNo = helpers.regex(/^(?!.* {2,})[\p{L}\p{M}\p{N}'\\. @#-]{2,30}$/u);
  const containsNumber = helpers.regex(/\d/);
  const addAddress = helpers.regex(/^(?!.* {2,})[\p{L}\p{M}\p{N}'\\,. -]{2,35}$/u);
  const city = helpers.regex(/^(?!.* {2,})[\p{L}\p{M}\p{N}'\\,. @#-]{2,30}$/u);

  const phone = helpers.regex(/^[\d\-\+\(\)\*\s#]{3,30}$/);
  const contractNumber = helpers.regex(/^[A-Z]{2}\d{5,10}$/);
  const searchCode = helpers.regex(/^[A-Z0-9]{4,}$/);
  const message = helpers.regex(/^[\p{L}\s\d\+\(\)\*#\.,;:!?'"#-]{0,3000}$/u);

  const validBirthdate = helpers.withParams(
    { type: "validBirthdate" },
    (value) =>
      !helpers.req(value) ||
      (new Date(value) <= new Date() && new Date(value) >= new Date(0, 0, 1)),
  );

  const birthdateValidator = {
    date: helpers.withMessage(`${errorMessageLabels["birthdate"]}`, validBirthdate),
  };

  const requiredValidator = {
    required: helpers.withMessage(`${errorMessageLabels["required"]}`, required),
  };

  const firstNameValidators = {
    minLengthValue: helpers.withMessage(`${errorMessageLabels["minLengthValue"]}`, minLength(2)),
    maxLengthValue: helpers.withMessage(`${errorMessageLabels["maxLengthValue"]}`, maxLength(35)),
    name: helpers.withMessage(`${errorMessageLabels["regex"]}`, name),
  };

  const lastNameValidators = {
    minLengthValue: helpers.withMessage(`${errorMessageLabels["minLengthValue"]}`, minLength(2)),
    maxLengthValue: helpers.withMessage(`${errorMessageLabels["maxLengthValue"]}`, maxLength(35)),
    name: helpers.withMessage(`${errorMessageLabels["regex"]}`, name),
  };

  const emailValidators = {
    email: helpers.withMessage(`${errorMessageLabels["email"]}`, email),
  };

  const productSearchCodeValidator = {
    searchCode: helpers.withMessage(`${errorMessageLabels["searchCode"]}`, searchCode),
  };

  const streetNumberValidators = {
    minLengthValue: helpers.withMessage(`${errorMessageLabels["minLengthValue"]}`, minLength(3)),
    maxLengthValue: helpers.withMessage(`${errorMessageLabels["maxLengthValue"]}`, maxLength(30)),
    containsNumber: helpers.withMessage(
      `${errorMessageLabels["doesNotContainNumber"]}`,
      containsNumber,
    ),
    streetNo: helpers.withMessage(`${errorMessageLabels["regex"]}`, streetNo),
  };

  const addressAdditionalValidators = {
    minLengthValue: helpers.withMessage(`${errorMessageLabels["minLengthValue"]}`, minLength(2)),
    maxLengthValue: helpers.withMessage(`${errorMessageLabels["maxLengthValue"]}`, maxLength(35)),
    addAddress: helpers.withMessage(`${errorMessageLabels["regex"]}`, addAddress),
  };

  const cityValidators = {
    minLengthValue: helpers.withMessage(`${errorMessageLabels["minLengthValue"]}`, minLength(2)),
    maxLengthValue: helpers.withMessage(`${errorMessageLabels["maxLengthValue"]}`, maxLength(30)),
    city: helpers.withMessage(`${errorMessageLabels["regex"]}`, city),
  };

  const zipCodeValidators = {
    minLengthValue: helpers.withMessage(`${errorMessageLabels["minLengthValue"]}`, minLength(3)),
    maxLengthValue: helpers.withMessage(`${errorMessageLabels["maxLengthValue"]}`, maxLength(8)),
  };

  const phoneValidators = {
    minLengthValue: helpers.withMessage(`${errorMessageLabels["minLengthValue"]}`, minLength(3)),
    maxLengthValue: helpers.withMessage(`${errorMessageLabels["maxLengthValue"]}`, maxLength(30)),
    phone: helpers.withMessage(`${errorMessageLabels["phone"]}`, phone),
  };
  const contractNumberValidators = {
    minLengthValue: helpers.withMessage(`${errorMessageLabels["minLengthValue"]}`, minLength(3)),
    maxLengthValue: helpers.withMessage(`${errorMessageLabels["maxLengthValue"]}`, maxLength(30)),
    contractNumber: helpers.withMessage(`${errorMessageLabels["contractNumber"]}`, contractNumber),
  };

  const messageValidators = {
    message: helpers.withMessage(`${errorMessageLabels["message"]}`, message),
    maxLengthValue: helpers.withMessage(`${errorMessageLabels["maxLengthValue"]}`, maxLength(3000)),
  };

  const consentValidators = {
    sameAs: helpers.withMessage(`${errorMessageLabels["sameAs"]}`, sameAs(true)),
  };
  const oneItemRequired = {
    required: helpers.withMessage(`${errorMessageLabels["oneItemRequired"]}`, required),
    minLength: helpers.withMessage(`${errorMessageLabels["oneItemRequired"]}`, minLength(1)),
  };

  return {
    firstNameValidators,
    lastNameValidators,
    emailValidators,
    streetNumberValidators,
    addressAdditionalValidators,
    cityValidators,
    phoneValidators,
    zipCodeValidators,
    consentValidators,
    contractNumberValidators,
    requiredValidator,
    messageValidators,
    oneItemRequired,
    withMessage,
    birthdateValidator,
    productSearchCodeValidator,
  };
}
