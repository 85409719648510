<template>
  <Teleport to="#teleports">
    <Transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-show="isOpen" class="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50">
        <div
          class="flex min-h-screen items-center justify-center px-4 py-16 text-center lg:-mt-[3%] xl:px-0 xl:py-28"
          @mousedown.self="close()"
        >
          <Transition
            enter-active-class="transition ease-out duration-500 transform "
            enter-from-class="opacity-0 translate-y-10 scale-95"
            enter-to-class="opacity-100 translate-y-0 scale-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100 translate-y-0 scale-100"
            leave-to-class="opacity-0 translate-y-10 translate-y-0 scale-95"
          >
            <div
              v-if="isOpen"
              id="modal-content"
              ref="modalContentElement"
              aria-labelledby="modal-headline"
              aria-modal="true"
              class="relative w-full overflow-hidden rounded-lg bg-white px-3 pb-8 pt-14 text-left shadow-xl xl:w-1/2 xl:px-12"
              role="dialog"
              @click.stop
            >
              <div class="absolute right-5 top-5">
                <button @click="close()">
                  <SvgIcon class="h-4 w-4" name="icon-menu-close"></SvgIcon>
                </button>
              </div>
              <slot></slot>
            </div>
          </Transition>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import type { useModal } from "~/composables/useModal";
import SvgIcon from "~/templates/elements/SvgIcon.vue";

const props = defineProps<{
  controller: ReturnType<typeof useModal>;
}>();

const { controller } = toRefs(props);
const { isOpen, close } = controller.value;

const { escape } = useMagicKeys();

watch(escape, () => {
  isOpen.value && close();
});
</script>
