<template>
  <dl class="space-y-1.5 lg:space-y-2">
    <div
      v-for="shopBenefit in shopBenefits"
      :key="shopBenefit.label"
      class="flex items-center gap-7 lg:gap-6"
    >
      <dt>
        <img
          v-if="containsImage(shopBenefit.image)"
          :alt="imageName(shopBenefit.image)"
          :src="imagePath(shopBenefit.image)"
          aria-hidden="true"
          class="h-4 w-4 lg:h-5 lg:w-5"
        />
        <span class="sr-only">{{ shopBenefit.label?.value }}</span>
      </dt>
      <dd class="text-sm font-normal text-black">
        {{ shopBenefit.label?.value ?? "Shop Benefit*" }}
      </dd>
    </div>
  </dl>
</template>
<script setup lang="ts">
import { imageName, imagePath, mapNodes, containsImage } from "~/utils/helper/magnolia";

const props = defineProps(["benefits"]);

const { marketSetup } = useMarketSetup();
const shopBenefits = mapNodes(props.benefits ?? marketSetup.value?.shopBenefits);
</script>
