<template>
  <div class="contents text-sm text-medium-grey">
    <div class="col-start-2">
      <!-- FILLING-->
      <div class="text-black">
        <CommonLabel path="BWF/shop/checkout/cart/exchangeBoxItem/fillingLabel"></CommonLabel>&nbsp;
        <CommonLabel
          :placeholder="{ amountCylinders: filling?.payload?.amountCylinders }"
          path="BWF/shop/checkout/cart/exchangeBoxItem/quantifierLabel"
        ></CommonLabel>
      </div>
      <!-- DEPOSIT-->
      <div class="text-black">
        <CommonLabel path="BWF/shop/checkout/includes"></CommonLabel> {{ deposit?.label }}&nbsp;
        <CommonLabel
          :placeholder="{ amountCylinders: filling?.payload?.amountCylinders }"
          path="BWF/shop/checkout/cart/exchangeBoxItem/quantifierLabel"
        ></CommonLabel>
      </div>
    </div>
    <div class="justify-self-end text-black xl:col-start-4">
      <!-- FILLING-->
      <SharedPrice :value="filling?.totalPrice" has-asterisk />
      <!-- DEPOSIT -->
      <SharedPrice :value="deposit?.totalPrice" has-asterisk />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OrderLineItem } from "@shopware-pwa/types";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import SharedPrice from "~/templates/components/shop/shared/SharedPrice.vue";
const props = defineProps<{
  lineItem: OrderLineItem;
}>();

const { lineItem } = toRefs(props);

// @ts-ignore
const filling = computed(() => lineItem?.value?.children?.[0]);
// @ts-ignore
const deposit = computed(() => lineItem?.value?.children?.[1]);
</script>
