export type MarketSetupAdmin = {
  textDirection?: boolean;
  cmpSnippet?: string;
  pregListingId?: string;
  myBritaOverviewPage?: string;
  myBritaAddressPage?: string;
  myBritaOrderPage?: string;
  myBritaOrderReturnPage?: string;
  myBritaOrderWarrantyPage?: string;
  myBritaOrderDetailsPage?: string;
  myBritaProfilePage?: string;
  myBritaSubscriptionPage?: string;
  shopEntrancePage?: string;
  cartPage?: string;
  checkoutPage?: string;
  checkoutSuccessPage?: string;
  legalCheckbox?: boolean;
  fallbackPdp?: string;
  // TODO this shpould probably move to regular marketsetup
  myBritaRegisterPage?: string;
  orderLookupPage?: string;
  pregLandingPage?: string;
  pregPage?: string;
  orderPaymentPage?: string;
  robotsText?: string;
  yourceMigrationPage?: string;
  yourceMigrationSuccessPage?: string;
  yourceMigrationActivePage?: string;
  yourceMigrationActiveSuccessPage?: string;
};
export type MarketSetup = {
  startPage?: string;
  searchResultPage?: string;
  dataPrivacyPage?: string;
  generalTermsAndConditionsPage?: string;
  returnPolicyPage?: string;
  fallbackTitle?: string;
  fallbackDescription?: string;
  pageNotFoundPage?: string;
  unauthorizedPage?: string;
  internalServerErrorPage?: string;
  loadingImage?: string;
  shopBenefits?: object;
  myBritaBenefits?: object;
  newsletterDoiPage?: string;
  newsletterUnsubscribePage?: string;
};

export type MarketSetupLinks = {
  startPage?: string;
  searchResultPage?: string;
  dataPrivacyPage?: string;
  generalTermsAndConditionsPage?: string;
  returnPolicyPage?: string;
  newsletterDoiPage?: string;
  newsletterUnsubscribePage?: string;
  orderLookupPage?: string;
  orderPaymentPage?: string;
  myBritaOverviewPage?: string;
  myBritaRegisterPage?: string;
  myBritaSubscriptionPage?: string;
  yourceMigrationPage?: string;
  yourceMigrationSuccessPage?: string;
  yourceMigrationActivePage?: string;
  yourceMigrationActiveSuccessPage?: string;
  cartPage?: string;
  pageNotFoundPage?: string;
  unauthorizedPage?: string;
  internalServerErrorPage?: string;
};

const useMarketSetup = function () {
  const marketSetup: Ref<MarketSetup> = useState("marketSetup", () => ({}));
  const marketSetupAdmin: Ref<MarketSetupAdmin> = useState("marketSetupAdmin", () => ({}));
  const marketSetupLinks: Ref<MarketSetupLinks> = useState("marketSetupLinks", () => ({}));

  return { marketSetup, marketSetupAdmin, marketSetupLinks };
};

export default useMarketSetup;
