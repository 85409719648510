export default defineNuxtRouteMiddleware(async () => {
  if (import.meta.server) return;

  const { $msal } = useNuxtApp();
  const { azureUser } = useAzureUser();
  const idToken = await $msal.acquireTokenSilent();
  const account = $msal.getActiveAccount();
  const isAuthenticated = !!($msal.isAuthenticated() && idToken);
  //
  if (isAuthenticated) {
    azureUser.value = {
      ...(account ?? {}),
      idToken: idToken,
    };
  }
});
