<template>
  <svg
    id="eLlfuYeVyGO1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 375 667"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
  >
    <g id="eLlfuYeVyGO2_ts" transform="translate(268,580) scale(0.2,0.2)">
      <ellipse
        id="eLlfuYeVyGO2"
        rx="52"
        ry="52"
        transform="translate(0,0)"
        opacity="0"
        fill="#fff"
        fill-opacity="0.35"
        stroke-width="0"
      />
    </g>
    <g id="eLlfuYeVyGO3_ts" transform="translate(268,468) scale(0.2,0.2)">
      <ellipse
        id="eLlfuYeVyGO3"
        rx="52"
        ry="52"
        transform="translate(0,0)"
        opacity="0"
        fill="#84d0f5"
        fill-opacity="0.65"
        stroke-width="0"
      />
    </g>
    <g id="eLlfuYeVyGO4_ts" transform="translate(380,244) scale(0.2,0.2)">
      <ellipse
        id="eLlfuYeVyGO4"
        rx="52"
        ry="52"
        transform="translate(0,0)"
        opacity="0"
        fill="#fff"
        fill-opacity="0.35"
        stroke-width="0"
      />
    </g>
    <g id="eLlfuYeVyGO5_ts" transform="translate(44,132) scale(0.2,0.2)">
      <ellipse
        id="eLlfuYeVyGO5"
        rx="52"
        ry="52"
        transform="translate(0,0)"
        opacity="0"
        fill="#fff"
        fill-opacity="0.35"
        stroke-width="0"
      />
    </g>
    <g id="eLlfuYeVyGO6_ts" transform="translate(268,20) scale(0.2,0.2)">
      <ellipse
        id="eLlfuYeVyGO6"
        rx="52"
        ry="52"
        transform="translate(0,0)"
        opacity="0"
        fill="#fff"
        fill-opacity="0.35"
        stroke-width="0"
      />
    </g>
  </svg>
</template>
<style scoped>
#eLlfuYeVyGO2_ts {
  animation: eLlfuYeVyGO2_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eLlfuYeVyGO2_ts__ts {
  0% {
    transform: translate(268px, 580px) scale(0.2, 0.2);
  }
  40% {
    transform: translate(268px, 580px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  90% {
    transform: translate(268px, 580px) scale(1, 1);
  }
  100% {
    transform: translate(268px, 580px) scale(1, 1);
  }
}
#eLlfuYeVyGO2 {
  animation: eLlfuYeVyGO2_c_o 2000ms linear 1 normal forwards;
}
@keyframes eLlfuYeVyGO2_c_o {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#eLlfuYeVyGO3_ts {
  animation: eLlfuYeVyGO3_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eLlfuYeVyGO3_ts__ts {
  0% {
    transform: translate(268px, 468px) scale(0.2, 0.2);
  }
  30% {
    transform: translate(268px, 468px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  80% {
    transform: translate(268px, 468px) scale(1, 1);
  }
  100% {
    transform: translate(268px, 468px) scale(1, 1);
  }
}
#eLlfuYeVyGO3 {
  animation: eLlfuYeVyGO3_c_o 2000ms linear 1 normal forwards;
}
@keyframes eLlfuYeVyGO3_c_o {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#eLlfuYeVyGO4_ts {
  animation: eLlfuYeVyGO4_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eLlfuYeVyGO4_ts__ts {
  0% {
    transform: translate(380px, 244px) scale(0.2, 0.2);
  }
  20% {
    transform: translate(380px, 244px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  70% {
    transform: translate(380px, 244px) scale(1, 1);
  }
  100% {
    transform: translate(380px, 244px) scale(1, 1);
  }
}
#eLlfuYeVyGO4 {
  animation: eLlfuYeVyGO4_c_o 2000ms linear 1 normal forwards;
}
@keyframes eLlfuYeVyGO4_c_o {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#eLlfuYeVyGO5_ts {
  animation: eLlfuYeVyGO5_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eLlfuYeVyGO5_ts__ts {
  0% {
    transform: translate(44px, 132px) scale(0.2, 0.2);
  }
  10% {
    transform: translate(44px, 132px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  60% {
    transform: translate(44px, 132px) scale(1, 1);
  }
  100% {
    transform: translate(44px, 132px) scale(1, 1);
  }
}
#eLlfuYeVyGO5 {
  animation: eLlfuYeVyGO5_c_o 2000ms linear 1 normal forwards;
}
@keyframes eLlfuYeVyGO5_c_o {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#eLlfuYeVyGO6_ts {
  animation: eLlfuYeVyGO6_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eLlfuYeVyGO6_ts__ts {
  0% {
    transform: translate(268px, 20px) scale(0.2, 0.2);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(268px, 20px) scale(1, 1);
  }
  100% {
    transform: translate(268px, 20px) scale(1, 1);
  }
}
#eLlfuYeVyGO6 {
  animation: eLlfuYeVyGO6_c_o 2000ms linear 1 normal forwards;
}
@keyframes eLlfuYeVyGO6_c_o {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
</style>
