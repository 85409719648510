import type { Order, OrderLineItem, ShopwareSearchParams } from "@shopware-pwa/types";
import { getLineItemsWithoutPromotion } from "~/utils/helper/shop/cart";

export const ORDER_DOCUMENT_TYPE_PARTIAL_CANCELLATION = "partial_cancellation";
export const ORDER_DOCUMENT_TYPE_INVOICE = "invoice";
export const ORDER_DOCUMENT_TYPE_CREDIT_NOTE = "credit_note";
export const ORDER_DOCUMENT_TYPE_STORNO = "storno";
export const ORDER_DOCUMENT_TYPE_DELIVERY_NOTE = "delivery_note";

export const ORDER_DETAILS_ASSOCIATIONS: ShopwareSearchParams = {
  associations: {
    documents: {
      associations: { documentType: {} },
    },
    lineItems: {
      associations: {
        cover: {},
        children: { associations: { cover: {} } },
        state: {},
      },
      // @ts-ignore this is actually needed to filter the double items when an eshopset is in the order
      filter: [
        {
          type: "equals",
          field: "parentId",
          value: null,
        },
      ],
    },
    currency: {},
    transactions: {
      sort: [
        {
          field: "createdAt",
          order: "DESC",
          naturalSorting: true,
        },
      ],
      associations: { paymentMethod: {} },
    },
    addresses: {
      associations: {
        country: {},
        countryState: {},
      },
    },
    billingAddress: {
      associations: {
        country: {},
        countryState: {},
      },
    },
    deliveries: {
      associations: {
        shippingMethod: {},
        shippingOrderAddress: {
          associations: {
            country: {},
            countryState: {},
          },
        },
      },
    },
    returns: {
      associations: {
        lineItems: {},
      },
    },
  },
};

/**
 * checks if lineItems are restricted in terms of regular returns
 *
 * @param lineItems
 */
export const containsReturnRestricted = (lineItems: OrderLineItem[]) => {
  return lineItems?.some((item) => {
    // @ts-ignore customField
    return !!item.payload?.customFields?.["brita_disable_regular_return"];
  });
};

/**
 * checks if an order is in a failed payment state
 *
 * @param order
 */
export const hasFailedPayment = (order: Order) => {
  const failedStates = ["failed", "cancelled"];
  return failedStates.includes(order?.transactions?.[0]?.stateMachineState?.technicalName ?? "");
};

/**
 * checks if an order is in a payment progress
 *
 * @param order
 */
export const hasInProgressPayment = (order: Order) => {
  const failedStates = ["in_progress"];
  return failedStates.includes(order?.transactions?.[0]?.stateMachineState?.technicalName ?? "");
};

/**
 * get URL query for guest order pages
 *
 * @param order
 */
export const orderQueryString = (order: Order) => {
  try {
    const {
      orderCustomer: { email },
    } = order;

    const params = new URLSearchParams({
      token: btoa(email) ?? "",
    });

    return params.toString();
  } catch (e) {
    console.error("BWF: Order Data incomplete. Could not build query for error handling");
    return ``;
  }
};

export const hasContractPlan = (lineItems: OrderLineItem[]) => {
  return (
    lineItems?.some((lineItem) =>
      lineItem?.children?.some((child) => child?.payload.hasOwnProperty("contractPlan")),
    ) ?? false
  );
};

export const containsCreditLineItems = (lineItems?: OrderLineItem[] | null) => {
  return lineItems?.some((item) => item?.type === "credit") ?? [];
};
export const getLineItemsWithoutCredit = (lineItems?: OrderLineItem[] | null) => {
  return lineItems?.filter((item) => item?.type !== "credit") ?? [];
};

export const getCreditLineItems = (lineItems?: OrderLineItem[] | null) => {
  return lineItems?.filter((item) => item?.type === "credit") ?? [];
};

/**
 * calculates the total amount of purchased articles
 *
 * @param order
 */
export const orderArticleCount = (order: Order) => {
  const cleanLineItems = getLineItemsWithoutCredit(
    getLineItemsWithoutPromotion(order?.lineItems) as OrderLineItem[],
  );

  return cleanLineItems?.reduce(
    (accumulator: number, lineItem) => lineItem.quantity + accumulator,
    0,
  );
};
