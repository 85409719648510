<template>
  <label class="lg:focus-within:custom-focus-outline relative flex text-base">
    <select
      v-if="options?.length > 0"
      :id="`select-${attrs.name}`"
      :autocomplete="attrs.autocomplete"
      :disabled="disabled"
      :name="attrs.name"
      :required="attrs.required"
      :value="modelValue"
      class="absolute inset-0 opacity-0"
      @change="onInput($event.target.value)"
    >
      <option ref="firstOptionRef" :label="label ?? ' '" disabled selected value="">
        {{ label ?? "" }}
      </option>

      <option
        v-for="{ value, label: optionLabel, disabled: optionDisabled } in options"
        :key="value"
        ref="optionRefs"
        :disabled="optionDisabled"
        :label="optionLabel ?? value"
        :selected="value === modelValue"
        :value="value"
      >
        {{ optionLabel ?? value ?? "" }}
      </option>
    </select>

    <span class="text-link pointer-events-none flex items-center gap-1 no-underline">
      <slot>{{ label }}</slot>
      <SvgIcon class="h-4 w-4 -rotate-180" name="Arrow-Up"></SvgIcon>
    </span>
  </label>
</template>

<script setup>
import SvgIcon from "~/templates/elements/SvgIcon.vue";
defineProps(["modelValue", "options", "label", "errorMessage", "disabled"]);
const attrs = useAttrs();
const emit = defineEmits(["update:modelValue"]);

const onInput = (value) => {
  emit("update:modelValue", value);
};

const firstOptionRef = ref(null);
const optionRefs = ref([]);
const reset = () => {
  optionRefs.value.forEach((option) => {
    option.selected = false;
  });
  firstOptionRef.value.selected = true;
  emit("update:modelValue", "");
};
defineExpose({ reset });
</script>

<style scoped>
select {
  @apply appearance-none;
}
[readonly],
[disabled] {
  @apply cursor-not-allowed opacity-60 grayscale;
}
</style>
