<template>
  <div
    class="grid grid-cols-[2fr_1fr] gap-2 rounded-lg text-sm xl:bg-light-grey xl:p-8 xl:text-base"
  >
    <HeadlineComponent
      class="col-span-2 mb-4 text-base font-medium text-gray-900 xl:text-2xl"
      tag="h2"
    >
      <CommonLabel path="BWF/shop/checkout/cart/summary" />
    </HeadlineComponent>

    <p><CommonLabel path="BWF/shop/checkout/cart/subtotal" /></p>
    <SharedPrice
      :value="subTotalWithoutPromotion"
      class="place-self-end font-medium"
      has-asterisk
    />

    <template v-for="promoLineItem in promoLineItems" :key="promoLineItem.id">
      <p>
        <CommonLabel path="BWF/shop/checkout/cart/voucher" />
        <span class="break-all">&nbsp;{{ promoLineItem.payload?.code }}</span>
      </p>

      <div class="flex flex-col items-end">
        <SharedPrice
          :value="`-${promoLineItem.extensions?.promotionDetails?.absoluteDiscount ?? 0}`"
          class="font-medium text-signal-red"
        />
        <button
          class="text-link text-right text-black"
          type="button"
          @click="removeItem(promoLineItem)"
        >
          <CommonLabel class="mb-2 block text-xs" path="BWF/shop/checkout/cart/removeVoucher" />
        </button>
      </div>
    </template>
    <p>
      <CommonLabel path="BWF/shop/checkout/cart/shippingCosts" />
    </p>
    <SharedPrice :value="shippingTotal" class="place-self-end font-medium" has-asterisk />
    <hr class="col-span-full my-4 bg-medium-grey" />
    <p class="font-medium">
      <CommonLabel path="BWF/shop/checkout/cart/total" />
    </p>
    <SharedPrice :value="totalPrice" class="place-self-end font-medium" />
    <div class="col-span-full grid gap-1 text-xs text-medium-grey">
      <CommonLabel path="BWF/shop/checkout/plusTax" />
    </div>
    <hr class="col-span-full my-4 bg-medium-grey" />
    <CheckoutPromotionCode class="col-span-full" />
  </div>
</template>

<script setup>
import { getPromotiontLineItems } from "~/utils/helper/shop/cart";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import HeadlineComponent from "~/templates/components/cms/HeadlineComponent.vue";
import SharedPrice from "~/templates/components/shop/shared/SharedPrice.vue";
const { subtotal, totalPrice, shippingTotal, cart, cartItems, removeItem } = useCart();

const promoLineItems = computed(() => getPromotiontLineItems(cartItems.value ?? []));
const subTotalWithoutPromotion = computed(() => {
  // has promo extension ???

  return cart?.value?.extensions?.priceWithoutPromotion?.price ?? subtotal.value ?? 0;
});
</script>
