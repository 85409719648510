<template>
  <div
    v-if="productSearchResult || filteredProducts?.length > 0"
    class="relative flex flex-col gap-4"
  >
    <div class="grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-6">
      <PregProductCard
        v-if="productSearchResult"
        :registration-product="productSearchResult"
        :is-product-selected="productSearchResult.id === selectedProductId"
        @click="onProductClick(productSearchResult)"
      />
      <div
        v-else-if="filteredProducts"
        class="col-span-full grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-6"
      >
        <div class="col-span-full grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-6">
          <PregProductCard
            v-for="(product, key) in filteredProducts"
            :key="key"
            :registration-product="product"
            :is-product-selected="product.id === selectedProductId"
            @click="onProductClick(product)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useProductRegistration } from "~/composables/shop/useProductRegistration";
import { computed } from "vue";
const props = defineProps(["categoryId", "productSearchResult"]);
const { allProducts } = await useProductRegistration();

const filteredProducts = computed(() => {
  return allProducts.value?.filter((product) => product.categoryIds.includes(props.categoryId));
});

const emit = defineEmits(["selected-product"]);

const selectedProductId = ref("");
const onProductClick = (registrationProduct) => {
  if (selectedProductId.value === registrationProduct.id) {
    selectedProductId.value = "";
    emit("selected-product", "");
  } else {
    // TODO find out it makes sense to send along productid
    emit("selected-product", registrationProduct);
    selectedProductId.value = registrationProduct.id;
  }
};
</script>
