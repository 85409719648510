import { createSharedComposable } from "@vueuse/core";
import { cleanupFormData } from "~/utils/helper/form";

const britaMiddleware = function () {
  const { newsletterSoiTracking } = useNewsletterTracking();
  const {
    public: {
      middleware: { apiKey, api },
    },
  } = useRuntimeConfig();

  const callApi = async ({ method = "", httpMethod = "POST", headers = {}, body }) => {
    return await $fetch(method, {
      baseURL: api,
      server: false,
      method: httpMethod,
      headers: {
        ...(api.includes("api/brita-middleware") ? {} : { "X-Api-Key": apiKey }),
        ...headers,
      },
      ...(body ? { body } : {}),
    });
  };

  const getDoiState = (email) => callApi({ method: "newsletter/doistate", body: { email } });

  const doiConfirmation = (doiToken) =>
    callApi({ method: "newsletter/doiconfirmation", body: { doiToken } });

  const addCustomerInformation = (information = {}) =>
    callApi({
      method: "newsletter/addcustomerinformation",
      body: { ...cleanupFormData(information), middlename: information?.middlename ?? "" },
    });

  const register = async (email, interactionId, actionId, middlename = "") => {
    const response = await callApi({
      method: "newsletter/registration",
      body: {
        email,
        interactionId: parseInt(interactionId),
        ...(actionId ? { actionId: parseInt(actionId) } : {}),
        middlename,
      },
    });
    newsletterSoiTracking();
    return response;
  };

  const unsubscribe = (payload) => callApi({ method: "newsletter/unsubscribe", body: payload });

  const contactForm = async (formData) =>
    callApi({
      method: "contactform/submit",
      body: { ...cleanupFormData(formData), middlename: formData?.middlename ?? "" },
    });

  const createRegistration = async (formData) =>
    callApi({
      method: "bwf/productregistration/create",
      body: { ...formData },
    });

  const deleteRegistration = async (formData) =>
    callApi({
      method: "bwf/productregistration/delete",
      body: { ...formData },
    });

  const retrieveRegistration = async (formData) =>
    callApi({
      method: "bwf/productregistration/retrieve",
      body: { ...formData },
    });

  const requestUpload = async (formData) =>
    callApi({ method: "contactform/requestupload", body: formData });

  const uploadFile = async (url, body, headers) =>
    $fetch(url, {
      server: false,
      method: "PUT",
      headers: { ...headers },
      ...(body ? { body } : {}),
    });

  const warrantyReturn = async (formData) =>
    callApi({ method: "contactform/submitwarranty", body: formData });

  return {
    createRegistration,
    deleteRegistration,
    retrieveRegistration,
    getDoiState,
    doiConfirmation,
    register,
    unsubscribe,
    addCustomerInformation,
    contactForm,
    warrantyReturn,
    requestUpload,
    uploadFile,
  };
};
const useBritaMiddleware = createSharedComposable(britaMiddleware);
export default useBritaMiddleware;
