<template>
  <div
    class="product-card relative flex flex-col justify-between gap-2 bg-white"
    data-testid="product-box"
  >
    <figure class="relative flex flex-col gap-4">
      <div class="relative">
        <div
          class="safari-overflow-hidden-fix relative aspect-[166/198] overflow-hidden rounded-lg bg-light-grey xl:rounded-xl"
        >
          <LoadingElement v-if="productLoading" :small="true" class="absolute"> </LoadingElement>
          <ProductImage
            v-else
            :class="{ 'opacity-50': !product?.available }"
            :product-image-loading-error="productImageLoadingError"
            :product-media="product?.cover?.media"
            :product-name="productName"
            fallback-class="absolute left-1/2 -translate-x-1/2 w-1/2 h-full object-contain"
            height-fallback="24"
            height-image="300"
            loading="lazy"
            main-class="absolute inset-0 w-full h-full object-contain"
            src-set-until="800"
            width-fallback="115"
            width-image="200"
            @product-image-error="productImageLoadingError = true"
          >
          </ProductImage>
        </div>
        <ProductBadge class="absolute bottom-0 left-0" />
      </div>
      <figcaption>
        <div v-if="product?.manufacturer?.name" class="text-sm font-medium">
          {{ product?.manufacturer?.name }}
        </div>
        <component
          :is="isEditMode() ? 'div' : NuxtLink"
          :title="productName"
          :to="pdpUrl"
          class="stretched-link block hyphens-auto"
          data-testid="product-box-product-name-link"
          @click="trackItemSelect"
        >
          {{ productName }}
        </component>
        <span v-if="product?.unit?.name" class="text-medium-grey">
          <span v-if="product?.packUnit">{{ product?.packUnit }}&nbsp;</span>
          <span v-else>{{ product?.purchaseUnit }}&nbsp;{{ product?.unit?.shortCode }}&nbsp;</span
          >({{
            getIntlFormattedPrice(product?.calculatedPrice.referencePrice?.price)
          }}&nbsp;/&nbsp;{{ product?.calculatedPrice.referencePrice?.referenceUnit }}&nbsp;{{
            product?.unit?.shortCode
          }})</span
        >
        <div v-if="firstTextOption && !hasColor && !containsExchangeBox">
          {{ firstTextOption?.name }}
        </div>
        <ProductCardPrice />
      </figcaption>
    </figure>
    <ProductVariantConfigurator
      v-if="hasColor"
      :colors-only="true"
      @change="onChange"
      @before-change="productLoading = true"
    />
    <AddToCartButton
      v-if="directToCart"
      :product="product"
      class="btn btn-white mt-2 border-2 border-black"
    />
  </div>
</template>

<script setup lang="ts">
import { useProductSearch } from "@shopware-pwa/composables-next";
import { getProductName } from "@shopware-pwa/helpers-next";
import type { Product, PropertyGroup } from "@shopware-pwa/types";
import ProductImage from "~/templates/elements/ProductImage.vue";
import { isEditMode } from "~/utils/content/magnolia";
import LoadingElement from "~/templates/elements/LoadingElement.vue";
import { getFirstTextOption, PRODUCT_CARD_ASSOCIATIONS } from "~/utils/helper/shop/product";
import { useProductEShopSetConfigurator } from "~/composables/shop/useProductEShopSetConfigurator";
import NuxtLink from "#app/components/nuxt-link";
import { usePrice } from "~/composables/shop/usePrice";

import { useExchangeBox } from "~/composables/shop/useExchangeBoxProduct";
import useItemTracking from "~/composables/tracking/useItemTracking";
import AddToCartButton from "~/templates/partials/header/AddToCartButton.vue";

const { getIntlFormattedPrice } = usePrice();
const props = defineProps<{
  product?: Product;
  configurator?: PropertyGroup[];
}>();

const directToCart = inject("directToCart", false);

const { product, changeVariant, configurator } = useProduct(
  props.product,
  props.configurator ?? ref([]),
);

const { search } = useProductSearch();
//
const productImageLoadingError = ref(false);
const productLoading = ref(false);

const onChange = async (changedProduct: Product) => {
  const { data: productResponse } = await useAsyncData("product" + changedProduct.id, async () => {
    return await search(changedProduct?.id ?? "", PRODUCT_CARD_ASSOCIATIONS);
  });

  productImageLoadingError.value = false;
  changeVariant(productResponse.value?.product as Product);
  productLoading.value = false;
};

const firstTextOption = getFirstTextOption(product?.value);

const hasColor = computed(() => {
  return (
    configurator?.value?.findIndex(
      (group) => (group.displayType === "color" && (group.options ?? []).length > 1) ?? -1,
    ) > -1
  );
});

const { eShopSet, hasEShopSet } = await useProductEShopSetConfigurator();
const { trackItemSelect } = useItemTracking({ item: product, hasEShopSet, eShopSet });

// init exchangebox and set configurator after retrieving the product
const { containsExchangeBox } = await useExchangeBox();
const { getPdpUrlByProduct } = useMagnoliaShopPages();
const pdpUrl = computed(() => {
  return getPdpUrlByProduct(product.value);
});

const productName = computed(() => getProductName({ product: product.value }));
</script>
