<template>
  <component
    :is="isEditMode() ? 'div' : NuxtLink"
    :to="pdpUrl"
    class="btn btn-blue btn-size-small xl:btn-size-normal mt-6 self-start"
    @click="trackItemSelect"
  >
    <CommonLabel path="BWF/shop/shared/learnMore"></CommonLabel>
  </component>
</template>

<script setup>
import CommonLabel from "~/templates/components/CommonLabel.vue";
import NuxtLink from "#app/components/nuxt-link";
import { isEditMode } from "~/utils/content/magnolia";
const props = defineProps(["pdpUrl", "product"]);

const item = toRef(props.product);
const { trackItemSelect } = useItemTracking({ item });
</script>
