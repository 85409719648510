<template>
  <div>
    <pre v-if="isEditMode" class="editmode-info-box">{{ code }}</pre>
    <div v-else v-html="code"></div>
  </div>
</template>

<script>
import { editableComponent } from "~/utils/mixins/editableComponent";

export default {
  name: "CodeComponent",
  mixins: [editableComponent],
  props: ["code"],
};
</script>
