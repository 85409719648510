<template>
  <AccountGrid>
    <OrderWarrantyForm
      :warranty-claim-area="warrantyClaimArea"
      :is-loading="isLoading"
      @warranty-confirm-modal="confirmModal.open()"
    >
      <template #loading
        ><LoadingElement v-if="isLoading" overlay="true" class="bg-white/70"></LoadingElement>
      </template>
    </OrderWarrantyForm>

    <EditableArea :content="warrantyClaimArea" :custom-view="EmptyArea"></EditableArea>

    <EditableArea :content="confirmModalArea" :custom-view="EmptyArea" />
    <ModalComponent :content="confirmModalArea" :controller="confirmModal">
      <template #headline>Kundenservice wurde kontaktiert *</template>
      <template #richText>
        Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut.
      </template>
      <template #primaryAction>
        <button class="btn btn-blue w-full min-w-fit" @click="push(marketSetup.startPage)">
          <CommonLabel path="BWF/shop/account/orderCancel/modal/shopButton"></CommonLabel>
        </button>
      </template>
      <template #secondaryAction>
        <button
          class="btn btn-ghost w-full min-w-fit"
          @click="push(marketSetupAdmin.myBritaOrderPage)"
        >
          <CommonLabel path="BWF/shop/account/orderCancel/modal/overviewButton"> </CommonLabel>
        </button>
      </template>
    </ModalComponent>
  </AccountGrid>
</template>

<script setup>
import AccountGrid from "~/templates/components/account/AccountGrid.vue";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import ModalComponent from "~/templates/components/ModalComponent.vue";
import EmptyArea from "~/templates/areas/EmptyArea.vue";
import LoadingElement from "~/templates/elements/LoadingElement.vue";
import useMagnoliaContent from "~/composables/useMagnoliaContent";
import { EditableArea } from "@magnolia/vue-editor";
import { isEditMode } from "~/utils/content/magnolia";
import { ORDER_DETAILS_ASSOCIATIONS } from "~/utils/helper/shop/order";
import OrderWarrantyForm from "~/templates/components/shop/account/OrderWarrantyForm.vue";

defineOptions({
  inheritAttrs: false,
});
defineProps([
  "title",
  "description",
  "metadata",
  "confirmModalArea",
  "warrantyClaimArea",
  "bottom",
]);
const { pathVariable } = useMagnoliaContent();
 
const orderId = pathVariable?.value?.replaceAll("/", "");
const { order, loadOrderDetails } = useOrderDetails(orderId, ORDER_DETAILS_ASSOCIATIONS);

const { push } = useRouter();
const { marketSetup, marketSetupAdmin } = useMarketSetup();

const isLoading = ref(true);

const confirmModal = useModal();

watch(confirmModal.isOpen, (state) => {
  if (!state) {
    // means user cancelled modal and dismissed modal -> order details page
    push(`${marketSetupAdmin.value?.myBritaOrderDetailsPage}/${order?.value?.id}`);
  }
});

onMounted(async () => {
  if (!isEditMode()) {
    await loadOrderDetails();
    isLoading.value = false;
  }
});
</script>
