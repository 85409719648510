<template>
  <slot v-if="isPreview" />
  <div v-else class="flex h-full min-h-screen w-full flex-col items-center">
    <div
      v-if="isDesktop"
      class="fixed z-40 bg-black bg-opacity-75 opacity-0 transition-opacity"
      :class="{
        'opacity-100': isMenuOpen,
        invisible: !isMenuOpen,
        'pointer-events-none': !isMenuOpen,
        'inset-0': isMenuOpen,
      }"
    ></div>

    <PageHeader class="print:hidden"></PageHeader>
    <main id="main" class="w-full flex-1 overflow-x-clip" role="main">
      <PageNotifications></PageNotifications>
      <slot />
    </main>
    <PageFooter></PageFooter>
  </div>
</template>

<script setup>
import PageHeader from "~/templates/partials/PageHeader";
import PageFooter from "~/templates/partials/PageFooter";
import PageNotifications from "~/templates/partials/PageNotifications.vue";
import { useNotifications } from "@shopware-pwa/composables-next";
import { isComponentPreview } from "~/utils/content/magnolia";
import useMainNavigationState from "~/composables/useMainNavigationState";

const isPreview = isComponentPreview();

const { isDesktop } = useDevice();
const { isMenuOpen } = useMainNavigationState();

useNotifications();
</script>
