<template>
  <section class="border-grey flex flex-col rounded-lg border px-4 py-6 xl:px-6">
    <h3 class="text-lg font-medium">
      <CommonLabel path="BWF/shop/account/profile/accessData"></CommonLabel>
    </h3>

    <div class="mt-4">
      <CommonLabel path="BWF/shop/shared/emailAddress"></CommonLabel>
      <span>&nbsp;{{ user?.email }}</span>
      <div v-if="azureUser?.username !== user?.email" class="text-medium-grey">
        <CommonLabel path="BWF/shop/shared/emailAddressUpdateInProgress" />
        {{ azureUser?.username }}
      </div>
    </div>
    <div class="mt-6 flex flex-col gap-4 xl:flex-row">
      <button class="btn btn-blue btn-size-sm xl:btn-size-normal" @click="onChangeEmail">
        <CommonLabel path="BWF/shop/account/profile/changeEmailAddress"></CommonLabel>
      </button>

      <button class="btn btn-blue btn-size-sm xl:btn-size-normal" @click="onChangePassword">
        <CommonLabel path="BWF/shop/account/profile/changePassword"></CommonLabel>
      </button>
    </div>
  </section>
</template>

<script setup lang="ts">
import useAccountTracking from "~/composables/tracking/useAccountTracking";

const { trackAccountModification } = useAccountTracking();

const { $msal } = useNuxtApp();
import CommonLabel from "~/templates/components/CommonLabel.vue";

const { azureUser } = useAzureUser();
const { user } = useUser();

const onChangePassword = () => {
  trackAccountModification("modify password");
  $msal.passwordChange();
};
const onChangeEmail = () => {
  trackAccountModification("modify email");
  $msal.emailChange();
};
</script>
