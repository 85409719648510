<template>
  <ProductImage
    :product-media="visibleLineItem"
    :product-name="lineItem?.label"
    fallback-class="product-image flex-shrink-0 w-20 object-contain object-center aspect-[74/100] rounded-lg bg-light-grey p-2"
    main-class="product-image w-20 flex-shrink-0 object-contain object-center aspect-[74/100] rounded-lg bg-light-grey"
    src-set-until="800"
  ></ProductImage>
</template>

<script setup lang="ts">
import type { OrderLineItem as OrderLineItemType } from "@shopware-pwa/types";
import ProductImage from "~/templates/elements/ProductImage.vue";

const props = defineProps<{
  lineItem?: OrderLineItemType;
}>();

/*
 lookup the first item that is not supposed to be hidden
 * */
const visibleLineItem = computed(() => {
  if (props.lineItem?.extensions?.brita_eshop_set_hide_main_product_image?.value) {
    // if main image shpuld be hidden show the first child that is not a hidden product
    return props.lineItem?.children?.find(
      (child, index) => index > 0 && !child?.customFields?.eshopSet?.eshopSetProductIsHidden,
    )?.cover;
  }
  return props.lineItem?.cover ?? props.lineItem?.children?.[0]?.cover;
});
</script>
