import { computed, type ComputedRef, type Ref, watch } from "vue";
import { invokePost } from "@shopware-pwa/api-client";

import type {
  EshopSet,
  EshopSetConfigurator,
  EshopSetConfiguratorStep,
  EshopSetConfiguratorStepProduct,
} from "./eShopSetConfiguratorTypes";
import type { Cart } from "@shopware-pwa/types";
import { _useContext } from "~/composables/shop/_useContext";

export type UseProductEShopSetConfigurator = {
  eShopSet: ComputedRef<EshopSet>;
  eshopSetConfigurator: ComputedRef<EshopSetConfigurator>;
  eshopSetConfiguratorSteps: ComputedRef<EshopSetConfiguratorStep[]>;
  eShopSetCurrentSelection: ComputedRef<(string | undefined)[]>;
  /**
   * Indicates if the product contains eShopSet key
   */
  hasEShopSet: ComputedRef<boolean>;
  eshopSetAvailable: ComputedRef<boolean>;
  hasHiddenMainProduct: ComputedRef<boolean>;
  hasBestPrice: ComputedRef<boolean>;

  /**
   * Indicates if the eShopSet is being updated
   */
  isLoadingUpdatedEShopSet: ComputedRef<boolean>;

  /**
   * Handler for action when the selected eShopSet option is changed
   * */
  handleChange(
    groupIndex: number,
    selectedProductId: string,
    onChangeHandled?: () => void,
  ): Promise<void>;
  updateEShopSet(initialSelection?: string[] | null): Promise<void>;
  addEShopSetToCart(): Promise<Cart | undefined>;
};

export async function useProductEShopSetConfigurator(
  options?: string[],
): Promise<UseProductEShopSetConfigurator> {
  const { apiInstance } = useShopwareContext();
  const { refreshCart } = useCart();

  const { product } = useProduct();
  // @ts-ignore
  const _hasEShopSet = computed(() => !!product.value.extensions?.foreignKeys?.eshopSetId);

  const _eShopSet: Ref<EshopSet> = _useContext("eShopSet");
  const _eShopSetIsLoading: Ref<boolean> = _useContext("eShopSetIsLoading");

  const eshopSetConfigurator = computed(() => _eShopSet?.value?.eshopSetConfigurator);
  const eshopSetConfiguratorSteps = computed(
    () => _eShopSet?.value?.eshopSetConfigurator?.eshopSetConfiguratorSteps,
  );
  const eShopSetCurrentSelection = computed(
    () =>
      eshopSetConfiguratorSteps?.value?.map((step: EshopSetConfiguratorStep) => {
        return step.eshopSetConfiguratorStepProducts?.find(
          (product: EshopSetConfiguratorStepProduct) => product?.selected,
        )?.id;
      }) ?? [],
  );

  const addEShopSetToCart = async () => {
    const selectedProducts = JSON.stringify(eShopSetCurrentSelection?.value ?? []);

    try {
      if (apiInstance) {
        apiInstance.defaults.headers.common["sw-include-seo-urls"] = "true";
      }
      const { data } = await invokePost(
        {
          address: `/store-api/eshopset/addtocart`,
          payload: { productId: product.value.id, selectedProducts },
        },
        apiInstance,
      );
      await refreshCart(data as Cart);
      return data as Cart;
    } catch (e) {
      console.error("SwProductDetails:getEShopSetForProduct", e);
    }
  };
  const updateEShopSet = async (selection?: (string | undefined)[] | null) => {
    const selectedProducts = selection ?? [];
    _eShopSetIsLoading.value = true;
    try {
      if (apiInstance) {
        apiInstance.defaults.headers.common["sw-include-seo-urls"] = "true";
      }
      const setkey = `product-set-${product.value.id}+${selectedProducts.join(",")}`;
      const { data } = await useAsyncData(setkey, async () => {
        const { data } = await invokePost(
          {
            address: `/store-api/eshopset/select`,
            payload: {
              productId: product.value.id,
              selectedProducts,
              associations: {
                eshopSetConfigurator: {
                  associations: {
                    eshopSetConfiguratorSteps: {
                      associations: {
                        eshopSetConfiguratorStepProducts: {
                          associations: {
                            product: {
                              associations: {
                                cover: {},
                                media: {},
                                options: {
                                  associations: {
                                    group: {},
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          apiInstance,
        );
        return data;
      });

      _eShopSet.value = data.value as EshopSet;
    } catch (e) {
      console.error("SwProductDetails:getEShopSetForProduct", (e as Error).message);
    }
    _eShopSetIsLoading.value = false;
  };

  const handleChange = async (
    groupIndex: number,
    selectedProductId: string,
    onChangeHandled?: () => void,
  ): Promise<void> => {
    const newSelection = [...eShopSetCurrentSelection.value];
    newSelection[groupIndex] = selectedProductId ?? "";
    await updateEShopSet(newSelection);

    if (typeof onChangeHandled === "function") {
      // run passed callback
      onChangeHandled();
    }
  };

  watch(
    () => product.value.id,
    async () => {
      //@ts-ignore
      if (_hasEShopSet.value) {
        await updateEShopSet();
      } else {
        //@ts-ignore
        _eShopSet.value = {};
      }
    },
  );

  if (!_eShopSet.value && _hasEShopSet.value) {
    // empty & hasEshopSet -> load it to init context
    await updateEShopSet(options);
  }

  return {
    eShopSet: computed(() => _eShopSet.value),
    hasEShopSet: computed(() => _hasEShopSet.value),
    eshopSetAvailable: computed(() => (_eShopSet.value?.eshopSetAvailable ?? 0) > 0),
    hasBestPrice: computed(
      () =>
        !!(
          !!_eShopSet.value?.firstDayPromoPrice &&
          _eShopSet.value?.bestEshopPriceFromHistory &&
          _eShopSet.value?.bestEshopPriceFromHistory > _eShopSet.value?.eshopSetPriceTotal
        ),
    ),
    hasHiddenMainProduct: computed(
      // @ts-ignore
      () => _eShopSet?.value?.customFields?.brita_eshop_set_hide_main_product_image ?? false,
    ),
    isLoadingUpdatedEShopSet: computed(() => _eShopSetIsLoading.value),
    eshopSetConfigurator,
    eshopSetConfiguratorSteps,
    eShopSetCurrentSelection,
    addEShopSetToCart,
    updateEShopSet,
    handleChange,
  };
}
