<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    shape-rendering="geometricPrecision"
    viewBox="0 0 100 100"
  >
    <line
      x1="0"
      y1="100"
      x2="100"
      y2="0"
      stroke="currentColor"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>
