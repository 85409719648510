<template>
  <div class="relative flex w-full flex-col gap-4 p-4 text-left lg:py-6">
    <ProductBadge :product="product" class="absolute right-4 top-0" />
    <div :class="{ '-ml-4': mainImage }" class="flex items-center gap-4">
      <div v-if="mainImage" class="w-18 shrink-0">
        <div class="aspect-square">
          <ProductImage
            :product-media="product?.cover?.media"
            main-class="object-contain w-full h-full"
          ></ProductImage>
        </div>
      </div>
      <div class="flex-1">
        <div class="flex justify-between gap-0.5">
          <h4 class="text-base font-medium leading-snug text-black">{{ title }}</h4>
          <div
            v-if="absPriceDifference && !stepProduct?.selected"
            class="inline-flex shrink-0 items-center gap-1 self-start text-sm font-normal leading-5 text-black"
          >
            <SharedPrice :value="absPriceDifference">
              <template #beforePrice>{{ priceSign }}</template>
            </SharedPrice>
          </div>
        </div>
        <div
          class="flex-none text-sm font-normal leading-5 text-black"
          v-html="productShortDescriptionEshopStep"
        />
      </div>
    </div>
    <template v-if="productLongDescriptionEshopStep">
      <hr />
      <div class="prose" v-html="productLongDescriptionEshopStep" />
    </template>
  </div>
</template>
<script setup lang="ts">
import { getSmallestThumbnailUrl, getTranslatedProperty } from "@shopware-pwa/helpers-next";
import type { EshopSetConfiguratorStepProduct } from "~/composables/shop/eShopSetConfiguratorTypes";
import type { Product } from "@shopware-pwa/types";
import { getValueSign } from "~/utils/helper/number";
import ProductImage from "~/templates/elements/ProductImage.vue";

const props = defineProps<{ stepProduct: EshopSetConfiguratorStepProduct }>();

const product = computed<Product>(() => props.stepProduct?.product as Product);
const title = computed(() => getTranslatedProperty(product.value, "name"));
const productShortDescriptionEshopStep = computed(() =>
  // @ts-ignore
  getTranslatedProperty(
    product?.value?.translated?.customFields,
    "brita_product_short_description_eshop_step",
  ),
);
const productLongDescriptionEshopStep = computed(() => props?.stepProduct?.description);
const absPriceDifference = computed(() => Math.abs(props?.stepProduct?.differencePrice ?? 0));
const priceSign = computed(() => getValueSign(props?.stepProduct?.differencePrice));
const mainImage = computed(() => getSmallestThumbnailUrl(product?.value?.cover?.media));
</script>
