<template>
  <component
    :is="isEditMode ? 'span' : 'a'"
    ref="downloadLinkRef"
    :href="href"
    :title="title"
    class="text-link flex items-end gap-2 no-underline"
    download
    target="_self"
    @click="trackDownload({ link: trackData, ref: downloadLinkRef, label: title })"
  >
    <span class="truncate leading-none underline">
      {{ title }}
    </span>
    <SvgIcon class="h-5 w-5" name="Download"></SvgIcon>
    <span class="ml-2 text-sm font-normal leading-none text-black">{{ size }}KB</span>
  </component>
</template>

<script setup>
import SvgIcon from "~/templates/elements/SvgIcon.vue";
import useDownloadTracking from "~/composables/tracking/useDownloadTracking";
import { isEditMode as evaluateEditMode } from "~/utils/content/magnolia";
const props = defineProps(["download", "label", "link"]);
const isEditMode = evaluateEditMode();
const downloadLinkRef = ref(null);
const trackData = props?.download ?? props?.link;
const { trackDownload } = useDownloadTracking();

const isDownloadAppOption = computed(() => {
  return props?.link?.type === "downloadApp";
});

const isDownloadOption = computed(() => {
  return props?.link?.type === "download";
});

const fileReference = computed(() => {
  if (props?.download?.["download_file"]) {
    // direct download passed
    return props?.download?.["download_file"];
  }
  if (isDownloadAppOption?.value) {
    // Download App Link chosen
    return props?.link?.download?.["download_file"];
  } else {
    // normal asset download
    return props?.link?.asset;
  }
});

const title = computed(() => {
  if (props?.label) {
    // explicit label
    return props?.label;
  }
  if (props?.download?.title) {
    // props download
    return props?.download?.title;
  }

  if (isDownloadAppOption?.value) {
    return (
      props.link?.download?.title ??
      props.link.asset?.["@name"] ??
      props.link?.download?.["download_file"]?.["@name"]
    );
  } else if (isDownloadOption?.value && props.link?.asset?.["@name"]) {
    return props.link?.asset?.["@name"];
  } else {
    return "Download";
  }
});

const href = computed(() => {
  return fileReference?.value?.["@link"] ?? "";
});

const size = computed(() => {
  return Math.round((fileReference?.value?.metadata?.fileSize ?? 0) / 1024);
});
</script>
