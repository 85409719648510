<template>
  <div>
    <div class="flex items-end gap-3">
      <div v-if="activeBulkChild?.totalPrice" class="flex font-medium leading-snug">
        <SharedPrice :value="activeBulkChild?.totalPrice"></SharedPrice>&nbsp;<CommonLabel
          class="content-evenly text-xs"
          path="BWF/shop/checkout/plusTax"
        />
      </div>

      <div
        v-if="activeBulkChild?.totalPriceWithoutDiscount !== activeBulkChild?.totalPrice"
        class="flex gap-x-4 font-normal leading-snug"
      >
        <SharedPrice
          :value="activeBulkChild?.totalPriceWithoutDiscount"
          class="items-center text-medium-grey line-through"
        />
        <DiscountRateLabel
          v-if="!isMobile && activeBulkChild?.displayDiscountRate"
          :discount-rate="activeBulkChild?.displayDiscountRate"
        />
      </div>
    </div>
    <div class="mt-1 flex text-xxxs font-normal leading-tight text-medium-grey lg:text-sm">
      <SharedPrice :value="activeBulkChild?.unitPrice"></SharedPrice>&nbsp;
      <CommonLabel path="BWF/shop/shared/singleUnitPrice" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Product } from "@shopware-pwa/types";
import CommonLabel from "~/templates/components/CommonLabel.vue";
import DiscountRateLabel from "~/templates/elements/DiscountRateLabel.vue";
const { isMobile } = useDevice();

defineProps<{
  product: Product;
  activeBulkChild: ProductBulkPrice;
}>();
</script>
